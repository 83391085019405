import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components';
import { newJobSchema } from 'Schemas/job-schema';
import { createPosition } from 'Api/job-service';
import { generateFormFields } from 'Helpers';

const AddJobModal = ({ showModal, setShowModal, orgId, refresh }) => {
  const history = useHistory();
  const [addJobForm] = Form.useForm();

  const jobSchema = orgId
    ? (() => {
        const tempSchema = [...newJobSchema];
        const indexToUpdate = tempSchema.findIndex((field) => field.name === 'organization_id');
        addJobForm.setFieldsValue({
          organization_id: orgId,
        });
        tempSchema[indexToUpdate]['span'] = 0;
        return tempSchema;
      })()
    : newJobSchema;

  const formatValuesForBe = () => {
    const { target_start_date, ...rest } = addJobForm.getFieldsValue();
    return {
      ...rest,
      target_start_date: target_start_date
        ? target_start_date.format('YYYY-MM-DD')
        : target_start_date,
    };
  };

  const addNewJob = async (redirect = true) => {
    try {
      const jobObj = formatValuesForBe();

      const {
        data: { id },
      } = await createPosition(jobObj);
      redirect && history.push(`/job/${id}`);
      if (typeof refresh === 'function') {
        refresh();
      }
      message.success('Job created successfully', 1);
      message.loading('Setting up profile', 2);
      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title="Add Job"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form form={addJobForm} onFinish={addNewJob} layout="vertical" preserve={false}>
        <Row gutter={[24]}>{generateFormFields(jobSchema)}</Row>
        <Row justify="space-between" style={{ marginTop: 15 }}>
          <Button type="link" onClick={async () => addNewJob(false)}>
            Save & add more info later
          </Button>
          <Space>
            <Button type="primary" htmlType="submit">
              Add more info
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddJobModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  orgId: PropTypes.number,
  refresh: PropTypes.func,
  existingVal: PropTypes.array,
};

export default AddJobModal;
