import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Space, message, Popconfirm, Select, Col } from 'antd';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Table, PreLoader, Button, Input } from 'Components';
import { isEmpty, cloneDeep, remove } from 'lodash';
import { useDebounce } from 'Helpers/hooks';
import { generateSelectOptions, pullAndSetViews } from 'Helpers';
import { updateView, createView } from 'Api/views-service';
import { quickSearchWithinView } from 'Api/common-service';
import { useStoreValue } from 'Context';
import { EmptyOperators } from 'Constants';
import AddColumnsModal from './add-columns-modal';

const defaultPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showQuickJumper: true,
};

const FeatureTable = ({
  entity,
  getList,
  currentView = {},
  setCurrentView,
  views,
  setViews,
  columns = [],
  enableCherryPick = true,
  enableQuickSearch = false,
  setDefaultViewId,
  setPortfolioKanbanData = null,
  opts = {},
  ...rest
}) => {
  // table states
  const [pagination, setPagination] = useState(defaultPagination);
  const [isLoading, setIsLoading] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [columnsConfig, setColumnsConfig] = useState([]);
  const [sorterMeta, setSorterMeta] = useState([]);
  // cherry pick states
  const [selectedRows, setSelectedRows] = useState([]);
  const [cherryPickMode, setCherryPickMode] = useState('unset');
  const [cherryPickView, setCherryPickView] = useState({});
  const [cherryPickIds, setCherryPickIds] = useState([]);
  const [newViewName, setNewViewName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [quickSearchText, setQuickSearchText] = useState();
  const [quickSearchResults, setQuickSearchResults] = useState();

  const [{ meta, user }] = useStoreValue();
  const userId = user.id;

  useEffect(() => {
    if (!currentView?.filters) return;
    const cherryPicks = currentView?.filters.find(({ field }) => field === 'cherry_picks');
    const cherryPickIds = cherryPicks ? cherryPicks.constraints.map(({ value }) => value) : [];
    setCherryPickIds(cherryPickIds);
    debouncedEntitySearch();
    return debouncedEntitySearch.cancel;
    // comparison by value happens only for primitive values (string,numbers,bool)
    // else comparison will be by reference which leads to multiple calls
    // even if value has'nt change. Hence JSON stringyfy below to negate effect of ref comp
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentView.filters)]);

  useEffect(() => {
    if (!isEmpty(meta)) setSorterMetaInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  useEffect(() => {
    let currentColumns = [];
    const { fields_to_show } = currentView;
    // if a view has saved columns filter that
    if (fields_to_show?.length) {
      currentColumns = columns.filter(({ dataIndex }) => fields_to_show.includes(dataIndex));
    } else {
      let columnsToShow = columns.filter(({ isDefault }) => isDefault);
      // if there are no default columns show all the columns
      currentColumns = [...(columnsToShow.length ? columnsToShow : columns)];
    }
    const sorterMetaHash = sorterMeta.reduce(function (acc, cur) {
      acc[cur] = true;
      return acc;
    }, {});
    // Update columns with sorter config
    const updatedSortInfoCols = currentColumns.map((column) => {
      return { ...column, sorter: !!sorterMetaHash[column.key] };
    });
    const updatedPickInfoCols = enableCherryPick
      ? [...updatedSortInfoCols, removePickConfig]
      : updatedSortInfoCols;
    setColumnsConfig(updatedPickInfoCols);
  }, [sorterMeta, cherryPickIds, currentView.fields_to_show]);

  useEffect(() => {
    setCherryPickMode('unset');
  }, [selectedRows]);

  useEffect(() => {
    if (!quickSearchText) return;
    debouncedQuickSearch();
    return debouncedQuickSearch.cancel;
  }, [quickSearchText]);

  const debouncedEntitySearch = useDebounce(() => getEntityList(), 500, currentView?.filters);
  const debouncedQuickSearch = useDebounce(() => getQuickSearchResults(), 500, quickSearchText);

  const setSorterMetaInfo = async () => {
    let { sort_options = {} } = meta;
    const entitySorterMeta = sort_options[entity];
    if (entitySorterMeta) setSorterMeta(entitySorterMeta);
  };

  const getQuickSearchResults = async () => {
    let { data } = await quickSearchWithinView(quickSearchText, currentView.id);
    if (entity === 'user') {
      data = data.map(({ custom_fields, ...rest }) => {
        return { ...rest, ...custom_fields };
      });
    }
    setQuickSearchResults(data);
  };

  const addSelectionsToView = async () => {
    try {
      const viewToUpdate = views.find(({ id }) => id === cherryPickView);
      // check if cherry picks exists
      const existingPicksFieldIndex = viewToUpdate.filters.findIndex(
        ({ field }) => field === 'cherry_picks'
      );
      let updatedFilters = [];
      if (existingPicksFieldIndex !== -1) {
        let { constraints } = viewToUpdate.filters[existingPicksFieldIndex];
        let updatedConstraints = [...constraints.map(({ value }) => value), ...selectedRows].map(
          (x) => {
            return { operator: 'is', value: x };
          }
        );
        viewToUpdate.filters[existingPicksFieldIndex].constraints = updatedConstraints;
        updatedFilters = viewToUpdate.filters;
      } else {
        updatedFilters = [
          ...viewToUpdate.filters,
          {
            field: 'cherry_picks',
            constraints: selectedRows.map((x) => {
              return { operator: 'is', value: x };
            }),
          },
        ];
      }
      const updatedView = { ...viewToUpdate, filters: updatedFilters };
      await updateView(updatedView);
      message.info('View updated', 5);
      setSelectedRows([]);
      setCherryPickMode('unset');
      pullLatestViews();
    } catch (err) {
      console.log(err);
    }
  };

  const pullLatestViews = async () => {
    return await pullAndSetViews(entity, user.id, setViews);
  };

  const removeCherryPicks = async (recordId) => {
    try {
      let viewToUpdate = cloneDeep(currentView);
      let indexToUpdate = viewToUpdate.filters.findIndex((item) => item.field === 'cherry_picks');
      remove(
        viewToUpdate.filters[indexToUpdate].constraints,
        (constraint) => constraint.value === recordId
      );
      // if all cherry pick is removed then remove the field from the filter
      if (!viewToUpdate.filters[indexToUpdate]?.constraints?.length) {
        remove(viewToUpdate.filters, (item) => item.field === 'cherry_picks');
      }
      await updateView(viewToUpdate);
      message.success('View updated');
      pullLatestViews();
    } catch (err) {
      message.error(`${err.message}, Kindly refresh`);
    }
  };

  const createCherryPickView = async () => {
    try {
      const { data } = await createView({
        entity_type: entity,
        filters: [
          {
            field: 'cherry_picks',
            constraints: selectedRows.map((x) => {
              return { operator: 'is', value: x };
            }),
          },
        ],
        name: newViewName,
      });
      await pullLatestViews();
      const { id } = data.data;
      setDefaultViewId(id);
      setSelectedRows([]);
    } catch (err) {
      message.error(`${err.message}, Kindly refresh`);
    }
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { columnKey: sort_by, order: sort_dir } = sorter;
    if (sort_by && sort_dir) {
      return getEntityList(pagination, {
        sort_by,
        sort_dir: sort_dir?.replace('end', ''),
      });
    }
    getEntityList(pagination);
  };

  const removePickConfig = {
    title: 'Pick',
    key: 'actions',
    render: (_, record) => {
      return cherryPickIds.includes(record.id) ? (
        <Popconfirm
          title="Are you sure you want to remove this record from this view?"
          onConfirm={() => removeCherryPicks(record.id)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small">
            <CloseOutlined />
          </Button>
        </Popconfirm>
      ) : null;
    },
    width: 50,
  };

  const getEntityList = async (paginationInfo = defaultPagination, sorterInfo) => {
    try {
      setIsLoading(true);
      const { current: page_num, pageSize: page_size } = paginationInfo;
      // Remove filters with empty values
      const validFilters = currentView?.filters
        .map((f) => {
          let processedFilter = JSON.parse(JSON.stringify(f));
          processedFilter.constraints = processedFilter.constraints.filter(
            ({ operator, value }) => value || EmptyOperators.includes(operator)
          );
          return processedFilter;
        })
        .filter((f) => f.constraints.length > 0);

      let { data, headers } = await getList(
        { page_num, page_size },
        sorterInfo,
        {
          filters: validFilters,
          entity_type: entity,
        },
        opts // not all getList functions take in opts
      );
      const {
        page_num: current,
        page_size: pageSize,
        total_records: total,
      } = JSON.parse(headers['x-pagination']);
      const canEditIDList = JSON.parse(headers['x-can-edit'] ? headers['x-can-edit'] : '[]');
      const updatedPaginationInfo = { current, pageSize, total };
      setPagination(updatedPaginationInfo);
      if (entity === 'user') {
        data = data.map(({ custom_fields, ...rest }) => {
          return { ...rest, ...custom_fields };
        });
      } else if (entity === 'portfolio') {
        setPortfolioKanbanData(data);
      }

      data = data.map((record) => {
        if (record && record?.id && canEditIDList.includes(record.id)) {
          record.editable = true;
        }
        return record;
      });

      setEntityList(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateColumnsVisibility = async (fieldsToShow) => {
    let viewToUpdate = cloneDeep(currentView);
    const updatedView = { ...viewToUpdate, fields_to_show: fieldsToShow };
    await updateView(updatedView);
    setCurrentView(updatedView);
    setShowModal(false);
    message.success('View updated');
  };

  return (
    <PreLoader spinning={isLoading}>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>
          {enableCherryPick ? (
            <Row>
              {cherryPickMode === 'existing' ? (
                <Space>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentElement}
                    defaultOpen
                    autoFocus
                    showSearch
                    onChange={setCherryPickView}
                    style={{ width: 200 }}
                    placeholder="Select view"
                    optionFilterProp="children"
                  >
                    {generateSelectOptions(
                      views
                        .filter(
                          ({ id, source_user_id, permissions }) =>
                            id !== currentView.id &&
                            (source_user_id === userId || permissions?.can_public_update)
                        )
                        .map(({ name, id }) => {
                          return { display_hint_primary: name, value: id };
                        })
                    )}
                  </Select>
                  <Button onClick={addSelectionsToView} size="middle" type="primary">
                    {`Select & add`}
                  </Button>
                  <Button size="middle" onClick={() => setCherryPickMode('unset')}>
                    Cancel
                  </Button>
                </Space>
              ) : cherryPickMode === 'new' ? (
                <Space>
                  <Input
                    autoFocus
                    onChange={(event) => {
                      const { value } = event.target;
                      setNewViewName(value);
                    }}
                    size="middle"
                    value={newViewName}
                    placeholder="View Name"
                  />
                  <Button onClick={createCherryPickView} size="middle" type="primary">
                    {`Create new view & add`}
                  </Button>
                  <Button size="middle" onClick={() => setCherryPickMode('unset')}>
                    Cancel
                  </Button>
                </Space>
              ) : null}
            </Row>
          ) : null}
          {selectedRows.length > 0 && cherryPickMode === 'unset' ? (
            <Space size="middle" align="baseline">
              <h4>Add to</h4>
              <Button onClick={() => setCherryPickMode('existing')} size="middle">
                Existing View
              </Button>
              <h4>OR</h4>
              <Button onClick={() => setCherryPickMode('new')} size="middle">
                New View
              </Button>
            </Space>
          ) : null}
        </Col>
        <Row>
          <Col>
            <Button size="middle" type="link" onClick={() => setShowModal(true)}>
              Add/Hide columns
            </Button>
          </Col>
          {enableQuickSearch ? (
            <Col>
              <Input
                prefix={<SearchOutlined />}
                allowClear
                placeholder="Quick search"
                size="default"
                onChange={({ target: { value } }) => setQuickSearchText(value)}
              />
            </Col>
          ) : null}
        </Row>
      </Row>
      <Table
        size="small"
        rowKey={({ id }) => id}
        pagination={quickSearchText?.length ? null : { ...pagination, showQuickJumper: true }}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: (data) => {
            setSelectedRows(data);
          },
        }}
        onChange={handleTableChange}
        columns={columnsConfig}
        dataSource={quickSearchText?.length ? quickSearchResults : entityList}
        scroll={{ y: 'calc(100vh - 301px)', x: 1300 }}
        rowClassName={(record) => (cherryPickIds.includes(record.id) ? 'ivory-bg' : null)}
        {...rest}
      />
      <AddColumnsModal
        columns={columns}
        showModal={showModal}
        setShowModal={setShowModal}
        columnsConfig={columnsConfig}
        setColumnsConfig={setColumnsConfig}
        fieldsToShow={currentView?.fields_to_show}
        updateColumnsVisibility={updateColumnsVisibility}
      />
    </PreLoader>
  );
};

FeatureTable.propTypes = {
  entity: PropTypes.string,
  getList: PropTypes.func,
  views: PropTypes.array,
  currentView: PropTypes.object,
  setCurrentView: PropTypes.func,
  columns: PropTypes.array,
  enableCherryPick: PropTypes.bool,
  setViews: PropTypes.func,
  enableQuickSearch: PropTypes.bool,
  setDefaultViewId: PropTypes.func,
  setPortfolioKanbanData: PropTypes.func,
  opts: PropTypes.func,
};

export default FeatureTable;
