import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Row,
  List,
  Anchor,
  Affix,
  Select,
  Table,
  Rate,
  message,
  Switch,
  Space,
  Typography,
} from 'antd';
import moment from 'moment';
import { get, isEmpty, keyBy, startCase } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import {
  Input,
  Content,
  NestedFormList,
  FundraisingMap,
  RelatedTable,
  PreLoader,
} from 'Components';
import { candidateProfileSchema } from 'Schemas/candidate-schema';
import { packNestedObj, unpackNestedObj } from 'Utils';
import AllEmails from './all-emails';
import { useStoreValue } from 'Context';
import { generateSelectOptions } from 'Helpers';
import { getEvents } from 'Api/event-service';
import { getUsers } from 'Api/user-service';
import { introsColumnConfig } from 'Schemas/intro-schema';
import { interviewsColumnConfig } from 'Schemas/interview-schema';
import { PublicRoles, ColorBlindFriendlyRolesPalette } from 'Constants';

const { Link } = Anchor;
const { Text } = Typography;

const defaultPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

function CandidateProfile({ canEdit = false, candidateInfo, updateCandidateInfo }) {
  const [candidateFormData, setCandidateFormData] = useState({});
  const filteredCandidateProfileSchema = useRef(candidateProfileSchema);
  const [memberList, setMemberList] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [npsData, setNpsData] = useState([]);
  const [form] = Form.useForm();
  const [{ meta: metaStore, user }, dispatch] = useStoreValue();
  const [isMembersLoading, setIsMembersLoading] = useState(false);

  const memberColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => <RouterLink to={`/human/${record.id}`}>{name}</RouterLink>,
      ellipsis: true,
    },
    {
      title: 'Matchmaking Status',
      dataIndex: 'matchmaking_status',
      key: 'matchmaking_status',
      render: (name, record) => record.matchmaking_status,
    },
    {
      title: 'NPS',
      dataIndex: 'score',
      key: 'score',
      render: (name, record) => record.score,
      width: 60,
    },
  ];

  const handleRelatedTable = {
    intros: {
      columnConfig: introsColumnConfig,
    },
    interviews: {
      columnConfig: interviewsColumnConfig,
    },
  };

  useEffect(async () => {
    setIsMembersLoading(true);
    if (!isEmpty(candidateInfo)) {
      if (isEmpty(memberList)) {
        await fetchMembersList();
      }
      // Filter profile schema for roles available in user
      filteredCandidateProfileSchema.current = candidateProfileSchema.filter(
        (item) =>
          item.type !== 'custom' ||
          item.name === 'custom_fields' ||
          candidateInfo['roles'][item.name]
      );
      const detailsForForm = formatValuesForFe(candidateInfo);
      setCandidateFormData(detailsForForm);

      setIsMembersLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateInfo]);

  useEffect(() => {
    let updatedForm = { ...candidateFormData };
    const { emails = [] } = candidateFormData;
    const primaryEmailObj = emails.find(({ is_primary }) => is_primary);
    if (primaryEmailObj) {
      const { email } = primaryEmailObj;
      updatedForm = { ...updatedForm, primary_email: email };
    }
    form.setFieldsValue(updatedForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateFormData]);

  const fetchNPSList = async () => {
    try {
      const type_str = 'submitted_member_form';
      let { data: data } = await getEvents(
        { type_str },
        { page_num: 1, page_size: 2500 },
        {
          from_str: moment({ year: 2021, month: 9, day: 1 }).utc().toISOString(),
          to_str: moment().utc().toISOString(),
        },
        {
          entity_ids: [candidateInfo.id],
          entity_type: 'user',
        },
        {}
      );
      return data;
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Fetching NPS: Something went wrong');
    }
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { columnKey: sort_by, order: sort_dir } = sorter;
    if (sort_by && sort_dir) {
      return fetchMembersList(pagination, {
        sort_by,
        sort_dir: sort_dir?.replace('end', ''),
      });
    }
    fetchMembersList(pagination);
  };

  var membersFilter = {
    filters: [
      {
        field: 'hc_poc',
        constraints: [
          {
            operator: 'is',
            value: null,
          },
        ],
      },
    ],
    entity_type: 'user',
  };

  const fetchMembersList = async (paginationInfo = defaultPagination, sorterInfo) => {
    try {
      setIsMembersLoading(true);
      if (isEmpty(npsData)) {
        const nps = await fetchNPSList();
        setNpsData(nps);
      }
      const { current: page_num, pageSize: page_size } = paginationInfo;
      if (!get(membersFilter, 'filters[0].constraints[0].value', false)) {
        membersFilter.filters[0].constraints[0].value = candidateInfo.id;
      }

      const { data: data, headers } = await getUsers(
        { page_num: page_num, page_size: page_size },
        { ...sorterInfo, sort_dir: 'desc' },
        membersFilter,
        { make_event: false }
      );

      const {
        page_num: current,
        page_size: pageSize,
        total_records: total,
      } = JSON.parse(headers['x-pagination']);
      const updatedPaginationInfo = { current, pageSize, total };
      setPagination(updatedPaginationInfo);

      var formattedList = data?.map(({ other_data, custom_fields, first_name, last_name, id }) => {
        const name = first_name + ' ' + last_name;
        const matchmaking_status =
          'Matchmaking Status' in custom_fields ? custom_fields['Matchmaking Status'] : '';
        var score =
          'Recommendation Score' in custom_fields ? custom_fields['Recommendation Score'] : '';
        for (let i in npsData[candidateInfo.id]) {
          if (npsData[candidateInfo.id][i]['destination_user_id'] === id) {
            score = npsData[candidateInfo.id][i]['meta']['recommendation_score'];
          }
        }
        return { ...other_data, name, matchmaking_status, score, id };
      });

      setMemberList(formattedList);
      setIsMembersLoading(false);
    } catch (err) {
      console.log(err);
      message.error(err.message || 'Fetching Members: Something went wrong');
    }
  };

  const formattedValue = (type, value) => {
    switch (type) {
      case 'date-obj':
      case 'date':
        return value && !isEmpty(value) ? moment(value) : null;
      case 'roles':
        return Object.keys(value).filter((role) => {
          return value[role];
        });
      case 'action-items':
        return value ? value.split('|') : [];
      default:
        return value;
    }
  };

  const inputOnChangeFunc = (fieldType, fieldName) => {
    switch (fieldType) {
      case 'num':
      case 'number':
      case 'select':
      case 'roles':
      case 'rate':
      case 'fundraising':
        return (value) => {
          return handleValueChange(fieldName, value);
        };
      case 'date':
        return (momentDate) => {
          handleValueChange(fieldName, momentDate?.toISOString());
        };
      case 'action-items':
        return (value = []) => handleValueChange(fieldName, value.join('|'));
      default:
        return (event) => {
          const { value } = event.target;
          handleValueChange(fieldName, value);
        };
    }
  };

  const toggleRole = (type, name, role_changed, isActive, getFieldValue) => {
    const changeFieldFunc = inputOnChangeFunc(type, name);
    let current_roles = getFieldValue(name);
    if (isActive) {
      current_roles = [role_changed].concat(current_roles);
    } else {
      current_roles = current_roles.filter((role) => role != role_changed);
    }
    changeFieldFunc(current_roles);
  };

  const formatValuesForFe = (formData) => {
    return filteredCandidateProfileSchema.current.reduce((acc, currentItem) => {
      if (currentItem.type === 'flat') {
        let formattedFields = currentItem.subFields.reduce((subAcc, field) => {
          subAcc[field.name] = formattedValue(field.type, formData[field.name]);
          return subAcc;
        }, {});
        const userEmails = formattedFields['emails'];
        if (userEmails.length) {
          const primaryEmailObj = userEmails.find(({ is_primary }) => is_primary);
          if (primaryEmailObj) {
            const { email } = primaryEmailObj;
            acc = { ...acc, primary_email: email };
          }
        }
        acc = { ...acc, ...formattedFields };
      } else if (currentItem.type === 'nested') {
        let subFieldTypeHash = currentItem.subFields.reduce((subAcc, field) => {
          subAcc[field.name] = field.type;
          return subAcc;
        }, {});
        let formattedNestedFields = formData[currentItem.name].map((batch) => {
          const hydratedBatchObj = unpackNestedObj(batch, 'other_data');
          return Object.keys(hydratedBatchObj).reduce((batchAcc, item) => {
            batchAcc[item] = formattedValue(subFieldTypeHash[item], batch[item]);
            return batchAcc;
          }, {});
        });
        acc = { ...acc, [currentItem.name]: formattedNestedFields };
      } else if (currentItem.type === 'custom') {
        let hydratedFields = {};
        if (currentItem.name === 'custom_fields') {
          hydratedFields = unpackNestedObj(
            { custom_fields: formData['custom_fields'] },
            'custom_fields'
          );
        } else if (currentItem.name === 'future_founder') {
          hydratedFields = unpackNestedObj(
            { future_founder: formData['roles']['future_founder'] },
            'future_founder'
          );
          hydratedFields = {
            ...hydratedFields,
            ...unpackNestedObj(hydratedFields, 'future_founder::custom_fields'),
          };
        } else if (currentItem.name === 'executive') {
          hydratedFields = unpackNestedObj(
            { executive: formData['roles']['executive'] },
            'executive'
          );
          hydratedFields = {
            ...hydratedFields,
            ...unpackNestedObj(hydratedFields, 'executive::custom_fields'),
          };
        }
        acc = { ...acc, ...hydratedFields };
      } else if (currentItem.type === 'related-table') {
        let hydratedFields = { [currentItem.name]: formData[currentItem.name] };
        acc = { ...acc, ...hydratedFields };
      }
      return acc;
    }, {});
  };

  const handleValueChange = async (fieldName, value) => {
    try {
      if (['edu_history', 'work_history'].includes(fieldName)) {
        // below filter takes care of weird behaviour when a
        // collection in nested field is removed
        value = value.filter((collection) => collection && collection.destination_organization_id);
        value = value.map((val) => packNestedObj({ ...val }, 'other_data'));
      }
      // later,update packNested util obj to handle below condition as well
      if (fieldName.includes('custom_fields')) {
        const startIndex = fieldName.indexOf(`custom_fields::`) + `custom_fields::`.length;
        const subKey = fieldName.substring(startIndex);

        // Get all existing custom_fields across roles
        let allCustomFields = { ...candidateInfo['custom_fields'] };
        // turn into recursive if we put custom fields in other fields?
        Object.values(candidateInfo['roles']).forEach((val) => {
          if (val && typeof val === 'object' && 'custom_fields' in val) {
            allCustomFields = { ...allCustomFields, ...val['custom_fields'] };
          }
        });

        value = { ...allCustomFields, [subKey]: value };
        fieldName = 'custom_fields';
      }
      if (fieldName === 'roles') {
        let newRoles = { ...candidateInfo['roles'] };
        Object.keys(candidateInfo[fieldName]).map((role) => {
          newRoles[role] = { is_active: value.includes(role) };
        });
        value = newRoles;
      }
      if (fieldName.includes('future_founder')) {
        const startIndex = fieldName.indexOf(`future_founder::`) + `future_founder::`.length;
        const subKey = fieldName.substring(startIndex);
        let updatedRole = candidateInfo['roles']['future_founder'];
        updatedRole[subKey] = value;

        value = { future_founder: updatedRole };
        fieldName = 'roles';
      }
      const updatedFormData = { [fieldName]: value };
      await updateCandidateInfo(updatedFormData);
      if (fieldName === 'roles' && candidateInfo['id'] == user.id) {
        let updateUser = { ...user };
        updateUser['roles'] = value;
        dispatch({ type: 'LOGIN', payload: { user: updateUser } });
      }
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  };

  const RenderRoleSwitches = (props) => {
    const { getFieldValue, roles, name, type } = props;

    const activeRoles = getFieldValue(name);

    const isClientPoc = candidateInfo?.flags?.is_client_poc;

    const visible_roles = isClientPoc
      ? roles
      : roles.filter((option) => option != PublicRoles.CLIENT_POC);

    const colSize = 24 / visible_roles.length;

    if (!activeRoles) {
      return <></>;
    }

    return (
      <Row justify="space-around">
        {visible_roles.map((option) => {
          return (
            <Col span={colSize} key={`role_${option}`} type="flex" align="middle">
              <Space direction="vertical" align="center">
                <Text>{startCase(option)}</Text>
                <Switch
                  disabled={!canEdit}
                  checked={activeRoles.includes(option)}
                  onChange={(isActive) => {
                    toggleRole(type, name, option, isActive, getFieldValue);
                  }}
                  style={{
                    backgroundColor: ColorBlindFriendlyRolesPalette[option],
                  }}
                ></Switch>
              </Space>
            </Col>
          );
        })}
      </Row>
    );
  };

  RenderRoleSwitches.propTypes = {
    getFieldValue: PropTypes.func,
    roles: PropTypes.array,
    name: PropTypes.string,
    type: PropTypes.string,
  };

  const generateFlatForm = (fields) => {
    return fields.map(
      ({ name, label, required = false, type, placeholder, allowed_values, span, ...rest }) => {
        return type === 'emails' ? (
          <React.Fragment key="emails-section">
            <Col span={10} key={name}>
              <Form.Item
                name={'primary_email'}
                label={label}
                disabled
                tooltip="Change primary email by clicking on email-tags on right"
              >
                <Input
                  disabled
                  onBlur={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  type={type}
                />
              </Form.Item>
            </Col>
            <Col span={14} key="all_emails">
              <Form.Item
                label={'All Emails'}
                tooltip="Email can be marked as verified or primary by clicking on tags"
              >
                <AllEmails
                  canEdit={canEdit}
                  allEmails={candidateFormData?.emails || []}
                  handleValueChange={handleValueChange}
                />
              </Form.Item>
            </Col>
          </React.Fragment>
        ) : (
          <Col span={span} key={name}>
            <Form.Item
              name={name}
              label={label}
              rules={[
                {
                  required,
                },
              ]}
            >
              {type === 'roles' ? (
                <RenderRoleSwitches
                  getFieldValue={form.getFieldValue}
                  roles={allowed_values}
                  type={type}
                  name={name}
                ></RenderRoleSwitches>
              ) : type === 'action-items' ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  tokenSeparators={['|']}
                  size="large"
                  placeholder={placeholder}
                  {...rest}
                ></Select>
              ) : type === 'select' ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  size="large"
                  placeholder={placeholder}
                  {...rest}
                >
                  {generateSelectOptions(allowed_values)}
                </Select>
              ) : type === 'date' ? (
                <Input
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  type={type}
                />
              ) : type === 'fundraising' ? (
                <FundraisingMap
                  disabled={!canEdit}
                  data={candidateFormData['future_founder::fundraising']}
                  handleValueChange={inputOnChangeFunc(type, name)}
                />
              ) : type === 'rate' ? (
                <Rate
                  disabled={!canEdit}
                  defaultValue={candidateFormData['future_founder::bullishness']}
                  onChange={inputOnChangeFunc(type, name)}
                />
              ) : (
                <Input
                  disabled={!canEdit}
                  onBlur={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  type={type}
                />
              )}
            </Form.Item>
          </Col>
        );
      }
    );
  };

  const generateCustomForm = (fields) => {
    if (metaStore) {
      const {
        custom_fields: { user: userCustomFields },
      } = metaStore;
      const customFieldHashMap = keyBy(userCustomFields, (field) => field.name);
      const updatedSchema = fields.map(({ label, ...rest }) => {
        const currentMapItem = customFieldHashMap[label];
        if (currentMapItem) {
          const { multi_valued, allow_new_values, allowed_values, ...beConfig } = currentMapItem;
          const fieldType =
            !multi_valued && allow_new_values && !allowed_values.length ? 'text' : 'select';
          return {
            mode:
              allow_new_values && !allowed_values.length && multi_valued
                ? 'tags'
                : multi_valued
                ? 'multiple'
                : undefined,
            label,
            type: fieldType,
            allowed_values,
            ...beConfig,
            ...rest,
          };
        }
        return { label, ...rest };
      });
      return generateFlatForm(updatedSchema);
    }
  };

  const generateRelatedMembers = () => {
    if (!candidateInfo || candidateInfo.role !== 'principal' || !candidateInfo.roles.principal) {
      return null;
    }
    return (
      <div id="members" style={{ marginBottom: 30 }}>
        <Row
          justify="space-between"
          style={{
            borderBottom: '1px solid #4e4e4e',
            paddingBottom: '10px',
            marginBottom: '20px',
          }}
        >
          <h3
            style={{
              margin: 'unset',
              fontWeight: 700,
            }}
          >
            {`📑 Members`}
          </h3>
        </Row>
        <Table
          loading={isMembersLoading}
          pagination={{
            ...pagination,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Members`,
          }}
          onChange={handleTableChange}
          scroll={{ y: 240 }}
          rowKey={({ id }) => id}
          size="small"
          dataSource={memberList}
          bordered
          columns={memberColumns}
        />
      </div>
    );
  };

  return (
    <PreLoader
      className="loadingSpinner"
      spinning={isMembersLoading}
      size="large"
      style={{ height: isMembersLoading ? 100 : null }}
    >
      <Content spacing="medium" style={{ paddingBottom: 300 }}>
        <Row>
          <Col span={4}>
            <Affix offsetTop={200}>
              <Content>
                <h3>Sections</h3>
                <Anchor>
                  <List
                    dataSource={[...filteredCandidateProfileSchema.current]}
                    renderItem={({ name, label }, index) => (
                      <List.Item key={index}>
                        <Link href={`#${name}`} title={label} />
                      </List.Item>
                    )}
                  />
                </Anchor>
              </Content>
            </Affix>
          </Col>
          <Col span={20}>
            <Content spacing="medium">
              {generateRelatedMembers()}
              <Form form={form} layout="vertical">
                {filteredCandidateProfileSchema.current.map(
                  ({ section, name, label, icon, type, subFields }, index) => {
                    return (
                      <div id={section} key={index} style={{ marginBottom: 20 }}>
                        <h3
                          style={{
                            borderBottom: '1px solid #4e4e4e',
                            paddingBottom: '10px',
                            marginBottom: '40px',
                            fontWeight: 700,
                          }}
                        >
                          {`${icon}     ${label}`}
                        </h3>
                        <Row gutter={[40, 4]}>
                          {type === 'flat' ? (
                            generateFlatForm(subFields)
                          ) : type === 'nested' ? (
                            <NestedFormList
                              canEdit={canEdit}
                              subFields={subFields}
                              parentName={name}
                              parentLabel={label}
                              formInstance={form}
                              valueChangeCallback={handleValueChange}
                            />
                          ) : type === 'custom' ? (
                            generateCustomForm(subFields)
                          ) : type === 'related-table' ? (
                            <RelatedTable
                              columnConfig={handleRelatedTable[name]['columnConfig']}
                              data={candidateFormData[name]}
                            />
                          ) : null}
                        </Row>
                      </div>
                    );
                  }
                )}
              </Form>
            </Content>
          </Col>
        </Row>
      </Content>
    </PreLoader>
  );
}

CandidateProfile.propTypes = {
  canEdit: PropTypes.bool,
  candidateInfo: PropTypes.object,
  updateCandidateInfo: PropTypes.func,
};

export default CandidateProfile;
