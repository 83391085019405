import React, { useState, useEffect } from 'react';
import { Space, Button, Row, Col, Popconfirm } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { v4 as uuid_v4 } from 'uuid';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import FilterCondition from './filter-condition';
import './filter-item.less';

const FilterItem = ({ item, currentView, updateViewData }) => {
  const [conditions, setConditions] = useState([]);
  const { label, name, filterOptions = [] } = item;
  const { filters } = currentView;
  const defaultOperator = filterOptions.length ? filterOptions[0]?.operator : '';
  const freshCondition = { key: uuid_v4(), operator: defaultOperator, value: null, value_2: null };

  const updateConditions = (updatedConditions) => {
    if (updatedConditions.length) {
      let idx = filters.findIndex(({ field }) => field === name);
      let a = { field: name, constraints: updatedConditions };
      let tempFilters = [...filters];
      if (idx > -1) {
        tempFilters[idx] = a;
      } else {
        tempFilters.push(a);
      }
      updateViewData({ ...currentView, filters: tempFilters });
    }
  };

  useEffect(() => {
    let currentFieldConfig = filters?.find((item) => item.field === name);
    if (!isEmpty(currentFieldConfig)) {
      const { constraints } = currentFieldConfig;
      setConditions(constraints);
    } else {
      setConditions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const addConditions = () => {
    const newConditions = [...conditions, freshCondition];
    updateConditions(newConditions);
  };

  const removeCondition = (key) => {
    let tempConditions = [...conditions];
    const indexToRemove = tempConditions.findIndex((item) => item.key === key);
    tempConditions.splice(indexToRemove, 1);
    updateConditions(tempConditions);
  };

  const handleOperatorChange = (key, value) => {
    let tempConditions = [...conditions];
    const indexToUpdate = tempConditions.findIndex((item) => item.key === key);
    tempConditions[indexToUpdate]['operator'] = value;
    updateConditions(tempConditions);
  };

  const handleValueChange = (fieldName, key, value) => {
    let tempConditions = [...conditions];
    const indexToUpdate = tempConditions.findIndex((item) => item.key === key);
    tempConditions[indexToUpdate][fieldName] = value;
    updateConditions(tempConditions);
  };

  const removeField = () => {
    const idx = filters.findIndex(({ field }) => field === name);
    const tempFilters = [...filters];
    tempFilters.splice(idx, 1);
    updateViewData({ ...currentView, filters: tempFilters });
  };

  return (
    <div className="filter-item">
      <Row align="center" justify="space-between" style={{ marginBottom: 3 }}>
        <Col>
          <Popconfirm
            title="Are you sure to remove this field?"
            onConfirm={removeField}
            okText="Yes"
            cancelText="No"
          >
            <MinusOutlined />
          </Popconfirm>
          {label}
        </Col>
      </Row>
      <>
        {conditions.map((condition, index) => (
          <FilterCondition
            key={index}
            condition={condition}
            conditions={conditions}
            filterOptions={filterOptions}
            handleOperatorChange={handleOperatorChange}
            removeCondition={removeCondition}
            handleValueChange={handleValueChange}
          ></FilterCondition>
        ))}
        <Space>
          <Button type="secondary" size="small" onClick={addConditions}>
            Or
          </Button>
        </Space>
      </>
    </div>
  );
};

FilterItem.propTypes = {
  item: PropTypes.object,
  currentView: PropTypes.shape({
    filters: PropTypes.array,
  }),
  updateViewData: PropTypes.func,
};

export default FilterItem;
