import React from 'react';
import { BugReportUrl } from 'Constants';

export default function FloatingBugReportLink() {
  return (
    <a
      href={BugReportUrl}
      rel="noreferrer"
      target="_blank"
      style={{ position: 'absolute', bottom: 15, right: 30 }}
    >
      🐞 Report Bug
    </a>
  );
}
