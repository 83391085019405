// const Offerings = ['Advisory', 'Advisory+', 'Embedded'];

const FounderMeetingSchema = [
  {
    label: 'Aligned on internal resourcing (e.g., OP, Principal(s), Ops)',
    name: 'internal_resourcing',
    type: 'textarea',
    placeholder: 'OP: John\nPrincipal: Jane',
    row: 2,
    span: 24,
  },
  {
    label: 'Introduction emails with founders / stakeholders',
    name: 'intro_emails',
    type: 'checkbox',
    span: 24,
  },
];

const AuditPendingSchema = [
  {
    label: 'Scheduled diagnostic / audit meetings',
    name: 'schedule_audit',
    type: 'checkbox',
    span: 24,
  },
];

const AuditSchema = [
  {
    label: 'Met with relevant stakeholder(s)',
    name: 'met_stakeholders',
    type: 'checkbox',
    span: 24,
  },
  {
    label: 'Drafted diagnostic and reviewed internally',
    name: 'drafted_diagnostic',
    type: 'textarea',
    placeholder: 'Link to diagnostic doc',
    row: 2,
    span: 24,
  },
];

const RecommendationSchema = [
  {
    label: 'Reviewed diagnostic with founders / stakeholders',
    name: 'reviewed_diagnostic',
    type: 'checkbox',
    span: 24,
  },
  {
    label: 'Aligned on go-forward plan (e.g., embedded vs. advisory)',
    name: 'aligned_plan',
    type: 'textarea',
    placeholder: 'Link to go-forward plan',
    row: 2,
    span: 24,
  },
];

const EmbeddedSchema = [
  {
    label: 'Held transition discussion with founders / stakeholders',
    name: 'transition_discussion',
    type: 'checkbox',
    span: 24,
  },
  {
    label: 'Set up ongoing cadence for advisory meetings',
    name: 'advisory_meetings',
    type: 'textarea',
    placeholder: 'Cadence/plan',
    row: 2,
    span: 24,
  },
];

// Schema for both advisory and advisory+ stage
const AdvisorySchema = [];
const CompletedSchema = [];

export {
  FounderMeetingSchema,
  AuditPendingSchema,
  AuditSchema,
  RecommendationSchema,
  EmbeddedSchema,
  AdvisorySchema,
  CompletedSchema,
};
