import React, { useEffect, useState } from 'react';
import { Table, SelectSearch, Button } from 'Components';
import PropTypes from 'prop-types';
import { Segmented, Checkbox, Popconfirm, Typography, message, Divider, Row, Col } from 'antd';
import {
  UserOutlined,
  StarOutlined,
  CloseOutlined,
  SearchOutlined,
  SafetyCertificateFilled,
  UnlockOutlined,
} from '@ant-design/icons';
import { getUserProfile, getUsersByIdList } from 'Api/user-service';
import { getPolicyBySecurityId, updatePolicy, getPolicyByShortcutName } from 'Api/security-service';
import { startCase, cloneDeep } from 'lodash';
import { getActiveRolesForUser } from 'Utils';
import { SecurityShortcutNames, ExpectedRolesForPolicy } from 'Constants';

const { Text } = Typography;

const SecurityTable = ({ securityId, updateHuman }) => {
  const [value, setValue] = useState('roles');
  const [rolePolicies, setRolePolicies] = useState([]);
  const [userPolicies, setUserPolicies] = useState([]);
  const [userSearchValue, setUserSearchValue] = useState();

  useEffect(() => {
    fetchPolicy(securityId);
  }, [securityId]);

  useEffect(async () => {
    if (userSearchValue > 0) {
      await addNewUser(userSearchValue);
      setUserSearchValue(undefined);
    }
  }, [userSearchValue]);

  const roleColumns = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => startCase(role),
    },
    {
      title: 'Read',
      dataIndex: 'read',
      width: 20,
      key: 'read',
      render: (read, record) => (
        <Checkbox
          className="green-box"
          checked={read}
          onChange={({ target: { checked } }) => onRolePolicyChange(record.role, 'read', checked)}
        ></Checkbox>
      ),
    },
    {
      title: 'Write',
      dataIndex: 'write',
      width: 20,
      key: 'write',
      render: (write, record) => (
        <Checkbox
          className="green-box"
          checked={record.write}
          onChange={({ target: { checked } }) => onRolePolicyChange(record.role, 'write', checked)}
        ></Checkbox>
      ),
    },
  ];

  const userColumns = [
    {
      title: 'Human',
      dataIndex: 'human',
      key: 'human',
      render: (human) => (
        <span>
          {`${human.first_name} ${human.last_name || ''} - `}
          <Text type="secondary">
            {getActiveRolesForUser(human)
              .filter((role) => !['member', 'admin'].includes(role))
              .join(' | ')}
          </Text>
        </span>
      ),
    },
    {
      title: 'Read',
      width: 15,
      dataIndex: 'read',
      key: 'read',
      render: (read, record) => (
        <Checkbox
          className="green-box"
          checked={read}
          onChange={({ target: { checked } }) => onUserPolicyChange(record.key, 'read', checked)}
        ></Checkbox>
      ),
    },
    {
      title: 'Write',
      width: 15,
      dataIndex: 'write',
      key: 'write',
      render: (write, record) => (
        <Checkbox
          className="green-box"
          checked={write}
          onChange={({ target: { checked } }) => onUserPolicyChange(record.key, 'write', checked)}
        ></Checkbox>
      ),
    },
    {
      title: '',
      width: 10,
      dataIndex: 'remove',
      key: 'remove',
      render: (_, record) => (
        <Popconfirm title="Sure to remove?" onConfirm={() => removeUser(record.key)}>
          <Button size="middle" style={{ padding: 'unset' }} type="link" icon={<CloseOutlined />} />
        </Popconfirm>
      ),
    },
  ];

  const onRolePolicyChange = (entity, property, value) => {
    let tempRolePolicies = cloneDeep(rolePolicies);
    tempRolePolicies.forEach((policy) => {
      if (policy['role'] === entity) {
        policy[property] = value;
      }
    });
    setRolePolicies(tempRolePolicies);
  };

  const onUserPolicyChange = (userId, property, value) => {
    let tempUserPolicies = cloneDeep(userPolicies);
    tempUserPolicies.forEach((policy) => {
      if (userId === policy['key']) {
        policy[property] = value;
      }
    });
    setUserPolicies(tempUserPolicies);
  };

  const fetchPolicy = async (id) => {
    try {
      let {
        data: { role_policies, user_policies },
      } = await getPolicyBySecurityId(id);
      let formattedRolePolicies = formatRoleForTable(role_policies);
      let formattedUserPolicies = await formatUserForTable(user_policies);
      setRolePolicies(formattedRolePolicies);
      setUserPolicies(formattedUserPolicies);
    } catch (error) {
      message.error(error.message || 'Something went wrong');
    }
  };

  const formatUserForTable = async (userPolicies) => {
    try {
      if (!userPolicies.length) return [];
      let hashMapByUserId = userPolicies.reduce((acc, user) => {
        acc[user['user_id']] = user['policy'];
        return acc;
      }, {});
      const { data: humansList } = await getUsersByIdList(Object.keys(hashMapByUserId));
      return humansList.reduce((acc, human) => {
        let objFortable = {};
        objFortable['key'] = human.id;
        objFortable['human'] = human;
        objFortable = { ...objFortable, ...hashMapByUserId[human.id] };
        acc.push(objFortable);
        return acc;
      }, []);
    } catch (error) {
      message.error(error.message || 'Something went wrong');
    }
  };

  const formatRoleForTable = (rolePolicies) => {
    if (!rolePolicies.length) return [];
    let expectedRoles = ExpectedRolesForPolicy;
    return rolePolicies.reduce((acc, policy, index) => {
      let roleData = {};
      if (policy) {
        roleData['key'] = index;
        roleData['role'] = policy['role_table_name'];
        roleData = { ...roleData, ...policy['policy'] };
        expectedRoles = expectedRoles.filter((item) => item !== policy['role_table_name']);
        acc.push(roleData);
      }
      if (index + 1 === rolePolicies.length && expectedRoles.length) {
        // we still want to show all roles even if absent
        expectedRoles.forEach(function (role) {
          let roleDataTemp = {};
          roleDataTemp['key'] = index + 1;
          roleDataTemp['role'] = role;
          roleDataTemp['read'] = false;
          roleDataTemp['write'] = false;
          acc.push(roleDataTemp);
          expectedRoles = expectedRoles.filter((item) => item !== role);
        });
      }
      return acc;
    }, []);
  };

  const formatPolicyForBe = () => {
    let userPoliciesForBe = userPolicies.reduce((acc, { key, read, write }) => {
      let tempPolicy = { user_id: key, policy: { read, write } };
      acc.push(tempPolicy);
      return acc;
    }, []);
    let rolePoliciesForBe = rolePolicies.reduce((acc, { role, read, write }) => {
      let tempPolicy = { role_table_name: role, policy: { read, write } };
      acc.push(tempPolicy);
      return acc;
    }, []);
    return { user_policies: userPoliciesForBe, role_policies: rolePoliciesForBe };
  };

  const updatePolicies = async () => {
    try {
      const policiesForBe = formatPolicyForBe();
      const { data: updatedSecurityId } = await updatePolicy(policiesForBe);
      await updateHuman({ security_id: updatedSecurityId });
    } catch (error) {
      message.error(error.message || 'Something went wrong');
    }
  };

  const addNewUser = async (userId) => {
    try {
      let { data } = await getUserProfile(userId, false);
      let tempUserData = [...userPolicies];
      tempUserData.push({
        key: userId,
        human: data,
        read: true,
        write: false,
      });
      setUserPolicies(tempUserData);
    } catch (error) {
      message.error(error.message || 'Something went wrong');
    }
  };

  const removeUser = (key) => {
    let tempUserData = [...userPolicies].filter((item) => item.key != key);
    setUserPolicies(tempUserData);
  };

  const generatePolicyContent = () => {
    if (value === 'roles') {
      return (
        <Table size="small" pagination={false} dataSource={rolePolicies} columns={roleColumns} />
      );
    } else {
      return (
        <>
          <SelectSearch
            suffixIcon={<SearchOutlined />}
            value={userSearchValue}
            onChange={setUserSearchValue}
            style={{ width: '100%', marginBottom: '25px' }}
            search_entity="user"
            placeholder="Search & Add HC human"
            allowClear
            size="large"
            onlyInternalHumans
          />
          <Table size="small" pagination={false} dataSource={userPolicies} columns={userColumns} />
        </>
      );
    }
  };

  return (
    <>
      <Segmented
        style={{ fontSize: 16 }}
        block
        options={[
          {
            label: <span style={{ padding: '5px 11px' }}>Role Policies</span>,
            value: 'roles',
            icon: <StarOutlined />,
          },
          {
            label: <span style={{ padding: '5px 11px' }}>User Policies</span>,
            value: 'users',
            icon: <UserOutlined />,
          },
        ]}
        value={value}
        onChange={setValue}
      />
      <br />
      {generatePolicyContent()}
      <br />
      <div style={{ fontSize: 16 }}>
        <Text strong mark>
          Note
        </Text>
        <br />
        <Text type="secondary">
          Read/Write operations for role affects everyone holding that role
        </Text>
        <br />
        <Text type="secondary">User polices always supersedes Role based policies</Text>
      </div>
      <Divider />
      <Row justify="space-between">
        <Col>
          <Popconfirm
            title="Make this public to all Human Capital?"
            onConfirm={async () => {
              try {
                const { data: policy } = await getPolicyByShortcutName(
                  SecurityShortcutNames.AccessToAllInternalUsers
                );
                await updateHuman({ security_id: policy.id });
              } catch (error) {
                if (error.message && error.message === 'NOT_FOUND') {
                  return message.error("Policy Doesn't Exists");
                }
                message.error(error.message || 'Something went wrong');
              }
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<UnlockOutlined />} danger>
              Unlock For All
            </Button>
          </Popconfirm>
        </Col>
        <Col>
          <Button
            size="large"
            style={{ color: 'white', backgroundColor: 'black' }}
            key="back"
            onClick={updatePolicies}
            icon={<SafetyCertificateFilled style={{ color: '#7dbb25', fontSize: 18 }} />}
          >
            Apply Policies
          </Button>
        </Col>
      </Row>
    </>
  );
};

SecurityTable.propTypes = {
  securityId: PropTypes.number,
  updateHuman: PropTypes.func,
};

export default SecurityTable;
