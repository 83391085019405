import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { Button } from 'Components';
import { newCompanyPocSchema } from 'Schemas/company-schema';
import { generateFormFields } from 'Helpers';
import { makeShowable } from 'Utils';

const AddPocModal = ({ showModal, setShowModal, orgId, refresh, existingVal = [] }) => {
  const [addPocForm] = Form.useForm();

  const addNewPoc = async ({ user_id }) => {
    try {
      //user with role client-poc can login to client portal
      // Todo: update with adding client-poc roles profile not role
      const initReln = {
        source_user_id: user_id,
        destination_organization_id: orgId,
        relationship_type_name: 'client_portal_user_for',
      };
      typeof refresh === 'function' && refresh('client_pocs', [...existingVal, initReln]);
      message.success('Poc created successfully', 2);
      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(makeShowable(err.message, 'add_poc'));
    }
  };

  return (
    <Modal
      title="Add Company POC"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form form={addPocForm} onFinish={addNewPoc} layout="vertical" preserve={false}>
        <Row gutter={[24]}>{generateFormFields(newCompanyPocSchema)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Add Poc
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddPocModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  orgId: PropTypes.number,
  refresh: PropTypes.func,
  existingVal: PropTypes.array,
};

export default AddPocModal;
