import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, message, Progress, Tag, Space, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Card, Button } from 'Components';
import { PortfolioHealthIndicators } from 'Constants';
import { generateFormFields } from 'Helpers';
import {
  FounderMeetingSchema,
  AuditPendingSchema,
  AuditSchema,
  RecommendationSchema,
  EmbeddedSchema,
  AdvisorySchema,
  CompletedSchema,
} from 'Schemas/portfolio-kanban-schema.js';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import AllPOCs from '../../../components/all-pocs/all-pocs';

const transitionSchemas = [
  FounderMeetingSchema,
  AuditPendingSchema,
  AuditSchema,
  RecommendationSchema,
  EmbeddedSchema,
  AdvisorySchema,
  CompletedSchema,
];

const health_indicator = {
  strong: 'green',
  on_track: 'lime',
  early: 'gold',
  blocked: 'red',
  'n/a': 'grey',
};

const today = new Date();

const PortfolioKanbanCard = ({ item, setModalContent, setShowKanbanModal, onClickItem }) => {
  const cardItem = item.roles.portfolio;
  var hasStartDate = false;
  var showProgress = false;
  if (cardItem.engagement_start_date) {
    cardItem.engagement_start_date = new Date(cardItem.engagement_start_date);
    hasStartDate = true;
  }
  if (cardItem.engagement_end_date) {
    cardItem.engagement_end_date = new Date(cardItem.engagement_end_date);
    showProgress = true && hasStartDate;
  }
  return (
    <Card
      onClick={() => {
        setModalContent(onClickItem(item));
        setShowKanbanModal(true);
      }}
      title={
        <Link to={`/org/${item.id}`} target="_blank" rel="noreferrer">
          {item.name}
        </Link>
      }
      style={{ border: 'none' }}
      extra={<EllipsisOutlined />}
      size="small"
    >
      <Row>
        Lead:{' '}
        <AllPOCs
          canEdit={false}
          pocs={item.hc_pocs || []}
          entityId={item.id}
          destEntity={'organization'}
          hideInactive={true}
        />
      </Row>
      <Row>Engagement Dates:</Row>
      <Row>
        {cardItem.engagement_start_date?.toLocaleDateString('en-US') || 'Start Date TBD'} -{' '}
        {cardItem.engagement_end_date?.toLocaleDateString('en-US') || 'End Date TBD'}
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Progress
          strokeColor="#52c41a"
          percent={
            showProgress
              ? Math.round(
                  ((today - cardItem.engagement_start_date) /
                    (cardItem.engagement_end_date - cardItem.engagement_start_date)) *
                    100
                )
              : 0
          }
          showInfo={false}
        />
      </Row>
      <Row justify="space-between">
        {[
          { val: cardItem.affinity, indicator: 'Affinity' },
          { val: cardItem.trust, indicator: 'Trust' },
          { val: cardItem.ops, indicator: 'Ops' },
          { val: cardItem.hiring, indicator: 'Hiring' },
        ].map(({ val, indicator }) => {
          var displayHint = 'Not Applicable';
          PortfolioHealthIndicators[indicator.toLowerCase()].map((obj) => {
            if (obj.value === val) {
              displayHint = obj.display_hint_primary;
            }
          });
          return (
            <Tooltip title={displayHint} key={item.id + indicator}>
              <Tag color={health_indicator[val || 'not_applicable']}>{indicator}</Tag>
            </Tooltip>
          );
        })}
      </Row>
    </Card>
  );
};

const PortfolioKanbanModal = ({
  title,
  transitionData,
  showModal,
  setShowModal,
  setReorderItems,
  transitionIdx,
  contentOverride,
  onSubmit,
}) => {
  const [modalTitle, setModalTitle] = title;
  const [override, setOverride] = contentOverride;
  const [transitionFormData, setTransitionFormData] = useState({});
  const [updateTransitionsform] = Form.useForm();

  useEffect(() => updateTransitionsform.resetFields(), [transitionIdx]);
  useEffect(() => {
    if (isEmpty(transitionData.requirements)) {
      return;
    }
    const tempForm = { ...transitionFormData };
    transitionData.requirements.map((transition) => {
      if (transition.value) {
        tempForm[transition.name] = transition.value;
      }
    });
    setTransitionFormData(tempForm);
  }, [transitionData]);

  useEffect(() => {
    updateTransitionsform.setFieldsValue(transitionFormData);
  }, [transitionFormData]);

  const checkTransitions = async (values) => {
    try {
      onSubmit({
        entity_id: transitionData.entity_id,
        trigger: transitionData.trigger,
        form: values,
      }).then((resp) => {
        if (resp) {
          setReorderItems(true);
        }
      });
      updateTransitionsform.resetFields();

      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
      setReorderItems(false);
    }
  };

  return (
    <Modal
      title={modalTitle ? modalTitle : 'Transition Requirements'}
      visible={showModal}
      footer={false}
      onCancel={() => {
        setShowModal(false);
        setReorderItems(false);
        setModalTitle('');
        setOverride(null);
      }}
      bodyStyle={{
        maxHeight: '70vh',
        overflowY: 'auto',
      }}
    >
      <Form form={updateTransitionsform} onFinish={checkTransitions}>
        {override ? (
          override
        ) : transitionIdx !== null ? (
          <Row gutter={[24]}>
            {generateFormFields(transitionSchemas[parseInt(transitionIdx)], updateTransitionsform)}
          </Row>
        ) : (
          <></>
        )}
        {override ? null : (
          <Row justify="end">
            <Space>
              <Button type="primary" htmlType="submit">
                Update
              </Button>
              <Button
                onClick={() => {
                  setShowModal(false);
                  setReorderItems(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

PortfolioKanbanCard.propTypes = {
  item: PropTypes.object,
  setModalContent: PropTypes.func,
  setShowKanbanModal: PropTypes.func,
  onClickItem: PropTypes.func,
};

PortfolioKanbanModal.propTypes = {
  title: PropTypes.object,
  transitionData: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setReorderItems: PropTypes.func,
  transitionIdx: PropTypes.string,
  contentOverride: PropTypes.any,
  onSubmit: PropTypes.func,
};

export { PortfolioKanbanCard, PortfolioKanbanModal };
