import React from 'react';
import { Card as AntdCard } from 'antd';
import PropTypes from 'prop-types';
import './card.less';
import cx from 'classnames';

const Card = ({ children, spacing = 'medium', className, ...rest }) => {
  return (
    <AntdCard className={cx(className, `card-spacing-${spacing}`)} {...rest}>
      {children}
    </AntdCard>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  spacing: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
};

Card.defaultProps = {
  spacing: 'medium',
  className: null,
};

export default Card;
