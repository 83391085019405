import { isEqual } from 'lodash';
import { request } from './http-service';

const getViews = (id) => {
  return request({
    url: `/allowed_views/${id}`,
    method: 'get',
  });
};

const getView = (id) => {
  return request({
    url: `/views/${id}`,
    method: 'get',
  });
};

const createView = (viewInfo) => {
  return request({
    url: `/views`,
    method: 'post',
    data: viewInfo,
  });
};

const updateView = (viewInfo) => {
  const { id } = viewInfo;
  viewInfo.filters = viewInfo.filters.reduce((acc, filter) => {
    if (
      !acc.reduce(
        (repeatedFilter, currentFilter) => repeatedFilter || isEqual(filter, currentFilter),
        false
      )
    ) {
      return [...acc, filter];
    }
    return acc;
  }, []);
  return request({
    url: `/views/${id}`,
    method: 'put',
    data: viewInfo,
  });
};

const deleteView = (id) => {
  return request({
    url: `/views/${id}`,
    method: 'delete',
  });
};

export { getViews, getView, createView, updateView, deleteView };
