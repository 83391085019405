import React from 'react';
import { Typography, Row, Col, Empty, Select } from 'antd';
const { Paragraph, Text } = Typography;
import { actionItemsToList } from 'Utils';
import PropTypes from 'prop-types';

const PrimitiveRow = (props) => {
  const { label, content } = props;

  return (
    <Row style={{ maxHeight: '250px', overflow: 'scroll' }}>
      <Col span={8}>
        <Text strong>{label}</Text>
      </Col>
      <Col span={16}>{content}</Col>
    </Row>
  );
};

PrimitiveRow.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const DisplayObject = (props) => {
  const { obj, fields, updateObjectByField, editable } = props;

  if (!obj) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;

  return (
    <div>
      {fields.map((field) => {
        switch (field.field) {
          case 'action-items':
            return (
              <PrimitiveRow
                label={field.label}
                content={
                  <Select
                    mode="tags"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    defaultValue={actionItemsToList(obj[field.field])}
                    tokenSeparators={['|']}
                    onChange={(value) => updateObjectByField(value ? value.join(',') : '')}
                    disabled={!editable}
                  />
                }
              />
            );
          default:
            switch (field.type) {
              case 'text':
                return (
                  <PrimitiveRow
                    label={field.label}
                    content={
                      <Paragraph
                        editable={
                          editable
                            ? { onChange: (value) => updateObjectByField(field.field, value) }
                            : null
                        }
                      >
                        {obj[field.field]}
                      </Paragraph>
                    }
                  />
                );

              default:
                console.log('default', field.type);
                break;
            }
        }
        return 'Hello World';
      })}
    </div>
  );
};

DisplayObject.propTypes = {
  obj: PropTypes.any,
  fields: PropTypes.array,
  updateObjectByField: PropTypes.func,
  editable: PropTypes.bool,
  style: PropTypes.any,
};

export default DisplayObject;
