import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Divider, Select, message } from 'antd';
import { Input, Button, Content, PreLoader } from 'Components';
import { getAssessment, updateAssessment } from 'Api/user-service';
import { candidateAssessmentSchema } from 'Schemas/candidate-schema';
import { isEmpty } from 'lodash';
import { useStoreValue } from 'Context';

const { Option } = Select;

const CandidateAssessment = ({ candidateInfo }) => {
  const [assessmentConfig, setAssessmentConfig] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [assessmentFormInfo, setAssessmentFormInfo] = useState({});
  const [form] = Form.useForm();
  const [{ meta: metaStore }] = useStoreValue();
  const entity = 'user';

  useEffect(() => {
    if (metaStore) {
      setUpAssessmentForm();
    }
  }, [metaStore]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(assessmentFormInfo);
  }, [assessmentFormInfo, form]);

  useEffect(() => {
    if (candidateInfo.id) fetchUserAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentConfig, candidateInfo]);

  const getFields = (fields) => {
    return fields.map(
      ({
        name,
        label,
        required = false,
        type,
        span,
        placeholder,
        allowed_values = [],
        multi_valued,
      }) => (
        <Col span={span} key={name}>
          <Form.Item
            name={name}
            label={label}
            rules={[
              {
                required: required,
                message: `${label} is required`,
              },
            ]}
          >
            {allowed_values?.length > 0 ? (
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                size="large"
                mode={multi_valued ? 'multiple' : null}
                placeholder={placeholder}
                allowClear
                showSearch
              >
                {allowed_values.map((val, index) => (
                  <Option key={index} value={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            ) : (
              <Input allowClear placeholder={placeholder} type={type} />
            )}
          </Form.Item>
        </Col>
      )
    );
  };

  const mapFieldConfigToSchema = (customFields, assessmentSchema) => {
    return assessmentSchema.map((item) => {
      if (customFields[item.label]) {
        const { ...beConfig } = customFields[item.label];
        // overwrite name from BE file
        delete beConfig.name;
        return { ...item, ...beConfig };
      } else {
        return item;
      }
    });
  };

  const setUpAssessmentForm = async () => {
    try {
      const { custom_fields: customFields } = metaStore;
      const entityCustomFields = customFields[entity];
      const customFieldsHash = entityCustomFields.reduce((acc, fieldItem) => {
        acc[fieldItem.name] = fieldItem;
        return acc;
      }, {});
      const formConfig = mapFieldConfigToSchema(customFieldsHash, candidateAssessmentSchema);
      setAssessmentConfig(formConfig);
    } catch (err) {
      console.log(err);
      message.error(err.message || 'Something went wrong while setting assessment');
    }
    setIsLoading(false);
  };

  const formatValuesForBe = (formValues) => {
    return assessmentConfig.reduce(
      (acc, { name, multi_valued }) => {
        if (multi_valued && formValues[name]) {
          acc[name] = formValues[name]?.join();
        } else if (formValues[name]) {
          acc[name] = formValues[name];
        }
        return acc;
      },
      { user_id: candidateInfo.id }
    );
  };

  const formatValuesFromBe = (formValues) => {
    return assessmentConfig.reduce((acc, { name, multi_valued }) => {
      if (multi_valued && formValues[name]) {
        acc[name] = formValues[name]?.split(',');
      } else if (formValues[name]) {
        acc[name] = formValues[name];
      }
      return acc;
    }, {});
  };

  const submitAssessment = async (formValues) => {
    try {
      setIsLoading(true);
      const formattedValues = formatValuesForBe(formValues);
      await updateAssessment(formattedValues);
      message.success('Assessment updated');
    } catch (err) {
      console.log(err);
      message.error(err.message || 'Something went wrong');
    }
    setIsLoading(false);
  };

  const fetchUserAssessment = async () => {
    try {
      const { data } = await getAssessment(candidateInfo.id);
      if (!isEmpty(data)) {
        const formattedValues = formatValuesFromBe(data);
        setAssessmentFormInfo(formattedValues);
      }
    } catch (err) {
      console.log(err);
      message.error(err.message || 'Something went wrong while fetching assessment');
    }
  };

  const onFinishFailed = () => {
    const fieldsErrorInfo = form.getFieldsError();
    const errorSummary = fieldsErrorInfo.reduce((acc, field) => {
      let errMessage = field.errors.reduce((subAcc, err) => {
        subAcc = subAcc + ' ' + err;
        return subAcc;
      }, '');
      if (errMessage) {
        acc = acc + ' | ' + errMessage;
      }
      return acc;
    }, '');
    message.error('Submit failed!' + errorSummary);
  };

  return (
    <Content spacing="medium" style={{ background: 'whitesmoke' }}>
      <Row className="form-layout" justify="space-between" align="middle">
        <Col>
          <h2 style={{ marginBottom: '0px' }}>🎯 Assessment Form</h2>
        </Col>
        <Divider />
        <PreLoader spinning={isLoading}>
          <Form
            initialValues={assessmentFormInfo}
            form={form}
            layout="vertical"
            onFinish={submitAssessment}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[24]}>{getFields(assessmentConfig)}</Row>
            <Row justify="center">
              <Button
                disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                type="primary"
                htmlType="submit"
                size="large"
              >
                Update
              </Button>
            </Row>
          </Form>
        </PreLoader>
      </Row>
    </Content>
  );
};
CandidateAssessment.propTypes = {
  candidateInfo: PropTypes.object,
};

export default CandidateAssessment;
