import React from 'react';
import { Badge, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { ExclamationCircleFilled, CloseOutlined } from '@ant-design/icons';
import { Tag } from 'Components';
import { ColorBlindFriendlyRolesPalette } from 'Constants';

const RoleTags = ({ value, onClose, showCloseConfirm = true }) => {
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const closePopConfirm = () => {
    if (showCloseConfirm) {
      return {
        closeIcon: (
          <Popconfirm
            icon={<ExclamationCircleFilled style={{ color: 'red' }} />}
            title={`Are you sure you want to remove ${value} role for this user`}
            onConfirm={onClose}
            okText="Yes I'm Sure"
            cancelText="Cancel"
            onCancel={(event) => event.preventDefault()}
          >
            <CloseOutlined style={{ marginLeft: 10 }} />
          </Popconfirm>
        ),
      };
    }
    return {};
  };
  return (
    <Tag
      size="large"
      onMouseDown={onPreventMouseDown}
      style={{ marginRight: 3 }}
      {...closePopConfirm}
    >
      <Badge color={ColorBlindFriendlyRolesPalette[value]} />
      {value}
    </Tag>
  );
};

RoleTags.propTypes = {
  value: PropTypes.any,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseConfirm: PropTypes.bool,
};

export default RoleTags;
