import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const ColumnsCheckbox = ({ dataIndex, checked, title, onCheckboxChange }) => {
  return (
    <Checkbox
      onChange={(event) => {
        const checked = event.target.checked;
        onCheckboxChange(checked, dataIndex);
      }}
      key="column"
      checked={checked}
    >
      {title}
    </Checkbox>
  );
};

ColumnsCheckbox.propTypes = {
  dataIndex: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string,
  onCheckboxChange: PropTypes.func,
};

export default ColumnsCheckbox;
