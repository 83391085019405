import React from 'react';
import { Card, List, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;

const InstructionsVertical = (props) => {
  const { steps, title, description } = props;

  const instructionSteps = steps
    ? steps.map((step, idx) => {
        return {
          item: step,
          idx: idx,
        };
      })
    : [];

  return (
    <Card title={<Title level={5}>{title || 'Instructions'}</Title>}>
      {description || ''}
      <List
        dataSource={instructionSteps}
        itemLayout="horizontal"
        renderItem={(e) => {
          const { item, idx } = e;
          return (
            <List.Item>
              <List.Item.Meta
                title={`${idx + 1} - ${item.title}`}
                description={item.description}
              ></List.Item.Meta>
            </List.Item>
          );
        }}
      ></List>
    </Card>
  );
};

InstructionsVertical.propTypes = {
  steps: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default InstructionsVertical;
