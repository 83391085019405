import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Result, Modal, Steps, Progress, Row, Col, Statistic, Input, Form, message } from 'antd';
import { CSVReader } from 'react-papaparse';
import {
  UploadOutlined,
  DislikeTwoTone,
  LikeTwoTone,
  DownloadOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Card, Button, Table } from 'Components';
import { useStoreValue } from 'Context';
import { exportCsv, convertArrayToCsvData } from 'Helpers';
import { createUser } from 'Api/user-service';
import { createView } from 'Api/views-service';
import { Email } from 'Models';
import { makeShowable } from 'Utils';

const { Step } = Steps;

const BulkUploadModal = ({ visible, setVisible, updateViews }) => {
  const [bulkHumans, setBulkHumans] = useState([]);
  const bulkUploadRef = useRef();
  const [current, setCurrent] = React.useState(0);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [finalResult, setFinalResult] = useState({ success: [], failed: [] });
  const [{ user }] = useStoreValue();
  const [batchTag, setBatchTag] = React.useState(undefined);

  const columnsConfig = [
    {
      title: 'first_name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'last_name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'roles',
      dataIndex: 'roles',
      key: 'roles',
    },
  ];

  useEffect(() => {
    if (current === 2) {
      uploadHumans();
    }
  }, [current]);

  useEffect(() => {
    if (bulkHumans.length) next();
  }, [bulkHumans]);

  const next = () => {
    setCurrent(current + 1);
  };

  const uploadHumans = async () => {
    let successUploads = [];
    let failedUploads = [];
    for (const [index, row] of bulkHumans.entries()) {
      let human = {};
      const { first_name, last_name, email, phone, roles } = row;
      const userEmailObj = new Email(email);
      userEmailObj.is_primary = true;
      if (roles) {
        let newRoles = roles.split(',').reduce((acc, role) => {
          if (role) {
            const cleanedRole = role.trim();
            acc[cleanedRole] = { is_active: true };
          }
          return acc;
        }, {});
        human['roles'] = newRoles;
      }
      human = {
        ...human,
        first_name,
        last_name,
        phone,
        emails: [userEmailObj],
      };
      if (batchTag !== undefined) {
        human['custom_fields'] = {
          'Batch Tag': batchTag,
        };
      }
      try {
        await createUser(human, user.id);
        successUploads.push(row);
      } catch (e) {
        failedUploads.push({ ...row, error: e.message });
      }
      setPercentUploaded(Math.ceil(((index + 1) / bulkHumans.length) * 100));
    }
    try {
      const newView = {
        name: batchTag,
        filters: [
          {
            field: 'Batch Tag',
            constraints: [
              {
                operator: 'is',
                value: batchTag,
              },
            ],
          },
        ],
        permissions: {
          is_public: false,
          can_public_update: false,
          can_public_delete: false,
        },
        is_author_default: false,
        entity_type: 'user',
      };
      await createView(newView);
      message.success(makeShowable('VIEW_CREATED', 'views'));
      updateViews();
    } catch (err) {
      message.error(`Unable to create new view: ${err}`);
    }
    setFinalResult({ success: successUploads, failed: failedUploads });
  };

  const handleOpenFile = (e) => {
    // Note that the ref is set async, so it might be null at some poin
    if (bulkUploadRef.current) {
      bulkUploadRef.current.open(e);
    }
  };

  const papaparseOptions = {
    header: true,
    skipEmptyLines: true,
  };

  const firstStep = () => (
    <Result
      icon={<UploadOutlined style={{ color: 'darkgrey' }} />}
      title="Upload humans CSV"
      subTitle={
        <>
          <div style={{ marginBottom: 10 }}>
            Inorder to map data to correct destination, kindly upload the CSV in{' '}
            <a
              onClick={() =>
                exportCsv(`first_name,last_name,email,phone,roles\n`, `humans_bulk_template.csv`)
              }
            >
              below format{' '}
            </a>
            only
          </div>
          <Table
            size="small"
            pagination={false}
            bordered
            columns={columnsConfig}
            dataSource={[
              columnsConfig.reduce((acc, item) => {
                acc[item.key] = '';
                return acc;
              }, {}),
            ]}
          />
        </>
      }
      extra={[
        <CSVReader
          key="bulk-upload-button"
          ref={bulkUploadRef}
          parserOptions={papaparseOptions}
          onDrop={(data) => {
            const mappedHumans = data
              .map(({ data: item }) => {
                return { ...item };
              })
              .filter((row) => row.first_name && row.last_name && row.email);
            setBulkHumans(mappedHumans);
          }}
          config={{ header: true }}
          onError={() => {}}
          onRemoveFile={() => {}}
        >
          {() => (
            <Button onClick={handleOpenFile} type="primary" key="buy">
              Upload
            </Button>
          )}
        </CSVReader>,
      ]}
    />
  );

  const secondStep = () => {
    return (
      <div>
        <Row justify="center" align="center">
          <Col span={32}>
            <Table
              className="excel-like"
              size="small"
              pagination={false}
              bordered
              rowKey={({ id }) => id}
              columns={columnsConfig}
              dataSource={bulkHumans}
              scroll={{ y: 340 }}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }} align="center">
          <Col span={32}>
            <Form.Item label="Batch Tag">
              <Input
                onChange={(e) => {
                  setBatchTag(e.target.value);
                }}
                placeholder="Talent Sourcing Batch 2"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  const thirdStep = () => {
    return (
      <div className="steps-content" style={{ border: 'None' }}>
        <Row justify="center" align="center">
          <Col span={8}>
            <h3>{percentUploaded === 100 ? 'Upload Complete' : 'Uploading in progress'}</h3>
            <Progress strokeColor="#52c41a" type="circle" percent={percentUploaded} />
          </Col>
          {percentUploaded === 100 ? (
            <Col span={16}>
              <Row gutter={32}>
                <Col span={12}>
                  <Card>
                    <Statistic
                      title="Successful"
                      value={finalResult.success?.length}
                      suffix={`/${bulkHumans.length}`}
                      prefix={<LikeTwoTone twoToneColor="#52c41a" />}
                    />
                    <Button
                      onClick={() =>
                        exportCsv(
                          convertArrayToCsvData(finalResult.success),
                          `success_uploads_${new Date().toLocaleDateString('en-US')}.csv`
                        )
                      }
                      size="small"
                      type="link"
                    >
                      <DownloadOutlined /> Download Csv
                    </Button>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card>
                    <Statistic
                      prefix={<DislikeTwoTone twoToneColor="red" />}
                      title="Failed"
                      value={finalResult.failed?.length}
                      suffix={`/${bulkHumans.length}`}
                    />
                    <Button
                      onClick={() =>
                        exportCsv(
                          convertArrayToCsvData(finalResult.failed),
                          `failed_uploads_${new Date().toLocaleDateString('en-US')}.csv`
                        )
                      }
                      size="small"
                      type="link"
                    >
                      <DownloadOutlined /> Download Csv
                    </Button>
                  </Card>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  };

  const steps = [
    {
      title: 'Upload CSV',
      content: firstStep(),
    },
    {
      title: 'Verification & Push',
      content: secondStep(),
    },
    {
      title: 'Result',
      content: thirdStep(),
    },
  ];

  return (
    <Modal
      title={
        <>
          <span style={{ fontSize: 20 }}>📦 </span> Bulk Upload Humans
        </>
      }
      centered
      visible={visible}
      footer={false}
      onCancel={() => {
        setVisible(false);
        setCurrent(0);
      }}
      width={1000}
      maskClosable={false}
      destroyOnClose
    >
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current]?.content}</div>
      <div className="steps-action">
        {current === 1 && (
          <>
            <Button style={{ margin: '0 8px' }} onClick={() => setCurrent(0)}>
              <ReloadOutlined /> Re upload
            </Button>
            <Button type="primary" style={{ margin: '0 8px' }} onClick={() => setCurrent(2)}>
              <UploadOutlined /> Push
            </Button>
          </>
        )}
        {current === 2 && (
          <Button
            type="primary"
            style={{ margin: '0 8px', marginLeft: 'auto' }}
            onClick={() => {
              setCurrent(0);
              setVisible(false);
              setBulkHumans([]);
            }}
          >
            Done
          </Button>
        )}
      </div>
    </Modal>
  );
};

BulkUploadModal.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  updateViews: PropTypes.func,
};

export default BulkUploadModal;
