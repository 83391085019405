import React, { useEffect, useState } from 'react';
import { Row, Col, Result, message } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import { PageHeader, Content, Table, Button, Radio, Card } from 'Components';
import { getOutstandingTasks, getCompletedMeetingFeedback } from 'Api/tasks-service';
import { getUsers } from 'Api/user-service';
import { columns } from './outstanding_tasks_config';
import GeneratePendingForm from '../meeting-feedback-view/generate-pending-form';
import { get } from 'lodash';
import './outstanding-tasks.less';

import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const defaultView = 'pending';
const defaultPagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

const OutstandingTasks = () => {
  const [currentView, setCurrentView] = useState(defaultView);
  const [pagination, setPagination] = useState(defaultPagination);
  const [meetingFeedbackRequests, setMeetingFeedbackRequests] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [emailToUserMap, setEmailToUserMap] = useState({});
  const [phoneToUserMap, setPhoneToUserMap] = useState({});
  const [completedFormsData, setCompletedFormsData] = useState([]);
  const [numCompletedFormsDisplay, setNumCompletedFormsDisplay] = useState(10);

  const preprocessAndSetTasks = async (resp) => {
    const data = resp.data.tasks;
    const emailUsermap = resp.data.email_to_meta_map;

    // get all unique ids
    const participantPhoneNumberSet = new Set();
    data.map((task) => {
      task.other_data.attendees.map((p) => {
        if (p.phoneNumber) {
          participantPhoneNumberSet.add(p.phoneNumber);
        }
      });
      task.other_data.description = get(task, 'other_data.description', '')
        ?.replaceAll(/<tel:+.*>/g, '')
        .replaceAll(/<https?:.+>/g, '');
      task.key = task.other_data.id;
    });

    setEmailToUserMap(emailUsermap ? emailUsermap : {});

    if (participantPhoneNumberSet.size > 0) {
      const phoneNumberFilter = [
        {
          field: 'phone',
          constraints: Array.from(participantPhoneNumberSet).map((phoneNumber) => {
            return {
              operator: 'contains',
              key: null,
              value: phoneNumber,
            };
          }),
        },
      ];
      const users = await getUsers(
        null,
        null,
        { filters: phoneNumberFilter, entity_type: 'user' },
        { make_event: false }
      );
      const userList = users.data;
      const phoneNumberMap = {};
      Array.from(participantPhoneNumberSet).map((phoneNumber) => {
        userList.forEach((user) => {
          if (user.phone.includes(phoneNumber)) {
            phoneNumberMap[phoneNumber] = user;
          }
        });
      });
      setPhoneToUserMap(phoneNumberMap);
    }
    setMeetingFeedbackRequests(data);
  };

  const preprocessAndSetFeedback = (resp) => {
    const data = resp.data;
    setCompletedFormsData(data);
  };

  // run this once on component load
  useEffect(async () => {
    try {
      setTableLoading(true);
      await handlePendingTableChange();

      const completedFeedbackResp = await getCompletedMeetingFeedback();
      preprocessAndSetFeedback(completedFeedbackResp);
    } catch (err) {
      console.log(err);
      message.error('Unable to fetch outstanding tasks');
    }
  }, []);

  const handleViewChange = (event) => {
    const { value: viewName } = event.target;
    if (viewName) setCurrentView(viewName);
    if (viewName === 'pending') {
      setNumCompletedFormsDisplay(10);
    }
  };

  const handlePendingTableChange = async (pagination = defaultPagination) => {
    const { current: page_num, pageSize: page_size } = pagination;
    setTableLoading(true);
    const resp = await getOutstandingTasks(null, { page_num, page_size });
    await preprocessAndSetTasks(resp);
    setTableLoading(false);
    const headers = resp.headers;
    const {
      page_num: current,
      page_size: pageSize,
      total_records: total,
    } = JSON.parse(headers['x-pagination']);
    const updatedPaginationInfo = { current, pageSize, total };
    setPagination(updatedPaginationInfo);
  };

  const handleShowMoreFeedback = () => {
    const moreCompletedFeedbacksToDisplay = numCompletedFormsDisplay + 10;
    setNumCompletedFormsDisplay(moreCompletedFeedbacksToDisplay);
  };

  const handleShowAllFeedback = () => {
    setNumCompletedFormsDisplay(completedFormsData.length);
  };

  const switchView = (currentView) => {
    let completedForms = [];
    if (currentView === 'completed') {
      completedForms = completedFormsData.map((item) => (
        <Card spacing="small" key={item.id} style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <GeneratePendingForm
            eventData={item}
            onFormSubmit={() => {
              console.log('Should not be able to submit');
            }}
            viewOnly={true}
          />
        </Card>
      ));
    }
    switch (currentView) {
      case 'pending':
        return (
          <Content>
            <Table
              loading={tableLoading}
              onChange={handlePendingTableChange}
              pagination={pagination}
              columns={columns(emailToUserMap, phoneToUserMap)}
              dataSource={meetingFeedbackRequests}
            ></Table>
          </Content>
        );
      case 'completed':
        return completedForms.length > 0 ? (
          // EOD
          <ResponsiveMasonry
            style={{ margin: 20 }}
            columnsCountBreakPoints={{ 350: 1, 950: 2, 1050: 3 }}
          >
            <Masonry columnsCount={3} gutter={'20px'}>
              {completedForms.slice(0, numCompletedFormsDisplay)}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <Result status="success" title={'No Completed Feedback Yet'} />
        );
      default:
        return setCurrentView(defaultView);
    }
  };

  return (
    <div>
      <Row>
        <Col span={24} className={'outstanding-container'}>
          <PageHeader
            sticky
            title="Outstanding Tasks"
            extra={[
              <Button
                type="link"
                key="3"
                icon={<FormOutlined />}
                onClick={() => window.open('feedback-pending', '_blank')}
              >
                Fill All Feedback
              </Button>,
              <Radio.Group
                value={currentView}
                key="radio-buttons"
                onChange={handleViewChange}
                buttonStyle="solid"
              >
                <Radio.Button value="pending">Pending</Radio.Button>
                <Radio.Button value="completed">Completed</Radio.Button>
              </Radio.Group>,
            ].concat(
              currentView === 'completed'
                ? [
                    <Button
                      disabled={numCompletedFormsDisplay >= completedFormsData.length}
                      onClick={handleShowMoreFeedback}
                      key="show-more-completed-form-btn"
                      size="middle"
                      shape="round"
                    >
                      Show More
                    </Button>,
                    <Button
                      disabled={numCompletedFormsDisplay >= completedFormsData.length}
                      onClick={handleShowAllFeedback}
                      key="show-all-completed-form-btn"
                      size="middle"
                      shape="round"
                    >
                      Show All ({completedFormsData.length})
                    </Button>,
                  ]
                : []
            )}
          ></PageHeader>
          {switchView(currentView)}
        </Col>
      </Row>
    </div>
  );
};

export default OutstandingTasks;
