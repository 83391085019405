import React, { useState } from 'react';
import { Form } from 'antd';
import { forgotPassword } from 'Api/auth-service';
import { Link } from 'react-router-dom';
import { Button, Card, Input } from 'Components';
import { makeShowable } from 'Utils';
import EmailSentCard from './email-sent';

const ForgotPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successScreenData, setSuccessScreenData] = useState({
    show: false,
    email: null,
  });
  const [error, setError] = useState(null);

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);
    setError(null);
    try {
      await forgotPassword(data);
      setSuccessScreenData({ show: true, email: data.email });
    } catch (e) {
      if (e.message === 'SERVER_ERROR') {
        setError(makeShowable(e.message, 'email_verification'));
      } else if (e.message === 'NOT_FOUND') {
        setError(makeShowable('NOT_FOUND', 'forgot_password'));
      } else {
        setError(`Something Went Wrong: ${e.message}`);
      }
    }
    setIsSubmitting(false);
  };

  if (successScreenData.show) {
    return <EmailSentCard emailType="forgot_password" email={successScreenData.email} />;
  }

  return (
    <div className="auth_container">
      <Card
        title={
          <span style={{ fontWeight: 200, fontSize: 24, letterSpacing: 1.2 }}>Forgot Password</span>
        }
        style={{ width: 350 }}
      >
        <div className="auth-card-header">
          <p>
            or{' '}
            <Link className="anchor_link" to="/login">
              log in
            </Link>
          </p>
        </div>

        <div className="auth-card-subheader">
          <p>Enter your email below to receive a password-reset email</p>
        </div>

        <Form
          name="forgot-password"
          className="forgot-password-form"
          initialValues={{ email: '' }}
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            className="auth-form-item"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="eg: abc@email.com" type="email" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={isSubmitting} loading={isSubmitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>
        {error ? <p className="auth-form-error">{error}</p> : null}
      </Card>
    </div>
  );
};

export default ForgotPassword;
