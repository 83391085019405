import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components';
import { useStoreValue } from 'Context';
import { newCandidateSchema } from 'Schemas/candidate-schema';
import { createUser } from 'Api/user-service';
import { Email } from 'Models';
import { generateFormFields } from 'Helpers';

const AddHuman = ({
  showModal,
  setShowModal,
  redirect = true,
  onSubmit = null,
  setResult = null,
}) => {
  const history = useHistory();
  const [{ user }] = useStoreValue();
  const [addHumanForm] = Form.useForm();

  const addNewHuman = async (values) => {
    try {
      const { email, ...rest } = values;
      const userEmailObj = new Email(email);
      userEmailObj.is_primary = true;
      const {
        data: { id },
      } = await createUser({ ...rest, emails: [userEmailObj] }, user.id);
      message.success('User created successfully', 1);
      message.loading('Setting up user profile', 2);
      setShowModal(false);
      if (onSubmit) {
        onSubmit(
          `${addHumanForm.getFieldValue('first_name')} ${addHumanForm.getFieldValue('last_name')}`
        );
        if (setResult) {
          setResult({
            label: `${values['first_name']} ${values['last_name']}`,
            value: id,
            key: id,
          });
        }
      }
      if (redirect) {
        history.push(`/human/${id}`);
      }
      addHumanForm.resetFields();
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title="Add New User"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={addHumanForm}
        onFinish={addNewHuman}
        layout="vertical"
        initialValues={{
          jobStatus: 'ACTIVE',
          positionType: 'Full-Time',
        }}
      >
        <Row gutter={[24]}>{generateFormFields(newCandidateSchema)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddHuman.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  redirect: PropTypes.bool,
  onSubmit: PropTypes.func,
  setResult: PropTypes.func,
};

export default AddHuman;
