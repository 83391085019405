import React from 'react';
import { Tag as AntdTag } from 'antd';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './tag.less';

const Tag = ({ type, className, ...rest }) => (
  <AntdTag className={cx(className, type)} {...rest}></AntdTag>
);

Tag.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};

export default Tag;
