import React from 'react';
import { Col, Form, Select, Divider, Checkbox, Radio, Space } from 'antd';
import { Input, SelectSearch } from 'Components';
import { getViews } from 'Api/views-service';
import { omit } from 'lodash';

const { Option } = Select;

const generateFormItemFieldType = (field, formInstance) => {
  const {
    name,
    type,
    allowed_values = [],
    multi_valued,
    initial_value,
    size = 'large',
    textColor = 'black',
    setState = undefined,
    buttonStyle = 'default',
    optionType = 'default',
    notFoundOverrideContent = null,
    disabled = false,
    ...rest
  } = field;

  return type === 'search' ? (
    <SelectSearch
      mode={multi_valued ? 'multiple' : null}
      value={formInstance?.getFieldValue(name)}
      onChange={(value) => {
        // setValue func below isnt enough when
        // mode = multiple
        // because it doesn't ejects prev values
        if (multi_valued) {
          formInstance?.setFieldsValue({
            [name]: value,
          });
        }
      }}
      notFoundOverride={notFoundOverrideContent}
      setValue={(newValue) => {
        // when we click an item from search results
        formInstance?.setFieldsValue({
          [name]: newValue,
        });
        if (setState !== undefined) setState(newValue);
      }}
      disabled={disabled}
      {...rest}
      size={size}
      initial_value={initial_value}
    />
  ) : type === 'select' ? (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      size={size}
      mode={multi_valued ? 'multiple' : null}
      disabled={disabled}
      {...rest}
    >
      {generateSelectOptions(allowed_values)}
    </Select>
  ) : type === 'divider' ? (
    <Divider
      dashed
      style={{
        borderColor: textColor,
        color: textColor,
      }}
    >
      {name}
    </Divider>
  ) : type === 'checkbox' ? (
    <Checkbox
      defaultChecked={formInstance?.getFieldValue(name) || false}
      onChange={(e) => formInstance?.setFieldsValue({ [name]: e.target.checked })}
      disabled={disabled}
    >
      {initial_value}
    </Checkbox>
  ) : // by default radio & checkbox groups below render vertically
  // can be updated dynamically in the future
  type === 'radio-group' ? (
    <Radio.Group
      onChange={(e) => formInstance?.setFieldsValue({ [name]: e.target.value })}
      optionType={optionType}
      buttonStyle={buttonStyle}
      disabled={disabled}
    >
      <Space direction="vertical">{generateGroupOptions(allowed_values, 'radio')}</Space>
    </Radio.Group>
  ) : type === 'checkbox-group' ? (
    <Checkbox.Group
      onChange={(checkedValues) => formInstance?.setFieldsValue({ [name]: checkedValues })}
      disabled={disabled}
    >
      <Space direction="vertical">{generateGroupOptions(allowed_values, 'checkbox')}</Space>
    </Checkbox.Group>
  ) : (
    <Input allowClear type={type} size={size} disabled={disabled} {...rest} />
  );
};

const generateFieldByConfig = (field, formInstance) => {
  const { name, label, required = false, span, initial_value, extra, conditional_item } = field;

  return (
    <React.Fragment key={name}>
      <Col span={span}>
        <Form.Item
          name={name}
          label={label}
          initialValue={initial_value}
          rules={[
            {
              required: required,
              message: `${label} is required`,
            },
          ]}
          extra={extra}
        >
          {/* omitting unwanted fields for regular form items to avoid warnings */}
          {generateFormItemFieldType(omit(field, ['when_to_show', 'when_to_update']), formInstance)}
        </Form.Item>
      </Col>
      {conditional_item ? generateConditionalItem(conditional_item, formInstance) : null}
    </React.Fragment>
  );
};

const generateConditionalItem = (conditional_items, formInstance) => {
  return conditional_items.map((conditional_item) => {
    const {
      span = 24,
      name,
      label,
      when_to_update,
      when_to_show,
      initial_value,
      required = false,
      conditional_item: nested_conditional_item,
    } = conditional_item;

    // passing unanticipated keys to generateFormItemFieldType will cause error
    delete conditional_item.when_to_show;
    delete conditional_item.when_to_update;

    const rules = [{ required: required, message: `${label} is required` }];
    return (
      <React.Fragment key={name}>
        <Col span={span}>
          <Form.Item noStyle shouldUpdate={when_to_update} rules={rules}>
            {({ getFieldValue }) =>
              when_to_show(getFieldValue) ? (
                <Form.Item name={name} label={label} initialValue={initial_value} rules={rules}>
                  {generateFormItemFieldType(conditional_item)}
                </Form.Item>
              ) : null
            }
          </Form.Item>
        </Col>
        {nested_conditional_item
          ? generateConditionalItem([nested_conditional_item], formInstance)
          : null}
      </React.Fragment>
    );
  });
};

const generateFormFields = (fields, formInstance) => {
  return fields.map((field) => generateFieldByConfig(field, formInstance));
};

const exportCsv = async (csvData, fileName) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  return;
};

const convertArrayToCsvData = (itemsArray) => {
  // array of objects
  if (!itemsArray.length) return [];
  const csvHeaders = Object.keys(itemsArray[0]);
  let csvString = [csvHeaders, ...itemsArray.map((item) => csvHeaders.map((attr) => item[attr]))]
    .map((e) => e.join(','))
    .join('\n');
  return csvString;
};

const generateSelectOptions = (allowedValues) => {
  return allowedValues
    .map((allowedVal) => {
      if (typeof allowedVal === 'string' || typeof allowedVal === 'number') {
        return { display_hint_primary: allowedVal, value: allowedVal };
      }
      return allowedVal;
    })
    .map(({ display_hint_primary, value }, index) => (
      <Option key={index} value={value}>
        {display_hint_primary || value}
      </Option>
    ));
};

const generateGroupOptions = (allowedValues, type) => {
  const groupType = {
    radio: Radio,
    checkbox: Checkbox,
  };
  if (!Object.keys(groupType).includes(type)) return null;
  const CurrentOptionType = groupType[type];
  return allowedValues
    .map((allowedVal) => {
      if (typeof allowedVal === 'string' || typeof allowedVal === 'number') {
        return { display_hint_primary: allowedVal, value: allowedVal };
      }
      return allowedVal;
    })
    .map(({ display_hint_primary, value }, index) => (
      <CurrentOptionType key={index} value={value}>
        {display_hint_primary || value}
      </CurrentOptionType>
    ));
};

const pullAndSetViews = async (entity, userId, setViewsFunc) => {
  try {
    let { data: viewsList = [] } = await getViews(userId);
    let entityViews = [];
    if (viewsList) {
      entityViews = viewsList.filter(({ entity_type }) => entity_type === entity);
      setViewsFunc(entityViews);
    }
    return entityViews;
  } catch (err) {
    console.log(err);
  }
};

const packLinkedinUrl = (rawLinkedin) => {
  return rawLinkedin && rawLinkedin.includes('www.linkedin.com')
    ? rawLinkedin
    : `https://www.linkedin.com/in/${rawLinkedin}/`;
};

const displayNameUser = (user) => {
  if (!user) return 'UNKNOWN';
  if (user.first_name && user.last_name) return `${user.first_name} ${user.last_name}`;
  if (user.first_name) return user.first_name;
  if (user.last_name) return user.last_name;

  if (user.emails && user.emails.length > 0) {
    const primary_email = user.emails.filter((emailObj) => emailObj.is_primary);
    if (primary_email && primary_email.length > 0) return primary_email[0].email;
    return user.emails[0].email;
  }

  if (user.phone) return user.phone;

  return 'UNKNOWN';
};

export {
  generateFormFields,
  exportCsv,
  generateSelectOptions,
  convertArrayToCsvData,
  pullAndSetViews,
  packLinkedinUrl,
  generateGroupOptions,
  displayNameUser,
};
