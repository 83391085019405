import React, { useState, useEffect } from 'react';
import { Col, Row, Avatar, message, Space, Tooltip, Modal, Result } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { isEmpty, startCase, pickBy, identity } from 'lodash';
import {
  LinkedinFilled,
  FilePdfTwoTone,
  PaperClipOutlined,
  ShareAltOutlined,
  LockOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  Button,
  Radio,
  NotesWindow,
  PageHeader,
  ThreeSections,
  AllPOCs,
  FileUpload,
  ProfileTimeline,
} from 'Components';
import { getUserProfile, updateUser } from 'Api/user-service';
import { generateDeeplinkToken, getDeeplinkInfo } from 'Api/common-service';
import CandidateAssessment from './components/candidate-assessment';
import HumanProfile from './components/human-profile';
import './human-view.less';
import { ColorBlindFriendlyRolesPalette, HumanEventConfig } from 'Constants';
import { packLinkedinUrl } from 'Helpers';
import { newHumanEventFormSchema } from 'Schemas/event-schema';
import { getRelationshipTypes, listRelationships } from 'Api/relationship-service';
import DedupeModal from './components/dedupe-modal';
import { useStoreValue } from 'Context';
import SecurityTable from 'Components/security/security-table.js';

const { MainWrapper, Body, RightSider } = ThreeSections;
const defaultView = 'profile';

const HumanView = () => {
  const [currentView, setCurrentView] = useState(defaultView);
  const [candidateInfo, setCandidateInfo] = useState();
  const [foundCandidate, setFoundCandidate] = useState(true);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showDedupeModal, setShowDedupeModal] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const location = useLocation();
  let { id: userId } = useParams();
  if (!userId) {
    const [{ user }] = useStoreValue();
    userId = user.id;
  }
  const resumeUploadUrl = `${process.env.REACT_APP_API_URL}/user/${userId}/resume`;

  useEffect(async () => {
    const userData = await getCandidateInfo();
    setShowDedupeModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const deeplinkToken = new URLSearchParams(location.search).get('deep_link_token');
    if (!isEmpty(deeplinkToken)) {
      try {
        const { data } = await getDeeplinkInfo(deeplinkToken);
        setCurrentView(data.component);
        if (data?.show_dedup_modal && userData?.potential_duplicates?.length)
          setShowDedupeModal(true);
      } catch (err) {
        console.log(err);
        message.warn('Invalid Deeplink Token');
      }
    }
  }, [userId]);

  const generateSocialIcons = () => {
    if (candidateInfo) {
      const { resume, linkedins, potential_duplicates } = candidateInfo;
      return [
        <Space key={1}>
          {linkedins ? (
            <a rel="noreferrer" target="_blank" href={packLinkedinUrl(linkedins)}>
              <LinkedinFilled style={{ fontSize: '24px', color: '#0072b1' }} />
            </a>
          ) : null}
          {resume ? (
            <a rel="noreferrer" target="_blank" href={resume}>
              <FilePdfTwoTone twoToneColor={'#D8221B'} style={{ fontSize: '24px' }} />
            </a>
          ) : null}
          <>
            <Button
              onClick={() => setShowResumeModal(!showResumeModal)}
              style={{ padding: 'unset' }}
              type="link"
            >
              <PaperClipOutlined style={{ fontSize: '20px' }} />
            </Button>
            <FileUpload
              title="Attach Resume"
              showModal={showResumeModal}
              setShowModal={setShowResumeModal}
              uploadUrl={resumeUploadUrl}
              validFileTypes={['pdf', 'doc', 'docx']}
              onUpdate={(resume) =>
                setCandidateInfo({
                  ...candidateInfo,
                  resume,
                })
              }
              userId={userId}
            />
            <Button
              onClick={() => setShowSecurityModal(!showSecurityModal)}
              style={{ padding: 'unset' }}
              type="link"
            >
              <LockOutlined style={{ fontSize: '20px' }} />
            </Button>
          </>
          {!isEmpty(potential_duplicates) ? (
            <Button
              size="small"
              className="ant-tag-volcano"
              onClick={() => setShowDedupeModal(true)}
            >
              {`${potential_duplicates.length} Duplicate Found*`}
            </Button>
          ) : null}
        </Space>,
      ];
    }
    return [];
  };

  const getShortInfo = () => {
    if (!candidateInfo) return '';
    const { work_history = [], edu_history = [] } = candidateInfo;
    let workDetails = work_history.reduce((acc, currentItem) => {
      const { other_data } = currentItem;
      if (other_data) {
        const { job_title, org_name } = other_data;
        const jobInfo =
          job_title && org_name
            ? `${job_title} at ${org_name} • `
            : job_title && !org_name
            ? `${job_title} • `
            : !job_title && org_name
            ? `Works at ${org_name} • `
            : '';
        acc = acc + jobInfo;
      }
      return acc;
    }, '');
    let eduDetails = edu_history.reduce((acc, currentItem) => {
      const { other_data } = currentItem;
      if (other_data) {
        const { end, org_name } = other_data;
        const eduInfo = org_name ? `🎓 ${org_name} ${end?.year || ''} • ` : '';
        acc = acc + eduInfo;
      }
      return acc;
    }, '');
    return workDetails + eduDetails;
  };

  const handleViewChange = (event) => {
    const { value: viewName } = event.target;
    if (viewName) setCurrentView(viewName);
  };

  const getCandidateInfo = async () => {
    try {
      let { data } = await getUserProfile(userId);
      let { data: relationshipTypes } = await getRelationshipTypes();
      const relTypeNameToId = {};
      relationshipTypes.map((relType) => {
        relTypeNameToId[relType.name] = relType.id;
      });
      const { data: candidate_intros } = await listRelationships(
        relTypeNameToId['matched_to'],
        userId
      );
      const { data: candidate_interviews } = await listRelationships(
        relTypeNameToId['interviewing_with'],
        userId
      );
      data = { ...data, intros: candidate_intros, interviews: candidate_interviews };
      setCanEdit(data?.flags?.can_write || false);
      setCandidateInfo(data);
      return data;
    } catch (err) {
      setCanEdit(false);
      setFoundCandidate(false);
      if (err.message.includes('not found')) {
        message.info('Candidate Not Found');
      }
    }
  };

  const recursiveFormat = (obj, isField, formatField) => {
    if (isEmpty(obj) || obj === 'null') {
      return obj;
    }

    if (isField(obj)) {
      obj = formatField(obj);
      return obj;
    }

    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        obj[key] = recursiveFormat(obj[key], isField, formatField);
      });
    }
    return obj;
  };

  const convertToBackendDate = (rawMoment) => {
    let momentObj = {
      year: rawMoment.year(),
      month: rawMoment.month(),
      date: rawMoment.date(),
      // "hour": rawMoment["hours"],
      // "minute": rawMoment["minutes"],
      // "second": rawMoment["second"],
      // "millisecond": rawMoment["milliseconds"]
    };
    return pickBy(momentObj, identity);
  };

  const updateCandidateInfo = async (updatedInfo) => {
    try {
      recursiveFormat(updatedInfo, (x) => moment.isMoment(x) && x.isValid(), convertToBackendDate);
      const { data } = await updateUser(candidateInfo.id, updatedInfo);
      setCandidateInfo(data);
      message.success('User info updated successfully');
    } catch (err) {
      console.log(err);
      message.error(err?.message || 'Something went wrong');
    }
  };

  const switchView = (currentView) => {
    if (!foundCandidate) {
      return <Result status="warning" title="Something went wrong" />;
    }
    switch (currentView) {
      case 'profile':
        return (
          <HumanProfile
            canEdit={canEdit}
            candidateInfo={candidateInfo}
            updateCandidateInfo={updateCandidateInfo}
          />
        );
      case 'timeline':
        return (
          <ProfileTimeline
            eventConfig={HumanEventConfig}
            timelineClassName={'candidate-timeline'}
            eventFormSchema={newHumanEventFormSchema}
            entityType={'user'}
            entityId={parseInt(userId)}
          />
        );
      case 'assessment':
        return <CandidateAssessment candidateInfo={candidateInfo} />;
      default:
        return setCurrentView(defaultView);
    }
  };

  const getRoles = () => {
    if (!candidateInfo || !('roles' in candidateInfo)) {
      return null;
    }
    candidateInfo.roles['member'] = candidateInfo.id;
    return Object.keys(candidateInfo.roles)
      .sort()
      .filter((role) => candidateInfo.roles[role])
      .map((role) => (
        <Tooltip key={role} title={role} placement="top">
          <Avatar
            style={{
              backgroundColor: ColorBlindFriendlyRolesPalette[role],
              marginRight: 'unset',
            }}
          >
            {`${role[0].toUpperCase()}${role[1].toLowerCase()}`}
          </Avatar>
        </Tooltip>
      ));
  };

  const generateDeeplink = async () => {
    const deeplinkData = {
      component: currentView,
    };
    const { data: deeplinkToken } = await generateDeeplinkToken(deeplinkData);
    let baseURL = window.location.origin;
    if (window.location.pathname === '/profile') {
      baseURL += '/human/' + userId;
    } else {
      baseURL += window.location.pathname;
    }
    const deeplink = baseURL + `?deep_link_token=${deeplinkToken}`;
    navigator.clipboard.writeText(deeplink);
    message.info('Link Copied');
  };

  return (
    <MainWrapper>
      <Body span={18} style={{ scrollPaddingTop: '125px' }}>
        <PageHeader
          sticky
          title={
            <Row>
              <Avatar.Group>{getRoles()}</Avatar.Group>
              <span style={{ marginLeft: 10 }}>
                {candidateInfo?.first_name} {candidateInfo?.last_name}
              </span>
            </Row>
          }
          tags={generateSocialIcons()}
          extra={
            foundCandidate
              ? [
                  <Button
                    onClick={generateDeeplink}
                    key="share-button"
                    style={{ padding: 'unset' }}
                    type="link"
                  >
                    <ShareAltOutlined style={{ fontSize: '20px' }} />
                  </Button>,
                  <Radio.Group
                    value={currentView}
                    key="radio-buttons"
                    onChange={handleViewChange}
                    buttonStyle="solid"
                  >
                    {['profile', 'timeline' /*,'assessment'*/].map((submenu) => (
                      <Radio.Button key={submenu} value={submenu}>
                        {startCase(submenu)}
                      </Radio.Button>
                    ))}
                  </Radio.Group>,
                ]
              : []
          }
        >
          <Row justify="space-between">
            <Col>
              <h4
                style={{
                  marginBottom: 0,
                  marginRight: '12px',
                  color: '#00000073',
                  fontSize: '14px',
                  lineHeight: 1.5715,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {getShortInfo()}
              </h4>
            </Col>
            <Col>
              <AllPOCs
                canEdit={canEdit}
                pocs={candidateInfo?.hc_pocs || []}
                entityId={parseInt(userId)}
              />
            </Col>
          </Row>
        </PageHeader>
        {switchView(currentView)}
      </Body>
      <RightSider>
        <NotesWindow
          notesInfo={candidateInfo?.notes || []}
          updateNotes={(updatedNotes) => updateCandidateInfo({ notes: updatedNotes })}
        ></NotesWindow>
      </RightSider>
      <DedupeModal
        showModal={showDedupeModal}
        setShowModal={setShowDedupeModal}
        duplicates={candidateInfo?.potential_duplicates}
      />
      <Modal
        footer={false}
        title="🔐  Security"
        visible={showSecurityModal}
        onCancel={() => setShowSecurityModal(false)}
        width={600}
        className="security-modal"
        closable
      >
        {/* this ensures that, security can be called from anywhere 
        from different components and pages, Hence Modal is not part of it */}
        <SecurityTable
          updateHuman={updateCandidateInfo}
          securityId={candidateInfo?.security_id}
        ></SecurityTable>
      </Modal>
    </MainWrapper>
  );
};

export default HumanView;
