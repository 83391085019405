import { request } from './http-service';

const getPolicyBySecurityId = (securityId) => {
  return request({
    url: `/security/${securityId}`,
    method: 'get',
  });
};

const updatePolicy = (data) => {
  return request({
    url: `/security/`,
    method: 'post',
    data,
  });
};

const getPolicyByShortcutName = (shortcutName) => {
  return request({
    url: `/security/shortcut_name/${shortcutName}`,
    method: 'get',
  });
};

export { getPolicyBySecurityId, updatePolicy, getPolicyByShortcutName };
