import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select, Divider } from 'antd';
import { MinusCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Input, Button } from 'Components';

function CustomFieldPair({
  customFieldsList = [],
  handleFieldChange,
  remove,
  fieldSet,
  setCustomFieldList,
}) {
  const [newFieldValue, setNewFieldValue] = useState(undefined);
  const defaultField = customFieldsList[0] || {};
  const { key, fieldName, value: fieldValue } = fieldSet;
  const { Option } = Select;
  const fieldConfig = customFieldsList.find(({ name }) => name === fieldName) || defaultField;

  const updateCustomFieldList = () => {
    if (!newFieldValue) return false;
    const valueExists = fieldConfig?.allowed_values?.includes(newFieldValue);
    if (!valueExists) {
      let tempFieldList = [...customFieldsList];
      const indexToUpdate = tempFieldList.findIndex(({ name }) => name === fieldName);
      tempFieldList[indexToUpdate]['allowed_values'] = [
        ...fieldConfig.allowed_values,
        newFieldValue,
      ];
      setCustomFieldList(tempFieldList);
      handleFieldChange(key, 'value', newFieldValue);
      return setNewFieldValue(undefined);
    }
    return handleFieldChange(key, 'value', newFieldValue);
  };

  return (
    <Row style={{ marginBottom: 5 }} justify="space-between">
      <Col span={11}>
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          value={fieldName}
          showSearch
          size="large"
          style={{ width: 200 }}
          placeholder="Select Field"
          onChange={(value) => handleFieldChange(key, 'fieldName', value)}
        >
          {customFieldsList.map(({ name }) => (
            <Option key={name} value={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={11}>
        {fieldConfig?.allowed_values?.length > 0 ? (
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            allowClear
            showSearch
            size="large"
            style={{ width: 200 }}
            placeholder="Select Option"
            value={fieldValue}
            onChange={(value) => handleFieldChange(key, 'value', value)}
            dropdownRender={
              fieldConfig.allow_new_values
                ? (menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          alignItems: 'center',
                          padding: 8,
                        }}
                      >
                        <Input
                          placeholder="Add new value"
                          value={newFieldValue}
                          onChange={({ target: { value } }) => setNewFieldValue(value)}
                          onPressEnter={updateCustomFieldList}
                        />

                        <Button
                          style={{ marginLeft: 4 }}
                          type="primary"
                          shape="circle"
                          onClick={updateCustomFieldList}
                          icon={<CheckOutlined />}
                          size="middle"
                          disabled={!newFieldValue}
                        ></Button>
                      </div>
                    </div>
                  )
                : null
            }
          >
            {fieldConfig.allowed_values.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        ) : (
          <Input
            defaultValue={fieldValue}
            onChange={(event) => {
              const { value } = event?.target;
              handleFieldChange(key, 'value', value);
            }}
            placeholder="Add Value"
          />
        )}
      </Col>
      <Col span={2}>
        <MinusCircleOutlined onClick={() => remove(key)} />
      </Col>
    </Row>
  );
}

CustomFieldPair.propTypes = {
  fieldSet: PropTypes.object,
  customFieldsList: PropTypes.array,
  remove: PropTypes.func,
  handleFieldChange: PropTypes.func,
  setCustomFieldList: PropTypes.func,
};

export default CustomFieldPair;
