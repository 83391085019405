import { UsStates, Genders, PublicUserRoles } from 'Constants';
import { startCase } from 'lodash';
import { Tooltip } from 'antd';

const candidateProfileSchema = [
  {
    label: 'Basic Info',
    name: 'basic_info',
    section: 'basic_info',
    type: 'flat',
    icon: '👤',
    subFields: [
      {
        label: 'First Name',
        name: 'first_name',
        type: 'text',
        span: 8,
      },
      {
        label: 'Last Name',
        name: 'last_name',
        type: 'text',
        span: 8,
      },
      {
        label: 'Gender',
        name: 'gender',
        type: 'select',
        span: 8,
        allowed_values: Genders.map((genderVal) => {
          return {
            display_hint_primary: startCase(genderVal),
            value: genderVal,
          };
        }),
      },

      {
        label: 'Phone',
        name: 'phone',
        type: 'text',
        span: 8,
      },
      {
        label: 'LinkedIn',
        name: 'linkedins',
        type: 'text',
        span: 8,
      },
      {
        label: 'DOB',
        name: 'dob',
        type: 'date',
        span: 8,
        be_format_type: 'string',
      },

      {
        label: 'Primary Email',
        name: 'emails',
        type: 'emails',
        span: 24,
      },
      {
        label: 'Role',
        name: 'roles',
        type: 'roles',
        mode: 'multiple',
        span: 24,
        allowed_values: PublicUserRoles,
      },
      {
        label: '✅  Action Items',
        name: 'action_items',
        type: 'action-items',
        mode: 'tags',
        span: 24,
        placeholder: 'Add action items for human',
        tagRender: ({ label, onClose }) => {
          return (
            <Tooltip title={label}>
              <span className="ant-select-selection-item">
                <span className="ant-select-selection-item-content">{label}</span>
                <span className="ant-select-selection-item-remove" onClick={onClose}>
                  X
                </span>
              </span>
            </Tooltip>
          );
        },
      },
      {
        label: 'Quick Notes',
        name: 'quick_notes',
        type: 'textarea',
        span: 24,
      },
    ],
  },
  {
    label: 'Intros',
    name: 'intros',
    type: 'related-table',
    section: 'intros',
    icon: '🤝',
  },
  {
    label: 'Interviews',
    name: 'interviews',
    type: 'related-table',
    section: 'interviews',
    icon: '💡',
  },
  {
    label: 'Address',
    name: 'addresses',
    type: 'nested',
    section: 'addresses',
    icon: '🏠',
    subFields: [
      {
        label: 'Street',
        name: 'street',
        type: 'text',
        span: 12,
      },
      {
        label: 'City',
        name: 'city',
        type: 'text',
        span: 12,
      },
      {
        label: 'State',
        name: 'state',
        type: 'select',
        span: 12,
        allowed_values: UsStates,
        showSearch: true,
      },
      {
        label: 'Country',
        name: 'country',
        type: 'text',
        span: 12,
      },
    ],
  },
  {
    label: 'Edu History',
    name: 'edu_history',
    section: 'edu_history',
    type: 'nested',
    icon: '🎓',
    subFields: [
      {
        label: 'School',
        name: 'other_data::org_name',
        type: 'search',
        search_entity: 'organization',
        span: 8,
        primary_key: 'destination_organization_id',
      },
      // hidden fields just for holding meta data
      {
        name: 'destination_organization_id',
        type: 'number',
        search_entity: 'organization',
        span: 0,
      },
      {
        name: 'relationship_type_id',
        type: 'number',
        span: 0,
      },
      {
        name: 'id',
        type: 'number',
        span: 0,
      },
      {
        name: 'relationship_type_name',
        type: 'number',
        span: 0,
      },
      // hidden end
      {
        label: 'Degree',
        name: 'other_data::degree',
        type: 'text',
        span: 8,
      },
      {
        label: 'Major',
        name: 'other_data::major',
        type: 'text',
        span: 8,
      },
      {
        label: 'Start Date',
        name: 'other_data::start',
        type: 'date',
        span: 8,
        picker: 'month',
      },
      {
        label: 'End Date',
        name: 'other_data::end',
        type: 'date',
        span: 8,
        picker: 'month',
      },
      {
        label: 'Currently studies here ?',
        name: 'other_data::currently_studies_here',
        type: 'select',
        span: 8,
        allowed_values: [
          { display_hint_primary: 'Yes', value: 'true' },
          { display_hint_primary: 'No', value: 'false' },
        ],
      },
    ],
  },
  {
    label: 'Work History',
    name: 'work_history',
    section: 'work_history',
    type: 'nested',
    icon: '👔',
    subFields: [
      {
        label: 'Organization',
        name: 'other_data::org_name',
        type: 'search',
        search_entity: 'organization',
        span: 12,
        primary_key: 'destination_organization_id',
      },
      {
        label: 'Job Title',
        name: 'other_data::job_title',
        type: 'text',
        span: 12,
      },
      {
        label: 'Start',
        name: 'other_data::start',
        type: 'date',
        span: 8,
      },
      {
        label: 'End',
        name: 'other_data::to',
        type: 'date',
        span: 8,
      },
      {
        label: 'Currently works here',
        name: 'other_data::currently_works_here',
        type: 'select',
        span: 8,
        allowed_values: [
          { display_hint_primary: 'Yes', value: 'true' },
          { display_hint_primary: 'No', value: 'false' },
        ],
      },
      {
        label: 'Likes',
        name: 'other_data::likes',
        type: 'text',
        span: 8,
      },
      {
        label: 'Dislikes',
        name: 'other_data::dislikes',
        type: 'text',
        span: 8,
      },
      {
        label: 'Why joined',
        name: 'other_data::why_joined',
        type: 'text',
        span: 8,
      },
      {
        label: 'Summary',
        name: 'other_data::summary',
        type: 'textarea',
        span: 24,
      },
    ],
  },
  {
    label: 'Executive',
    name: 'executive',
    section: 'executive',
    type: 'custom',
    icon: '💡',
    subFields: [
      {
        label: 'Functions',
        name: 'executive::custom_fields::Executive Functions',
        span: 8,
        type: 'select',
        mode: 'tags',
        allowed_values: [
          {
            display_hint_primary: 'Chief Executive Officer (CEO)',
            value: 'Chief Executive Officer (CEO)',
          },
          {
            display_hint_primary: 'Division Chief Executive Officer (Div CEO) / President',
            value: 'Goto Market',
          },
          { display_hint_primary: 'Chief Financial Officer', value: 'Chief Financial Officer' },
          { display_hint_primary: 'Chief Operating Officer', value: 'Chief Operating Officer' },
          { display_hint_primary: 'Chief Marketing Officer', value: 'Chief Marketing Officer' },
          {
            display_hint_primary: 'Chief Sales/Revenue Officer',
            value: 'Chief Sales/Revenue Officer',
          },
          {
            display_hint_primary: 'Chief Supply Chain Officer',
            value: 'Chief Supply Chain Officer',
          },
          { display_hint_primary: 'Chief Medical Officer', value: 'Chief Medical Officer' },
          {
            display_hint_primary: 'Financial Services Executive',
            value: 'Financial Services Executive',
          },
          { display_hint_primary: 'Investment Professional', value: 'Investment Professional' },
          { display_hint_primary: 'Potential Board Member', value: 'Potential Board Member' },
          {
            display_hint_primary: 'General Counsel or Chief Legal Officer',
            value: 'General Counsel or Chief Legal Officer',
          },
          {
            display_hint_primary: 'Diversity, Equity & Inclusion Executive',
            value: 'Diversity, Equity & Inclusion Executive',
          },
          { display_hint_primary: 'Compliance Executive', value: 'Compliance Executive' },
          {
            display_hint_primary: 'Strategy/Business Operations Executive',
            value: 'Strategy/Business Operations Executive',
          },
          { display_hint_primary: 'Potential Advisors', value: 'Potential Advisor' },
        ],
      },
      {
        label: 'Geographies',
        name: 'executive::custom_fields::Executive Geographies',
        span: 8,
        type: 'select',
        mode: 'tags',
        allowed_values: [
          { display_hint_primary: 'SF Bay Area', value: 'SF Bay Area' },
          { display_hint_primary: 'New York Metro', value: 'New York Metro' },
          { display_hint_primary: 'Seattle', value: 'Seattle' },
          { display_hint_primary: 'Los Angeles', value: 'Los Angeles' },
          { display_hint_primary: 'Greater Boston', value: 'Greater Boston' },
          { display_hint_primary: 'DC Metro', value: 'DC Metro' },
          { display_hint_primary: 'Denver Metro', value: 'Denver Metro' },
          { display_hint_primary: 'Austin', value: 'Austin' },
          { display_hint_primary: 'Chicago', value: 'Chicago' },
          { display_hint_primary: 'West Coast', value: 'West Coast' },
          { display_hint_primary: 'Mid West', value: 'Mid West' },
          { display_hint_primary: 'East Coast', value: 'East Coast' },
          { display_hint_primary: 'Miami', value: 'Miami' },
          { display_hint_primary: 'EMEA', value: 'EMEA' },
          { display_hint_primary: 'APAC', value: 'APAC' },
          { display_hint_primary: 'LatAm', value: 'LatAm' },
        ],
      },
      {
        label: 'Key Experiences',
        name: 'executive::custom_fields::Executive Key Experiences',
        span: 8,
        type: 'select',
        mode: 'tags',
        allowed_values: [
          { display_hint_primary: 'High growth', value: 'High growth' },
          { display_hint_primary: 'Turnaround', value: 'Turnaround' },
          { display_hint_primary: 'Early venture', value: 'Early venture' },
          {
            display_hint_primary: 'Growth / late stage venture',
            value: 'Growth / late stage venture',
          },
          { display_hint_primary: 'Private equity', value: 'Private equity' },
          { display_hint_primary: 'Crypto', value: 'Crypto' },
          { display_hint_primary: 'HealthTech', value: 'HealthTech' },
          { display_hint_primary: 'FinTech', value: 'FinTech' },
          { display_hint_primary: 'Payments', value: 'Payments' },
          { display_hint_primary: 'WellnessTech', value: 'WellnessTech' },
          { display_hint_primary: 'SaaS', value: 'SaaS' },
          { display_hint_primary: 'Public Company', value: 'Public Company' },
          { display_hint_primary: 'Entrepreneurship', value: 'Entrepreneurship' },
          { display_hint_primary: 'Fundraising', value: 'Fundraising' },
          { display_hint_primary: 'M&A', value: 'M&A' },
        ],
      },
      {
        label: 'Ethnic Diversity',
        name: 'executive::custom_fields::Ethnic Diversity',
        span: 8,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Black or African American', value: 'Black or African American' },
          { display_hint_primary: 'White', value: 'White' },
          { display_hint_primary: 'Asian', value: 'Asian' },
          { display_hint_primary: 'Latinx or Hispanic', value: 'Latinx or Hispanic' },
          {
            display_hint_primary: 'Native American or Alaska Native',
            value: 'Native American or Alaska Native',
          },
          {
            display_hint_primary: 'Native Hawaiian or Other Pacific Islander',
            value: 'Native Hawaiian or Other Pacific Islander',
          },
          {
            display_hint_primary: 'Middle Eastern or North African',
            value: 'Middle Eastern or North African',
          },
          {
            display_hint_primary: 'Sub-Saharan African or Caribbean Ancestry',
            value: 'Sub-Saharan African or Caribbean Ancestry',
          },
          { display_hint_primary: 'Prefer Not to Disclose', value: 'Prefer Not to Disclose' },
        ],
      },
      {
        label: 'Executive Projects',
        name: 'executive::custom_fields::Executive Projects',
        span: 8,
        type: 'select',
        mode: 'tags',
        allowed_values: [],
      },
    ],
  },
  {
    label: 'Future Founder',
    name: 'future_founder',
    section: 'future_founder',
    type: 'custom',
    icon: '🚀',
    subFields: [
      {
        label: 'Occupation',
        name: 'future_founder::custom_fields::Future Founder Occupation',
        span: 8,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'In School', value: 'In school' },
          { display_hint_primary: 'Dropped Out', value: 'Drop out' },
          { display_hint_primary: 'Graduated', value: 'Graduated' },
          { display_hint_primary: 'Working', value: 'Working' },
          { display_hint_primary: 'Quit Job', value: 'Quit job' },
        ],
      },
      {
        label: 'Stage',
        name: 'future_founder::custom_fields::Future Founder Stage',
        span: 8,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Ideating', value: 'Ideating' },
          { display_hint_primary: 'Prototyping', value: 'Prototyping' },
          { display_hint_primary: 'Fundraising', value: 'Fundraising' },
        ],
      },
      {
        label: 'Bullishness',
        name: 'future_founder::bullishness_scores',
        span: 8,
        type: 'rate',
      },
      {
        label: 'Fundraising',
        name: 'future_founder::fundraising',
        span: 24,
        type: 'fundraising',
      },
    ],
  },
  {
    label: 'Custom Fields',
    name: 'custom_fields',
    section: 'custom_fields',
    type: 'custom',
    icon: '📝',
    subFields: [
      {
        label: 'Preferred Pronoun',
        name: 'custom_fields::Preferred Pronoun',
        span: 8,
      },
      {
        label: 'Relationship Strength',
        name: 'custom_fields::Relationship Strength',
        span: 8,
      },
      {
        label: 'Member Persona',
        name: 'custom_fields::Member Persona',
        span: 8,
      },
      {
        label: 'Engineering Rank',
        name: 'custom_fields::Engineering Rank',
        span: 8,
      },
      {
        label: 'Entrepreneurship Persona',
        name: 'custom_fields::Entrepreneurship Persona',
        span: 8,
      },
      {
        label: 'Entrepreneur Monitoring',
        name: 'custom_fields::Entrepreneur Monitoring',
        span: 8,
      },
      {
        label: 'Matchmaking Status',
        name: 'custom_fields::Matchmaking Status',
        span: 8,
      },
      {
        label: 'Batch Tag',
        name: 'custom_fields::Batch Tag',
        span: 8,
      },
      {
        label: '2020-2021 Matchmaking Tags',
        name: 'custom_fields::2020-2021 Matchmaking Tags',
        span: 8,
      },
      {
        label: 'Gap Semester/Year',
        name: 'custom_fields::Gap Semester/Year',
        span: 8,
      },
      {
        label: 'Top Motivations',
        name: 'custom_fields::Top Motivations',
        span: 8,
      },
      {
        label: 'Ideal Role',
        name: 'custom_fields::Ideal Role',
        span: 8,
      },
      {
        label: 'Industries',
        name: 'custom_fields::Industries',
        span: 8,
      },
      {
        label: 'Looking For?',
        name: 'custom_fields::Looking For?',
        span: 8,
      },
      {
        label: 'Founder Score',
        name: 'custom_fields::Founder Score',
        span: 8,
      },
      {
        label: 'Estimated Timeline To Founding',
        name: 'custom_fields::Estimated Timeline To Founding',
        span: 8,
      },
      {
        label: 'Investment Team Status',
        name: 'custom_fields::Investment Team Status',
        span: 8,
      },
      {
        label: '2019-2020 Matchmaking Tags',
        name: 'custom_fields::2019-2020 Matchmaking Tags',
        span: 8,
      },
      {
        label: '2018-2019 Matchmaking Tags',
        name: 'custom_fields::2018-2019 Matchmaking Tags',
        span: 8,
      },
      {
        label: 'Describe Your Interest In Further Education',
        name: 'custom_fields::Describe Your Interest In Further Education',
        span: 8,
      },
      {
        label: 'Are You Legally Authorized To Work In The US?',
        name: 'custom_fields::Are You Legally Authorized To Work In The US?',
        span: 8,
      },
      {
        label: 'Will You Require Sponsorship For An Employment Visa?',
        name: 'custom_fields::Will You Require Sponsorship For An Employment Visa?',
        span: 8,
      },

      {
        label: 'Permanent Principal Tags',
        name: 'custom_fields::Permanent Principal Tags',
        span: 8,
      },
      {
        label: '2021 Ops Tags',
        name: 'custom_fields::2021 Ops Tags',
        span: 8,
      },
      {
        label: 'Fit For Our Team?',
        name: 'custom_fields::Fit For Our Team?',
        span: 8,
      },
      {
        label: 'How Did You Meet?',
        name: 'custom_fields::How Did You Meet?',
        span: 8,
      },
      {
        label: 'Additional Motivations',
        name: 'custom_fields::Additional Motivations',
        span: 8,
      },
      {
        label: 'Currently Working On',
        name: 'custom_fields::Currently Working On',
        span: 8,
      },
      {
        label: 'Recommendation Score',
        name: 'custom_fields::Recommendation Score',
        span: 8,
      },
      {
        label: 'Recommendation Review',
        name: 'custom_fields::Recommendation Review',
        span: 8,
      },
      {
        label: 'Comments',
        name: 'custom_fields::Comments',
        span: 8,
      },
      {
        label: 'Outreach Communication Log',
        name: 'custom_fields::Outreach Communication Log',
        span: 8,
      },
      {
        label: 'Gem Projects',
        name: 'custom_fields::Gem Projects',
        span: 8,
      },
    ],
  },
];

const newCandidateSchema = [
  {
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    required: true,
    span: 12,
  },
  {
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    required: false,
    span: 12,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    required: true,
    span: 24,
  },
];

const createCandidateSchema = [
  {
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    required: true,
    span: 12,
  },
  {
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    required: false,
    span: 12,
  },
  {
    label: 'Email',
    name: 'email',
    type: 'text',
    required: true,
    span: 24,
  },
  {
    label: 'Password',
    name: 'password',
    type: 'password',
    required: true,
    span: 12,
  },
  {
    label: 'Confirm Password',
    name: 'confirm_password',
    type: 'password',
    required: true,
    span: 12,
  },
];

const candidateAssessmentSchema = [
  {
    label: 'Full Name',
    name: 'full_name',
    type: 'text',
    span: 8,
    required: true,
  },
  {
    label: 'Primary Email',
    name: 'primary_email',
    type: 'text',
    span: 8,
    required: true,
  },
  {
    label: 'Gender',
    name: 'gender',
    type: 'select',
    span: 8,
    allowed_values: Genders,
  },
  {
    label: 'University',
    name: 'university',
    type: 'text',
    span: 6,
    required: true,
  },
  {
    label: 'Degree',
    name: 'degree',
    type: 'text',
    span: 6,
  },
  {
    label: 'Major',
    name: 'major',
    type: 'text',
    span: 6,
  },
  {
    label: 'Grad Year',
    name: 'grad_year',
    type: 'text',
    span: 6,
  },
  {
    label: 'Personal Story',
    name: 'personal_story',
    type: 'textarea',
    span: 24,
  },
  {
    label: 'Summer 2021 Company',
    name: 'summer_2021_company',
    type: 'text',
    span: 8,
    required: true,
  },
  {
    label: 'Summer 2021 Role',
    name: 'summer_2021_role',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2021 Why Joined',
    name: 'summer_2021_why_joined',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2021 Likes',
    name: 'summer_2021_likes',
    type: 'text',
    span: 12,
  },
  {
    label: 'Summer 2021 Dislikes',
    name: 'summer_2021_dislikes',
    type: 'text',
    span: 12,
  },
  {
    label: 'Summer 2020 Company',
    name: 'summer_2020_company',
    type: 'text',
    span: 8,
    required: true,
  },
  {
    label: 'Summer 2020 Role',
    name: 'summer_2020_role',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2020 Why Joined',
    name: 'summer_2020_why_joined',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2020 Likes',
    name: 'summer_2020_likes',
    type: 'text',
    span: 12,
  },
  {
    label: 'Summer 2020 Dislikes',
    name: 'summer_2020_dislikes',
    type: 'text',
    span: 12,
  },
  {
    label: 'Summer 2019 Company',
    name: 'summer_2019_company',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2019 Role',
    name: 'summer_2019_role',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2019 Why Joined',
    name: 'summer_2019_why_joined',
    type: 'text',
    span: 8,
  },
  {
    label: 'Summer 2019 Likes',
    name: 'summer_2019_likes',
    type: 'text',
    span: 12,
  },
  {
    label: 'Summer 2019 Dislikes',
    name: 'summer_2019_dislikes',
    type: 'text',
    span: 12,
  },

  {
    label: 'Member Summary',
    name: 'member_summary',
    type: 'text',
    span: 24,
  },
  {
    label: 'Internal Note',
    name: 'internal_note',
    type: 'textarea',
    span: 24,
  },
  {
    label: 'Matchmaking Status',
    name: 'member_status',
    type: 'text',
    span: 12,
    required: true,
  },
  {
    label: '2020-2021 Matchmaking Tags',
    name: 'matchmaking_tags_2020_2021',
    type: 'text',
    span: 12,
  },
  {
    label: 'Gap Semester/Year',
    name: 'gap_semester_or_year',
    type: 'text',
    span: 12,
  },
  {
    label: 'Engineering Rank',
    name: 'eng_ranking',
    type: 'text',
    span: 12,
  },
  {
    label: 'Top Motivations',
    name: 'top_motivations',
    type: 'text',
    span: 12,
  },
  {
    label: 'Ideal Role',
    name: 'ideal_role',
    type: 'text',
    span: 12,
  },
  {
    label: 'Industries',
    name: 'industries',
    type: 'text',
    span: 12,
  },
  {
    label: 'Fit For Our Team?',
    name: 'fit_for_our_team',
    type: 'text',
    span: 12,
  },
  {
    label: 'Looking For?',
    name: 'looking_for',
    type: 'text',
    span: 12,
  },
  {
    label: 'Founder Score',
    name: 'founder_score',
    type: 'text',
    span: 12,
  },
  {
    label: 'Estimated Timeline To Founding',
    name: 'estimated_timeline_to_founding',
    type: 'text',
    span: 12,
  },
  {
    label: 'Investment Team Status',
    name: 'investment_team_status',
    type: 'text',
    span: 12,
  },

  {
    label: 'Investment Team Note',
    name: 'investment_team_note',
    type: 'textarea',
    span: 12,
  },
  {
    label: 'How Did You Meet',
    name: 'how_did_you_meet',
    type: 'text',
    span: 12,
  },
];

export {
  candidateProfileSchema,
  newCandidateSchema,
  candidateAssessmentSchema,
  createCandidateSchema,
};
