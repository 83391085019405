import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Steps,
  Popover,
  Button,
  message,
  Input as AntdInput,
  InputNumber as AntdInputNum,
} from 'antd';
import moment from 'moment';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Input } from 'Components';
import { v4 as uuid_v4 } from 'uuid';

const { Step } = Steps;
const { TextArea } = AntdInput;

const FundraisingMap = ({ data, handleValueChange }) => {
  const [fundList, setFundList] = useState([]);
  const [newFund, setNewFund] = useState({
    stage: undefined,
    amount: undefined,
    valuation: undefined,
    date: undefined,
    note: undefined,
    event_id: uuid_v4(),
  });

  useEffect(() => {
    var dataList = data;
    if (typeof dataList === 'object' && Object.keys(dataList).length === 0) {
      dataList = [];
    } else if (data !== undefined && !Array.isArray(data)) {
      dataList = [...data];
    }
    dataList?.sort((a, b) => moment(a.date) - moment(b.date));
    setFundList(dataList);
  }, [data]);

  // Todo:
  // - propogate updated fundlist to candidate profile (handlevaluechange)
  // - center add new fund content

  const onDelete = (index) => () => {
    let newFunds = [...fundList];
    newFunds.splice(index, 1);
    setFundList(newFunds);
    handleValueChange(newFunds);
  };

  const onAdd = () => {
    if (
      typeof newFund.amount === 'undefined' ||
      typeof newFund.valuation === 'undefined' ||
      typeof newFund.date === 'undefined'
    ) {
      message.warning('Amount/Valuation/Date Not Set');
      return;
    }
    let newFunds = [...fundList, newFund];
    newFunds.at(-1).stage = newFunds.length;
    newFunds?.sort((a, b) => moment(a.date) - moment(b.date));
    setFundList(newFunds);
    handleValueChange(newFunds);
    setNewFund({
      stage: fundList?.length + 1,
      amount: undefined,
      valuation: undefined,
      date: undefined,
      note: undefined,
      event_id: uuid_v4(),
    });
  };

  const packEvent = (thisName, thisValue) => ({
    target: {
      name: thisName,
      value: thisValue,
    },
  });

  const updateNewFund = (e) => {
    setNewFund({ ...newFund, [e.target.name]: e.target.value });
  };

  const curryUpdateFund = (name) => (val) => {
    setNewFund({ ...newFund, [name]: val });
  };

  const customDot = (dot, { index }) => {
    return index < fundList?.length ? (
      <Popover
        content={
          <Button shape="circle" size="small" onClick={onDelete(index)}>
            <CloseOutlined />
          </Button>
        }
      >
        {dot}
      </Popover>
    ) : (
      <Popover
        content={
          <div
            style={{
              width: '200px',
              display: 'block',
              alignContent: 'center',
            }}
          >
            <AntdInputNum
              formatter={(value) => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={curryUpdateFund('amount')}
              size="middle"
              placeholder="Raised"
            />
            <AntdInputNum
              formatter={(value) => value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={curryUpdateFund('valuation')}
              size="middle"
              placeholder="Valuation"
            />
            <TextArea
              name="note"
              onChange={updateNewFund}
              size="large"
              placeholder="Notes: Funding Round, HC Invest Amount, etc."
            />
            <Input
              type="date"
              onChange={(newVal) => updateNewFund(packEvent('date', newVal.toISOString()))}
              size="middle"
              placeholder="Date"
              showTime="true"
            />
            <Button onClick={onAdd}>
              <PlusOutlined /> Add
            </Button>
          </div>
        }
      >
        {dot}
      </Popover>
    );
  };

  return (
    <Steps progressDot={customDot} current={fundList?.length - 1}>
      {fundList?.map((fund, index) => (
        <Step
          key={index}
          title={'Round ' + (index + 1)}
          subTitle={'Raised $' + fund?.amount + ', Valuation $' + fund?.valuation}
          description={fund?.note + ' - ' + moment(fund?.date).format('l')}
        />
      ))}
      <Step key={fundList?.length} />
    </Steps>
  );
};

FundraisingMap.propTypes = {
  data: PropTypes.array,
  handleValueChange: PropTypes.func,
};

export default FundraisingMap;
