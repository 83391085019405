import React from 'react';
import { Select, Button } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import { startCase, isObject, toLower } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Input } from 'Components';
import _ from 'lodash';

const { Option } = Select;

const FilterCondition = ({
  condition,
  handleOperatorChange,
  filterOptions = [],
  handleValueChange,
  removeCondition,
  conditions,
}) => {
  const { key, operator } = condition;
  const defaultOperator = filterOptions.length ? filterOptions[0]?.operator : '';
  const currentFilter = filterOptions.find((option) =>
    operator ? option.operator === operator : option.operator === defaultOperator
  );
  const requires = currentFilter?.requires || [];

  const inputOnChangeFunc = (fieldType, fieldName, key) => {
    switch (fieldType) {
      case 'num':
      case 'number':
      case 'select':
        return (value) => handleValueChange(fieldName, key, value);
      case 'date':
        return (_, dateString) => handleValueChange(fieldName, key, dateString);
      default:
        return (event) => {
          const { value } = event.target;
          handleValueChange(fieldName, key, value);
        };
    }
  };

  const formattedValue = (type, value) => {
    switch (type) {
      case 'date':
        return value ? moment(value) : '';
      default:
        return value;
    }
  };

  const formattedFieldType = (type) => {
    if (type !== undefined && type.includes('date')) return 'date';
    return type;
  };

  const formatAllowedValues = (allowedValues) => {
    const formattedValues = allowedValues.map((item) =>
      isObject(item)
        ? item
        : {
            value: item,
            display_hint_primary: toLower(item).replace('_', ' ').replace(/\w+/g, _.capitalize),
          }
    );
    return formattedValues.filter((item) => item.display_hint_primary);
  };

  return (
    <div key={key} style={{ display: 'flex', alignItems: 'center' }}>
      {conditions?.length > 1 ? (
        <Button
          size="small"
          className="btn-icon-only"
          icon={<MinusOutlined />}
          style={{ color: 'black', margin: '3px' }}
          onClick={() => removeCondition(key)}
        ></Button>
      ) : null}
      <Select
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        size="small"
        bordered={false}
        defaultValue={defaultOperator}
        value={operator}
        style={{
          backgroundColor: '#f4f5ff',
          color: 'black',
          width: '115px',
          margin: '5px 0px',
          fontSize: '12px',
        }}
        onChange={(value) => handleOperatorChange(key, value)}
      >
        {filterOptions.map((option, index) => (
          <Option key={index} value={option.operator}>
            {startCase(option.operator) || option.operator}
          </Option>
        ))}
      </Select>
      {requires.map(({ field_type, field_name, allowed_values = [] }, index) => (
        <div style={{ margin: '0px 5px' }} key={index}>
          {!allowed_values.length ? (
            <Input
              type={formattedFieldType(field_type)}
              onChange={inputOnChangeFunc(formattedFieldType(field_type), field_name, key)}
              value={formattedValue(formattedFieldType(field_type), condition[field_name])}
              size="middle"
              name={field_name}
            />
          ) : (
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              showSearch
              placeholder="Select"
              optionFilterProp="children"
              onChange={inputOnChangeFunc(formattedFieldType('select'), field_name, key)}
              value={formattedValue('select', condition[field_name])}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {formatAllowedValues(allowed_values).map(({ value, display_hint_primary }, index) => (
                <Option key={index} value={value}>
                  {display_hint_primary}
                </Option>
              ))}
            </Select>
          )}
        </div>
      ))}
    </div>
  );
};

FilterCondition.propTypes = {
  condition: PropTypes.object,
  handleOperatorChange: PropTypes.func,
  filterOptions: PropTypes.array,
  handleValueChange: PropTypes.func,
  removeCondition: PropTypes.func,
  conditions: PropTypes.array,
};

export default FilterCondition;
