import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Menu, message } from 'antd';
import { PlusOutlined, TableOutlined, DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, FilterSection, PageHeader, Content } from 'Components';
import HumanList from './components/human-list';
import AddHumanModal from './components/add-human-modal';
import BulkUpdateDrawer from './components/bulk-update-drawer';
import BulkUploadModal from './components/bulk-upload-modal';
import { exportUsers } from 'Api/user-service';
import { makeShowable } from 'Utils';
import { exportCsv, pullAndSetViews } from 'Helpers';
import { CSVReader } from 'react-papaparse';
import { useStoreValue } from 'Context';
import './humans.less';
import SubMenu from 'antd/lib/menu/SubMenu';
import ExportLeverModal from './components/export-lever-modal';

const Humans = () => {
  const [views, setViews] = useState([]);
  const [showAddHumanModal, setShowAddHumanModal] = useState(false);
  const [showExportLeverModal, setShowExportLeverModal] = useState(false);
  const [showBulkUpdateDrawer, setShowBulkUpdateDrawer] = useState(false);
  const [bulkData, setBulkData] = useState([]);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [currentView, setCurrentView] = useState({});
  const [defaultViewId, setDefaultViewId] = useState(null);
  const bulkUpdateRef = useRef();
  const [{ user }] = useStoreValue();
  const entity = 'user';

  useEffect(() => {
    updateViews();
  }, []);

  const updateViews = () => {
    pullAndSetViews(entity, user?.id, setViews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const exportUsersCsv = async (csvFormat, options = {}, make_event = false) => {
    try {
      let { data: csvData } = await exportUsers(
        {
          filters: currentView.filters,
          entity_type: entity,
        },
        options,
        make_event
      );
      if (csvData) {
        exportCsv(
          csvData,
          `${csvFormat}_Users_${new Date().toLocaleDateString('en-US')}.csv`,
          options
        );
        message.success('Candidates CSV downloading...');
      } else {
        message.warning('No Data!');
      }
    } catch (e) {
      message.error(makeShowable(e.message, 'Something went wrong while exporting CSV'));
    }
  };

  const handleMoreMenuClick = ({ key }) => {
    if (key === 'bulk-update') {
      // setBulkUpdateDrawer(true);
    } else if (key === 'export-csv-hc') {
      exportUsersCsv('HC');
    } else if (key === 'export-csv-lever') {
      setShowExportLeverModal(true);
    }
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (bulkUpdateRef.current) {
      bulkUpdateRef.current.open(e);
    }
  };

  const moreMenu = (
    <Menu onClick={handleMoreMenuClick} style={{ width: 128 }}>
      <SubMenu icon={<DownloadOutlined />} title="  Export CSV" key="export-csv">
        <Menu.Item key="export-csv-hc">Human Capital Format</Menu.Item>
        <Menu.Item key="export-csv-lever">Lever Format</Menu.Item>
      </SubMenu>
      <Menu.Item key="bulk-update" icon={<SyncOutlined />}>
        <CSVReader
          ref={bulkUpdateRef}
          onDrop={(data) => {
            const mappedCandidates = data.map(({ data: item }) => {
              return { ...item };
            });
            setBulkData(mappedCandidates);
            setShowBulkUpdateDrawer(true);
          }}
          config={{ header: true }}
          onError={() => {}}
          onRemoveFile={() => {}}
        >
          {() => <span onClick={handleOpenDialog}>Bulk update</span>}
        </CSVReader>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      <Row>
        <Col
          span={5}
          style={{
            borderRight: '1px solid lightgrey',
          }}
        >
          <FilterSection
            entity="user"
            views={views}
            setViews={setViews}
            moreMenu={moreMenu}
            currentView={currentView}
            setCurrentView={setCurrentView}
            defaultViewId={defaultViewId}
            setDefaultViewId={setDefaultViewId}
          />
        </Col>

        <Col
          span={19}
          style={{
            maxHeight: 'calc(100vh - 70px)',
            overflowY: 'scroll',
          }}
        >
          <PageHeader
            sticky
            title="💗  Humans"
            extra={[
              <Button key="bulk-upload" size="middle" onClick={() => setShowBulkUploadModal(true)}>
                <TableOutlined /> Bulk Upload
              </Button>,
              <Button
                key="add-new"
                size="middle"
                type="primary"
                onClick={() => setShowAddHumanModal(true)}
              >
                <PlusOutlined /> Add new
              </Button>,
            ]}
          ></PageHeader>
          <Content>
            <HumanList
              entity={entity}
              views={views}
              setViews={setViews}
              currentView={currentView}
              setCurrentView={setCurrentView}
              setDefaultViewId={setDefaultViewId}
            />
          </Content>
        </Col>
      </Row>
      <AddHumanModal showModal={showAddHumanModal} setShowModal={setShowAddHumanModal} />
      <BulkUpdateDrawer
        visible={showBulkUpdateDrawer}
        setVisible={setShowBulkUpdateDrawer}
        bulkData={bulkData}
      />
      <BulkUploadModal
        visible={showBulkUploadModal}
        setVisible={setShowBulkUploadModal}
        updateViews={updateViews}
        setCurrentView={setCurrentView}
      ></BulkUploadModal>
      <ExportLeverModal
        showModal={showExportLeverModal}
        setShowModal={setShowExportLeverModal}
        exportCsv={exportUsersCsv}
      />
    </div>
  );
};

export default Humans;
