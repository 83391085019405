import {
  Divider,
  DatePicker,
  Timeline,
  Row,
  Col,
  Space,
  Badge,
  Menu,
  Checkbox,
  Dropdown,
  Button,
  message,
} from 'antd';
import { FilterFilled, PlusOutlined, FilterTwoTone } from '@ant-design/icons';
import AddEventModal from './components/add-event-modal';
import { generateDetails } from './detail-config';
import { getEvents, getEventTypes } from 'Api/event-service';
import { listRelationships } from 'Api/relationship-service';
import { getUsersByIdList } from 'Api/user-service';
import { getOrgProfile, getOrgCodenames } from 'Api/org-service';
import { TimelineCard, Input, Content, PreLoader } from 'Components';
import { AdminEventConfig } from 'Constants';
import { useStoreValue } from 'Context';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { formatDateTimeStrFromISO, replaceStringArray } from 'Utils';

/*
eventConfig object in src/contants/index.js

const eventConfig = {
  sent_email: { color: 'orange', displayName: 'Email' },
  calendly_meeting: { color: 'blue', displayName: 'Meeting' },
  intro_update: { color: 'teal', displayName: 'Stage Change' },
  interview_update: { color: 'green', displayName: 'Interview' },
  updated_profile: { color: 'purple', displayName: 'Info Updated' },
};
*/

const { RangePicker } = DatePicker;

const ProfileTimeline = ({
  eventConfig,
  timelineClassName,
  eventFormSchema,
  entityType,
  entityId,
  hideHeader = false,
  showLoading = false,
  limit = -1,
  meetingId = null,
}) => {
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [entityEvents, setEntityEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [eventFilter, setEventFilter] = useState([]);
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'M'), moment().add(1, 'M')]);

  const maskingInfo = useRef({});
  const eventTypeIdToName = useRef({});

  const [{ user }] = useStoreValue();

  const [loading, setLoading] = useState(false);

  let idToUserMap = {};
  let idToOrgMap = {};

  useEffect(async () => {
    const eventTypes = await getEventTypes();
    const orgMaskingInfo = await getOrgCodenames();
    maskingInfo.current = get(orgMaskingInfo, 'data', {});
    let idToName = {};
    for (const eventType of eventTypes.data) {
      idToName[eventType.id] = eventType.name;
    }
    eventTypeIdToName.current = idToName;
    await updateEntityEvents(dateRange[0], dateRange[1]);
  }, []);

  useEffect(async () => {
    if (showLoading) {
      setLoading(true);
    }
    await updateEntityEvents(dateRange[0], dateRange[1]);
    setLoading(false);
  }, [entityId]);

  useEffect(() => {
    const searchValue = searchText.toLowerCase();
    const isAdmin = user?.role == 'admin';
    if (searchValue || !!eventFilter.length || dateRange) {
      let filterEventsData = entityEvents.filter(
        (event) =>
          (event.title?.toLowerCase()?.includes(searchValue) ||
            event.other_data.detailStr.toLowerCase()?.includes(searchValue)) &&
          ((eventFilter.length && eventFilter.includes(event.eventType)) || !eventFilter.length) &&
          (event.eventType in eventConfig || (isAdmin && event.eventType in AdminEventConfig))
      );
      if (dateRange[0] && dateRange[1]) {
        filterEventsData = filterEventsData.filter(
          (event) => event.date >= dateRange[0] && event.date < dateRange[1]
        );
      }
      filterEventsData.sort((eventA, eventB) => {
        let time1 = moment(
          eventA.date.format('MM-DD-YYYY') + ' ' + eventA.time,
          'MM-DD-YYYY hh:mm A'
        );
        let time2 = moment(
          eventB.date.format('MM-DD-YYYY') + ' ' + eventB.time,
          'MM-DD-YYYY hh:mm A'
        );
        return time2 - time1;
      });
      if (limit > 0) {
        filterEventsData = filterEventsData.slice(0, limit);
      }
      setFilteredEvents(filterEventsData);
    } else {
      setFilteredEvents(entityEvents);
    }
  }, [searchText, dateRange, eventFilter, entityEvents]);

  const formatValuesForFE = (formData) => {
    const realNames = get(maskingInfo.current, 'real_names', []);
    const codenames = get(maskingInfo.current, 'codenames', []);
    const resp = formData
      .filter(
        (data) =>
          (get(data, 'other_data.event_time', false) || get(data, 'other_data.status', false)) !==
          false
      )
      .map((data) => {
        const eventTime = data.other_data.event_time
          ? data.other_data.event_time
          : formatDateTimeStrFromISO(data.other_data.start_time);
        const title = replaceStringArray(
          data.title ? data.title : data.other_data.name + ' (' + data.other_data.status + ')',
          realNames,
          codenames
        );

        const details = replaceStringArray(
          generateDetails(data, eventTime, idToUserMap, eventTypeIdToName, idToOrgMap),
          realNames,
          codenames
        );

        const detailStr = replaceStringArray(
          get(data, 'other_data.detail', false)
            ? data.other_data.detail
            : `Calendly meeting with ${get(
                data,
                'other_data.invitees[0].name',
                'Someone'
              )} (${eventTime},${formatDateTimeStrFromISO(data.other_data.end_time)})`,
          realNames,
          codenames
        );

        return {
          title: title,
          date: moment(eventTime.split(' ')[0], 'MM-DD-YYYY'),
          time: eventTime.split(' ').slice(1, 3).join(' '),
          eventType: eventTypeIdToName.current[data.type_id],
          other_data: { detail: details, detailStr },
        };
      });
    return resp;
  };

  const getFundraisingEventForHuman = async (loadEvents, startDate, endDate) => {
    // Should probably use an api to ensure this value
    const foundedRelnId = 11;

    const { data: foundedReln } = await listRelationships(foundedRelnId, entityId);
    const foundedOrgIds = foundedReln.map((reln) => reln.destination_organization_id);
    if (foundedOrgIds.length > 0) {
      if (!loadEvents[entityId]) {
        loadEvents[entityId] = [];
      }
      const { data: orgEvents } = await getEvents(
        {
          type_str: 'updated_fundraising',
        },
        {},
        { from_str: startDate.utc().toISOString(), to_str: endDate.utc().toISOString() },
        {
          entity_ids: foundedOrgIds,
          entity_type: 'organization',
        },
        {}
      );
      Object.entries(orgEvents).map((eventEntry) => {
        loadEvents[entityId] = [...loadEvents[entityId], ...eventEntry[1]];
      });
    }
  };

  const updateEntityEvents = async (startDate = null, endDate = null) => {
    try {
      if (!startDate && !endDate) {
        if (dateRange.length !== 2) {
          return;
        }
        startDate = dateRange[0];
        endDate = dateRange[1];
      }

      // get role type
      // filter out events not allowed for access level
      let { data: loadEvents } = await getEvents(
        {
          type_str: '',
        },
        {},
        { from_str: startDate.utc().toISOString(), to_str: endDate.utc().toISOString() },
        { entity_ids: [entityId], entity_type: entityType },
        {}
      );

      if (entityType === 'user') {
        await getFundraisingEventForHuman(loadEvents, startDate, endDate);
      }

      if (loadEvents[entityId]) {
        const userIdSet = new Set();
        loadEvents[entityId].forEach((event) => {
          // get users from potential other_data fields
          const referral = event?.other_data?.referral;
          const participant_ids = event?.other_data?.participant_ids;
          if (participant_ids) participant_ids.forEach((id) => userIdSet.add(id));
          if (referral) referral.forEach((id) => userIdSet.add(id));

          if (event.destination_id) userIdSet.add(event.destination_id);
          if (event.destination_ids) event.destination_ids.forEach((id) => userIdSet.add(id));
          if (event.source_id) userIdSet.add(event.source_id);
        });

        // generate mapping id -> user object
        if (!isEmpty(userIdSet)) {
          const userListResp = await getUsersByIdList(Array.from(userIdSet));
          const map = {};
          userListResp.data.forEach((user) => {
            map[user['id']] = {
              first_name: user['first_name'],
              last_name: user['last_name'],
              emails: user['emails'],
            };
          });
          idToUserMap = map;
        }

        // accumulate org ids
        const orgIdSet = new Set();
        loadEvents[entityId].forEach((event) => {
          const interested_portfolio = event?.other_data?.interested_portfolio;
          const orgs_source = event?.other_data?.orgs_source;

          if (interested_portfolio) {
            interested_portfolio.forEach((id) => orgIdSet.add(id));
          }
          if (orgs_source) {
            orgs_source.forEach((id) => orgIdSet.add(id));
          }
        });

        // fetch org info
        const newOrgMap = {};
        for (const orgId of orgIdSet) {
          try {
            const org = await getOrgProfile(orgId);
            newOrgMap[orgId] = org.data;
          } catch (err) {
            // pass
          }
        }
        idToOrgMap = newOrgMap;

        const formattedEvents = formatValuesForFE(loadEvents[entityId]);
        setEntityEvents(formattedEvents);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleEventSearch = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const onCalendarChange = async (dates) => {
    if (!dates) {
      setDateRange([null, null]);
    } else if (dates[0] && dates[1]) {
      await updateEntityEvents(dates[0], dates[1]);
      setDateRange(dates);
    }
  };

  const onFilterChange = (e) => {
    let newEventFilter = [...eventFilter];
    const selectedFilter = e.target.title;
    if (newEventFilter.includes(selectedFilter) && !e.target.checked) {
      newEventFilter = newEventFilter.filter((event) => event != selectedFilter);
    } else if (!newEventFilter.includes(selectedFilter) && e.target.checked) {
      newEventFilter.push(selectedFilter);
    }
    setEventFilter(newEventFilter);
  };

  const combinedEventConfig = {
    ...eventConfig,
    ...(user?.role == 'admin' ? AdminEventConfig : {}),
  };

  const filterMenu = (
    <Menu>
      {Object.keys(combinedEventConfig).map((event) => (
        <Menu.Item index={event} value={event} key={event}>
          <Checkbox onChange={onFilterChange} title={event}>
            <Badge
              color={combinedEventConfig[event]?.color}
              text={combinedEventConfig[event]?.displayName}
            />
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  const isLatestEvent = (meetingId, detailStr) =>
    meetingId && detailStr.includes('meeting_id ' + meetingId);

  return (
    <PreLoader
      className="loadingSpinner"
      spinning={loading}
      size="large"
      style={{ height: showLoading && loading ? 100 : null }}
    >
      <Content
        spacing="medium"
        className={timelineClassName}
        style={{ display: showLoading && loading ? 'none' : 'block' }}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ display: hideHeader ? 'none' : 'flex' }}
        >
          <Col>
            <h2 style={{ marginBottom: '0px' }}>📅 Timeline</h2>
          </Col>
          <Col>
            <Space>
              <RangePicker onCalendarChange={onCalendarChange} value={dateRange} />
              <Input
                type="search"
                placeholder="Search Events"
                size="medium"
                onChange={handleEventSearch}
              />
              <Dropdown overlay={filterMenu}>
                {eventFilter.length === 0 ? <FilterFilled /> : <FilterTwoTone />}
              </Dropdown>
              {eventFormSchema.length ? (
                <Button size="middle" type="primary" onClick={() => setShowAddEventModal(true)}>
                  <PlusOutlined /> Add new
                </Button>
              ) : (
                <></>
              )}
            </Space>
          </Col>
        </Row>
        {eventFormSchema.length ? (
          <AddEventModal
            showModal={showAddEventModal}
            setShowModal={setShowAddEventModal}
            eventFormSchema={eventFormSchema}
            updateEntityEvents={updateEntityEvents}
            srcType={'user'}
            srcId={user ? user.id : entityId}
            destType={entityType}
            destId={entityId}
          />
        ) : null}
        <Divider style={{ marginBottom: '40px' }} />
        <Timeline>
          {filteredEvents.map(({ date, other_data, time, title, eventType }, index) =>
            !meetingId || isLatestEvent(meetingId, other_data['detailStr']) ? (
              <Timeline.Item
                color={
                  isLatestEvent(meetingId, other_data['detailStr'])
                    ? '#47c616'
                    : combinedEventConfig[eventType]?.color || 'grey'
                }
                key={index}
              >
                <>
                  <div
                    className={`timeline-event ${
                      isLatestEvent(meetingId, other_data['detailStr']) ? 'highlight' : null
                    }`}
                  >
                    <span>{date.format('MM-DD-YYYY')}</span>

                    <br />
                    <small>{time}</small>
                  </div>
                  <TimelineCard
                    body={other_data.detail}
                    title={combinedEventConfig[eventType]?.displayName + ': ' + title}
                  />
                </>
              </Timeline.Item>
            ) : null
          )}
        </Timeline>
      </Content>
    </PreLoader>
  );
};

ProfileTimeline.propTypes = {
  eventConfig: PropTypes.object,
  timelineClassName: PropTypes.string,
  eventFormSchema: PropTypes.array,
  entityType: PropTypes.string,
  entityId: PropTypes.number,
  hideHeader: PropTypes.bool,
  showLoading: PropTypes.bool,
  limit: PropTypes.number,
  meetingId: PropTypes.number,
};

export default ProfileTimeline;
