import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Form, message } from 'antd';
import { Button, Card, Input } from 'Components';
import { forgotPassword, resendVerification } from 'Api/auth-service';
import { makeShowable } from 'Utils';

function EmailSentCard({ emailType, email }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async () => {
    setIsSubmitting(true);

    const fnToUse = emailType === 'forgot_password' ? forgotPassword : resendVerification;

    try {
      const { message: msg } = await fnToUse({ email });
      message.success(makeShowable(msg, emailType));
    } catch (e) {
      if (e.message === 'SERVER_ERROR') {
        message.error(makeShowable(e.message, emailType));
      } else {
        message.success(makeShowable(e.message, emailType));
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className="auth_container">
      <Card style={{ width: 350 }}>
        <div className="auth-card-header">
          <h1>Please Check Your Email</h1>
        </div>

        <div className="smalltext">
          {emailType === 'forgot_password' ? (
            <p>
              If <span className="bold">{email}</span> exists in our system, we will send an email
              with password-reset instructions.
            </p>
          ) : emailType === 'signup_email_verification' ? (
            <p>
              Thanks for signing up. We sent an email to <span className="bold">{email}</span> with
              instructions for verifying your account.
            </p>
          ) : (
            <p>
              If <span className="bold">{email}</span> exists in our system, we will send an email
              with instructions for verifying your account.
            </p>
          )}

          <p>
            If you don&apos;t see it, you may need to{' '}
            <span className="bold">check your spam folder</span>.
          </p>

          <p className="bold">Didn&apos;t receive the email?</p>
        </div>

        <Form
          name={
            emailType === 'forgot_password'
              ? 'forgot-password-hidden'
              : 'resend-verification-hidden'
          }
          initialValues={{ email }}
          onFinish={handleFormSubmit}
          layout="vertical"
        >
          <Form.Item
            label="Email"
            name="email"
            className="auth-form-item hidden"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input placeholder="eg: abc@email.com" type="email" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" disabled={isSubmitting}>
              Resend Email
            </Button>
          </Form.Item>
        </Form>

        <div>
          <p className="compact auth-card-backlink">
            <Link className="anchor_link" to="/login">
              back to login
            </Link>
          </p>
        </div>
      </Card>
    </div>
  );
}

EmailSentCard.propTypes = {
  emailType: PropTypes.oneOf([
    'forgot_password',
    'email_verification',
    'signup_email_verification',
  ]),
  email: PropTypes.string.isRequired,
};

EmailSentCard.defaultProps = {
  emailType: 'email_verification',
};

export default EmailSentCard;
