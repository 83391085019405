import React, { useState, useEffect, useRef } from 'react';
import { Col, Avatar, message, Row, Result } from 'antd';
import {
  Radio,
  NotesWindow,
  PageHeader,
  ThreeSections,
  AllPOCs,
  ProfileTimeline,
} from 'Components';
import { useParams } from 'react-router-dom';
import { getOrgProfile, updateOrg, getOrgCodenames } from 'Api/org-service';
import OrgProfile from './components/org-profile';
import './org-view.less';
import { newOrgEventFormSchema } from '../../schemas/event-schema';
import { OrgEventConfig } from 'Constants';
import { filterContentToCodenames } from 'Utils';
import { get } from 'lodash';

const { MainWrapper, Body, RightSider } = ThreeSections;
const defaultView = 'profile';

const OrgView = () => {
  const [currentView, setCurrentView] = useState(defaultView);
  const [orgInfo, setOrgInfo] = useState();
  const [foundOrg, setFoundOrg] = useState(true);
  const [canEdit, setCanEdit] = useState(false);

  const orgToCodenames = useRef({});

  let { id: companyId } = useParams();

  const handleViewChange = (event) => {
    const { value: viewName } = event.target;
    if (viewName) setCurrentView(viewName);
  };

  useEffect(async () => {
    if (companyId !== get(orgInfo, 'id', null)) {
      await fetchOrgInfo();
    }
    await getCodenames();
    await filterContentToCodenames(orgToCodenames.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, currentView]);

  const getCodenames = async () => {
    let records = await getOrgCodenames();
    records = get(records, 'data', {});
    let codenames = {};
    get(records, 'real_names', []).forEach((key, i) => (codenames[key] = records['codenames'][i]));
    orgToCodenames.current = codenames;
  };

  const fetchOrgInfo = async () => {
    try {
      const result = await getOrgProfile(companyId);
      const { data } = result;
      setOrgInfo(data);
      setCanEdit(data?.flags?.can_write || false);
    } catch (err) {
      setCanEdit(false);
      setFoundOrg(false);
      if (err.message.includes('not found')) {
        message.info('Organization Not Found');
      }
    }
  };

  const updateOrgInfo = async (updatedInfo, refreshOrgInfo = false) => {
    try {
      if (refreshOrgInfo) {
        await fetchOrgInfo();
        message.success('Company info refreshed');
        return;
      }
      const newOrgInfo = {
        ...orgInfo.other_data,
        ...updatedInfo,
      };
      const { data } = await updateOrg(orgInfo.id, { ...newOrgInfo });
      setOrgInfo(data);
      message.success('Company info updated successfully');
    } catch (err) {
      console.log(err);
      if (err?.message) {
        message.error(err.message);
      }
    }
  };

  const switchView = (currentView) => {
    if (!foundOrg) {
      return <Result status="warning" title="Something went wrong" />;
    }
    switch (currentView) {
      case 'profile':
        return <OrgProfile canEdit={canEdit} orgInfo={orgInfo} updateOrgInfo={updateOrgInfo} />;
      case 'timeline':
        return (
          <ProfileTimeline
            eventConfig={OrgEventConfig}
            timelineClassName={'organization-timeline'}
            eventFormSchema={newOrgEventFormSchema}
            entityType={'organization'}
            entityId={parseInt(companyId)}
          />
        );
      default:
        return setCurrentView(defaultView);
    }
  };

  return (
    <MainWrapper>
      <Body span={18} style={{ scrollPaddingTop: '90px' }}>
        <PageHeader
          sticky
          title={
            <>
              <Avatar style={{ backgroundColor: 'black' }} size="middle">
                {orgInfo?.name[0].toUpperCase()}
              </Avatar>
              {orgInfo?.name}
            </>
          }
          extra={
            foundOrg
              ? [
                  <Radio.Group
                    value={currentView}
                    key="radio-buttons"
                    onChange={handleViewChange}
                    buttonStyle="solid"
                  >
                    <Radio.Button value="profile">Profile</Radio.Button>
                    <Radio.Button value="timeline">Timeline</Radio.Button>
                  </Radio.Group>,
                ]
              : []
          }
        >
          <Row justify="end">
            <Col>
              <AllPOCs
                canEdit={canEdit}
                pocs={orgInfo?.hc_pocs || []}
                entityId={parseInt(orgInfo?.id)}
                destEntity={'organization'}
              />
            </Col>
          </Row>
        </PageHeader>
        {switchView(currentView)}
      </Body>
      <RightSider>
        <NotesWindow
          notesInfo={orgInfo?.notes || []}
          updateNotes={(updatedNotes) => updateOrgInfo({ notes: updatedNotes })}
        ></NotesWindow>
      </RightSider>
    </MainWrapper>
  );
};

export default OrgView;
