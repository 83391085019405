import { request } from './http-service';

const getOutstandingTasks = (token, paginationInfo = {}) => {
  const urlParams = new URLSearchParams({ ...paginationInfo }).toString();
  return request({
    url: `/outstanding_tasks?${urlParams}`,
    method: 'get',
    ...(token
      ? {
          headers: {
            Authorization: 'Bearer ' + token,
            'x-api-key': 'ignore',
          },
        }
      : {}),
  });
};

const getNumOutstandingTasks = () => {
  return request({
    url: `/num_outstanding_tasks`,
    method: 'get',
  });
};

const getMeetingURL = (meeting_id) => {
  return request({
    url: `/get_meeting_url?meeting_id=${meeting_id}`,
    method: 'get',
  });
};

const getCompletedMeetingFeedback = () => {
  return request({
    url: '/completed_feedback_forms',
    method: 'get',
  });
};

export { getOutstandingTasks, getNumOutstandingTasks, getMeetingURL, getCompletedMeetingFeedback };
