import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Select, message, Tooltip } from 'antd';
import { Tag } from 'Components';
import { useStoreValue } from 'Context';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { createPocRelationship, updateRelationship } from 'Api/relationship-service';

const { Option } = Select;

const AllPOCs = ({
  canEdit = false,
  pocs,
  entityId,
  destEntity = 'user',
  hideInactive = false,
}) => {
  const [newInputVisible, setNewInputVisible] = useState(false);
  const [allPocList, setAllPocList] = useState([]);
  const [pocList, setPocList] = useState([]);
  const [{ meta }] = useStoreValue();

  useEffect(() => {
    setPocList(pocs);
  }, [pocs]);

  useEffect(() => {
    setAllPocList(meta?.filter_options?.user?.hc_poc[0]?.requires[0]?.allowed_values);
  }, [meta]);

  const handleChange = async (removedPocId, pocStatus) => {
    if (!['active', 'inactive'].includes(pocStatus)) {
      pocStatus = 'inactive';
    }

    const pocIdx = pocList.findIndex(({ id }) => parseInt(id) === parseInt(removedPocId));
    const removedPoc = pocList.filter(({ id }) => parseInt(id) === parseInt(removedPocId))[0];
    const filteredPocs = pocList.filter(({ id }) => parseInt(id) !== parseInt(removedPocId));

    if (!filteredPocs.length) {
      message.error('Candidates need at least 1 POC');
      return;
    }

    const newOtherData = { ...removedPoc.other_data, status: pocStatus };
    const { data } = await updateRelationship(removedPocId, { other_data: newOtherData });
    const newPocList = [...filteredPocs];
    newPocList.splice(pocIdx, 0, data);
    setPocList(newPocList);
  };

  const showInput = () => {
    setNewInputVisible(true);
  };

  const onPocAdd = async (pocId) => {
    const filteredPocs = pocList.filter(
      ({ source_user_id }) => parseInt(source_user_id) === parseInt(pocId)
    );
    const remainingPocs = pocList.filter(
      ({ source_user_id }) => parseInt(source_user_id) !== parseInt(pocId)
    );
    let newPoc = undefined;
    if (filteredPocs.length) {
      const newOtherData = { ...filteredPocs[0].other_data, status: 'active' };
      const { data } = await updateRelationship(filteredPocs[0].id, { other_data: newOtherData });
      newPoc = data;
    } else {
      if (destEntity.includes('user')) {
        const { data } = await createPocRelationship(pocId, { destUserId: entityId });
        newPoc = data;
      } else if (destEntity.includes('organization')) {
        const { data } = await createPocRelationship(pocId, { destOrgId: entityId });
        newPoc = data;
      }
    }
    setPocList([...remainingPocs, newPoc]);
    setNewInputVisible(false);
  };

  return (
    <>
      {pocList.map(({ id, other_data }) => {
        const isActive = !('status' in other_data) || other_data?.status === 'active';
        if (!isActive && hideInactive) {
          return null;
        }
        const tagElem = (
          <Tooltip
            title={
              (other_data?.poc_name || other_data?.principal_name || other_data?.member_name) +
              (isActive ? '' : ' (inactive)')
            }
            key={id}
          >
            <Tag
              size="middle"
              className="edit-tag"
              key={id}
              color={isActive ? 'blue' : 'lightgray'}
              closable={canEdit}
              onClose={(event) => event.preventDefault()}
              closeIcon={
                isActive ? (
                  <Popconfirm
                    disabled={!canEdit}
                    title="Are you sure to disable this POC?"
                    onConfirm={() => handleChange(id, 'inactive')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseOutlined style={{ marginLeft: 10 }} />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    disabled={!canEdit}
                    title="Are you sure to enable this POC?"
                    onConfirm={() => handleChange(id, 'active')}
                    okText="Yes"
                    cancelText="No"
                  >
                    <PlusOutlined style={{ marginLeft: 10 }} />
                  </Popconfirm>
                )
              }
              style={{ fontSize: '12px', margin: '3px' }}
            >
              {
                (() =>
                  other_data?.poc_name ||
                  other_data?.principal_name ||
                  other_data?.member_name)()?.split(' ')[0]
              }
            </Tag>
          </Tooltip>
        );
        return tagElem;
      })}
      {newInputVisible ? (
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          showSearch
          autoFocus
          onChange={onPocAdd}
          size="small"
          optionFilterProp="label"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder="Search POC"
          style={{ fontSize: '12px', margin: '3px', width: 130 }}
        >
          {allPocList.map(({ display_hint_primary, value }, index) => (
            <Option key={index} value={value}>
              {display_hint_primary}
            </Option>
          ))}
        </Select>
      ) : (
        canEdit && (
          <Tag
            style={{ fontSize: '12px', margin: '3px' }}
            className="site-tag-plus"
            onClick={showInput}
          >
            <PlusOutlined /> New POC
          </Tag>
        )
      )}
    </>
  );
};

AllPOCs.propTypes = {
  canEdit: PropTypes.bool,
  pocs: PropTypes.array,
  entityId: PropTypes.number,
  destEntity: PropTypes.string,
  hideInactive: PropTypes.bool,
};

export default AllPOCs;
