import { request } from './http-service';
import { createPocRelationship } from 'Api/relationship-service';
import { isEqual } from 'lodash';

const createOrg = async (data, userId) => {
  const result = await request({
    url: `/organization/`,
    method: 'post',
    data,
  });
  const {
    data: { id },
  } = result;
  await createPocRelationship(userId, { destOrgId: id });
  return result;
};

const deleteOrg = async (orgId) => {
  return request({
    url: `/organization/${orgId}`,
    method: 'delete',
  });
};

const deactivateOrg = async (orgId) => {
  return request({
    url: `/deactivate_organization/${orgId}`,
    method: 'put',
  });
};

const getOrgProfile = (orgId) => {
  return request({
    url: `/organization/${orgId}`,
    method: 'get',
  });
};

const updateOrg = (orgId, data) => {
  return request({
    url: `/organization/${orgId}`,
    method: 'put',
    data,
  });
};

const getOrgEventLogs = () => {
  return {
    name: 'Anduril',
  };
};

const getOrgs = (pagination, sorter = {}, meta, opts) => {
  const urlParams = new URLSearchParams({ ...pagination, ...sorter }).toString();
  const { filters } = meta;

  // Filter dedupe
  meta.filters = meta.filters.reduce((acc, filter) => {
    if (
      !acc.reduce(
        (repeatedFilter, currentFilter) => repeatedFilter || isEqual(filter, currentFilter),
        false
      )
    ) {
      return [...acc, filter];
    }
    return acc;
  }, []);

  const query = filters.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  const options = opts ? encodeURIComponent(JSON.stringify(opts)) : '';
  return request({
    url: `/organization/?${urlParams}&q=${query}&opts=${options}`,
    method: 'get',
    headers: {
      make_event: true,
    },
  });
};

const getDeals = (pagination, sorter = {}, meta) => {
  let deal_meta = { ...meta };
  deal_meta.entity_type = 'organization';
  deal_meta.filters.push({
    field: 'role',
    constraints: [{ operator: 'is', value: 'deal', key: null }],
  });
  let options = { fetch_partial_records: false };
  return getOrgs(pagination, sorter, deal_meta, options);
};

const getPortfolio = (pagination, sorter = {}, meta, opts = {}) => {
  let portfolio_meta = { ...meta };
  portfolio_meta.entity_type = 'organization';
  portfolio_meta.filters.push({
    field: 'role',
    constraints: [{ operator: 'is', value: 'portfolio', key: null }],
  });
  let options = { fetch_partial_records: false, ...opts };
  return getOrgs(pagination, sorter, portfolio_meta, options);
};

const exportPortfolios = (meta) => {
  const { filters } = meta;
  const query = filters.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  return request({
    url: `/portfolio/download/?q=${query}`,
    method: 'get',
  });
};

const exportDeals = (meta) => {
  const { filters } = meta;
  const query = filters.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  return request({
    url: `/deals/download/?q=${query}`,
    method: 'get',
  });
};

const getOrgPocs = (orgId) => {
  return request({
    url: `/org_pocs/${orgId}`,
    method: 'get',
  });
};

const getOrgCodenames = () => {
  return request({
    url: `/org_codenames`,
    method: 'get',
  });
};

export {
  createOrg,
  deleteOrg,
  deactivateOrg,
  exportPortfolios,
  exportDeals,
  getOrgs,
  getDeals,
  getPortfolio,
  getOrgCodenames,
  getOrgProfile,
  getOrgEventLogs,
  getOrgPocs,
  updateOrg,
};
