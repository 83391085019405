import { request } from './http-service';

// Refactor it after BE automatically creates rel for new org & user
const createPocRelationship = async (currentUserId, { destUserId, destOrgId }) => {
  const navPocRelId = 4;
  const data = {
    source_user_id: currentUserId,
    destination_user_id: destUserId,
    destination_organization_id: destOrgId,
    relationship_type_id: navPocRelId,
  };
  return request({
    url: `/relationship/`,
    method: 'post',
    data,
  });
};

// curry function to initialize a func with reltype
// useful in relationships using generic components
const getRelationships = (reltype) => {
  return async (pagination, sorter = {}, meta) => {
    const urlParams = new URLSearchParams({
      ...pagination,
      ...sorter,
      ...{ reltype },
    }).toString();
    const { filters } = meta;
    const query = filters?.length ? encodeURIComponent(JSON.stringify(meta)) : '';
    return request({
      url: `/relationships/?${urlParams}&q=${query}`,
      method: 'get',
      headers: {
        make_event: true,
      },
    });
  };
};

const listRelationships = async (
  reltype,
  src_user_id = null,
  src_org_id = null,
  des_user_id = null,
  des_org_id = null
) => {
  // Create object with all non-null ids
  const ids = Object.entries({ src_user_id, src_org_id, des_user_id, des_org_id }).filter(
    (kv) => kv[1] !== null
  );
  const urlParams = new URLSearchParams({
    reltype,
    ...Object.fromEntries(ids),
  }).toString();
  return request({ url: `/relationships/?${urlParams}`, method: 'get' });
};

//fetches the associated candidates for given job
const getAssociatedCandidates = (positionId) => {
  const urlParams = new URLSearchParams({
    position_id: positionId,
  }).toString();
  return request({
    url: `/interviews/?${urlParams}`,
    method: 'get',
  });
};

const createIntro = async (introInfo) => {
  const relationship_type_name = 'matched_to';
  const data = { ...introInfo, relationship_type_name };
  return request({
    url: `/relationship/`,
    method: 'post',
    data,
  });
};

const createOrgPocRelationship = async (pocInfo) => {
  const relationship_type_name = 'client_portal_user_for';
  const data = { ...pocInfo, relationship_type_name };
  return request({
    url: `/relationship/`,
    method: 'post',
    data,
  });
};

const createRelationship = async (data) => {
  return request({
    url: `/relationship/`,
    method: 'post',
    data,
  });
};

const deleteRelationship = (relnId) => {
  return request({
    url: `/relationship/${relnId}`,
    method: 'delete',
  });
};

const updateRelationship = (relnId, data) => {
  return request({
    url: `/relationship/${relnId}`,
    method: 'put',
    data,
  });
};

const createInterview = async (interviewInfo) => {
  const relationship_type_name = 'interviewing_with';
  const data = { ...interviewInfo, relationship_type_name };
  return request({
    url: `/relationship/`,
    method: 'post',
    data,
  });
};

const getRelationshipTypes = async () => {
  return request({
    url: `/relationship_type/`,
    method: 'get',
  });
};

const getMyPOCs = async (entity) => {
  return request({
    url: `/my_pocs?entity=${entity}`,
    method: 'get',
  });
};

export {
  createPocRelationship,
  getRelationships,
  listRelationships,
  createIntro,
  createInterview,
  createOrgPocRelationship,
  getAssociatedCandidates,
  deleteRelationship,
  updateRelationship,
  createRelationship,
  getRelationshipTypes,
  getMyPOCs,
};
