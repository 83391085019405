import PropTypes from 'prop-types';
import { Col, Form, Row, Timeline, Select, Popconfirm } from 'antd';
import { PlusOutlined, CloseSquareTwoTone } from '@ant-design/icons';
import { Input, Button, SelectSearch } from 'Components';
import { generateSelectOptions } from 'Helpers';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';

const NestedFormList = ({
  canEdit,
  subFields,
  parentName,
  parentLabel,
  formInstance,
  valueChangeCallback,
}) => {
  return (
    <Col span={24} key={parentName}>
      <Form.List name={parentName}>
        {(fields, { add, remove }) => (
          <Timeline>
            {fields.map((field, index) => (
              <Timeline.Item dot={null} key={index}>
                <div
                  style={{
                    left: '-28px',
                    position: 'absolute',
                    textAlign: 'right',
                    zIndex: 9,
                  }}
                >
                  <Popconfirm
                    disabled={!canEdit}
                    title={`Are you sure to delete this ${parentLabel}?`}
                    onConfirm={() => {
                      remove(field.name);
                      valueChangeCallback(parentName, formInstance.getFieldValue(parentName));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseSquareTwoTone twoToneColor="#3645BF" style={{ fontSize: 16 }} />
                  </Popconfirm>
                </div>
                <Row align="bottom" gutter={[40, 4]}>
                  {subFields.map((fieldConfig) => (
                    <Col span={fieldConfig.span} key={`${field.fieldKey}_${fieldConfig.name}`}>
                      <Form.Item
                        {...field}
                        label={fieldConfig.label}
                        name={[field.name, fieldConfig.name]}
                        fieldKey={[field.fieldKey, fieldConfig.name]}
                      >
                        {fieldConfig.type === 'select' ? (
                          <Select
                            getPopupContainer={(triggerNode) => triggerNode.parentElement}
                            disabled={!canEdit}
                            onChange={() =>
                              valueChangeCallback(
                                parentName,
                                formInstance.getFieldValue(parentName)
                              )
                            }
                            size="large"
                            placeholder={fieldConfig.placeholder}
                            showSearch
                          >
                            {generateSelectOptions(fieldConfig.allowed_values)}
                          </Select>
                        ) : fieldConfig.type === 'date' ? (
                          <Input
                            disabled={!canEdit}
                            onChange={(momentDate) => {
                              let nestedFieldValue = formInstance
                                .getFieldValue(parentName)
                                ?.slice();
                              const currentItem = {
                                ...nestedFieldValue[field.key],
                              };
                              currentItem[fieldConfig.name] = momentDate;
                              nestedFieldValue[field.key] = currentItem;
                              valueChangeCallback(parentName, nestedFieldValue);
                            }}
                            type={fieldConfig.type}
                            placeholder={fieldConfig.placeholder}
                            picker={fieldConfig.picker}
                          />
                        ) : fieldConfig.type === 'search' ? (
                          <SelectSearch
                            disabled={!canEdit}
                            setValue={(_, { label, value }) => {
                              let nestedFieldValue = cloneDeep(
                                formInstance.getFieldValue(parentName)
                              );
                              const currentItem = { ...nestedFieldValue[field.key] };
                              currentItem[fieldConfig.primary_key] = value;
                              currentItem[fieldConfig.name] = label;
                              nestedFieldValue[field.key] = currentItem;
                              valueChangeCallback(parentName, nestedFieldValue);
                            }}
                            size="large"
                            search_entity={fieldConfig.search_entity}
                            skipEmail={fieldConfig.skip_email}
                          />
                        ) : fieldConfig.type === 'link' ? (
                          formInstance.getFieldValue(parentName)[field.key] !== undefined &&
                          fieldConfig !== undefined &&
                          'link_destination_key' in fieldConfig &&
                          fieldConfig.link_destination_key !== undefined ? (
                            <Link
                              to={`/${fieldConfig.link_destination_type}/${
                                formInstance.getFieldValue(parentName)[field.key][
                                  fieldConfig.link_destination_key
                                ]
                              }`}
                            >
                              {`${
                                formInstance.getFieldValue(parentName)[field.key][fieldConfig.name]
                              }`}
                            </Link>
                          ) : null
                        ) : (
                          <Input
                            disabled={!canEdit}
                            onBlur={() =>
                              valueChangeCallback(
                                parentName,
                                formInstance.getFieldValue(parentName)
                              )
                            }
                            type={fieldConfig.type}
                            placeholder={fieldConfig.placeholder}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Timeline.Item>
            ))}

            <Form.Item>
              <Button
                disabled={!canEdit}
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                {`Add ${parentLabel}`}
              </Button>
            </Form.Item>
          </Timeline>
        )}
      </Form.List>
    </Col>
  );
};

NestedFormList.propTypes = {
  canEdit: PropTypes.bool,
  subFields: PropTypes.array,
  parentName: PropTypes.string,
  parentLabel: PropTypes.string,
  formInstance: PropTypes.object,
  valueChangeCallback: PropTypes.func,
};

export default NestedFormList;
