const ForOrgProfile = {
  company: {
    entity: 'user',
    roles: ['admin', 'investment', 'legal'],
  },
  deal: {
    entity: 'user',
    roles: ['investment'],
  },
  portfolio: {
    entity: 'organization',
    roles: ['portfolio'],
  },
  talent_pipeline: {
    entity: 'organization',
    roles: ['portfolio'],
  },
};

export default { ForOrgProfile };
