import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Table } from 'antd';

const RelatedTable = ({ columnConfig, data, columnSpan = 24, size = 'small' }) => {
  const [entityList, setEntityList] = useState([]);

  useEffect(async () => {
    if (data) {
      setEntityList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Col span={columnSpan}>
      <Table
        // loading={isLoading}
        pagination={false}
        rowKey={(record) => record.id}
        size={size}
        dataSource={entityList}
        bordered
        columns={columnConfig}
      />
    </Col>
  );
};

RelatedTable.propTypes = {
  columnConfig: PropTypes.array,
  data: PropTypes.array,
  columnSpan: PropTypes.number,
  size: PropTypes.string,
};

export default RelatedTable;
