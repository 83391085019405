import React from 'react';

import { Content } from 'Components';
import { Row, Col, Result } from 'antd';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SplashPage = ({ content, showing = true, subOverride }) => {
  let query = useQuery();

  const subTitle = subOverride || query.get('sub_title', null) || 'You may now close this window.';
  return showing ? (
    <div className="auth_container splash">
      <Row>
        <Col
          span={24}
          style={{
            minHeight: '100vh',
          }}
        >
          <Content
            spacing="medium"
            style={{
              background: '#f7f7f7',
              display: 'table-cell',
              verticalAlign: 'middle',
              width: '100vw',
              height: '100vh',
            }}
          >
            <div>
              <Result
                status="success"
                title={'Thank you for your submission!'}
                subTitle={subTitle}
              />
              {content}
            </div>
          </Content>
        </Col>
      </Row>
    </div>
  ) : null;
};

SplashPage.propTypes = {
  content: PropTypes.any,
  showing: PropTypes.bool,
  subOverride: PropTypes.string,
};

export default SplashPage;
