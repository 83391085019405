import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { Button } from 'Components';
import { newFounderFormSchema } from 'Schemas/company-schema';
import { createUser } from 'Api/user-service';
import { generateFormFields } from 'Helpers';
import { makeShowable } from 'Utils';

const AddFounderModal = ({ showModal, setShowModal, orgId, refresh, existingVal = [] }) => {
  const [addFounderForm] = Form.useForm();

  const addNewFounder = async ({ user_id, member_name, member_linkedin, member_email }) => {
    try {
      if (member_email !== undefined) {
        const userData = {
          first_name: member_name.split(' ').slice(0, -1).join(' '),
          last_name: member_name.split(' ').slice(-1).join(' '),
          linkedins: member_linkedin,
          emails: [{ email: member_email }],
        };
        const { data } = await createUser(userData);
        user_id = data['id'];
      }
      const initReln = {
        source_user_id: user_id,
        destination_organization_id: orgId,
        relationship_type_name: 'founded',
      };
      typeof refresh === 'function' && refresh('founders', [...existingVal, initReln]);
      message.success('Founder created successfully', 2);
      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(makeShowable(err.message, 'add_founder'));
    }
  };

  return (
    <Modal
      title="Add Founder"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <Form form={addFounderForm} onFinish={addNewFounder} layout="vertical" preserve={false}>
        <Row gutter={[24]}>{generateFormFields(newFounderFormSchema)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Add Founder
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddFounderModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  orgId: PropTypes.number,
  refresh: PropTypes.func,
  existingVal: PropTypes.array,
};

export default AddFounderModal;
