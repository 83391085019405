import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import './three-sections.less';

export const LeftSider = ({ span = 4, ...rest }) => {
  return <Col className="left-sider" span={span} {...rest}></Col>;
};

export const RightSider = ({ span = 6, ...rest }) => {
  return <Col className="right-sider" span={span} {...rest}></Col>;
};

export const Body = ({ span = 14, ...rest }) => {
  return <Col className="body" span={span} {...rest}></Col>;
};

export const MainWrapper = (props) => {
  return <Row className="three-sections" {...props} />;
};

LeftSider.propTypes = {
  span: PropTypes.number,
};

RightSider.propTypes = {
  span: PropTypes.number,
};

Body.propTypes = {
  span: PropTypes.number,
};
