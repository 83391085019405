import { JobStatus, JobTypes, UsStates } from 'Constants';

const newJobSchema = [
  {
    label: 'Organization',
    name: 'organization_id',
    type: 'search',
    required: true,
    search_entity: 'organization',
    span: 24,
    placeholder: 'Search Company',
  },
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
    span: 12,
    placeholder: 'Job Name',
  },
  {
    label: 'Type',
    name: 'type',
    type: 'select',
    span: 12,
    allowed_values: JobTypes,
    placeholder: 'Full time, Intern etc',
  },
  {
    label: 'Target Start Date',
    name: 'target_start_date',
    type: 'date',
    span: 8,
  },
  {
    label: 'No of Openings',
    name: 'num_openings',
    type: 'number',
    span: 8,
  },
  {
    label: 'Status',
    name: 'status',
    type: 'select',
    allowed_values: JobStatus,
    span: 8,
  },
];

const jobProfileSchema = [
  {
    label: 'Basic Info',
    name: 'basic_info',
    section: 'basic_info',
    type: 'flat',
    icon: '📇',
    subFields: [
      {
        label: 'Name',
        name: 'name',
        type: 'text',
        span: 16,
      },
      {
        label: 'Type',
        name: 'type',
        type: 'select',
        span: 8,
        allowed_values: JobTypes,
      },
      {
        label: 'Target Start Date',
        name: 'target_start_date',
        type: 'date',
        span: 8,
      },
      {
        label: 'No of Openings',
        name: 'num_openings',
        type: 'number',
        span: 8,
      },
      {
        label: 'Status',
        name: 'status',
        type: 'select',
        allowed_values: JobStatus,
        span: 8,
      },
      {
        label: 'Is Stale',
        name: 'stale',
        type: 'select',
        span: 8,
        allowed_values: [
          { display_hint_primary: 'Yes', value: true },
          { display_hint_primary: 'No', value: false },
        ],
      },
      {
        label: 'Skills',
        name: 'skills',
        type: 'select',
        span: 16,
        multi_valued: true,
      },
      {
        label: 'Job Description',
        name: 'description',
        type: 'rte',
        span: 24,
      },
    ],
  },
  {
    label: 'Locations',
    name: 'locations',
    type: 'nested',
    section: 'locations',
    icon: '🏠',
    subFields: [
      {
        label: 'City',
        name: 'city',
        type: 'text',
        span: 8,
      },
      {
        label: 'State',
        name: 'state',
        type: 'select',
        span: 8,
        allowed_values: UsStates,
      },
      {
        label: 'Country',
        name: 'country',
        type: 'text',
        span: 8,
      },
    ],
  },
];

export { newJobSchema, jobProfileSchema };
