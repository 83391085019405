import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, message, Form, Select, Tooltip } from 'antd';
// import KanbanModal from '../../pages/deals/components/kanban-modal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Spin } from 'antd';
import {
  makeTransition,
  getTransitionValues,
  setTransitionValues,
  getConfig,
} from 'Api/state-service';
import './kanban.less';
import {
  PortfolioEngagementStages,
  BannedModalKeys,
  PortfolioHealthIndicators,
  ModalLinkKeys,
} from 'Constants';
import { capitalize, cloneDeep, get, isEmpty, isEqual, uniqueId } from 'lodash';
import { getDeals, updateOrg } from 'Api/org-service';
import { getPortfolio } from 'Api/org-service';
import { Content } from 'antd/lib/layout/layout';
import { getURLWithHTTPSProtocol } from 'Utils';

const { Text, Title } = Typography;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#e5e5e5' : '#F5F5F5',
});

const getItemStyle = (draggableStyle, isDragging) => ({
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const Kanban = ({
  currentView,
  kanbanData,
  setKanbanData,
  customizeKanbanCard,
  customizeKanbanModal,
  kanbanType,
  kanbanEnumField,
}) => {
  const [items, setItems] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContentOverride, setModalContentOverride] = useState(null);
  const [showTransitionModal, setShowTransitionModal] = useState(false);
  const [shouldReorderItems, setShouldReorderItems] = useState(false);
  const [currentItemColIdx, setCurrentItemColIdx] = useState(null);
  const [targetItemColIdx, setTargetItemColIdx] = useState(null);
  const [transitionMapper, setTransitionMapper] = useState(null);
  const [transitionRequirements, setTransitionRequirements] = useState([]);
  const [previousFilters, setPreviousFilters] = useState([]);

  useEffect(() => {
    const config_meta = { entity_type: kanbanType, enum_field: kanbanEnumField };
    getConfig(config_meta).then((result) => {
      if (result.status !== 200) {
        message.error('Unable to load kanban config');
        return;
      }
      setTransitionMapper(result.data);
    });
  }, []);

  useEffect(async () => {
    if (isEmpty(previousFilters) || !isEqual(currentView?.filters, previousFilters)) {
      setPreviousFilters(currentView?.filters);
      if (kanbanEnumField.includes('Deal')) {
        const { data } = await getDeals({}, {}, { filters: currentView.filters }, {});
        setKanbanData(data);
      } else if (kanbanEnumField.includes('Portfolio')) {
        const { data } = await getPortfolio({}, {}, { filters: currentView.filters }, {});
        setKanbanData(data);
      }
    }
  }, [currentView]);

  useEffect(async () => {
    if (shouldReorderItems) {
      /*
      const newItems = reorder(
        items,
        currentItemColIdx,
        targetItemColIdx,
        currentItem.source.index,
        currentItem.destination.index
        updateKanban(newItems);
      );*/
      if (kanbanEnumField.includes('Deal')) {
        const { data } = await getDeals({}, {}, { filters: currentView.filters }, {});
        setKanbanData(data);
      } else if (kanbanEnumField.includes('Portfolio')) {
        const { data } = await getPortfolio({}, {}, { filters: currentView.filters }, {});
        setKanbanData(data);
      }
    }
  }, [shouldReorderItems]);

  useEffect(() => {
    updateKanban();
  }, [kanbanData, transitionMapper]);

  const updateKanban = (newItems = []) => {
    // getData();
    if (transitionMapper === null || isEqual(kanbanData, {})) {
      return;
    }
    var data = [];
    const newKanbanData = !isEqual(
      items.reduce((acc, elementArr) => [...acc, ...elementArr], []),
      kanbanData
    );
    if (columnData.length === 0 || (newKanbanData && newItems.length === 0)) {
      transitionMapper.states.map((stage) => {
        data[stage] = [];
      });
      kanbanData.map((entity) => {
        const stage = kanbanEnumField.includes('PortfolioProfile')
          ? get(entity, 'roles.portfolio.engagement_stage', 'n/a')
          : get(entity, 'roles.deal.stage', 'n/a');
        if (transitionMapper.states.includes(stage)) {
          data[stage].push(entity);
        }
      });
    } else {
      transitionMapper.states.map((stage, index) => {
        if (newItems.length === 0) {
          data[stage] = items[index];
        } else {
          data[stage] = newItems[index];
        }
      });
    }
    const newColumnData = Array.from(Object.keys(data), (column_name, index) =>
      Column(data[column_name], column_name, index, onClickCardModal)
    );
    setColumnData(newColumnData);
    setItems(
      newItems.length > 0 ? newItems : Array.from(Object.keys(data), (col_name) => data[col_name])
    );
    setShouldReorderItems(false);
  };
  const wordLibrary = { pocs: 'POCs', poc: 'POC', hc: 'HC' };
  const titleStyle = { paddingTop: 3, color: 'black' };
  const parseKey = (key) => {
    let res = [];
    key = capitalize(key.replaceAll('_', ' '));
    let words = key.split(' ');
    words.forEach((word) => {
      word = word.toLowerCase() in wordLibrary ? wordLibrary[word.toLowerCase()] : capitalize(word);
      res.push(word);
    });
    return <Text style={{ fontSize: 15 }}>{res.join(' ')}</Text>;
  };

  const readObject = (val) => {
    if (val === null || typeof val !== 'object') {
      return val;
    }
    if ('member_name' in val) {
      // Read HC POC
      return val['member_name'];
    }

    // Read Deal and Portfolio Info
    let res = [];
    let objectInfo = {};
    if ('deal' in val && val['deal'] !== null) {
      objectInfo = { ...objectInfo, ...cloneDeep(val['deal']) };
      objectInfo.deal_stage = objectInfo['stage'];
      delete objectInfo.stage;
    }
    if ('portfolio' in val && val['portfolio'] !== null) {
      objectInfo = { ...objectInfo, ...cloneDeep(val['portfolio']) };
    }
    let keys = Object.keys(objectInfo).filter((elem) => !BannedModalKeys.has(elem));
    for (let k = keys.length - 1; k >= 0; k--) {
      let key = keys[k];
      if (key in PortfolioHealthIndicators) {
        PortfolioHealthIndicators[key].map((indicator) => {
          if (objectInfo[key] === indicator.value) {
            objectInfo[key] = indicator.display_hint_primary;
          }
        });
      } else if (objectInfo[key] in PortfolioEngagementStages) {
        objectInfo[key] = PortfolioEngagementStages[objectInfo[key]];
      }
      if (key === 'fundraising') {
        key = 'fundraising_rounds';
        objectInfo[key] = objectInfo['fundraising'].length;
      }
      BannedModalKeys.has(key) || (!objectInfo[key] && objectInfo[key] !== 0)
        ? null
        : res.push(
            <Form.Item
              key={key}
              label={parseKey(key)}
              // labelCol={{ span: 12 }}
              // wrapperCol={{ span: 12 }}
              style={{
                marginBottom: k == 0 ? 0 : 24,
              }} // eliminate extra space at bottom
            >
              {parseVal(key, objectInfo[key])}
            </Form.Item>
          );
    }
    return res;
  };
  const parseVal = (key, val) => {
    if (key === 'action_items') {
      val = val?.length ? val?.split('|') : [];
    }
    if (typeof val === 'number') {
      // if number just return
      return (
        <Title level={5} style={titleStyle}>
          {val}
        </Title>
      );
    } else if (typeof val === 'string') {
      if (ModalLinkKeys.has(key)) {
        return (
          <a rel="noreferrer" target="_blank" href={getURLWithHTTPSProtocol(val)}>
            <Title level={5} style={titleStyle}>
              {val}
            </Title>
          </a>
        );
      }
      return <Content className="popup-content">{val}</Content>;
    } else if (Array.isArray(val)) {
      if (val.length >= 1 && typeof val[0] === 'object' && 'other_data' in val[0]) {
        val = val.map((x) => x['other_data']);
      }
      return (
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          defaultValue={val?.length ? val.map((x) => readObject(x)) : []}
          tokenSeparators={['|']}
          size="middle"
          mode="tags"
          placeholder="none"
          style={{ width: '100%' }}
          disabled={true}
          tagRender={({ label }) => {
            return (
              <Tooltip title={label} color="lightgrey">
                <span className="ant-select-selection-item">
                  <span className="ant-select-selection-item-content">{label}</span>
                </span>
              </Tooltip>
            );
          }}
        ></Select>
      );
    } else if (typeof val == 'object') {
      // if object
      return readObject(val);
    }
    const date = Date.parse(val);
    // TODO: apply support for all regions, currently only US-West
    return !isNaN(date) ? date.toLocaleString('en-US', { timeZone: 'PST' }) : val;
  };

  const invalidValues = (key, val) => val == null || BannedModalKeys.has(key);
  const onClickCardModal = (content) => {
    setModalTitle(content['name']);

    setShowTransitionModal(true);
    return Object.keys(content).map((key) =>
      invalidValues(key, content[key]) ? null : (
        <Form.Item
          label={key == 'roles' ? '' : parseKey(key)}
          // labelCol={{ span: 12 }}
          // wrapperCol={{ span: typeof content[key] == 'object' ? 24 : 12 }}
          key={key + uniqueId()}
          // rules={[{ message: 'Please input your username!' }]}
        >
          {parseVal(key, content[key])}
        </Form.Item>
      )
    );
  };

  // TODO: move item and column back out of Kanban before merging to staging
  const Item = (item, index, onClickItem) => (
    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={index}>
      {(provided, snapshot) => (
        <div>
          <div
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            style={getItemStyle(provided.draggableProps.style, snapshot.isDragging)}
            className="item"
          >
            {customizeKanbanCard({
              item: item,
              setModalContent: setModalContentOverride,
              setShowKanbanModal: setShowTransitionModal,
              onClickItem: onClickItem,
            })}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Draggable>
  );

  const Column = (columnItems, name, index, onCardClick) => {
    const isPortfolio = kanbanEnumField.includes('PortfolioProfile');
    return (
      <div key={name}>
        <div className="column-title center">
          <Title level={4}>{isPortfolio ? PortfolioEngagementStages[name] : name}</Title>
        </div>
        <Droppable droppableId={name}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
              className="list"
              {...provided.droppableProps}
            >
              {columnItems.map((item, index) => Item(item, index, onCardClick))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const fromColIdx = transitionMapper.states.indexOf(result.source.droppableId);
    const toColIdx = transitionMapper.states.indexOf(result.destination.droppableId);
    setCurrentItemColIdx(fromColIdx);
    setTargetItemColIdx(toColIdx);

    var transitionFound = false;
    var transitionData = null;
    transitionMapper.transitions.map((transition) => {
      if (
        (transition.source === result.source.droppableId || transition.source === '*') &&
        (transition.dest === result.destination.droppableId || transition.dest === '*')
      ) {
        transitionFound = true;
        transitionData = cloneDeep(transition);
      }
    });
    if (!transitionFound && toColIdx > fromColIdx) {
      message.error('Transition Path Not Available.');
      return;
    }
    if (toColIdx <= fromColIdx || !transitionData.requirements) {
      // Empty requirements,just move the entity
      if (kanbanType === 'Organization') {
        kanbanData.map((entity) => {
          if (entity.id === parseInt(result.draggableId)) {
            const updatedRoles = cloneDeep(entity.roles);
            const orgIsDeal = kanbanEnumField.includes('Deal');
            updatedRoles[orgIsDeal ? 'deal' : 'portfolio'][
              orgIsDeal ? 'stage' : 'engagement_stage'
            ] = result.destination.droppableId;
            updateOrg(parseInt(result.draggableId), { roles: updatedRoles })
              .then((response) => {
                if (response.status === 200) {
                  updateKanban();
                  message.success('Stage updated.');
                  setShouldReorderItems(true);
                }
              })
              .catch((error) => {
                console.log(error);
                setCurrentItemColIdx(null);
                setTargetItemColIdx(null);
                setShouldReorderItems(false);
                message.warning('Non-PoC unable to backtrack card.');
              });
          }
        });
        //updateOrg(result.draggableId, )
      }

      return;
    }

    // Requirements exist, check for pre-existing transition values
    setLoading(true);
    const transition_meta = {
      entity_type: kanbanType,
      entity_id: parseInt(result.draggableId),
      enum_field: kanbanEnumField,
      trigger: transitionData.trigger,
    };
    getTransitionValues(transition_meta).then((response) => {
      if (response.status !== 200) {
        setLoading(false);
        return;
      }
      response.data.other_data?.requirements.map((requirement) => {
        transitionData.requirements.map((req) => {
          if (req.name === requirement.name) {
            req.value = requirement.value;
          }
        });
      });
      transitionData.entity_id = parseInt(result.draggableId);
      setTransitionRequirements(transitionData);
      setShouldReorderItems(false);
      setShowTransitionModal(true);
      setLoading(false);
    });
  };

  const onSubmitTransitionModal = (result) => {
    return setTransitionValues({
      entity_id: result.entity_id,
      entity_type: kanbanType,
      enum_field: kanbanEnumField,
      trigger: result.trigger,
      form: result.form,
    }).then((response) => {
      setLoading(false);
      if (response.status === 201) {
        return makeTransition({
          entity_id: result.entity_id,
          entity_type: kanbanType,
          enum_field: kanbanEnumField,
          trigger: result.trigger,
        }).then((resp) => {
          if (resp.status !== 200) {
            message.error('Transition Failed.');
            return false;
          }
          if (resp.data !== transitionRequirements.dest) {
            message.info('Transition(s) Not Fulfilled.');
            return false;
          }
          message.success('Transitioned Successfully!');
          return true;
        });
      } else {
        message.error('onSubmitTransitionModal failed', response);
        return false;
      }
    });
  };

  return (
    <div>
      <Spin className="loadingSpinner" spinning={loading} size="large">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="kanban-container">{columnData}</div>
        </DragDropContext>
        {customizeKanbanModal({
          transitionData: transitionRequirements,
          title: [modalTitle, setModalTitle],
          showModal: showTransitionModal,
          setShowModal: setShowTransitionModal,
          setReorderItems: setShouldReorderItems,
          transitionIdx: kanbanEnumField.includes('Deal') ? targetItemColIdx : currentItemColIdx,
          contentOverride: [modalContentOverride, setModalContentOverride],
          onSubmit: onSubmitTransitionModal,
        })}
      </Spin>
    </div>
  );
};

Kanban.propTypes = {
  currentView: PropTypes.object,
  kanbanData: PropTypes.array,
  setKanbanData: PropTypes.func,
  customizeKanbanCard: PropTypes.func,
  customizeKanbanModal: PropTypes.func,
  kanbanType: PropTypes.string,
  kanbanEnumField: PropTypes.string,
  kanbanStageIdx: PropTypes.object,
};

export default Kanban;
