import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './app.less';
import { openRoutes, closedRoutes } from './limited.routes.config';
import { Header, ProtectedRoute } from 'Components';
import { useStoreValue } from 'Context';
import { getAllowedRoutes } from 'Utils';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  const [{ user }] = useStoreValue();
  const [allowedRoutes, setAllowedRoutes] = useState([]);

  useEffect(() => {
    if (user) {
      let filteredRoutes = getAllowedRoutes(closedRoutes);
      setAllowedRoutes([...filteredRoutes]);
    }
  }, [user]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <div className="app-container">
        <Router>
          <Header routes={allowedRoutes} />
          <Switch>
            {allowedRoutes.map(({ path, component }) => (
              <ProtectedRoute key={path} exact path={path} component={component} />
            ))}
            {openRoutes.map(({ path, component }) => (
              <Route key={path} exact path={path} component={component} />
            ))}
          </Switch>
        </Router>
      </div>
    </GoogleOAuthProvider>
  );
};

export default App;
