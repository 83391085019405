// Admins can be added from BE only
const Roles = {
  MEMBER: 'member',
  CLIENT_POC: 'client_poc',
  LEGAL: 'legal',
  PRINCIPAL: 'principal',
  EXECUTIVE: 'executive',
  FUTURE_FOUNDER: 'future_founder',
  INVESTMENT: 'investment',
};

const PublicRoles = {
  CLIENT_POC: 'client_poc',
  EXECUTIVE: 'executive',
  FUTURE_FOUNDER: 'future_founder',
};

const NonMemberRoles = [Roles.MEMBER, Roles.INVESTMENT];

const UserRoles = Object.keys(Roles).reduce((acc, type) => {
  acc.push(Roles[type]);
  return acc;
}, []);

const PublicUserRoles = Object.keys(PublicRoles).reduce((acc, type) => {
  acc.push(Roles[type]);
  return acc;
}, []);

const OrgRoles = ['company', /* 'school', 'campus_org', */ 'deal', 'portfolio'];

const PortfolioEngagementStages = {
  founder_meeting: 'Founder Meeting',
  audit_pending: 'Audit Pending',
  audit: 'Audit',
  recommendation: 'Recommendation',
  embedded: 'Embedded',
  advisory: 'Advisory',
  advisory_plus: 'Advisory+',
  completed: 'Completed',
};

const PortfolioHealthIndicators = {
  affinity: [
    { display_hint_primary: 'Strong Affinity Established', value: 'strong' },
    { display_hint_primary: 'Building Affinity; On Track', value: 'on_track' },
    { display_hint_primary: 'Early in Building Affinity', value: 'early' },
    { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
    { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
  ],
  trust: [
    { display_hint_primary: 'Strong Trust Established', value: 'strong' },
    { display_hint_primary: 'Building Trust; On Track', value: 'on_track' },
    { display_hint_primary: 'Early in Building Trust', value: 'early' },
    { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
    { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
  ],
  ops: [
    { display_hint_primary: 'Supported Significant Ops Work', value: 'strong' },
    { display_hint_primary: 'Supported Meaningful Ops Work', value: 'on_track' },
    { display_hint_primary: 'Supported Some Ops Work', value: 'early' },
    { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
    { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
  ],
  hiring: [
    { display_hint_primary: 'Supported Significant Hires', value: 'strong' },
    { display_hint_primary: 'Supported Meaningful Hires', value: 'on_track' },
    { display_hint_primary: 'Supported Some Hires', value: 'early' },
    { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
    { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
  ],
};

const PortfolioStageIdx = {
  'Founder Meeting': 0,
  'Audit Pending': 1,
  Audit: 2,
  Recommendation: 3,
  Embedded: 4,
  'Advisory/Advisory+': 5,
  Completed: 6,
};

const BannedModalKeys = new Set([
  'hc_client_status',
  'logo_link',
  'name',
  'created_at',
  'updated_at',
  'locations',
  'notes',
  'tech_stack',
  'is_active',
  'client_name',
  'member_linkedin',
  'positions',
  'deal_profile_id',
  'type',
  'portfolio_profile_id',
  'company_profile_id',
  'id',
  'engagement_start_date',
  'engagement_end_date',
]);

const ModalLinkKeys = new Set(['deck', 'investment_memo', 'website']);

const ColorBlindFriendlyRolesPalette = {
  member: '#785EF0',
  client_poc: '#994F00',
  executive: '#DC267F',
  legal: '#ED659A',
  future_founder: '#FFB000',
  investment: '#1AFF1A',
  principal: '#39A5FF',
};

const InterviewStages = [
  'Stage 1 (Intro Call / Meet)',
  'Stage 2 (Tech Challenge)',
  'Stage 3 (Tech Phone Screen)',
  'Stage 4 (Onsite / Remote Onsite)',
  'Stage 5 (Offer will be given)',
  'Stage 6 (Offer)',
  'Stage 7 (Placement, Internship Placement)',
  'Stage -1 (Interviewing Halted)',
];

const Genders = ['male', 'female', 'non_binary'];

const TagColors = [
  'green',
  'magenta',
  'cyan',
  'lime',
  'red',
  'gold',
  'blue',
  'orange',
  'volcano',
  'purple',
  'geekblue',
];

const HcClientStatus = ['current_client', 'past_client', 'never_client'];

const OrgTypes = ['company', 'school', 'campus_org'];

const UsStates = [
  'Alaska',
  'Alabama',
  'Arkansas',
  'American Samoa',
  'Arizona',
  'California',
  'Colorado',
  'Connecticut',
  'District of Columbia',
  'Delaware',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Iowa',
  'Idaho',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Massachusetts',
  'Maryland',
  'Maine',
  'Michigan',
  'Minnesota',
  'Missouri',
  'Mississippi',
  'Montana',
  'North Carolina',
  ' North Dakota',
  'Nebraska',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'Nevada',
  'New York',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Virginia',
  'Virgin Islands',
  'Vermont',
  'Washington',
  'Wisconsin',
  'West Virginia',
  'Wyoming',
];

const JobStatus = ['ACTIVE', 'PAUSED', 'FILLED', 'CLOSED'];

const JobTypes = ['Full-Time', 'Internship'];

const IntroStages = ['TO REVIEW', 'HIDDEN', 'ON HOLD', 'PASSED', 'ACCEPTED', 'INTRODUCED'];

// Operators without constraints
const EmptyOperators = ['isEmpty', 'isNotEmpty'];

const HumanEventConfig = {
  // calendly_meeting: { color: 'blue', displayName: 'Calendly Meeting' },
  google_calendar_event: { color: '#4ec2e6', displayName: 'Meeting' },
  created_at: { color: 'yellow', displayName: 'Profile Creation Date' },
  outreach_meeting: { color: 'cyan', displayName: 'Outreach Meeting' },
  received_email: { color: 'red', displayName: 'Email Received' },
  sent_email: { color: 'orange', displayName: 'Email Sent' },
  updated_fundraising: { color: 'aquamarine', displayName: 'Fundraise' },
  updated_interview: { color: 'green', displayName: 'Interview Update' },
  updated_intro: { color: 'teal', displayName: 'Intro Update' },
  updated_profile: { color: 'purple', displayName: 'Profile Update' },
  post_meeting_update: { color: 'gray', displayName: 'Post Meeting Update' },
  // accessed_filter: { color: 'brown', displayName: 'Query Made' },
  // executed_transition: { color: 'black', displayName: 'Executed Transition' },
  // logged_in: { color: 'violet', displayName: 'Log In' },
  // merge_records: { color: 'yellow', displayName: 'Merged Records' },
};

const AdminEventConfig = {
  accessed_page: { color: 'violet', displayName: 'Profile View' },
  created_page: { color: 'turquoise', displayName: 'Created Page' },
  post_meeting_update: { color: 'gray', displayName: 'Post Meeting Update' },
  // export_deals: { color: 'black', displayName: 'Exported Deals' },
  // export_portfolio: { color: 'black', displayName: 'Exported Portfolio' },
  // accessed_kanban: { color: 'black', displayName: 'Accessed Kanban' },
  // 'DealProfile.stage/ic_to_app': { color: 'black', displayName: 'Deal Success' },
  gem_update: { color: 'black', displayName: 'Gem Update' },
};

const OrgEventConfig = {
  created_at: { color: 'yellow', displayName: 'Profile Creation Date' },
  updated_fundraising: { color: 'aquamarine', displayName: 'Fundraise' },
  updated_profile: { color: 'purple', displayName: 'Profile Update' },
  post_meeting_update: { color: 'gray', displayName: 'Post Meeting Update' },
};

const JobEventConfig = {
  created_at: { color: 'yellow', displayName: 'Profile Creation Date' },
  updated_profile: { color: 'purple', displayName: 'Profile Update' },
};

// Events that are not automatically tracked yet
const UntrackHumanEventTypes = ['outreach_meeting', 'sent_email', 'received_email'];

// We will add untracked event types for org and job in the future
const UntrackOrgEventTypes = [];
const UntrackJobEventTypes = [];

// Voting committes for deals, currently it's only Armaan and Baris
const VotingCommittee = ['Armaan', 'Baris'];

const OrgDefaultType = { field: 'type', constraints: [{ operator: 'is', value: 'company' }] };
const InterviewDefaultType = {
  field: 'position_name',
  constraints: [{ operator: 'is', value: '' }],
};
const JobDefaultType = { field: 'name', constraints: [{ operator: 'is', value: '' }] };
const IntroDefaultType = {
  field: 'member',
  constraints: [{ operator: 'is', value: '' }],
};
const PortfolioDefaultType = {
  field: 'role',
  constraints: [{ operator: 'is', value: 'portfolio' }],
};
const HumanDefaultType = { field: 'first_name', constraints: [{ operator: 'is', value: '' }] };
const MetricDefaultType = { field: 'metric', constraints: [{ operator: 'is', value: '' }] };

const DefaultFiltersByEntity = {
  organization: OrgDefaultType,
  interview: InterviewDefaultType,
  position: JobDefaultType,
  intro: IntroDefaultType,
  portfolio: PortfolioDefaultType,
  user: HumanDefaultType,
  analytics: MetricDefaultType,
};

const DealStages = [
  'First meeting',
  'Due Diligence',
  'Investment Committee',
  'Approved',
  'Monitor',
  'Pass',
];

const BugReportUrl = 'https://human-capital-bug.paperform.co/';

const SecurityShortcutNames = {
  AccessToAllInternalUsers: 'read_write_internal',
};

const ExpectedRolesForPolicy = ['investment_profiles', 'principal_profiles'];

export {
  Roles,
  PublicRoles,
  UserRoles,
  PublicUserRoles,
  OrgRoles,
  NonMemberRoles,
  PortfolioEngagementStages,
  PortfolioHealthIndicators,
  PortfolioStageIdx,
  InterviewStages,
  UsStates,
  Genders,
  TagColors,
  HcClientStatus,
  OrgTypes,
  JobStatus,
  JobTypes,
  IntroStages,
  ColorBlindFriendlyRolesPalette,
  BannedModalKeys,
  ModalLinkKeys,
  EmptyOperators,
  HumanEventConfig,
  AdminEventConfig,
  OrgEventConfig,
  JobEventConfig,
  UntrackHumanEventTypes,
  UntrackOrgEventTypes,
  UntrackJobEventTypes,
  VotingCommittee,
  PortfolioDefaultType,
  IntroDefaultType,
  JobDefaultType,
  InterviewDefaultType,
  OrgDefaultType,
  HumanDefaultType,
  MetricDefaultType,
  DefaultFiltersByEntity,
  BugReportUrl,
  DealStages,
  SecurityShortcutNames,
  ExpectedRolesForPolicy,
};
