import React, { useEffect, useState } from 'react';
import { List, Select, message, Dropdown, Empty, Space, Divider, Col, Row } from 'antd';
import { isEmpty, isEqual, kebabCase, some } from 'lodash';
import {
  FilterTwoTone,
  DownOutlined,
  LayoutTwoTone,
  PlusOutlined,
  SearchOutlined,
  LockTwoTone,
  EyeTwoTone,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Input, FilterItem, Button } from 'Components';
import { v4 as uuid_v4 } from 'uuid';
import { createView, updateView, deleteView } from 'Api/views-service';
import { filterSchema } from './filter-schema';
import ViewsListItem from './views-list-item';
import { makeShowable, checkOverflow } from 'Utils';
import { generateSelectOptions, pullAndSetViews } from 'Helpers';
import { useDebounce } from 'Helpers/hooks';
import { useStoreValue } from 'Context';
import './filter-section.less';
import { getAllowedSubCategories } from 'Utils';
const { Option } = Select;
import { OrgDefaultType, DefaultFiltersByEntity } from 'Constants';

const FilterSection = ({
  entity,
  views = [],
  setViews,
  moreMenu,
  currentView,
  setCurrentView,
  defaultViewId,
  setDefaultViewId,
  ...rest
}) => {
  const [searchText, setSearchText] = useState('');
  const [filterMeta, setFilterMeta] = useState([]);
  const [filterConfigList, setFilterConfigList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // hold newly modified view, so to set it after view list update
  const [searchableViews, setSearchableViews] = useState(views);
  const [newConditionMode, setNewConditionMode] = useState(false);
  const [{ meta, user }] = useStoreValue();

  const { subTypes } = filterSchema[entity];
  const { filters } = currentView.filters ? currentView : { filters: [] };
  const subCategories = subTypes
    ? filters?.find(({ field }) => field === subTypes.key)?.constraints?.map(({ value }) => value)
    : null;
  const userId = user.id;

  useEffect(() => {
    setFilterMetaData();
    pullLatestViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filters) return;
    const subcategoryConfig = filters.find(
      ({ field }) => subTypes !== undefined && field === subTypes.key
    );
    if (!subcategoryConfig) return;
    const existingSubcategories = subcategoryConfig.constraints.map(({ value }) => value);
    updateFilterSet([...existingSubcategories]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (!searchText) return setSearchableViews(views);
    const filteredViewsList = searchableViews.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchableViews(filteredViewsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    setSearchableViews(views);
  }, [views]);

  useEffect(() => {
    if (!currentView?.filters?.length) return;
    const activeFilters = filters
      .map(({ field }) => {
        return filterMeta.find(({ name }) => name === field);
      })
      .filter((item) => item);
    setFilterConfigList(activeFilters);
  }, [filterMeta, filters]);

  useEffect(() => {
    setFilterMetaData();
    if (defaultViewId) {
      let updatedNewView = views.find(({ id }) => id === defaultViewId);
      setCurrentView(updatedNewView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, defaultViewId]);

  useEffect(() => {
    if (filterConfigList?.length || subTypes?.key || isEmpty(currentView)) {
      setIsLoading(false);
    }
  }, [filterConfigList, subTypes]);

  useEffect(() => {
    if (views.length) {
      if (defaultViewId) {
        let updatedNewView = views.find(({ id }) => id === defaultViewId);
        return setCurrentView(updatedNewView);
      }
      const authorDefaultView = views.find(
        ({ is_author_default, source_user_id }) => is_author_default && source_user_id === userId
      );
      if (authorDefaultView) {
        return setDefaultViewId(authorDefaultView.id);
      }
      const systemDefaultView = views.find(({ is_system_default }) => is_system_default);
      if (systemDefaultView) {
        return setDefaultViewId(systemDefaultView.id);
      }
      setDefaultViewId(views[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views]);

  const pullLatestViews = async () => {
    await pullAndSetViews(entity, userId, setViews);
  };

  const handleViewChange = (viewId) => {
    setDefaultViewId(viewId);
  };

  // entites like deals have hidden filters setup
  const entityDefaultFilter = (viewData) => {
    let defaultFilter = {
      field: 'role',
      constraints: [
        {
          operator: 'is',
          value: 'deal',
        },
      ],
    };
    viewData.filters.push(defaultFilter);
  };

  const createViewData = async (viewData) => {
    if (viewData?.entity_type === 'deal') entityDefaultFilter(viewData);
    try {
      const newView = {
        ...viewData,
        // initialize all the properties below
        permissions: {
          is_public: false,
          can_public_update: false,
          can_public_delete: false,
        },
        is_author_default: false,
      };
      if (newView?.filters.length) {
        const {
          data: { data: newViewData },
        } = await createView(viewData);
        message.success(makeShowable('VIEW_CREATED', 'views'));
        await pullLatestViews();
        return newViewData;
      } else {
        message.warning('Cannot create view without any filters');
      }
    } catch (err) {
      message.error(`Unable to create new view: ${err}`);
    }
  };

  const debouncedViewUpdate = useDebounce(
    (viewData, prevCurrentView) => pushViewData(viewData, prevCurrentView),
    500
  );

  const pushViewData = async (viewData, prevCurrentView) => {
    try {
      if (
        viewData.entity_type === 'organization' &&
        !some(viewData.filters, (v) => isEqual(v, OrgDefaultType))
      ) {
        viewData.filters = [...viewData.filters, OrgDefaultType];
      }
      const {
        data: { message: msg },
      } = await updateView(viewData);
      // stacks up notification if changes are made frequently
      // hence keep only the latest notification
      message.destroy();
      message.success(
        <Space direction="horizontal" size="large">
          <span>{makeShowable(msg, 'views')}</span>
          <Button
            size="small"
            onClick={() => {
              updateViewData({ ...prevCurrentView });
              message.destroy();
            }}
          >
            UNDO
          </Button>
        </Space>,
        [10]
      );
      pullLatestViews();
    } catch (err) {
      message.error(`Unable to update view: ${err}`);
    }
  };

  const updateViewData = async (viewData) => {
    const prevCurrentView = currentView;
    try {
      const { permissions, source_user_id } = viewData;
      const canUpdate = source_user_id === userId || permissions?.can_public_update;
      if (isEmpty(currentView) && viewData.id === undefined) {
        // Create a new view with random name if no view available
        viewData = {
          ...viewData,
          entity_type: entity,
          name: 'View ' + Math.floor(Math.random() * 1234567),
          source_user_id: user.id,
          source_user_name: user.first_name,
        };
        createViewData(viewData);
        return;
      }
      if (!canUpdate) return message.warning("The author has locked the view, can't update");
      if (viewData?.filters.length) {
        setCurrentView(viewData);
        debouncedViewUpdate(viewData, prevCurrentView);
      } else {
        let emptyView = { ...viewData, filters: [DefaultFiltersByEntity[entity]] };
        setCurrentView(emptyView);
        debouncedViewUpdate(emptyView, prevCurrentView);
      }
    } catch (err) {
      console.log(err);
      message.error(err.message || 'Something went wrong').then(() => {
        message.warning('Reverting the view to prev state');
      });
      setCurrentView(prevCurrentView);
    }
  };

  const deleteCurrentView = async (viewId) => {
    try {
      const {
        data: { message: msg },
      } = await deleteView(viewId);
      setDefaultViewId(null);
      message.success(makeShowable(msg, 'views'));
      pullLatestViews();
    } catch (err) {
      console.log(err);
    }
  };

  const mapMetaToFilterSchema = (entityFilterMeta, currentSchema) => {
    return currentSchema.map((ele) => {
      return { ...ele, filterOptions: entityFilterMeta[ele.name] };
    });
  };

  const setFilterMetaData = async () => {
    if (!meta) {
      return;
    }
    let { filter_options } = meta;
    if (!isEmpty(filter_options)) {
      updateFilterSet(subCategories);
    }
  };

  const updateFilterSet = (categories) => {
    let { filter_options } = meta;
    const entityFilterMeta = filter_options[entity];
    let currentSchema = filterSchema[entity].default;
    let updatedFieldConfig =
      categories?.sort().reduce((acc, category) => {
        let categoryFields = filterSchema[entity][category];
        if (categoryFields) {
          acc = [...acc, ...categoryFields];
        }
        return acc;
      }, []) || [];
    const filterConfig = mapMetaToFilterSchema(entityFilterMeta, [
      ...currentSchema,
      ...updatedFieldConfig,
    ]);
    setFilterMeta(filterConfig);
  };

  const handleSubCategoryChange = (categories = []) => {
    let updatedFilters = [];
    // sub category has been removed
    if (!categories.length) {
      updatedFilters = filters.filter(({ field }) => field !== subTypes.key);
    } else {
      let updatedConstraints = categories.map((category) => {
        return { operator: 'is', value: category, value_2: null };
      });
      updatedFilters = [...filters];
      const indexToUpdate = filters.findIndex((filter) => filter.field === subTypes.key);
      if (indexToUpdate >= 0) {
        updatedFilters[indexToUpdate] = { field: subTypes.key, constraints: updatedConstraints };
      } else {
        updatedFilters.push({ field: subTypes.key, constraints: updatedConstraints });
      }
    }
    updateViewData({ ...currentView, filters: updatedFilters });
  };

  const addFieldToFilters = (fieldName) => {
    const fieldToAdd = filterMeta.find(({ name }) => name === fieldName);
    if (!fieldToAdd) return message.error('Invalid field');
    let tempFilters = [...filters];
    const { filterOptions = [] } = fieldToAdd;
    const defaultOperator = filterOptions.length ? filterOptions[0]?.operator : '';
    const defaultCondition = {
      key: uuid_v4(),
      operator: defaultOperator,
      value: null,
      value_2: null,
    };
    tempFilters.push({ field: fieldName, constraints: [defaultCondition] });
    return updateViewData({ ...currentView, filters: tempFilters });
  };

  const addDefaultView = async (viewId) => {
    // user can set default view created by them
    // only one default view exists per entity per user
    const toBeDefaultView = views.find(({ id }) => id === viewId);
    const existingDefaultView = views.filter(
      ({ source_user_id, is_author_default }) => source_user_id === userId && is_author_default
    );
    if (!toBeDefaultView) return message.warning('View does not exists');
    if (toBeDefaultView['source_user_id'] !== userId)
      return message
        .warning('Only views created by you can be set as Default')
        .then(() => message.info('TIP : Duplicate the view'));

    const updatedView = { ...toBeDefaultView, is_author_default: true };
    try {
      const {
        data: { message: msg },
      } = await updateView(updatedView);
      message.success(makeShowable(msg, 'views'));
      if (existingDefaultView?.length) {
        for (const view of existingDefaultView) {
          let removeDefaultView = { ...view, is_author_default: false };
          await updateView(removeDefaultView);
        }
      }
      pullLatestViews();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="filter-section" style={{ height: 'calc(100vh - 70px)' }}>
      <Dropdown.Button
        overlay={moreMenu}
        icon={moreMenu ? <DownOutlined /> : null}
        loading={'false'}
        style={{
          width: '100%',
          borderRadius: 5,
          padding: '10px 10px 5px 10px',
        }}
        buttonsRender={([, rightButton]) => [
          <Button
            style={{
              width: '100%',
              textAlign: 'left',
              fontWeight: 500,
              color: '#333',
            }}
            disabled
            key="1"
            size="medium"
          >
            <LayoutTwoTone style={{ fontSize: '18px' }} /> {currentView?.name}
          </Button>,
          React.cloneElement(rightButton, {
            loading: false,
            size: 'medium',
          }),
        ]}
      ></Dropdown.Button>
      <>
        <div style={{ padding: '0px 10px', marginBottom: 5 }}>
          <Input
            suffix={<SearchOutlined />}
            size="middle"
            placeholder="Find a view"
            bordered={false}
            style={{ borderBottom: '1px solid lightgrey', borderRadius: 0 }}
            value={searchText}
            allowClear
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
        <div
          style={{ height: 130, overflow: 'scroll', boxShadow: 'inset 0px -11px 8px -10px #CCC' }}
        >
          {[
            {
              title: `Private`,
              dataItems: searchableViews.filter(
                ({ source_user_id, permissions }) =>
                  source_user_id === userId && !permissions.is_public
              ),
              icon: <LockTwoTone />,
            },
            {
              title: `Public`,
              dataItems: searchableViews.filter(
                ({ source_user_id, permissions }) =>
                  source_user_id !== userId || permissions.is_public
              ),
              icon: <EyeTwoTone />,
            },
          ]
            .filter(({ dataItems }) => dataItems.length)
            .map(({ title, dataItems, icon }) => {
              return (
                <div key={title}>
                  <Divider style={{ margin: '5px 0px' }} orientation="right" plain>
                    {icon} {title}
                  </Divider>
                  <List
                    style={{ cursor: 'pointer', padding: '0px 10px' }}
                    size="small"
                    itemLayout="horizontal"
                    dataSource={dataItems}
                    renderItem={(item) => (
                      <List.Item
                        style={{
                          padding: 5,
                          ...(item.id === defaultViewId
                            ? { background: '#e6f6ff', border: '1px solid #409dfd' }
                            : null),
                        }}
                        onClick={() => handleViewChange(item.id)}
                      >
                        <ViewsListItem
                          viewItem={item}
                          addDefaultView={addDefaultView}
                          deleteCurrentView={deleteCurrentView}
                          updateViewData={updateViewData}
                          createViewData={createViewData}
                          setDefaultViewId={setDefaultViewId}
                          pullLatestViews={pullLatestViews}
                        />
                      </List.Item>
                    )}
                  />
                </div>
              );
            })}
        </div>
      </>
      <div
        style={{
          padding: 10,
          background: '#fbfbfb',
          marginBottom: 10,
          borderBottom: '1px solid #dfdada',
        }}
      >
        <Row justify="space-between">
          <Col>
            <h3
              style={{
                fontWeight: 300,
                margin: 'unset',
                fontSize: 15,
              }}
            >
              <FilterTwoTone /> Filter records where...
            </h3>
          </Col>
          <Col>
            {checkOverflow(document.getElementById('filters-item')) ? (
              <Button
                onClick={() => {
                  const element = document.getElementById('filters-item');
                  element.scrollTop = element.scrollHeight;
                }}
                type="link"
                size="small"
              >
                Add Filter
              </Button>
            ) : null}
          </Col>
        </Row>
      </div>
      <div
        style={{ overflow: 'scroll', height: 'calc(100% - 270px)', scrollBehavior: 'smooth' }}
        id="filters-item"
      >
        {subTypes ? (
          <div
            style={{
              borderBottom: '1px solid #d8d8d8',
              padding: '0px 10px 12px 10px',
            }}
          >
            <h4 style={{ fontWeight: 300 }}>Sub category</h4>
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              mode="multiple"
              showArrow
              style={{ width: '100%' }}
              size="middle"
              placeholder={subTypes.placeholder}
              onChange={handleSubCategoryChange}
              value={subCategories}
            >
              {generateSelectOptions(getAllowedSubCategories(subTypes.allowed_values))}
            </Select>
          </div>
        ) : null}
        <List
          loading={isLoading}
          dataSource={filterConfigList}
          locale={{ emptyText: <Empty style={{ margin: '0px auto' }} description="No Filters" /> }}
          renderItem={(item) => (
            <List.Item key={kebabCase(item.name)} style={{ padding: '12px 10px' }}>
              <FilterItem
                {...rest}
                currentView={currentView}
                filters={filters}
                item={item}
                updateViewData={updateViewData}
              />
            </List.Item>
          )}
        />
        {newConditionMode ? (
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            autoFocus
            style={{ width: '100%' }}
            showSearch
            placeholder="Search field name"
            allowClear
            onBlur={() => setNewConditionMode(false)}
            onClear={() => setNewConditionMode(false)}
            onSelect={(fieldName) => {
              addFieldToFilters(fieldName);
              setNewConditionMode(false);
            }}
          >
            {filterMeta
              .filter(({ name }) => !filterConfigList.map(({ name }) => name).includes(name))
              .map(({ label, name }, index) => (
                <Option key={index} value={name}>
                  {label}
                </Option>
              ))}
          </Select>
        ) : (
          <Button
            onClick={() => setNewConditionMode(true)}
            style={{ margin: '10px' }}
            type="primary"
            size="middle"
          >
            <PlusOutlined /> Add Filter
          </Button>
        )}
      </div>
    </div>
  );
};

FilterSection.propTypes = {
  entity: PropTypes.string,
  filters: PropTypes.array,
  meta: PropTypes.object,
  setFilters: PropTypes.func,
  moreMenu: PropTypes.object,
  views: PropTypes.array,
  setViews: PropTypes.func,
  currentView: PropTypes.object,
  setCurrentView: PropTypes.func,
  defaultViewId: PropTypes.number,
  setDefaultViewId: PropTypes.func,
};

export default FilterSection;
