import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import './comment.less';

const Comment = ({ body, title, created_at, ...rest }) => {
  Date.prototype.toShortFormat = function () {
    let monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    let day = this.getDate();

    let monthIndex = this.getMonth();
    let monthName = monthNames[monthIndex];

    let year = this.getFullYear();

    return `${day}-${monthName}-${year}`;
  };

  return (
    <div className="comment" {...rest}>
      <Row justify="space-between">
        <Col>
          <strong>{title}</strong>
        </Col>
      </Row>
      <div>{body}</div>
      {created_at ? (
        <div style={{ fontSize: 12, textAlign: 'right' }}>
          {new Date(created_at).toShortFormat()}
        </div>
      ) : null}
    </div>
  );
};

Comment.propTypes = {
  body: PropTypes.node,
  title: PropTypes.string,
  created_at: PropTypes.string,
};

export default Comment;
