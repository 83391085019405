import React from 'react';
import debounce from 'lodash.debounce';

const useDebounce = (callback, delay, dependecies) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useCallback(
    debounce((...args) => callback(...args), delay),
    [dependecies]
  );

export { useDebounce };
