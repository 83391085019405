import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Typography,
  message,
  Divider,
  Select,
  Tooltip,
  Popconfirm,
  Switch,
} from 'antd';
import { MailOutlined, UserOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'Components';
import { useStoreValue } from 'Context';
import { updateUser } from 'Api/user-service';
import { makeShowable } from 'Utils';
import { generateSelectOptions } from 'Helpers';
import { get } from 'lodash';

const { Title } = Typography;

const Account = () => {
  const [form] = Form.useForm();
  const [basicForm] = Form.useForm();
  const [{ meta, user }] = useStoreValue();
  const [personalContactList, setPersonalContactList] = useState([]);

  useEffect(() => {
    if (user) {
      const personalEmails = get(get(user, 'custom_fields', {}), 'Personal Emails', []).map(
        (email) => {
          return { email: email };
        }
      );
      setPersonalContactList(personalEmails);
    }
  }, []);

  const contactsColumnConfig = [
    {
      title: 'Email',
      dataIndex: 'email',
      render: (email) => <Tooltip title={email}>{email}</Tooltip>,
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: (email) => (
        <Popconfirm
          title={'Receive feedback for this contact?'}
          onConfirm={async () => {
            await handleValueChange(
              'personal_contact',
              personalContactList.filter((c) => c.email !== email)
            );
            message.success('Personal Contact removed');
          }}
          okText="Yes"
          cancelText="No"
        >
          <Tooltip title="Receive meeting feedback request for this email." placement="right">
            <Button type="link" size="small">
              <UserAddOutlined />
            </Button>
          </Tooltip>
        </Popconfirm>
      ),
    },
  ];

  const userPrimaryEmail =
    user?.emails?.find((emailObj) => emailObj.is_primary)?.['email'] ||
    user?.emails?.[0]?.['email'] ||
    null;

  const userPreferredMedium = user?.custom_fields?.['Preferred Medium'];
  const userOutreachMethod = user?.custom_fields?.['Outreach Method'];
  const userPregameToggle = user?.custom_fields?.['Brief Notification'] === 'True';
  const userFeedbackToggle = user?.custom_fields?.['Feedback Notification'] === 'True';
  const userDedupBriefToggle = user?.custom_fields?.['Deduplication Brief'] === 'True';
  const userPhone = user?.phone;
  basicForm.setFieldsValue({
    phone: userPhone,
    preferred_medium: userPreferredMedium,
    outreach_method: userOutreachMethod,
    pregame_notification: userPregameToggle,
    feedback_notification: userFeedbackToggle,
    pregame_dedup: userDedupBriefToggle,
  });

  const preferredMediumConfig = meta?.['custom_fields']?.['user']?.find(
    (field) => field.name === 'Preferred Medium'
  ) || { allowed_values: ['sms', 'email', 'slack'] };

  const outreachMethodConfig = meta?.['custom_fields']?.['user']?.find(
    (field) => field.name === 'Outreach Method'
  ) || { allowed_values: ['EOD', 'JIT'] };

  const updatePassword = async (fieldsValue) => {
    const { password } = fieldsValue;
    try {
      await updateUser(user?.id, {
        password: password,
      });
      message.success('Password Updated!!');
    } catch (e) {
      message.error(makeShowable(e.message, 'get_profile'));
    }
  };

  const updateFields = async ({
    phone,
    preferred_medium,
    outreach_method,
    pregame_notification,
    feedback_notification,
    pregame_dedup,
  }) => {
    let objToBeUdpated = {
      phone,
      custom_fields: {
        ...user.custom_fields,
        'Preferred Medium': preferred_medium,
        'Outreach Method': outreach_method,
        'Brief Notification': pregame_notification,
        'Feedback Notification': feedback_notification,
        'Deduplication Brief': pregame_dedup,
      },
    };
    try {
      await updateUser(user.id, objToBeUdpated);
      message.success('Account updated successfully');
    } catch (e) {
      message.error(makeShowable(e.message, 'get_profile'));
    }
  };

  const handleValueChange = async (fieldName, value) => {
    const newEmails = value.map((c) => c.email);
    try {
      await updateUser(
        user.id,
        {
          custom_fields: { ...user.custom_fields, 'Personal Emails': newEmails },
        },
        { replace_all: false }
      );
      setPersonalContactList(newEmails);
    } catch (e) {
      message.error('Unable to update Personal Contact');
    }
  };

  return (
    <div style={{ padding: 70, height: '100%' }}>
      <Row gutter={48} justify="center">
        <Col span={8}>
          <Title level={2} style={{ fontWeight: 200 }}>
            Account Information
          </Title>
          <div style={{ marginTop: 30 }}>
            <Title level={4} style={{ fontWeight: 400 }}>
              <UserOutlined style={{ marginRight: 10 }} />
              Name -
              <span style={{ fontWeight: 200 }}> {`${user?.first_name} ${user?.last_name}`}</span>
            </Title>
            <Title level={4} style={{ fontWeight: 400 }}>
              <MailOutlined style={{ marginRight: 10 }} />
              Email - <span style={{ fontWeight: 200 }}> {`${userPrimaryEmail}`}</span>
            </Title>
            <Title level={4} style={{ fontWeight: 200, marginTop: '0.5em' }}>
              {user?.email}
            </Title>
          </div>
          <Divider />
          <Form form={form} onFinish={updatePassword} layout="vertical" name="normal_login">
            <Row justify="space-between" style={{ marginBottom: 20 }}>
              <Col>
                <Title level={2} style={{ fontWeight: 200 }}>
                  Change Password
                </Title>
              </Col>
            </Row>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your new password' },
                { min: 6, message: 'Please enter at least 6 characters' },
              ]}
            >
              <Input placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              hasFeedback
              name="confirm_password"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please enter your new password again',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('The two passwords do not match');
                  },
                }),
              ]}
            >
              <Input placeholder="Confirm Password" type="password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}>
          <Title level={2} style={{ fontWeight: 200 }}>
            Meeting Capture
          </Title>
          <Form form={basicForm} onFinish={updateFields} layout="vertical">
            <Form.Item label="Phone" name="phone" help="Phone number is mandatory for SMS">
              <Input size="large" />
            </Form.Item>
            <br />
            <Form.Item label="Preferred Medium of Communication" name="preferred_medium">
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                size="large"
                placeholder="SMS, Email"
              >
                {generateSelectOptions(preferredMediumConfig?.['allowed_values'])}
              </Select>
            </Form.Item>
            <Form.Item
              label="Outreach Method"
              name="outreach_method"
              help="EOD (End of Day) or JIT (Just in Time) notifications"
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                size="large"
                placeholder="JIT"
              >
                {generateSelectOptions(outreachMethodConfig?.['allowed_values'])}
              </Select>
            </Form.Item>
            <br />
            <Form.Item
              label="Meeting Feedback"
              name="feedback_notification"
              valuePropName="checked"
            >
              <Switch></Switch>
            </Form.Item>
            <Form.Item label="Meeting Brief" name="pregame_notification" valuePropName="checked">
              <Switch></Switch>
            </Form.Item>
            <Form.Item label="Deduplication Brief" name="pregame_dedup" valuePropName="checked">
              <Switch></Switch>
            </Form.Item>
            <Form.Item>
              <Button style={{ marginTop: 20 }} type="primary" htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col span={8}>
          <Title level={2} style={{ fontWeight: 200 }}>
            Personal Contacts
          </Title>
          <p className="ant-form-item-extra">
            Emails of which you do not wish to collect meeting feedback for.
          </p>
          <Table
            pagination={false}
            rowKey={(record) => record.email}
            size="small"
            dataSource={personalContactList}
            bordered
            columns={contactsColumnConfig}
            style={{ margin: '20px 0px' }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Account;
