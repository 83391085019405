import React, { useState } from 'react';
import { Form, message } from 'antd';
import { resetPassword } from 'Api/auth-service';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Input, Card } from 'Components';
import { makeShowable } from 'Utils';
import { useStoreValue } from 'Context';

const ResetPassword = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [, dispatch] = useStoreValue();
  const history = useHistory();
  const { token } = useParams();

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);
    setError(null);
    try {
      const { data: user_data } = await resetPassword(data);
      message.success('Your password has been updated');
      dispatch({
        type: 'LOGIN',
        payload: { user: user_data, stale: false },
      });
      history.push('/');
    } catch (e) {
      setError(makeShowable(e.message, 'reset_password'));
    }
    setIsSubmitting(false);
  };

  return (
    <div className="auth_container">
      <Card
        title={
          <span
            style={{
              fontWeight: 200,
              fontSize: 24,
              letterSpacing: 1.2,
            }}
          >
            Reset Password
          </span>
        }
        style={{ width: 350 }}
      >
        <Form
          name="reset-password"
          className="reset-password-form"
          initialValues={{
            token,
            password: '',
            confirm_password: '',
          }}
          onFinish={handleFormSubmit}
        >
          <Form.Item
            hidden
            name="token"
            className="auth-form-item hidden"
            rules={[
              {
                required: true,
                message: 'Please enter your new token',
              },
            ]}
          >
            <Input placeholder="Password" type="password" size="large" />
          </Form.Item>

          <Form.Item
            name="password"
            className="auth-form-item"
            rules={[
              {
                required: true,
                message: 'Please enter your new password',
              },
              {
                min: 6,
                message: 'Please enter at least 6 characters',
              },
            ]}
          >
            <Input placeholder="Password" type="password" size="large" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            className="auth-form-item"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Please enter your new password again',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('The two passwords do not match');
                },
              }),
            ]}
          >
            <Input placeholder="Confirm Password" type="password" size="large" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="primary" disabled={isSubmitting} loading={isSubmitting}>
              Submit
            </Button>
          </Form.Item>
        </Form>

        {error ? <p className="auth-form-error">{error}</p> : null}
      </Card>
    </div>
  );
};

export default ResetPassword;
