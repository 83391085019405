import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const StoreContext = createContext();

export const StoreProvider = ({ reducer, initialState, children }) => (
  <StoreContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StoreContext.Provider>
);
StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({}).isRequired,
  reducer: PropTypes.func.isRequired,
};

// useStoreValue function is basically a custom hook and
// it allows to access your store in any component of your application
// with less amount of code. It returns exactly the same [state, dispatch] array,
// that is passed as a value to our Provider.

export const useStoreValue = () => useContext(StoreContext);
