import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, Row, Col, Space, Dropdown, message, Avatar, Badge, Divider } from 'antd';
import {
  // DownOutlined,
  UserOutlined,
  UnorderedListOutlined,
  LockOutlined,
  BugFilled,
  GlobalOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { useHistory, useLocation, matchPath, Link } from 'react-router-dom';
import { kebabCase } from 'lodash';
import { logout } from 'Api/auth-service';
import { getMeta, authorizeCalendar } from 'Api/common-service';
// import { getNumOutstandingTasks } from 'Api/tasks-service';
import { useStoreValue } from 'Context';
// import CommonSearchBar from './components/common-search-bar';
import Logo from 'Assets/images/hc-logo-black.png';
import { makeShowable } from 'Utils';
import { googleLogout } from '@react-oauth/google';
import './header.less';
import { BugReportUrl } from 'Constants';

const FullHeader = ({ routes = [] }) => {
  const [selectedMenu, setSelectedMenu] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [{ user }, dispatch] = useStoreValue();
  // menus that should be visible on nav bar
  const mainMenus = ['Events'];
  // const subMenus = routes?.filter((menu) => !mainMenus.includes(menu.title) && menu.title) || [];
  // Outstanding Tasks Counter
  // const [OTCounter, setOTCounter] = useState(0);

  useEffect(() => {
    setMetaStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // getNumOutstandingTasks()
    //   .then((resp) => {
    //     const data = resp.data;
    //     setOTCounter(data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    const currentMenuItem = routes.find(
      (menuItem) =>
        location.pathname === menuItem.path ||
        (location.pathname === '/' && menuItem.path === '/humans')
    );
    if (currentMenuItem) {
      const currentKey = kebabCase(currentMenuItem.title);
      setSelectedMenu([currentKey]);
    } else if (routes.length === 0) {
      setSelectedMenu(['Humans']);
    } else {
      setSelectedMenu(['More']);
    }
  }, [location]);

  const setMetaStore = async () => {
    try {
      let { data } = await getMeta();
      dispatch({ type: 'META', payload: { meta: data } });
    } catch (err) {
      console.log(err);
    }
  };

  // const generateSubMenu = (subMenus) => {
  //   if (!subMenus.length) return null;
  //   return (
  //     <Menu>
  //       {subMenus.map(({ path, title }) => (
  //         <Menu.Item key={kebabCase(title)} onClick={() => history.push(path)}>
  //           {title}
  //         </Menu.Item>
  //       ))}
  //     </Menu>
  //   );
  // };

  const generateHeader = (title, path) => {
    return (
      <Menu.Item key={kebabCase(title)} onClick={() => history.push(path)}>
        {title}
      </Menu.Item>
    );
  };

  const grantAccessToCalendar = async () => {
    try {
      await authorizeCalendar();
    } catch (err) {
      message.info(err.message || 'Error');
    }
  };

  const accountMenu = (
    <Menu>
      <Menu.ItemGroup title={`${user?.first_name} ${user?.last_name}`}>
        <Menu.Item
          icon={<UserOutlined />}
          key="2"
          onClick={async () => {
            try {
              history.push(`/account`);
            } catch (e) {
              message.error(makeShowable(e.message));
            }
          }}
        >
          Account
        </Menu.Item>
        <Menu.Item
          icon={<UnorderedListOutlined />}
          key={'outstanding_tasks'}
          onClick={() => history.push('/outstanding_tasks')}
          style={{ marginLeft: 'auto', marginRight: 5 }}
        >
          <a
            className="ant-dropdown-link"
            style={{ textDecoration: 'unset' }}
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            Pending Feedbacks
          </a>
        </Menu.Item>
        <Menu.Item
          icon={<GlobalOutlined />}
          key={'poc_tracker'}
          onClick={() => history.push('/poc_tracker')}
          style={{ marginLeft: 'auto', marginRight: 5 }}
        >
          <a
            className="ant-dropdown-link"
            style={{ textDecoration: 'unset' }}
            href="/#"
            onClick={(e) => e.preventDefault()}
          >
            My Network
          </a>
        </Menu.Item>
        <Menu.Item
          icon={<LockOutlined />}
          key="1"
          onClick={async () => {
            try {
              // we have enable onetap google-sign in
              // to prevent auto login on logout we call google's func
              googleLogout();
              await logout();
              // important to change the url *before* updating the state, otherwise:
              //   1. header component re-rendered *before* the location changes
              //   2. user sees full header on login page
              history.push('/login');
              dispatch({ type: 'LOGOUT' });
            } catch (e) {
              message.error(makeShowable(e.message), 'logout');
            }
          }}
        >
          Logout
        </Menu.Item>
        {!user?.calendar_id ? (
          <Menu.Item
            icon={<LinkOutlined />}
            key={'link Calendar'}
            onClick={() => grantAccessToCalendar()}
            style={{ marginLeft: 'auto', marginRight: 5 }}
          >
            Link HC Calendar <Badge color="green" />
          </Menu.Item>
        ) : null}
        <Divider style={{ margin: '5px 0px' }} />
        <Menu.Item
          icon={<BugFilled />}
          key={'report-bug'}
          onClick={() => window.open(BugReportUrl, '_blank', 'noopener,noreferrer')}
          style={{ marginLeft: 'auto', marginRight: 5 }}
        >
          Report Bug
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Row align="middle" className="main-header">
      <Col md={2}>
        <Link to={'/'}>
          <img style={{ height: '30px' }} src={Logo} alt="Human Capital Logo" />
        </Link>
      </Col>
      <Col md={21}>
        <Menu
          mode="horizontal"
          style={{ borderBottom: 'unset', background: 'unset' }}
          selectedKeys={selectedMenu}
        >
          {routes.map(({ title, path }) =>
            mainMenus.includes(title) ? generateHeader(title, path) : null
          )}
          {/* <Menu.Item key="more">
            <Dropdown overlay={generateSubMenu(subMenus)} trigger={['click']}>
              <a
                className="ant-dropdown-link"
                style={{ textDecoration: 'unset' }}
                href="/#"
                onClick={(e) => e.preventDefault()}
              >
                More <DownOutlined />
              </a>
            </Dropdown>
          </Menu.Item> */}
        </Menu>
      </Col>
      <Col md={1}>
        <Space size={40}>
          <Dropdown overlay={accountMenu} trigger={['click']} placement="bottomRight">
            <a className="ant-dropdown-link" href="/#" onClick={(e) => e.preventDefault()}>
              {/* <Badge count={OTCounter}> */}
              <Avatar
                size={38}
                style={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                }}
                {...(user?.profile_img ? { src: user?.profile_img } : { icon: <UserOutlined /> })}
              ></Avatar>
              {/* </Badge> */}
            </a>
          </Dropdown>
        </Space>
      </Col>
    </Row>
  );
};

const SparseHeader = () => (
  <header>
    <div className="logo-container">
      <img
        style={{ height: '30px', margin: '20px', position: 'absolute' }}
        src={Logo}
        alt="Human Capital Logo"
        className="logo"
      />
    </div>
  </header>
);

const Header = ({ routes = [] }) => {
  const location = useLocation();
  const matchedRoute = routes.find(({ path }) =>
    matchPath(location.pathname, { path, exact: true, strict: false })
  );
  if (!matchedRoute?.header) return null;
  if (matchedRoute?.header === 'sparse') return <SparseHeader routes={routes} />;
  return <FullHeader routes={routes} />;
};

FullHeader.propTypes = {
  routes: PropTypes.array,
};

Header.propTypes = {
  routes: PropTypes.array,
};

export default Header;
