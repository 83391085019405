import React, { useState } from 'react';
import { Button, Card } from 'Components';
import { Form, Row, Space, message } from 'antd';
import { createCandidateSchema } from '../../schemas/candidate-schema';
import { generateFormFields } from 'Helpers';
import { useHistory } from 'react-router-dom';
import { makeShowable } from 'Utils';
import { createAccount } from '../../api/auth-service';

const _validateForm = (data) => {
  if (data.password !== data.confirm_password) {
    throw new Error('Passwords do not match');
  }
  return true;
};

const CreateAccount = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const history = useHistory();

  if (!isSubmitting) setIsSubmitting(true);

  const handleFormSubmit = async (data) => {
    try {
      _validateForm(data);
      await createAccount(data);
      history.push('/login');
      message.success('Created account. Please verify your email.');
    } catch (err) {
      console.log(err.message);
      message.error(makeShowable(err.message));
    }
  };

  return (
    <div className="auth_container">
      <Card
        title={
          <span style={{ fontWeight: 200, fontSize: 24, letterSpacing: 1.2 }}>Create Account</span>
        }
        style={{ width: 500 }}
      >
        <Form name="create-account" onFinish={handleFormSubmit} layout="vertical">
          <Row gutter={[24]}>{generateFormFields(createCandidateSchema)}</Row>
          <Row justify="end">
            <Space>
              <Button type="primary" htmlType="submit">
                Create Account
              </Button>
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>
    </div>
  );
};

export default CreateAccount;
