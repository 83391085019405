import React, { useState, useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { Spin } from 'antd';
import { useStoreValue } from 'Context';
import PropTypes from 'prop-types';
import { getUserProfile } from 'Api/user-service';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [{ user, stale }, dispatch] = useStoreValue();
  const { search = '', pathname } = useLocation();
  // if we have user data in the store, and it's not stale, we know
  //   they're logged in right now; no need to check against the backend
  const [status, setStatus] = useState({
    loading: !(user && !stale),
    authenticated: user && !stale,
  });

  useEffect(() => {
    if (status.loading) {
      (async () => {
        try {
          const { data } = await getUserProfile('me', false);
          dispatch({ type: 'LOGIN', payload: { user: { ...user, ...data } } });
          setStatus({ loading: false, authenticated: true });
        } catch (e) {
          dispatch({ type: 'LOGOUT' });
          setStatus({ loading: false, authenticated: false });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, authenticated } = status;
  if (loading)
    return (
      <Spin
        style={{
          display: 'flex',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        size="large"
      />
    );
  if (!authenticated)
    return <Redirect to={{ pathname: '/login', state: { from: pathname + search } }} />;
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

ProtectedRoute.propTypes = {
  component: PropTypes.elementType,
};

export default ProtectedRoute;
