import { Input, message, Result, Spin, Tag } from 'antd';
import { getEvents } from 'Api/event-service';
import { useState } from 'react';

import { Button, PageHeader, Table } from 'Components';

const defaultPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
  showQuickJumper: true,
};

const GlobalTimeline = () => {
  const [listData, setListData] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [searchName, setSearchName] = useState(null);
  const [searchEmail, setSearchEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNoResults, setShowNoResults] = useState(false);

  const getDisplayName = ({ organizer = {} }) => {
    return organizer?.displayName || organizer?.email;
  };

  const getNestedAttendeeInfo = (attendeeInfo = []) => {
    return attendeeInfo.map(({ displayName, email }, index) => {
      let currentName =
        displayName && displayName?.trim() !== '#MISSINGVALUE' ? displayName : email;
      return <Tag key={index}>{currentName}</Tag>;
    });
  };

  const columns = [
    {
      title: 'Meeting Title',
      dataIndex: 'title',
      key: 'title',
      type: 'string',
      fixed: 'left',
      width: 320,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'role',
      type: 'string',
      width: 220,
      render: (_, { other_data }) => readableMeetingTime(other_data.start_time),
    },

    {
      title: 'Organizer',
      dataIndex: 'organizer',
      key: 'role',
      type: 'string',
      width: 200,
      render: (_, { other_data }) => getDisplayName(other_data),
    },
    {
      title: 'Attendees',
      dataIndex: 'attendees',
      key: 'role',
      type: 'string',
      isDefault: true,
      render: (_, { other_data }) => <div>{getNestedAttendeeInfo(other_data?.attendees)}</div>,
      width: 420,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      type: 'string',
      render: (_, { other_data }) => other_data.description,
      width: 620,
      ellipsis: {
        showTitle: true,
      },
    },
  ];

  // const fetchLatestData = async () => {
  //   let { data, headers } = await getEvents(pagination, searchName, searchEmail);
  //   const {
  //     page_num: current,
  //     page_size: pageSize,
  //     total_records: total,
  //   } = JSON.parse(headers['x-pagination']);
  //   const updatedPaginationInfo = { current, pageSize, total };
  //   setPagination(updatedPaginationInfo);
  //   setListData(data);
  //   setIsLoading(false);
  // };

  let readableMeetingTime = (time) =>
    new Date(time).toLocaleString('en-US', {
      timeZone: 'America/Los_Angeles',
      weekday: 'long',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });

  const showSearch = async () => {
    try {
      setShowNoResults(false);
      setIsLoading(true);
      let { data = [], headers = {} } = await getEvents(pagination, searchName, searchEmail);
      if (headers['x-pagination']) {
        const {
          page_num: current,
          page_size: pageSize,
          total_records: total,
        } = JSON.parse(headers['x-pagination']);
        const updatedPaginationInfo = { current, pageSize, total };
        setPagination(updatedPaginationInfo);
      }
      setListData(data);
      setShowNoResults(true);
      setIsLoading(false);
    } catch (err) {
      message.error(err.message || 'Something went wrong');
    }
  };

  const clearSearch = async () => {
    setSearchEmail(null);
    setSearchName(null);
    setListData([]);
    setShowNoResults(false);
    // setIsLoading(true);
    // let { data, headers } = await getEvents(pagination, null, null);
    // const {
    //   page_num: current,
    //   page_size: pageSize,
    //   total_records: total,
    // } = JSON.parse(headers['x-pagination']);
    // const updatedPaginationInfo = { current, pageSize, total };
    // setPagination(updatedPaginationInfo);
    // setListData(data);
    // setIsLoading(false);
  };

  const handleTableChange = async (paginationInfo) => {
    setIsLoading(true);
    let { data, headers } = await getEvents(paginationInfo, searchName, searchEmail);
    const {
      page_num: current,
      page_size: pageSize,
      total_records: total,
    } = JSON.parse(headers['x-pagination']);
    const updatedPaginationInfo = { current, pageSize, total };
    setPagination(updatedPaginationInfo);
    setListData(data);
    setIsLoading(false);
  };

  return (
    <>
      <PageHeader
        title="📅  Events"
        extra={[
          <Input
            onChange={(event) => {
              const regex = /[^a-zA-Z0-9\s'-]/g; // matches any non-alphanumeric character except space
              const value = event.target.value;
              const filteredValue = value.replace(regex, ''); // removes any non-alphanumeric character except space
              setSearchName(filteredValue);
            }}
            size="large"
            placeholder="Name"
            key="name"
            value={searchName}
          ></Input>,
          <Input
            onChange={(event) => {
              const regex = /[^a-zA-Z0-9_.@]/g; // matches any character that is not allowed in an email
              const value = event.target.value;
              const filteredValue = value.replace(regex, '');
              setSearchEmail(filteredValue);
            }}
            size="large"
            placeholder="Email"
            key="email"
            value={searchEmail}
          ></Input>,
          <Button
            disabled={!(searchName || searchEmail)}
            onClick={() => showSearch()}
            type="primary"
            key="search"
          >
            Search
          </Button>,
          <Button onClick={() => clearSearch()} type="secondary" key="clear-search">
            Clear
          </Button>,
        ]}
      ></PageHeader>

      <div style={{ overflowX: 'scroll', minHeight: '100vh', margin: 20 }}>
        <Spin
          wrapperClassName="spinner"
          style={{ marginTop: '15%' }}
          tip="Searching"
          spinning={isLoading}
          size="large"
        >
          {listData.length ? (
            <div style={{ scrollX: 'scroll', paddingBottom: 20 }}>
              <Table
                size="small"
                pagination={[]?.length ? null : { ...pagination, showQuickJumper: true }}
                columns={columns}
                dataSource={listData}
                onChange={handleTableChange}
              />
            </div>
          ) : showNoResults ? (
            <Result title="No Results Found" />
          ) : null}
        </Spin>
      </div>
    </>
  );
};

export default GlobalTimeline;
