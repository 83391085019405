class Email {
  constructor(email) {
    this.email = email;
    this.is_verified = false;
    this.is_primary = false;
  }
}
class StageHistory {
  constructor(stage, additionalInfo) {
    this.stage = stage;
    this.time = this.timestamp;
    this.stage_additional_info = additionalInfo;
  }

  get timestamp() {
    const currentDate = new Date();
    return currentDate.toLocaleString();
  }
}

export { Email, StageHistory };
