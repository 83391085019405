import { Menu, Dropdown, Badge, Space, Row } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CaretDownOutlined } from '@ant-design/icons';
import { InterviewStages } from 'Constants';
import { Tag } from 'Components';
import { StageHistory } from 'Models';

// these colors are arranged for the sequence of InterviewStages
const stageColors = ['purple', 'geekblue', 'blue', 'cyan', 'orange', 'gold', 'green', 'red'];

const getStageColor = (currentStage) =>
  stageColors[InterviewStages.findIndex((stage) => stage === currentStage)];

const InterviewStage = ({ onStageChange, recordId, otherData = {} }) => {
  const { stage: currentStage, history = [] } = otherData;
  const [currentStageState, setCurrentStageState] = useState(currentStage);

  const onMenuClick = ({ key: updatedStage }) => {
    const newHistory = new StageHistory(updatedStage);
    const updatedHistory = [...history, newHistory];
    const updatedData = {
      ...otherData,
      stage: updatedStage,
      history: updatedHistory,
    };
    onStageChange(updatedData, recordId);
    setCurrentStageState(updatedStage);
  };

  const menu = (
    <Menu onClick={onMenuClick}>
      {InterviewStages.map((stage) => (
        <Menu.Item key={stage}>
          <Space>
            <Badge color={getStageColor(stage)} />
            <span>{stage}</span>
          </Space>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Row justify="space-between">
      <Tag className="hc-custom-tags" color={getStageColor(currentStageState)}>
        {currentStageState}
      </Tag>
      <Dropdown trigger={['click']} overlay={menu}>
        <CaretDownOutlined />
      </Dropdown>
    </Row>
  );
};

InterviewStage.propTypes = {
  otherData: PropTypes.object,
  onStageChange: PropTypes.func,
  recordId: PropTypes.number,
};

export default InterviewStage;
