import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Form,
  Row,
  List,
  Anchor,
  Affix,
  message,
  Select,
  Popconfirm,
  Typography,
  Dropdown,
  Menu,
  Tooltip,
  Input as AntdInput,
} from 'antd';
import { DownOutlined, CopyOutlined, LinkedinFilled, EditOutlined } from '@ant-design/icons';
import { get, isEmpty, keyBy, uniqueId } from 'lodash';
import moment from 'moment';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Input,
  Content,
  Button,
  NestedFormList,
  RoleTags,
  FundraisingMap,
  RelatedTable,
  InterviewStage,
} from 'Components';
import { companyProfileSchema } from 'Schemas/company-schema';
import { packNestedObj, unpackNestedObj } from 'Utils';
import AddJobModal from '../../job-view/components/add-job-modal';
import AddPocModal from './add-poc-modal';
import AddFounderModal from './add-founder-modal';
import { generateSelectOptions, packLinkedinUrl } from 'Helpers';
import { deactivateOrg } from 'Api/org-service';
import { createEvent, deleteEvent, getEventsByTitle, getEventTypes } from 'Api/event-service';
import { updateRelationship } from 'Api/relationship-service';
import { VotingCommittee } from 'Constants';
import { OrgRoles } from 'RoleLayer';
import { useStoreValue } from 'Context';
import { updateObjInArray, allowIfHasRole } from 'Utils';

const { Link } = Anchor;
const { Text } = Typography;

function OrgProfile({ orgInfo, updateOrgInfo, canEdit = false }) {
  const [companyFormData, setCompanyFormData] = useState({});
  const [hydratedCompanySchema, setHydratedCompanySchema] = useState(companyProfileSchema);
  const history = useHistory();

  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [showAddPocModal, setShowAddPocModal] = useState(false);
  const [showAddFounderModal, setShowAddFounderModal] = useState(false);

  const [companyForm] = Form.useForm();
  const [{ user, meta: metaStore }] = useStoreValue();

  const filteredOrganizationProfileSchema = useRef(companyProfileSchema);
  const eventTypeNamesToId = useRef({});

  const updateStage = async (updatedOtherData, id) => {
    try {
      await updateRelationship(id, {
        other_data: updatedOtherData,
      });
      message.success('Stage updated successfully');
    } catch (err) {
      console.log(err);
      message.error(err?.message || 'Something went wrong');
    }
  };

  const pocColumns = [
    {
      title: 'Name',
      dataIndex: 'other_data::member_name',
      render: (_, record) => (
        <RouterLink to={`/human/${record.source_user_id}`}>
          {record?.other_data?.member_name}
        </RouterLink>
      ),
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Popconfirm
          title={`Are you sure to remove this POC?`}
          onConfirm={async () => {
            await handleValueChange(
              'client_pocs',
              companyFormData?.client_pocs?.filter((c) => c.id !== id)
            );
            message.success('POC removed');
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small">
            Remove
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const founderColumns = [
    {
      title: 'Name',
      dataIndex: 'other_data::member_name',
      render: (_, record) => (
        <RouterLink to={`/human/${record?.source_user_id}`}>
          {record?.other_data?.member_name}
        </RouterLink>
      ),
    },
    {
      title: 'LinkedIn',
      dataIndex: 'other_data::member_linkedin',
      render: (_, record) =>
        record?.other_data?.member_linkedin ? (
          <a href={packLinkedinUrl(record?.other_data?.member_linkedin)}>
            <LinkedinFilled style={{ fontSize: '24px', color: '#0072b1' }} />
          </a>
        ) : null,
    },
    // {
    //   title: 'Background',
    //   dataIndex: 'other_data::background_summary',
    //   render: (_, record) => (
    //     <TextArea></TextArea>
    //   ),
    // },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Popconfirm
          title={`Are you sure to remove this Founder?`}
          onConfirm={async () => {
            await handleValueChange(
              'founders',
              companyFormData?.founders?.filter((f) => f.id !== id)
            );
            message.success('Founder removed');
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="link" size="small">
            Remove
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const jobColumns = [
    {
      title: '#',
      key: 'id',
      dataIndex: 'id',
      render: (_, record, index) => index + 1,
      width: 55,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      render: (name, record) => <RouterLink to={`/job/${record.id}`}>{name}</RouterLink>,
      ellipsis: true,
    },
    {
      title: 'Start Date',
      dataIndex: 'target_start_date',
      key: 'target_start_date',
      render: (target_start_date) =>
        target_start_date ? moment(target_start_date).format('DD MMM YYYY') : target_start_date,
    },
    {
      title: 'Openings',
      dataIndex: 'num_openings',
      key: 'num_openings',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Is Stale',
      dataIndex: 'stale',
      key: 'stale',
      render: (stale) => (stale ? 'Yes' : 'No'),
    },
  ];

  const talentColumns = [
    {
      title: 'Candidate',
      dataIndex: 'other_data',
      key: 'member_name',
      width: 300,
      render: (other_data, record) =>
        'member_name' in other_data ? (
          <RouterLink to={`/human/${record.source_user_id}`}>
            {other_data['member_name']}
          </RouterLink>
        ) : (
          '-'
        ),
      ellipsis: true,
    },
    {
      title: 'Position',
      dataIndex: 'other_data',
      key: 'position_id',
      render: (other_data) =>
        'position_id' in other_data ? (
          <RouterLink to={`/job/${other_data['position_id']}`}>
            {other_data['position_name']}
          </RouterLink>
        ) : (
          '-'
        ),
    },
    {
      title: 'Stage',
      dataIndex: 'other_data.stage',
      key: 'stage',
      render: (other_data, record) => (
        <InterviewStage
          recordId={record.id}
          otherData={record.other_data}
          onStageChange={updateStage}
        />
      ),
    },
  ];

  const handleRelatedTable = {
    founders: {
      action: () => setShowAddFounderModal(true),
      columnConfig: founderColumns,
    },
    positions: {
      action: () => setShowAddJobModal(true),
      columnConfig: jobColumns,
    },
    talent_pipeline: {
      action: () => console.log('Adding Candidate...'),
      columnConfig: talentColumns,
    },
    client_pocs: {
      action: () => setShowAddPocModal(true),
      columnConfig: pocColumns,
    },
  };

  useEffect(async () => {
    try {
      const eventTypes = await getEventTypes();
      let nameToId = {};
      for (const eventType of eventTypes.data) {
        nameToId[eventType.name] = eventType.id;
      }
      eventTypeNamesToId.current = nameToId;
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  }, []);

  useEffect(async () => {
    if (!isEmpty(orgInfo)) {
      filteredOrganizationProfileSchema.current = companyProfileSchema.filter(
        // Todo: Also filter sections current user is authorized for
        (item) =>
          item.type !== 'custom' || item.name === 'custom_fields' || orgInfo['roles'][item.name]
      );
      const detailsForForm = formatValuesForFe(orgInfo);
      setCompanyFormData({ ...detailsForForm });
      updateCompanySchema();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgInfo]);

  useEffect(() => {
    companyForm.setFieldsValue(companyFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyFormData]);

  const formattedValue = (type, value) => {
    switch (type) {
      case 'date':
        return value && !isEmpty(value) ? moment(value) : null;
      case 'roles':
        return Object.keys(value).filter((role) => {
          return value[role];
        });
      case 'action-items':
        return value ? value.split('|') : [];
      default:
        return value;
    }
  };

  const inputOnChangeFunc = (fieldType, fieldName) => {
    switch (fieldType) {
      case 'num':
      case 'number':
      case 'select':
      case 'roles':
      case 'fundraising':
      case 'fixed-text':
        return (value) => handleValueChange(fieldName, value);
      case 'date':
        return (momentDate) => {
          handleValueChange(fieldName, momentDate?.toISOString());
        };
      case 'action-items':
        return (value = []) => handleValueChange(fieldName, value.join('|'));
      case 'mapped-selects':
        return (value) => {
          const [field, k] = [...fieldName];
          let newValue = { ...companyFormData[field] };
          newValue[k]['vote'] = value;
          newValue[k]['last_updated'] = moment().toISOString();
          return handleValueChange(field, newValue);
        };
      default:
        return (event) => {
          const { value } = event.target;
          handleValueChange(fieldName, value);
          if (['website'].includes(fieldName)) {
            let val = document.getElementById(fieldName);
            val.setAttribute('disabled', 'disabled');
            val.setAttribute('type', 'password');
          }
        };
    }
  };

  const formatValuesForFe = (formData) => {
    return filteredOrganizationProfileSchema.current.reduce((acc, currentItem) => {
      if (currentItem.type === 'flat') {
        let formattedFields = currentItem.subFields.reduce((subAcc, field) => {
          subAcc[field.name] = formattedValue(field.type, formData[field.name]);
          return subAcc;
        }, {});
        acc = { ...acc, ...formattedFields };
      } else if (currentItem.type === 'nested') {
        let subFieldTypeHash = currentItem.subFields.reduce((subAcc, field) => {
          subAcc[field.name] = field.type;
          return subAcc;
        }, {});
        let formattedNestedFields = formData[currentItem.name].map((batch) => {
          const hydratedBatchObj = unpackNestedObj(batch, 'other_data');
          return Object.keys(hydratedBatchObj).reduce((batchAcc, item) => {
            batchAcc[item] = formattedValue(subFieldTypeHash[item], batch[item]);
            return batchAcc;
          }, {});
        });
        acc = { ...acc, [currentItem.name]: formattedNestedFields };
      } else if (currentItem.type === 'custom') {
        let hydratedFields = {};
        if (currentItem.name === 'deal') {
          hydratedFields = unpackNestedObj({ deal: formData['roles']['deal'] }, 'deal');
        } else if (currentItem.name === 'portfolio') {
          hydratedFields = unpackNestedObj(
            { portfolio: formData['roles']['portfolio'] },
            'portfolio'
          );
          if (hydratedFields.portfolio.engagement_start_date) {
            hydratedFields['portfolio::engagement_start_date'] = moment(
              hydratedFields['portfolio::engagement_start_date']
            );
          }
          if (hydratedFields.portfolio.engagement_end_date) {
            hydratedFields['portfolio::engagement_end_date'] = moment(
              hydratedFields['portfolio::engagement_end_date']
            );
          }
        } else if (currentItem.name === 'company') {
          hydratedFields = unpackNestedObj({ company: formData['roles']['company'] }, 'company');
        }
        acc = { ...acc, ...hydratedFields };
      } else if (currentItem.type === 'related-table') {
        let hydratedFields = { [currentItem.name]: formData[currentItem.name] };
        acc = { ...acc, ...hydratedFields };
      }
      return acc;
    }, {});
  };

  const addEvent = async (newEvent) => {
    try {
      const values = {
        title: `${orgInfo.name} - New Fundraising Event`,
        source_type: 'user',
        source_id: user.id,
        destination_type: 'organization',
        destination_id: orgInfo.id,
        event_type_id: eventTypeNamesToId.current['updated_fundraising'],
        meta: {},
        'other_data::event_id': `${newEvent['event_id']}`,
        'other_data::detail': `Raised $${newEvent['amount']}, Valuation: $${newEvent['valuation']}. ${newEvent['note']}`,
        'other_data::event_time': moment(newEvent['date']).format('MM-DD-YYYY hh:mm A'),
      };

      const formattedForBe = packNestedObj(values, 'other_data');
      await createEvent(formattedForBe);
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  const removeEvent = async (removeEvent) => {
    try {
      const eventTitle = `${orgInfo.name} - New Fundraising Event`;
      const { data: removeEventId } = await getEventsByTitle(eventTitle);
      if (removeEventId.length > 0) {
        const targetEvent = removeEventId.filter(
          (event) => event.other_data.event_id === removeEvent['event_id']
        );
        if (targetEvent.length > 0) {
          await deleteEvent(targetEvent[0].id);
        }
      }
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  const handleFundRaiseEventChange = async (frEventsBefore, frEventsAfter) => {
    if (frEventsAfter.length > frEventsBefore.length) {
      const newFrEvents = frEventsAfter.filter((event) => !frEventsBefore.includes(event));
      for (const event of newFrEvents) {
        await addEvent(event);
      }
    } else {
      const deletedFrEvents = frEventsBefore.filter((event) => !frEventsAfter.includes(event));
      for (const event of deletedFrEvents) {
        await removeEvent(event);
      }
    }
  };

  const handleValueChange = async (fieldName, value) => {
    try {
      if (['founders', 'client_pocs'].includes(fieldName)) {
        value = value.filter((collection) => collection && collection.source_user_id);
        value = value.map((val) => packNestedObj({ ...val }, 'other_data'));
      }
      if (fieldName === 'roles') {
        let newRoles = { ...orgInfo['roles'] };
        Object.keys(orgInfo[fieldName]).map((role) => {
          newRoles[role] = { is_active: value.includes(role) };
        });
        value = newRoles;
      }
      let triggerFundraiseEvent = false;
      let triggerCodenameEvent = '';
      let frEventsBefore = [];
      let frEventsAfter = [];
      if (
        fieldName.includes('deal') ||
        fieldName.includes('portfolio') ||
        fieldName.includes('company')
      ) {
        const primeKey = fieldName.substring(0, fieldName.indexOf('::'));
        const startIndex = fieldName.indexOf(primeKey + '::') + (primeKey + '::').length;
        const subKey = fieldName.substring(startIndex);
        let updatedRole = orgInfo['roles'][primeKey];
        if ('fundraising'.includes(subKey)) {
          triggerFundraiseEvent = true;
          frEventsBefore = updatedRole[subKey];
          frEventsAfter = value;
        }
        if (fieldName === 'company::codename') {
          triggerCodenameEvent = value;
          if (isEmpty(updatedRole['real_name'])) {
            updatedRole['real_name'] = orgInfo['name'];
          }
        }
        updatedRole[subKey] = value;
        value = { [primeKey]: updatedRole };
        fieldName = 'roles';
      }
      const updatedFormData = { [fieldName]: value };
      if (!isEmpty(triggerCodenameEvent)) {
        updatedFormData['name'] = triggerCodenameEvent;
      }
      await updateOrgInfo(updatedFormData);
      if (triggerFundraiseEvent) {
        handleFundRaiseEventChange(frEventsBefore, frEventsAfter);
      }
    } catch (err) {
      console.log(err);
      if (err?.message) {
        message.error(err.message);
      }
    }
  };

  const reloadPage = async () => {
    await updateOrgInfo({}, true);
  };

  const handleMappedSelects = (
    name,
    type,
    placeholder,
    multi_valued,
    showSearch,
    allowed_values
  ) => {
    if (name.includes('ic_approval')) {
      return VotingCommittee.map((k) => {
        return (
          <Col span={10} key={k + uniqueId()}>
            <Text>{k}</Text>
            <Select
              disabled={!canEdit}
              key={k}
              value={get(companyFormData, `${name}.${k}.vote`, '')}
              size="large"
              onChange={inputOnChangeFunc(type, [name, k])}
              placeholder={placeholder}
              mode={multi_valued ? 'multiple' : null}
              showSearch={showSearch}
            >
              {generateSelectOptions(allowed_values)}
            </Select>
          </Col>
        );
      });
    }
    return null;
  };

  const generateFlatForm = (fields) => {
    return fields.map(
      ({
        name,
        label,
        required = false,
        type,
        placeholder,
        allowed_values = [],
        span,
        multi_valued,
        showSearch,
        tooltip,
        help,
        ...rest
      }) => {
        if (name === 'website' && !isEmpty(get(orgInfo, 'roles.company.codename', ''))) {
          type = 'blurred-text';
        }
        return (
          <Col span={span} key={name}>
            <Form.Item
              name={name}
              label={label}
              tooltip={tooltip}
              help={help}
              rules={[
                {
                  required,
                },
              ]}
            >
              {type === 'select' ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  disabled={!canEdit}
                  size="large"
                  onChange={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  mode={multi_valued ? 'multiple' : null}
                  showSearch={showSearch}
                >
                  {generateSelectOptions(allowed_values)}
                </Select>
              ) : type === 'mapped-selects' ? (
                <Row justify="space-between">
                  {handleMappedSelects(
                    name,
                    type,
                    placeholder,
                    multi_valued,
                    showSearch,
                    allowed_values
                  )}
                </Row>
              ) : type === 'roles' ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  showArrow
                  size="large"
                  placeholder={placeholder}
                  tagRender={RoleTags}
                  {...rest}
                >
                  {generateSelectOptions(
                    allowed_values.filter(
                      (option) => !option.includes(companyForm.getFieldValue(name))
                    )
                  )}
                </Select>
              ) : type === 'action-items' ? (
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  tokenSeparators={['|']}
                  size="large"
                  placeholder={placeholder}
                  {...rest}
                ></Select>
              ) : type === 'fundraising' ? (
                <FundraisingMap
                  disabled={!canEdit}
                  data={companyFormData['deal::fundraising']}
                  handleValueChange={inputOnChangeFunc(type, name)}
                />
              ) : type === 'fixed-text' ? (
                <Text strong>{companyFormData[name]}</Text>
              ) : type === 'date' ? (
                <Input
                  disabled={!canEdit}
                  onChange={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  type={type}
                />
              ) : type === 'blurred-text' ? (
                <AntdInput.Group compact>
                  <Input
                    disabled={true}
                    onBlur={inputOnChangeFunc('text', name)}
                    placeholder={placeholder}
                    defaultValue={get(orgInfo, name, '')}
                    type={'password'}
                    visibilityToggle={false}
                    style={{
                      width: '60%',
                    }}
                    id={name}
                    {...rest}
                  />
                  <Tooltip title={`copy ${name}`} key={`copy-${name}-btn`}>
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() => {
                        let val = document.getElementById(name);
                        val.select();
                        navigator.clipboard.writeText(val.value);
                        message.success(`${name} copied`);
                      }}
                    />
                  </Tooltip>
                  <Tooltip title={`edit ${name}`} key={`edit-${name}-btn`}>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        let val = document.getElementById(name);
                        val.setAttribute('type', 'text');
                        val.removeAttribute('disabled');
                        val.focus();
                      }}
                    />
                  </Tooltip>
                </AntdInput.Group>
              ) : (
                <Input
                  disabled={!canEdit}
                  onBlur={inputOnChangeFunc(type, name)}
                  placeholder={placeholder}
                  type={type}
                  {...rest}
                />
              )}
            </Form.Item>
          </Col>
        );
      }
    );
  };

  const generateCustomForm = (fields) => {
    if (metaStore) {
      const { custom_fields: customFields } = metaStore;
      const customFieldHashMap = keyBy(customFields, (field) => field.name);
      const updatedSchema = fields.map(({ label, ...rest }) => {
        const currentMapItem = customFieldHashMap[label];
        if (currentMapItem) {
          const { multi_valued, allow_new_values, allowed_values, ...beConfig } = currentMapItem;
          const fieldType =
            !multi_valued && allow_new_values && !allowed_values.length ? 'text' : 'select';
          return {
            mode:
              allow_new_values && !allowed_values.length && multi_valued
                ? 'tags'
                : multi_valued
                ? 'multiple'
                : undefined,
            label,
            type: fieldType,
            allowed_values,
            ...beConfig,
            ...rest,
          };
        }
        return { label, ...rest };
      });
      return generateFlatForm(updatedSchema);
    }
  };

  const updateCompanySchema = () => {
    const { custom_fields: customFields } = metaStore;
    const beSkillConfig = customFields?.all.find(({ name }) => name === 'skill');
    let basicInfoSchema = {
      ...filteredOrganizationProfileSchema.current.find(({ name }) => name === 'basic_info'),
    };
    const updatedSubFields = updateObjInArray(
      basicInfoSchema?.subFields,
      { ...beSkillConfig, name: 'tech_stack' },
      'tech_stack'
    );
    basicInfoSchema['subFields'] = updatedSubFields;
    filteredOrganizationProfileSchema.current = filteredOrganizationProfileSchema.current.filter(
      (section) => {
        const allowedRoles = OrgRoles.ForOrgProfile[section.name];

        // if no role list is required for section then access will be granted
        if (!allowedRoles) return true;

        if (allowedRoles.entity === 'user') {
          return allowIfHasRole(user, allowedRoles.roles);
        } else {
          return allowIfHasRole(orgInfo, allowedRoles.roles);
        }
      }
    );
    const updatedSchema = updateObjInArray(
      filteredOrganizationProfileSchema.current,
      basicInfoSchema,
      'basic_info'
    );
    setHydratedCompanySchema(updatedSchema);
  };

  const handleOrgDelete = () => {
    deactivateOrg(orgInfo?.id).then(() => history.push('/'));
  };

  const dropDownMenu = (
    <Menu
      items={[
        {
          label: (
            <Button type="primary" block danger onClick={handleOrgDelete}>
              <Text style={{ color: 'white' }}>Delete</Text>
            </Button>
          ),
          key: '0',
        },
      ]}
    />
  );

  return (
    <Content spacing="medium" style={{ paddingBottom: 300 }}>
      <Row>
        <Col span={4}>
          <Affix offsetTop={200}>
            <Content>
              <h3>Sections</h3>
              <Anchor>
                <List
                  dataSource={[...filteredOrganizationProfileSchema.current]}
                  renderItem={({ name, label }, index) => (
                    <List.Item key={index}>
                      <Link href={`#${name}`} title={label} />
                    </List.Item>
                  )}
                />
              </Anchor>
            </Content>
          </Affix>
        </Col>
        <Col span={20}>
          <Content spacing="medium">
            <Form form={companyForm} layout="vertical">
              {hydratedCompanySchema.map(
                ({ section, name, label, icon, type, subFields }, index) => (
                  <div id={section} key={index} style={{ marginBottom: 20 }}>
                    <Row
                      justify="space-between"
                      style={{
                        borderBottom: '1px solid #4e4e4e',
                        paddingBottom: '10px',
                        marginBottom: '20px',
                        fontWeight: 700,
                      }}
                    >
                      <h3>{`${icon}     ${label}`}</h3>
                      {type === 'related-table' && handleRelatedTable[name]['action'] && canEdit && (
                        <Button
                          type="link"
                          size="middle"
                          onClick={() => handleRelatedTable[name]['action']()}
                        >
                          + Add New {label}
                        </Button>
                      )}
                    </Row>
                    <Row gutter={[40, 4]}>
                      {type === 'flat' ? (
                        generateFlatForm(subFields)
                      ) : type === 'nested' ? (
                        <NestedFormList
                          canEdit={canEdit}
                          subFields={subFields}
                          parentName={name}
                          parentLabel={label}
                          formInstance={companyForm}
                          valueChangeCallback={handleValueChange}
                        />
                      ) : type === 'custom' ? (
                        generateCustomForm(subFields)
                      ) : type === 'related-table' ? (
                        <RelatedTable
                          columnConfig={handleRelatedTable[name]['columnConfig']}
                          data={companyFormData[name]}
                        />
                      ) : null}
                    </Row>
                  </div>
                )
              )}
            </Form>
            <Row>
              <Dropdown overlay={dropDownMenu} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <Button>
                    <Text type="danger">Delete Org</Text>
                    <DownOutlined />
                  </Button>
                </a>
              </Dropdown>
            </Row>
          </Content>
        </Col>
      </Row>
      <AddJobModal
        showModal={showAddJobModal}
        setShowModal={setShowAddJobModal}
        orgId={orgInfo?.id}
        refresh={reloadPage}
      />
      <AddPocModal
        existingVal={orgInfo?.client_pocs}
        showModal={showAddPocModal}
        setShowModal={setShowAddPocModal}
        orgId={orgInfo?.id}
        refresh={handleValueChange}
      />
      <AddFounderModal
        existingVal={orgInfo?.founders}
        showModal={showAddFounderModal}
        setShowModal={setShowAddFounderModal}
        orgId={orgInfo?.id}
        refresh={handleValueChange}
      />
    </Content>
  );
}

OrgProfile.propTypes = {
  orgInfo: PropTypes.object,
  updateOrgInfo: PropTypes.func,
  canEdit: PropTypes.bool,
};

export default OrgProfile;
