const newMeetingSchema = [
  {
    label: 'Founder',
    name: 'founder_id',
    type: 'text',
    required: true,
    span: 24,
    placeholder: 'Founder Name',
  },
  {
    label: 'Other POC',
    name: 'other_poc',
    type: 'text',
    required: true,
    span: 12,
    placeholder: 'Other POC',
  },
  {
    label: 'Candidate',
    name: 'type',
    type: 'text',
    span: 12,
    placeholder: 'Portfolio Company Candidate',
  },
  {
    label: 'Future Founder/Investment Prospect',
    name: 'ff_or_investment_prospect',
    type: 'select',
    allowed_values: ['Future Founder', 'Investment Prospect'],
    span: 8,
  },
  {
    label: 'HC Candidate',
    name: 'hc_candidate',
    type: 'text',
    span: 8,
  },
  {
    label: 'External Vendor',
    name: 'external_vendor',
    type: 'text',
    span: 8,
  },
  {
    label: 'Investor',
    name: 'investor_name',
    type: 'text',
    placeholder: 'Investor Name',
    span: 8,
  },
  {
    label: 'External Strategic',
    name: 'strategic',
    type: 'text',
    span: 8,
  },
  {
    label: 'Internal Recruiting',
    name: 'internal_recruiting',
    type: 'text',
    span: 8,
  },
  {
    label: 'Agency Partner',
    name: 'agency_partner',
    type: 'text',
    span: 8,
  },
  {
    label: 'Internal HC Meeting',
    name: 'select',
    type: 'text',
    allowed_values: ['yes', 'no'],
    span: 8,
  },
];

const meetingFeedbackSchema = [
  {
    label: 'Did this meeting happen?',
    name: 'meeting_happened',
    type: 'select',
    required: true,
    allowed_values: ['Yes', 'No'],
    span: 24,
  },
  {
    label: 'Who was it with?',
    name: 'meeting_with',
    type: 'select',
    required: true,
    allowed_values: ['Member', 'Future Founder', 'Investment', 'Client POC'],
    span: 24,
  },
  {
    label: 'What was it about?',
    name: 'meeting_about',
    type: 'radio-group',
    required: true,
    allowed_values: [
      'First Meeting',
      'Regularly Scheduled Meeting',
      'Inbound catch-up',
      'Outbound catch-up',
      'Interview',
    ],
    span: 24,
    conditional_items: [
      {
        label: 'What was the source of the meeting?',
        name: 'meeting_source',
        type: 'radio-group',
        required: true,
        allowed_values: ['Referral', 'Cold outreach', 'Inbound'],
        span: 24,
        parent_key: 'parent_key',
        when_to_update: (dynamicKey) => (prevValues, currentValues) =>
          prevValues[dynamicKey] !== currentValues[dynamicKey],
        when_to_show: (dynamicKey) => (getFieldValue) =>
          getFieldValue(dynamicKey)?.includes('First Meeting'),
        conditional_item: {
          label: 'Search & select from Humans database',
          name: 'referral',
          type: 'search',
          required: true,
          multi_valued: false,
          search_entity: 'user',
          canAddNewEntity: true,
          span: 24,
          parent_key: 'meeting_source',
          when_to_update: (dynamicKey) => (prevValues, currentValues) =>
            prevValues[dynamicKey] !== currentValues[dynamicKey],
          when_to_show: (dynamicKey) => (getFieldValue) => getFieldValue(dynamicKey) === 'Referral',
          // this stores the label as well as value
          // else existing data are shown as ids onload
          labelInValue: false,
          // to avoid storing html node skipEmail is true
          skipEmail: true,
        },
      },
    ],
  },
  {
    label: 'Notes',
    required: false,
    name: 'meeting_notes',
    type: 'textarea',
    span: 24,
  },
];

const userFeedbackSchema = [
  {
    label: 'Who was it with?',
    name: 'meeting_with',
    type: 'radio-group',
    required: true,
    allowed_values: [
      'Investment Prospect',
      'HC Engagement - Founder',
      'HC Engagement - POC',
      'HC Engagement - Candidate',
      'Future Founder',
      'External Strategic',
      'Internal Recruiting',
      'Agency Partner',
    ],
    span: 24,
    conditional_items: [
      {
        // will be displayed only when to_show is true
        // using curry function to inject dynamic keys
        label: 'Which Portfolio companies is this candidate interested in?',
        name: 'interested_portfolio',
        type: 'search',
        required: true,
        multi_valued: true,
        search_entity: 'portfolio',
        span: 24,
        parent_key: 'meeting_with',
        when_to_update: (dynamicKey) => (prevValues, currentValues) =>
          prevValues[dynamicKey] !== currentValues[dynamicKey],
        when_to_show: (dynamicKey) => (getFieldValue) =>
          getFieldValue(dynamicKey) === 'HC Engagement - Candidate',
        hasSchoolAndCampusOrg: false,
        labelInValue: true,
      },
      /*
      TODO: @zhiczz
      Revisit after deal kanban workflow has been revised
      {
        label: 'Which stage is this prospect currently in?',
        name: 'current_deal_stage',
        type: 'select',
        multi_valued: true,
        allowed_values: DealStages,
        span: 24,
        parent_key: 'meeting_with',
        when_to_update: (dynamicKey) => (prevValues, currentValues) =>
          prevValues[dynamicKey] !== currentValues[dynamicKey],
        when_to_show: (dynamicKey) => (getFieldValue) =>
          getFieldValue(dynamicKey) === 'Investment Prospect',
      },
      */
      {
        label: 'Which Portfolio company is this person associated with?',
        name: 'org_association',
        required: true,
        type: 'search',
        span: 24,
        parent_key: 'meeting_with',
        when_to_update: (dynamicKey) => (prevValues, currentValues) =>
          prevValues[dynamicKey] !== currentValues[dynamicKey],
        when_to_show: (dynamicKey) => (getFieldValue) =>
          getFieldValue(dynamicKey) === 'HC Engagement - POC' ||
          getFieldValue(dynamicKey) === 'HC Engagement - Founder',
        hasSchoolAndCampusOrg: false,
        labelInValue: true,
        search_entity: 'portfolio',
      },
    ],
  },
  {
    label: 'Attendee Notes',
    required: false,
    name: 'attendee_notes',
    type: 'textarea',
    span: 24,
  },
];

const sketchyAttendee = [
  {
    size: 'default',
    label: 'First Name',
    name: 'first_name',
    type: 'text',
    span: 12,
  },
  {
    size: 'default',
    label: 'Last Name',
    name: 'last_name',
    type: 'text',
    span: 12,
  },
  {
    size: 'default',
    label: 'LinkedIn Profile',
    name: 'linkedins',
    type: 'text',
    span: 24,
  },
];

export { newMeetingSchema, meetingFeedbackSchema, userFeedbackSchema, sketchyAttendee };
