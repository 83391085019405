import React, { forwardRef } from 'react';
import { Input as AntdInput, DatePicker, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './input-field.less';

const { TextArea, Search } = AntdInput;

const Input = forwardRef((props, ref) => {
  const { size = 'large', className, type, ...rest } = props;
  switch (type) {
    case 'textarea':
    case 'multiline':
      return <TextArea {...rest} className={cx(className, 'hc-input-field')} ref={ref} />;
    case 'date':
      return (
        <DatePicker size={size} {...rest} className={cx(className, 'hc-input-field')} ref={ref} />
      );
    case 'num':
    case 'number':
      return (
        <InputNumber size={size} {...rest} className={cx(className, 'hc-input-field')} ref={ref} />
      );
    case 'password':
      return (
        <AntdInput.Password
          size={size}
          {...rest}
          className={cx(className, 'hc-input-field')}
          ref={ref}
        />
      );
    case 'search':
      return <Search size={size} {...rest} className={cx(className, 'hc-input-field')} ref={ref} />;
    default:
      return (
        <AntdInput size={size} {...rest} className={cx(className, 'hc-input-field')} ref={ref} />
      );
  }
});

Input.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

Input.displayName = 'Input';

export default Input;
