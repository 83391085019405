import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import './timeline-card.less';

const { Panel } = Collapse;

// TODO: figure out why only inline styles working for p
const TimelineCard = ({ body, title, ...rest }) => {
  return (
    <Collapse {...rest} className="timeline-collapse">
      <Panel header={title}>
        <p className="whitespace">{body}</p>
      </Panel>
    </Collapse>
  );
};

TimelineCard.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
};

TimelineCard.defaultProps = {
  body: null,
  title: null,
};

export default TimelineCard;
