import { Result } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, FloatingBugReportLink } from 'Components';

const NotFound = () => {
  const history = useHistory();
  const { pathname, search = '' } = useLocation();

  return (
    <>
      <Result
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        status="warning"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={[
          <Button key="goback" onClick={() => history.goBack()}>
            Go back
          </Button>,
          <Button
            type="primary"
            key="login"
            onClick={() => history.push('/login', { from: pathname + search })}
          >
            Login
          </Button>,
        ]}
      />
      <FloatingBugReportLink />
    </>
  );
};

export default NotFound;
