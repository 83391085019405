import { OrgTypes, OrgRoles, HcClientStatus, UsStates } from 'Constants';
import { range, startCase } from 'lodash';
import { Tooltip } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

const newCompanyFormSchema = [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
    span: 12,
  },
  {
    label: 'Organization Type',
    name: 'type',
    type: 'select',
    span: 12,
    allowed_values: OrgTypes.map((type) => {
      return { display_hint_primary: startCase(type), value: type };
    }),
  },
];

const newPortfolioFormSchema = [
  {
    label: 'Name',
    name: 'name',
    type: 'text',
    required: true,
    span: 12,
  },
  {
    label: 'Organization Type',
    name: 'type',
    type: 'select',
    required: true,
    span: 12,
    allowed_values: OrgTypes.map((type) => {
      return { display_hint_primary: startCase(type), value: type };
    }),
  },
  {
    label: 'Engagement Stage',
    name: 'engagement_stage',
    type: 'select',
    span: 24,
    required: true,
    allowed_values: [
      { display_hint_primary: 'Founder Meeting', value: 'founder_meeting' },
      { display_hint_primary: 'Audit Pending', value: 'audit_pending' },
      { display_hint_primary: 'Audit', value: 'audit' },
      { display_hint_primary: 'Recommendation', value: 'recommendation' },
      { display_hint_primary: 'Embedded', value: 'embedded' },
      { display_hint_primary: 'Advisory', value: 'advisory' },
      { display_hint_primary: 'Advisory+', value: 'advisory_plus' },
      { display_hint_primary: 'Completed', value: 'completed' },
    ],
  },
];

const newFounderFormSchema = [
  {
    label: 'Search Human and Add',
    name: 'user_id',
    span: 24,
    type: 'search',
    search_entity: 'user',
  },
  {
    name: 'or Create Human',
    span: 24,
    type: 'divider',
  },
  {
    label: 'Name',
    name: 'member_name',
    span: 12,
    type: 'text',
  },
  {
    label: 'LinkedIn',
    name: 'member_linkedin',
    span: 12,
    type: 'text',
  },
  {
    label: 'Email',
    name: 'member_email',
    span: 24,
    type: 'text',
  },
];

const companyProfileSchema = [
  {
    label: 'Basic Info',
    name: 'basic_info',
    section: 'basic_info',
    type: 'flat',
    icon: '📇',
    subFields: [
      {
        label: 'Name',
        name: 'name',
        type: 'text',
        span: 9,
      },
      {
        label: 'Org type',
        name: 'type',
        type: 'select',
        span: 6,
        allowed_values: OrgTypes.map((type) => {
          return {
            display_hint_primary: startCase(type),
            value: type,
          };
        }),
      },
      {
        label: 'HC client status',
        name: 'hc_client_status',
        type: 'select',
        span: 9,
        tooltip: {
          title: `Changing to "Current Client" will result in all (MM, MM + FF)
        matchmaking candidates receiving introduction with the organization.`,
          icon: <InfoCircleTwoTone twoToneColor={'#f5222d'} />,
        },
        allowed_values: HcClientStatus.map((type) => {
          return {
            display_hint_primary: startCase(type),
            value: type,
          };
        }),
      },
      {
        label: 'Logo link',
        name: 'logo_link',
        type: 'text',
        span: 9,
      },
      {
        label: 'Year founded',
        name: 'year_founded',
        type: 'select',
        allowed_values: range(1980, 2030).map((year) => {
          return { value: year };
        }),
        span: 6,
      },
      {
        label: 'Company website',
        name: 'website',
        type: 'text',
        span: 9,
      },
      {
        label: 'Tech Stack',
        name: 'tech_stack',
        type: 'select',
        span: 24,
      },
      {
        label: 'Role',
        name: 'roles',
        type: 'roles',
        mode: 'multiple',
        span: 24,
        allowed_values: OrgRoles,
      },
      {
        label: '✅  Action Items',
        name: 'action_items',
        type: 'action-items',
        mode: 'tags',
        span: 24,
        placeholder: 'Add action items for human',
        tagRender: ({ label, onClose }) => {
          return (
            <Tooltip title={label}>
              <span className="ant-select-selection-item">
                <span className="ant-select-selection-item-content">{label}</span>
                <span className="ant-select-selection-item-remove" onClick={onClose}>
                  X
                </span>
              </span>
            </Tooltip>
          );
        },
      },
      {
        label: 'Quick Notes',
        name: 'quick_notes',
        type: 'textarea',
        span: 24,
      },
    ],
  },
  {
    label: 'Security',
    name: 'company',
    type: 'custom',
    section: 'company',
    icon: '🔐',
    subFields: [
      {
        label: 'Codename',
        name: 'company::codename',
        type: 'text',
        span: 9,
      },
    ],
  },
  {
    label: 'Founders',
    name: 'founders',
    type: 'related-table',
    section: 'founders',
    icon: '🚀',
  },
  {
    label: 'Portfolio Engagement',
    name: 'portfolio',
    type: 'custom',
    section: 'portfolio',
    icon: '💼',
    subFields: [
      {
        label: 'Engagement Stage',
        name: 'portfolio::engagement_stage',
        span: 24,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Founder Meeting', value: 'founder_meeting' },
          { display_hint_primary: 'Audit Pending', value: 'audit_pending' },
          { display_hint_primary: 'Audit', value: 'audit' },
          { display_hint_primary: 'Recommendation', value: 'recommendation' },
          { display_hint_primary: 'Embedded', value: 'embedded' },
          { display_hint_primary: 'Advisory', value: 'advisory' },
          { display_hint_primary: 'Advisory+', value: 'advisory_plus' },
          { display_hint_primary: 'Completed', value: 'completed' },
        ],
      },
      {
        label: 'Engagement Start Date',
        name: 'portfolio::engagement_start_date',
        type: 'date',
        span: 12,
      },
      {
        label: 'Engagement End Date',
        name: 'portfolio::engagement_end_date',
        type: 'date',
        span: 12,
      },
      {
        label: 'Affinity',
        name: 'portfolio::affinity',
        span: 12,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Strong Affinity Established', value: 'strong' },
          { display_hint_primary: 'Building Affinity; On Track', value: 'on_track' },
          { display_hint_primary: 'Early in Building Affinity', value: 'early' },
          { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
          { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
        ],
      },
      {
        label: 'Affinity Notes',
        name: 'portfolio::affinity_notes',
        type: 'textarea',
        span: 12,
      },
      {
        label: 'Trust',
        name: 'portfolio::trust',
        span: 12,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Strong Trust Established', value: 'strong' },
          { display_hint_primary: 'Building Trust; On Track', value: 'on_track' },
          { display_hint_primary: 'Early in Building Trust', value: 'early' },
          { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
          { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
        ],
      },
      {
        label: 'Trust Notes',
        name: 'portfolio::trust_notes',
        type: 'textarea',
        span: 12,
      },
      {
        label: 'Ops',
        name: 'portfolio::ops',
        span: 12,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Supported Significant Ops Work', value: 'strong' },
          { display_hint_primary: 'Supported Meaningful Ops Work', value: 'on_track' },
          { display_hint_primary: 'Supported Some Ops Work', value: 'early' },
          { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
          { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
        ],
      },
      {
        label: 'Ops Notes',
        name: 'portfolio::ops_notes',
        type: 'textarea',
        span: 12,
      },
      {
        label: 'Hiring',
        name: 'portfolio::hiring',
        span: 12,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'Supported Significant Hires', value: 'strong' },
          { display_hint_primary: 'Supported Meaningful Hires', value: 'on_track' },
          { display_hint_primary: 'Supported Some Hires', value: 'early' },
          { display_hint_primary: 'Challenges and/or Blocked', value: 'blocked' },
          { display_hint_primary: 'Not Applicable', value: 'not_applicable' },
        ],
      },
      {
        label: 'Hiring Notes',
        name: 'portfolio::hiring_notes',
        type: 'textarea',
        span: 12,
      },
    ],
  },
  {
    label: 'Deal',
    name: 'deal',
    section: 'deal',
    type: 'custom',
    icon: '📁',
    subFields: [
      {
        label: 'Fundraising',
        name: 'deal::fundraising',
        span: 24,
        type: 'fundraising',
      },
      {
        label: 'Status',
        name: 'deal::status',
        span: 4,
        type: 'fixed-text',
        allowed_values: [
          { display_hint_primary: 'Active', value: 'Active' },
          { display_hint_primary: 'Passive', value: 'Passive' },
          { display_hint_primary: 'Archived', value: 'Archived' },
        ],
      },
      {
        label: 'Stage',
        name: 'deal::stage',
        span: 8,
        type: 'select',
        allowed_values: [
          { display_hint_primary: 'First Meeting', value: 'First meeting' },
          { display_hint_primary: 'Due Diligence', value: 'Due Diligence' },
          { display_hint_primary: 'Approved', value: 'Approved' },
          { display_hint_primary: 'Monitor', value: 'Monitor' },
          { display_hint_primary: 'Pass', value: 'Pass' },
        ],
      },
      {
        label: 'IC Approval',
        name: 'deal::ic_approval',
        span: 24,
        type: 'mapped-selects',
        allowed_values: [
          { display_hint_primary: 'Yes', value: 'Yes' },
          { display_hint_primary: 'No', value: 'No' },
          { display_hint_primary: 'Hold', value: 'Hold' },
        ],
      },
      {
        label: 'Pitch Deck',
        name: 'deal::deck',
        span: 12,
        type: 'text',
      },
      {
        label: 'Investment Memo',
        name: 'deal::investment_memo',
        span: 12,
        type: 'text',
      },
    ],
  },
  {
    label: 'Positions',
    name: 'positions',
    type: 'related-table',
    section: 'positions',
    icon: '💼',
  },
  {
    label: 'Talent Pipeline',
    name: 'talent_pipeline',
    type: 'related-table',
    section: 'talent_pipeline',
    icon: '👋',
  },
  {
    label: 'Client POCs',
    name: 'client_pocs',
    type: 'related-table',
    section: 'client_pocs',
    icon: '📌',
  },
  {
    label: 'Other Info',
    name: 'other_info',
    section: 'other_info',
    type: 'flat',
    icon: '📎',
    subFields: [
      {
        label: 'About',
        name: 'about',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Size & team breakdown',
        name: 'size_and_team_breakdown',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Founder bios',
        name: 'founder_bios',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Core team highlights',
        name: 'core_team_highlights',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Values & culture',
        name: 'values_and_culture',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Engineering culture',
        name: 'engineering_culture',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Impact culture',
        name: 'impact',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Technical Challenges',
        name: 'technical_challenges',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Mentorship Opportunities',
        name: 'mentorship_opportunities',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Funding highlights',
        name: 'funding_highlights',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Recognition and press',
        name: 'recognition_and_press',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
      {
        label: 'Perks & benefits',
        name: 'perks_and_benefits',
        type: 'textarea',
        rows: 4,
        span: 12,
      },
    ],
  },
  {
    label: 'Locations',
    name: 'locations',
    type: 'nested',
    section: 'locations',
    icon: '📍',
    subFields: [
      {
        label: 'City',
        name: 'city',
        type: 'text',
        span: 8,
      },
      {
        label: 'State',
        name: 'state',
        type: 'select',
        span: 8,
        allowed_values: UsStates,
        showSearch: true,
      },
      {
        label: 'Country',
        name: 'country',
        type: 'text',
        span: 8,
      },
    ],
  },
];

const newCompanyPocSchema = [
  {
    label: 'User',
    name: 'user_id',
    type: 'search',
    required: true,
    search_entity: 'user',
    span: 24,
    placeholder: 'Search User',
    extra: 'POC should be a existing user, if not add a user from candidates page first',
  },
];

export {
  newCompanyFormSchema,
  newPortfolioFormSchema,
  companyProfileSchema,
  newCompanyPocSchema,
  newFounderFormSchema,
};
