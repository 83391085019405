import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'Components';
import ColumnsCheckbox from './columns-checkbox';

const AddColumnsModal = ({
  columns = [],
  columnsConfig = [],
  showModal,
  setShowModal,
  updateColumnsVisibility,
}) => {
  const [filterColumns, setFilterColumns] = useState([]);
  const [holdColumnsConfig, setHoldColumnsConfig] = useState([]);

  useEffect(() => {
    // filter cherry-pick action column
    setHoldColumnsConfig(columnsConfig.filter(({ title }) => title !== 'Pick'));
  }, [columnsConfig]);

  useEffect(() => {
    setFilterColumns(columns);
  }, [columns]);

  const onCheckboxChange = (checked, dataIndex) => {
    const tempColumnConfig = [...holdColumnsConfig];
    if (checked) {
      const columnToAdd = columns.find((item) => item.dataIndex === dataIndex);
      if (columnToAdd) {
        tempColumnConfig.push(columnToAdd);
        return setHoldColumnsConfig(tempColumnConfig);
      }
      return;
    }
    if (holdColumnsConfig.length === 1) return message.info('Atleast one column is required');
    let updatedColumns = tempColumnConfig.filter((item) => item.dataIndex !== dataIndex);
    return setHoldColumnsConfig(updatedColumns);
  };

  const onSearch = (event) => {
    const { value: searchText } = event.target;
    if (!searchText) return setFilterColumns(columns);
    const filteredList = columns.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilterColumns(filteredList);
  };

  const updateFieldsConfig = async () => {
    const columnsToShow = holdColumnsConfig.map((column) => column.dataIndex);
    await updateColumnsVisibility(columnsToShow);
  };

  return (
    <Modal
      title="Add/Hide Columns"
      size="middle"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      okText="Apply"
      width={800}
      onOk={updateFieldsConfig}
    >
      <Row>
        <Col span={24}>
          <Input
            suffix={<SearchOutlined />}
            size="middle"
            placeholder="Search column"
            bordered={false}
            style={{ borderBottom: '1px solid lightgrey', borderRadius: 0, marginBottom: 15 }}
            allowClear
            onChange={onSearch}
          />
        </Col>
        <ul
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            listStyleType: 'unset',
            padding: 'unset',
          }}
        >
          {filterColumns.map((column) => (
            <li key={column.dataIndex}>
              <ColumnsCheckbox
                {...column}
                checked={
                  !!holdColumnsConfig.find(({ dataIndex }) => dataIndex === column.dataIndex)
                }
                onCheckboxChange={onCheckboxChange}
              />
            </li>
          ))}
        </ul>
      </Row>
    </Modal>
  );
};

AddColumnsModal.propTypes = {
  columns: PropTypes.array,
  columnsConfig: PropTypes.array,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  updateColumnsVisibility: PropTypes.func,
};

export default AddColumnsModal;
