import { request } from './http-service';
import { createPocRelationship } from 'Api/relationship-service';
import moment from 'moment';

const createUser = async (data, userId = undefined) => {
  let result = await request({
    url: `/user/`,
    method: 'post',
    data,
  });
  const {
    data: { id },
  } = result;

  if (userId !== undefined) {
    createPocRelationship(userId, { destUserId: id });
  }
  return result;
};

const updateUser = (userId, data, headers = {}) => {
  return request({
    url: `/user/${userId}`,
    method: 'put',
    data,
    headers: {
      ...headers,
    },
  });
};

const bulkUpdateUsers = (data) => {
  return request({
    url: `/users/`,
    method: 'put',
    data,
  });
};

const getUsers = (pagination, sorter = {}, meta = {}, { make_event = true }) => {
  const urlParams = new URLSearchParams({
    ...pagination,
    ...sorter,
  }).toString();
  const { filters } = meta;
  const query = filters?.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  return request({
    url: `/user/?${urlParams}&q=${query}`,
    method: 'get',
    headers: {
      make_event: make_event,
    },
  });
};

const getUsersByIdList = (userIdList) => {
  return request({
    url: `/user-meta/?${userIdList.map((id) => `user_id_list=${id}`).join('&')}`,
    method: 'get',
  });
};

const quickSearchUsers = (searchText, current_view_id) => {
  return request({
    url: `/user/quick-search?search_text=${searchText}&view_id=${current_view_id}`,
    method: 'get',
    headers: {
      make_event: false,
    },
  });
};

const exportUsers = (meta, options = {}, make_event = false) => {
  const { filters } = meta;
  const query = filters.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  return request({
    url: `/user/download/?q=${query}&options=${encodeURIComponent(JSON.stringify(options))}`,
    method: 'get',
    headers: {
      make_event: make_event,
    },
  });
};

const getUserProfile = (userId = 'me', makeEvent = true) => {
  return request({
    url: `/user/${userId}`,
    method: 'get',
    headers: {
      make_event: makeEvent,
    },
  });
};

const updateAssessment = (formData) => {
  return request({
    url: `/assessment_form`,
    method: 'post',
    data: formData,
  });
};

const getAssessment = (userId) => {
  return request({
    url: `/assessment_form/${userId}`,
    method: 'get',
  });
};

const uploadResume = (userId = 'me', formData) => {
  return request({
    url: `/user/${userId}/resume`,
    method: 'put',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const timeoutTest = (sleep_time) => {
  return request({
    url: '/timeout/' + sleep_time,
    method: 'get',
  });
};

const getInternalMembers = () => {
  return request({
    url: '/internal-users',
    method: 'get',
  });
};

const getUserEventLogs = () => {
  /*
  eventSchema: {
    created_at: timestamp,
    updated_at: timestamp,
    type_id: int(event_type_id),
    author: int (user_id? user name?),
    title: string,
    source_type: string (users, organizations, tags),
    source_id: int,
    destination_type: string(users, organizations, tags),
    destination_id: int,
    other_data: {},
    meta: {},
  }
  */
  return [
    {
      title: 'Candidate Accepted Anduril',
      other_data: {
        detail:
          'The problem is that the last two list points are not at the bottom. i would like to have them at the ',
      },
      date: moment('02-08-2022', 'MM-DD-YYYY'),
      time: '4:30 pm',
      eventType: 'interview_update',
    },
    {
      title: 'Outreach meeting with Neelan',
      other_data: {
        detail:
          'this one works with the bottom. but if i zoom into the site the two bottom icons will move up and lay over the other one',
      },
      date: moment('01-20-2022', 'MM-DD-YYYY'),
      time: '02:00 pm',
      eventType: 'calendly_meeting',
    },
    {
      title: 'Added from Gem',
      other_data: { detail: 'Was added through Gem migration' },
      date: moment('02-03-2022', 'MM-DD-YYYY'),
      time: '12:30 pm',
      eventType: 'updated_profile',
    },
  ];
};

export {
  createUser,
  updateUser,
  getUsers,
  getUserProfile,
  getUserEventLogs,
  updateAssessment,
  getAssessment,
  uploadResume,
  exportUsers,
  bulkUpdateUsers,
  timeoutTest,
  quickSearchUsers,
  getUsersByIdList,
  getInternalMembers,
};
