import Humans from './pages/humans/humans';
import HumanView from './pages/human-view/human-view';
import OrgView from './pages/org-view/org-view';
import Organizations from './pages/organizations/organizations';
import Portfolios from './pages/portfolios/portfolios';
import NotFound from './pages/not-found';
import { ForgotPassword, ResetPassword, Login, CreateAccount } from './pages/auth';
import PendingForms from './pages/meeting-feedback-view/pending-forms';
import OutstandingTasks from './pages/outstanding-tasks/outstanding-tasks';
import Account from './pages/account/account';
import SplashPage from './components/splash/splash-page';
import GlobalTimeline from './pages/global-timeline/global-timeline';

const openRoutes = [
  { path: '/login', component: Login, header: 'sparse' },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    header: 'sparse',
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
    header: 'sparse',
  },
  {
    path: '/create_account',
    component: CreateAccount,
    header: 'sparse',
  },
  {
    path: '/feedback',
    component: PendingForms,
    header: 'sparse',
  },
  {
    title: 'Feedback Pending',
    path: '/feedback-pending',
    component: PendingForms,
    header: 'sparse',
  },
  {
    path: '/done',
    component: SplashPage,
    header: 'sparse',
  },
  {
    path: '*',
    component: NotFound,
    header: 'sparse',
  },
];
// Routes are visible based on permission attribute
// for eg: permission: [Roles.INVESTMENT] will be visible
// to investment & admin roles only

//Note : Routes obj without permission attr is visible to everyone

//LATEST
// Add events list component for OutstandingTasks
const closedRoutes = [
  { title: 'Events', path: '/', component: GlobalTimeline, header: 'full' },
  {
    title: 'Account',
    path: '/account',
    component: Account,
    header: 'full',
  },
  {
    title: 'Humans',
    path: '/humans',
    component: Humans,
    header: 'full',
  },
  {
    path: '/human/:id',
    component: HumanView,
    header: 'full',
  },
  {
    title: 'Organizations',
    path: '/organizations',
    component: Organizations,
    header: 'full',
  },
  {
    title: 'Portfolio',
    path: '/portfolio',
    component: Portfolios,
    header: 'full',
  },
  {
    path: '/org/:id',
    component: OrgView,
    header: 'full',
  },
  {
    title: 'Outstanding Tasks',
    path: '/outstanding_tasks',
    component: OutstandingTasks,
    header: 'full',
  },
];

export { openRoutes, closedRoutes };
