import { UntrackHumanEventTypes, HumanEventConfig } from 'Constants';

/*
  class Event(BaseSchema, UnstructuredDataSchema):
    source_id: Optional[int] = None
    source_type: Optional[str] = None
    destination_id: Optional[int] = None
    destination_type: Optional[str] = None
    event_type_id: Optional[int] = None
    relationship_id: Optional[int] = None
*/

const newHumanEventFormSchema = [
  {
    label: 'Event Type',
    name: 'event_type',
    type: 'select',
    required: true,
    span: 12,
    allowed_values: UntrackHumanEventTypes.map((type) => {
      return { display_hint_primary: HumanEventConfig[type].displayName, value: type };
    }),
  },
  {
    label: 'Date',
    name: 'other_data::event_time',
    type: 'date',
    showTime: true,
    required: true,
    span: 12,
  },
  {
    label: 'Title',
    name: 'title',
    type: 'text',
    required: true,
    placeholder: 'Coffee Chat/Call/Convo...',
    span: 24,
  },
  {
    label: 'Detail',
    name: 'other_data::detail',
    type: 'textarea',
    required: true,
    placeholder: 'Brief summary',
    span: 24,
  },
];

const newOrgEventFormSchema = [];

const newJobEventFormSchema = [];

export { newHumanEventFormSchema, newOrgEventFormSchema, newJobEventFormSchema };
