import { at, intersection } from 'lodash';
import { CODES_TO_SENTENCES } from '../messages.config';
import moment from 'moment';
import { Tooltip } from 'antd';
import ReactDOM from 'react-dom';

const makeShowable = (err, context) => {
  const paths = [`${context}.${err}`, `general.${err}`];
  return at(CODES_TO_SENTENCES, paths).find((x) => x) || err;
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// converts {a:"",keyName::b:"",keyName::c:""}
// to {a:"",keyName:{b:"",c:""}} ,VV for unpack func below
const packNestedObj = (obj, keyName) => {
  if (!(keyName in obj)) {
    obj[keyName] = {};
  }
  const keysToBePacked = Object.keys(obj).filter((key) => key.includes(`${keyName}::`));
  keysToBePacked.forEach((key) => {
    const subKey = key.substring(`${keyName}::`.length);
    obj[keyName][subKey] = obj[key];
    delete obj[key];
  });
  return obj;
};

const unpackNestedObj = (obj, keyName) => {
  if (!(keyName in obj && obj[keyName])) return obj;
  const keysToBeUnpacked = Object.keys(obj[keyName]);
  keysToBeUnpacked.forEach((key) => {
    const unpackedSubKey = `${keyName}::${key}`;
    obj[unpackedSubKey] = obj[keyName][key];
  });
  return obj;
};

// obj in array to be updated by name field
const updateObjInArray = (array, obj, nameSearch) => {
  let tempArray = [...array];
  const indexToUpdate = tempArray.findIndex((item) => item.name === nameSearch);
  if (indexToUpdate === -1) return array;
  tempArray[indexToUpdate] = { ...tempArray[indexToUpdate], ...obj };
  return tempArray;
};

const isArrayWithLength = (arr) => {
  return Array.isArray(arr) && arr.length;
};

const activeRolesOfLoggedInUser = () => {
  const user =
    localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : {};
  return getActiveRolesForUser(user);
};

const getActiveRolesForUser = (user) => {
  const roles = user?.roles;
  if (!roles) return [];
  return [
    ...Object.keys(roles)
      .sort()
      .filter((role) => roles[role] && roles[role]['is_active']),
    user.role,
  ].filter(Boolean);
};

const getAllowedRoutes = (routes) => {
  const activeRoles = activeRolesOfLoggedInUser();
  // admin has access to all the routes
  if (activeRoles.length && activeRoles.includes('admin')) return routes;
  return routes.filter(({ permission }) => {
    if (!permission) return true;
    else if (!isArrayWithLength(permission)) return true;
    else return intersection(permission, activeRoles).length;
  });
};

const getAllowedSubCategories = (subCategories) => {
  const activeRoles = activeRolesOfLoggedInUser();
  if (activeRoles.length && activeRoles.includes('admin')) return subCategories;
  return subCategories.filter(({ value }) => {
    if ('deal'.includes(value) && !activeRoles.includes('investment')) {
      return false;
    }
    return true;
  });
};

// pocs here is a list of human-ids
const isUserPoc = (pocs = []) => {
  // admin can edit everything
  const activeRoles = activeRolesOfLoggedInUser();
  if (activeRoles.length && activeRoles.includes('admin')) return true;
  const user = JSON.parse(localStorage.getItem('user'));
  return pocs.includes(user.id);
};

// Determines if the passed element is overflowing vertically
// Will temporarily modify the "overflow" style to detect this
// if necessary.
function checkOverflow(el) {
  if (!el) return false;
  let curOverflow = el.style.overflow;
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden';
  return el.clientHeight < el.scrollHeight;
}

// num = number of aruments to skip
// eg someFunc(...skipArguments(2), reqArg), better readibility
const skipArguments = (num) => new Array(num);

const getURLWithHTTPSProtocol = (url) => {
  return url.includes('http') ? url : `https://${url}`;
};

const formatDateTimeStrFromISO = (dateTimeStr) => {
  var dateTime = moment(dateTimeStr);
  var dateComponent = dateTime.format('MM-DD-YYYY');
  var timeComponent = dateTime.format('hh:mm A');
  return dateComponent + ' ' + timeComponent;
};

const dateWithoutTime = (dateTimeStr) => {
  let newDate = new Date(dateTimeStr);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
};

const dateOnlyComparison = (dateStr1, dateStr2) => {
  const date1 = dateWithoutTime(dateStr1);
  const date2 = dateWithoutTime(dateStr2);
  return +date1 === +date2;
};

// default => Oct 3rd, 22
const toReadableDateFormat = (date, format = 'MMM Do, YY') => {
  return moment(new Date(date)).format(format);
};

const actionItemsToList = (action_items) => {
  if (!action_items) return [];
  return action_items.split('|');
};

const filterContentToCodenames = async (orgToCodeName = {}, domain = document.body) => {
  function replaceNodeWithReactComponent(element, reactComponent) {
    const parent = document.createElement('div');
    ReactDOM.render(reactComponent, parent, () => {
      element.replaceWith(...Array.from(parent.childNodes));
    });
  }
  function replaceRecursively(element, map) {
    if (element.childNodes.length) {
      element.childNodes.forEach((child) => replaceRecursively(child, map));
    } else if (element && (element.textContent || element.value)) {
      let cont = element.textContent;
      let val = element.value;

      if (cont || val) {
        for (const key in map) {
          if (cont && cont.toLowerCase().includes(key)) {
            const filteredText = element.textContent.replace(key, map[key]);

            const elemWithViewLayer = (
              <Tooltip content={element.textContent}>{filteredText}</Tooltip>
            );
            replaceNodeWithReactComponent(element, elemWithViewLayer);
          }

          if (val && val.includes(key)) {
            // TODO: add popover for inputs too
            val = val.replace(key, map[key]);
          }
        }
      }
      element.textContent = cont ? cont : element.textContent;
      element.value = val ? val : element.value;
    }
  }

  setTimeout(() => replaceRecursively(domain, orgToCodeName), 1);
};

const replaceStringArray = (originalString, finds, replaces) => {
  /*
  originalString: String
  finds: [str], list of strings to be replaced
  replaces: [str], list of replacement string for the same-indexed string in finds
  Replace occurences of different strings, assume finds.length === replaces.length
  */
  let replaceString = originalString;
  for (var i = 0; i < finds.length; i++) {
    replaceString = replaceString.replaceAll(finds[i], replaces[i]);
  }
  return replaceString;
};

const allowIfHasRole = (obj, roleList) => {
  if (!obj || !(obj.role || obj.roles)) {
    throw new Error('Object needs a role/roles field');
  }

  if (roleList.includes('admin') && obj.role == 'admin') {
    return true;
  }

  const roleMatching = roleList.map((role) => {
    const roleInfo = obj?.roles[role];
    if (roleInfo && roleInfo.is_active) {
      return true;
    }
  });

  // true if there is at least one true in list
  return roleMatching.reduce((acc, curr) => acc || curr, false);
};

export {
  makeShowable,
  validateEmail,
  packNestedObj,
  unpackNestedObj,
  updateObjInArray,
  getAllowedRoutes,
  getAllowedSubCategories,
  activeRolesOfLoggedInUser,
  isUserPoc,
  checkOverflow,
  skipArguments,
  getURLWithHTTPSProtocol,
  formatDateTimeStrFromISO,
  dateOnlyComparison,
  toReadableDateFormat,
  actionItemsToList,
  filterContentToCodenames,
  replaceStringArray,
  allowIfHasRole,
  getActiveRolesForUser,
};
