import { request } from './http-service';

const getEvents = (pagination, searchName, searchEmail) => {
  let searchObj = { target_name: searchName?.trim(), target_email: searchEmail?.trim() };
  let filteredSearchObj = Object.keys(searchObj).reduce((acc, item) => {
    if (searchObj[item]) {
      acc[item] = searchObj[item];
    }
    return acc;
  }, {});
  const urlParams = new URLSearchParams({
    ...pagination,
    ...filteredSearchObj,
  }).toString();
  return request({
    url: `/all-events/?${urlParams}`,
    method: 'get',
  });
};

const getLeaderBoard = (user_ids) => {
  return request({
    url: `/analytics/leaderboard/${user_ids}`,
    method: 'get',
  });
};

const getDataTablesRecordCount = () => {
  return request({
    url: `/analytics/fetch_data_count_records`,
    method: 'get',
  });
};

const getEventsByTitle = (event_title) => {
  const urlParams = new URLSearchParams({ event_title }).toString();
  return request({ url: `/event/title?${urlParams}`, method: 'get' });
};

const getEventTypes = () => {
  return request({
    url: `/event_type/`,
    method: 'get',
  });
};

const createEvent = (data) => {
  return request({
    url: `/event/`,
    method: 'post',
    data,
  });
};

const updateEvent = (event_id, data) => {
  return request({
    url: `/event/${event_id}`,
    method: 'put',
    data,
  });
};

const deleteEvent = (event_id) => {
  return request({
    url: `/event/${event_id}`,
    method: 'delete',
  });
};

const getPendingMeetingFeedbacks = () => {
  return request({
    url: '/pending_feedbacks',
    method: 'get',
  });
};

const getOrgDomains = (domains = null) => {
  const urlParams = new URLSearchParams({ attendee_domains: domains.join(',') }).toString();
  return request({
    url: `/org_domain_list?${urlParams}`,
    method: 'get',
  });
};

export {
  getEvents,
  getLeaderBoard,
  getEventsByTitle,
  getEventTypes,
  getDataTablesRecordCount,
  createEvent,
  updateEvent,
  deleteEvent,
  getPendingMeetingFeedbacks,
  getOrgDomains,
};
