import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const fileTypesConfig = {
  pdf: ['application/pdf'],
  doc: ['application/msword'],
  docx: ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
};

const { Dragger } = Upload;

const FileUpload = ({
  title,
  showModal,
  setShowModal,
  onUpdate,
  uploadUrl,
  validFileTypes = [],
}) => {
  const [customFileList, setCustomFileList] = useState([]);
  const allowedFileTypes = validFileTypes.reduce((acc, fileType) => {
    acc = [...acc, ...fileTypesConfig[fileType]];
    return acc;
  }, []);
  const validFileTypesString = validFileTypes.join(', ');

  const props = {
    name: 'uploaded_file',
    multiple: false,
    action: uploadUrl,
    method: 'PUT',
    withCredentials: true,
    fileList: customFileList,
    beforeUpload: (file) => {
      if (!file.type) return Upload.LIST_IGNORE;
      if (!allowedFileTypes.includes(file.type)) {
        message.error(
          `${file.name} is not a valid file, 
                    only filetype ${validFileTypesString} are accepted`
        );
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status, response } = info.file;
      setCustomFileList(info.fileList);
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        onUpdate(response);
        setShowModal(false);
        setCustomFileList([]);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        setCustomFileList([]);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
      setCustomFileList([]);
    },
  };

  return (
    <Modal
      title={title}
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      forceRender
    >
      <p className="ant-upload-hint" style={{ textAlign: 'center' }}>
        {`Accepted file formats ${validFileTypesString}`}
      </p>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Note: Uploading a file will overwrite the existing file if it exists
        </p>
      </Dragger>
    </Modal>
  );
};

FileUpload.propTypes = {
  title: PropTypes.string,
  showModal: PropTypes.bool,
  onUpdate: PropTypes.func,
  setShowModal: PropTypes.func,
  uploadUrl: PropTypes.string,
  validFileTypes: PropTypes.array,
};

export default FileUpload;
