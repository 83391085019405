import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Form, Empty } from 'antd';
import { Comment, Input, Button, Content } from 'Components';
import './notes-window.less';

const NotesWindow = ({ notesInfo, updateNotes }) => {
  const [notes, setNotes] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (notesInfo) {
      setNotes(notesInfo);
    }
  }, [notesInfo]);

  useEffect(() => {
    var elem = document.getElementById('notes-list');
    elem.scrollTop = elem.scrollHeight;
  }, [notes]);

  const addNotes = async (values) => {
    let tempNotes = [...notes];
    tempNotes.push(values);
    await updateNotes(tempNotes);
    form.resetFields();
  };

  return (
    <div className="notes-window">
      <Content className="notes-list" id="notes-list">
        {notes.length ? (
          notes.map((notes_info, index) => <Comment key={index} {...notes_info}></Comment>)
        ) : (
          <Empty description={'No Notes'} />
        )}
      </Content>
      <Content style={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <Form form={form} onFinish={addNotes}>
          <Form.Item name="title" noStyle>
            <Input name="title" placeholder="Title" />
          </Form.Item>
          <Form.Item name="body" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
            <Input style={{ marginTop: 5 }} placeholder="Body" rows={4} type="multiline" />
          </Form.Item>
          <Form.Item noStyle>
            <Row justify="space-between" style={{ marginTop: 5 }}>
              <Button size="middle" htmlType="submit" type="primary">
                Add Notes
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Content>
    </div>
  );
};

NotesWindow.propTypes = {
  notesInfo: PropTypes.array,
  updateNotes: PropTypes.func,
};

export default NotesWindow;
