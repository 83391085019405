import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { StoreProvider } from 'Context';
import { authReducer } from 'Reducers';

let initialState = {
  isAuthenticated: !!localStorage.getItem('user'),
  stale: true,
};
try {
  initialState.user = JSON.parse(localStorage.getItem('user'));
  initialState.meta = JSON.parse(localStorage.getItem('meta'));
} catch (e) {
  initialState.user = {};
  initialState.meta = {};
}

ReactDOM.render(
  <StoreProvider initialState={initialState} reducer={authReducer}>
    <App />
  </StoreProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
