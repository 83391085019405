import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { formatDateTimeStrFromISO } from 'Utils';
import { Tag, Typography, Row, Col, Divider, Descriptions, Collapse, Empty } from 'antd';

import { MehTwoTone, SmileTwoTone, FrownTwoTone } from '@ant-design/icons';

const { Text } = Typography;
const { Panel } = Collapse;

const meeting_color_to_actual_color = {
  red: 'red',
  yellow: '#FCCC1A',
  green: '#B2D732',
};

const colorToEmoji = (color) => {
  const common_style = {
    fontSize: '20px',
  };
  if (color == 'red')
    return (
      <FrownTwoTone twoToneColor={meeting_color_to_actual_color[color]} style={common_style} />
    );
  if (color == 'yellow')
    return <MehTwoTone twoToneColor={meeting_color_to_actual_color[color]} style={common_style} />;
  if (color == 'green')
    return (
      <SmileTwoTone twoToneColor={meeting_color_to_actual_color[color]} style={common_style} />
    );
};

const generateOrgLinks = (ids, idToOrgMap) => {
  return ids.map((id) => {
    const org = idToOrgMap ? idToOrgMap[id] : undefined;
    const name = org ? org.name || 'Name Missing' : 'Name Missing';
    const tag_color = org ? 'blue' : null;
    return (
      <a href={`/org/${id}`} key={`${id}_link`} target="_blank" rel="noreferrer">
        <Tag color={tag_color}>{`${name}`}</Tag>
      </a>
    );
  });
};

const generateReferrals = (ids, idToUserMap) => {
  return ids.map((id) => {
    const user = idToUserMap ? idToUserMap[id] : undefined;
    const name = user ? `${user?.first_name} ${user?.last_name}` || 'Name Missing' : 'Name Missing';
    const tag_color = user ? 'blue' : null;
    return (
      <a href={`/human/${id}`} key={`${id}_link`} target="_blank" rel="noreferrer">
        <Tag color={tag_color}>{`${name}`}</Tag>
      </a>
    );
  });
};

const generateParticipantLinks = (ids, participants) => {
  // generate links for multiple destination ids given details of each
  // user id in participants
  return ids.map((user_id, index) => {
    const pname = participants ? participants[index].displayName : 'No Name';
    return (
      <Link to={`/human/${user_id}`} key={`${user_id}_link`} target="_blank" rel="noreferrer">
        {pname + (index < participants?.length ? ', ' : '')}
      </Link>
    );
  });
};

const postMeetingUpdateDetails = (data, eventTime, idToUserMap, idToOrgMap) => {
  const {
    participant_ids,
    meeting_title,
    meeting_notes,
    attendee_notes,
    meeting_with,
    meeting_source,
    meeting_about,
    meeting_happened,
    meeting_color,
    interested_portfolio,
    referral,
    orgs_source,
    regular_meeting_purpose,
  } = data.other_data;
  // const meetingIsConfidential = get(data, 'meta.is_confidential', false);
  const meetingHappened = meeting_happened === 'Yes';
  const participantLinks = participant_ids
    ? data.other_data.participant_ids.map((pId) => {
        const user = idToUserMap[pId];
        const email =
          user && 'emails' in user && user.emails?.length ? user.emails[0].email : 'No Name';
        const full_name = user
          ? user.first_name.includes('#MISSINGVALUE')
            ? email
            : `${user.first_name} ${user.last_name}`
          : 'No Name';
        const tag_color = user ? 'blue' : null;

        const anchor_props = {};

        if (user) {
          anchor_props['href'] = `/human/${pId}`;
          anchor_props['target'] = '_blank';
          anchor_props['rel'] = 'noreferrer';
        }

        return (
          <a key={`pId:${pId}`} {...anchor_props}>
            <Tag color={tag_color}>{`${full_name}`}</Tag>
          </a>
        );
      })
    : [];

  // TODO: @zhi Include referral in the future
  return (
    <>
      <Divider strong>
        {meeting_title} <br />{' '}
      </Divider>
      {meeting_color ? (
        <>
          <Row justify="center">
            <Text type="secondary">How did the meeting go?</Text>
          </Row>
          <Row justify="center">{colorToEmoji(meeting_color)}</Row>
          <br />
        </>
      ) : null}
      {meetingHappened ? (
        <>
          <Descriptions layout="vertical" size="small" bordered>
            <Descriptions.Item span={3} label="Meeting notes">
              {meeting_notes || 'N/A'}
            </Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
          <Descriptions layout="vertical" size="small" bordered>
            <Descriptions.Item label="Meeting with">{meeting_with || 'SKIPPED'}</Descriptions.Item>
            <Descriptions.Item label="Meeting about">
              {meeting_about || 'SKIPPED'}
            </Descriptions.Item>
            {meeting_source ? (
              <Descriptions.Item label="Source Lead">{meeting_source || 'N/A'}</Descriptions.Item>
            ) : null}
            {regular_meeting_purpose ? (
              <Descriptions.Item label="Purpose">
                {regular_meeting_purpose || 'N/A'}
              </Descriptions.Item>
            ) : null}
            <Descriptions.Item span={3} label="Attendee notes">
              {attendee_notes || 'N/A'}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <>
            {participantLinks.length > 0 ? (
              <Collapse>
                <Panel header="Links">
                  <div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
                    <Row>
                      <Col span={8}>
                        <Row justify="center">
                          <Text strong>Participants</Text>
                        </Row>
                        <Row justify="center">{participantLinks}</Row>
                      </Col>

                      {interested_portfolio ? (
                        <Col span={8}>
                          <Row justify="center">
                            <Text strong>Interested Portfolios</Text>
                          </Row>
                          <Row justify="center">
                            <ul>{generateOrgLinks(interested_portfolio, idToOrgMap)}</ul>
                          </Row>
                        </Col>
                      ) : null}

                      {referral ? (
                        <Col span={8}>
                          <Row justify="center">
                            <Text strong>Referral</Text>
                          </Row>
                          <Row justify="center">
                            <ul>{generateReferrals(referral, idToUserMap)}</ul>
                          </Row>
                        </Col>
                      ) : null}

                      {orgs_source ? (
                        <Col span={8}>
                          <Row justify="center">
                            <Text strong>Organization Referrals</Text>
                          </Row>
                          <Row justify="center">
                            <ul>{generateOrgLinks(orgs_source, idToOrgMap)}</ul>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </Panel>
              </Collapse>
            ) : null}
          </>
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

const defaultResponse = (data, eventTime) => {
  return get(data, 'other_data.detail', false) ? (
    data.other_data.detail
  ) : (
    <>
      Meeting with{' '}
      {data.destination_id ? (
        <Link to={`/human/${data.destination_id}`} target="_blank" rel="noreferrer">
          {get(data, 'other_data.invitees[0].name', 'Someone')}
        </Link>
      ) : (
        generateParticipantLinks(data.destination_ids, data.other_data.attendees)
      )}{' '}
      ({eventTime},{formatDateTimeStrFromISO(data.other_data.end_time)})
    </>
  );
};

const generateDetails = (data, eventTime, idToUserMap, eventTypeIdToName, idToOrgMap) => {
  const eventType = eventTypeIdToName.current[data.type_id];
  if (eventType == 'post_meeting_update') {
    return postMeetingUpdateDetails(data, eventTime, idToUserMap, idToOrgMap);
  } else {
    return defaultResponse(data, eventTime, idToUserMap);
  }
};

export { generateDetails };
