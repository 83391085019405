import { Link as RouterLink } from 'react-router-dom';
import { InterviewStages } from 'Constants';

const interviewsColumnConfig = [
  {
    title: 'Company',
    dataIndex: 'client_name',
    key: 'client_name',
    width: 200,
    render: (_, record) => (
      <RouterLink to={`/org/${record.destination_organization_id}`}>
        {record.other_data.client_name}
      </RouterLink>
    ),
    ellipsis: true,
    sorter: (a, b) =>
      a.other_data.client_name < b.other_data.client_name
        ? -1
        : a.other_data.client_name > b.other_data.client_name
        ? 1
        : 0,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: 'Position Name',
    dataIndex: 'position_name',
    render: (_, record) => (
      <RouterLink to={`/job/${record.other_data.position_id}`}>
        {record.other_data.position_name}
      </RouterLink>
    ),
    key: 'position_name',
    width: 120,
  },
  {
    title: 'Interview Stage',
    dataIndex: 'stage',
    render: (name, record) => record.other_data.stage,
    key: 'stage',
    width: 120,
    filters: InterviewStages.map((interviewStage) => {
      return { text: interviewStage, value: interviewStage };
    }),
    onFilter: (value, record) => record.other_data.stage === value,
    sorter: (a, b) =>
      InterviewStages.indexOf(a.other_data.stage) - InterviewStages.indexOf(b.other_data.stage),
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    title: 'Stale',
    dataIndex: 'is_stale',
    width: 60,
    render: (name, record) => (record.other_data.is_stale ? 'Yes' : 'No'),
    key: 'is_stale',
    filters: [
      {
        text: 'Yes',
        value: 'Yes',
      },
      {
        text: 'No',
        value: 'No',
      },
    ],
    onFilter: (value, record) => (record.other_data.is_stale ? 'Yes' : 'No') === value,
    defaultFilteredValue: ['No'],
  },
];

export { interviewsColumnConfig };
