import { request } from './http-service';

const createPosition = (data) => {
  return request({
    url: `/position/`,
    method: 'post',
    data,
  });
};

const getPositionsByOrg = (orgId) => {
  return request({
    url: `/positions/${orgId}`,
    method: 'get',
  });
};

const getPositionProfile = (positionId) => {
  return request({
    url: `/position/${positionId}`,
    method: 'get',
  });
};

const updatePosition = (positionId, data) => {
  return request({
    url: `/position/${positionId}`,
    method: 'put',
    data,
  });
};

const getPositionEventLogs = () => {
  return {
    name: 'Anduril',
  };
};

const getPositions = (pagination, sorter = {}, meta) => {
  const urlParams = new URLSearchParams({
    ...pagination,
    ...sorter,
  }).toString();
  const { filters } = meta;
  let query = '';
  if (filters) {
    query = filters.length ? encodeURIComponent(JSON.stringify(meta)) : '';
  }
  return request({
    url: `/positions/?${urlParams}&q=${query}`,
    method: 'get',
  });
};

export {
  createPosition,
  getPositionsByOrg,
  getPositionProfile,
  getPositionEventLogs,
  updatePosition,
  getPositions,
};
