const leverSchema = [
  {
    label: 'Lever Posting URL (Optional)',
    placeholder: 'https://jobs.lever.co/example/uuid',
    name: 'lever_posting_url',
    type: 'text',
    required: false,
    span: 24,
  },
];

export { leverSchema };
