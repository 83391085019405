import React from 'react';
import { Table as AntTable, Empty } from 'antd';
import PropTypes from 'prop-types';
import './table.less';

const Table = ({ columns, dataSource, noDataText = 'No Data', ...rest }) => {
  return (
    <AntTable
      className="hc-table"
      {...rest}
      bordered
      columns={columns}
      dataSource={dataSource}
      locale={{
        emptyText: <Empty style={{ margin: '0px auto' }} description={noDataText} />,
      }}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  dataSource: PropTypes.array,
  noDataText: PropTypes.string,
};

export default Table;
