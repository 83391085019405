import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { Button } from 'Components';
import { createEvent, getEventTypes } from 'Api/event-service';
import { generateFormFields } from 'Helpers';
import { packNestedObj } from 'Utils';

const AddEventModal = ({
  showModal,
  setShowModal,
  eventFormSchema,
  updateEntityEvents,
  srcType,
  srcId,
  destType,
  destId,
}) => {
  const [addEventForm] = Form.useForm();

  const addNewEvent = async (values) => {
    try {
      const eventTypes = await getEventTypes();
      const eventTypeNameToId = {};
      for (const eventType of eventTypes.data) {
        eventTypeNameToId[eventType.name] = eventType.id;
      }

      values = {
        ...values,
        source_type: srcType,
        source_id: srcId,
        destination_type: destType,
        destination_id: destId,
        event_type_id: eventTypeNameToId[values.event_type],
        meta: {},
        'other_data::event_time': values['other_data::event_time'].format('MM-DD-YYYY hh:mm A'),
      };

      const formattedForBe = packNestedObj(values, 'other_data');
      await createEvent(formattedForBe);
      updateEntityEvents();
      addEventForm.resetFields();
      message.success('Event created successfully', 2);
      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title="Add New Event"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      forceRender
    >
      <Form form={addEventForm} onFinish={addNewEvent} layout="vertical">
        <Row gutter={[24]}>{generateFormFields(eventFormSchema, addEventForm)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Add Event
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddEventModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  eventFormSchema: PropTypes.array,
  updateEntityEvents: PropTypes.func,
  srcType: PropTypes.string,
  srcId: PropTypes.number,
  destType: PropTypes.string,
  destId: PropTypes.number,
};

export default AddEventModal;
