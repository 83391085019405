import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components';
import { createOrg, updateOrg } from 'Api/org-service';
import { useStoreValue } from 'Context';
import { generateFormFields } from 'Helpers';
import { newPortfolioFormSchema } from 'Schemas/company-schema.js';

const AddPortfolioModal = ({ showModal, setShowModal, redirect = false, setResult = null }) => {
  const history = useHistory();
  const [{ user }] = useStoreValue();
  const [addPortfolioform] = Form.useForm();

  const addNewPortfolio = async (values) => {
    try {
      const {
        data: { id },
      } = await createOrg(values, user.id);
      await updateOrg(id, {
        roles: { portfolio: { is_active: true, engagement_stage: values.engagement_stage } },
      });
      message.success('Org created successfully', 1);
      message.loading('Setting up profile', 2);
      setShowModal(false);
      if (redirect) history.push(`/org/${id}`);
      if (setResult) {
        setResult({
          label: `${values['name']}`,
          value: id,
          key: id,
        });
      }
      addPortfolioform.resetFields();
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title="Add New Portfolio"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={addPortfolioform}
        onFinish={addNewPortfolio}
        layout="vertical"
        initialValues={{ jobStatus: 'ACTIVE', positionType: 'Full-Time' }}
      >
        <Row gutter={[24]}>{generateFormFields(newPortfolioFormSchema)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Next
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

AddPortfolioModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  redirect: PropTypes.bool,
  setResult: PropTypes.func,
};

export default AddPortfolioModal;
