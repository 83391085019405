import { request } from './http-service';

const getTransitionValues = (meta = {}) => {
  /*
  meta: {
    entity_type: str, // 'User' or 'Organization' 
    entity_id: int, 
    enum_field: str, // 'DealProfile.stage' 
    trigger: str, // 'first_meeting_to_audit_pending'
  }
  */
  const urlParams = new URLSearchParams({
    ...meta,
  }).toString();

  return request({
    url: `/state/subtransition?${urlParams}`,
    method: 'get',
  });
};

const setTransitionValues = (data = {}) => {
  /*
  data: {
    entity_id: Optional[int] = None,
    entity_type: Optional[str] = None,
    enum_field: Optional[str] = None,
    trigger: Optional[str] = None,
    form: Optional[dict] = None,
  }
  */
  return request({
    url: `/state/subtransition`,
    method: 'post',
    data,
  });
};

const getConfig = (meta = {}) => {
  /*
  meta: {
    entity_type: str, // 'User' or 'Organization' 
    enum_field: str, // 'DealProfile.stage'
  }
  */
  const urlParams = new URLSearchParams({
    ...meta,
  }).toString();
  return request({
    url: `/state/config?${urlParams}`,
    method: 'get',
    headers: { make_event: true },
  });
};

const makeTransition = (meta = {}) => {
  /*
  meta: {
    entity_id: Optional[int] = None,
    entity_type: Optional[str] = None,
    enum_field: Optional[str] = None,
    trigger: Optional[str] = None,
  }
  */
  const urlParams = new URLSearchParams({
    ...meta,
  }).toString();
  return request({
    url: `/state/transition?${urlParams}`,
    method: 'get',
  });
};

export { getTransitionValues, setTransitionValues, getConfig, makeTransition };
