import _ from 'lodash';
import axios from 'axios';
import { message } from 'antd';

const API_URL = process.env.REACT_APP_API_URL;
// enables consistent error handling for all requests
async function request(options) {
  try {
    return await axios.request({
      baseURL: API_URL,
      withCredentials: true,
      ...options,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    if (e?.response?.status === 401) {
      message.destroy();
      message.error('Please login & authenticate yourself');
      message.loading('Redirecting to login page');
      return window.location.replace('/login');
    }
    if (e?.response?.status === 403) {
      return message.error('Action Forbidden');
    }
    if (!e.response) console.error(e.toJSON());
    const throwablesPaths = ['response.data.error', 'response.data.message', 'message'];
    const toThrow = _.at(e, throwablesPaths).find((x) => x);
    throw new Error(toThrow);
  }
}

export { request };
