import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Popover, Row, Space, Switch } from 'antd';
import { Tag, Input } from 'Components';
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { validateEmail } from 'Utils';

const AllEmails = ({ canEdit, allEmails, handleValueChange }) => {
  const [newInputVisible, setNewInputVisible] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const inputTag = useRef(null);

  useEffect(() => {
    if (newInputVisible) {
      inputTag.current.focus();
    }
  }, [newInputVisible]);

  const handleClose = (removedEmail) => {
    const filteredEmails = allEmails.filter(({ email }) => email !== removedEmail);
    handleValueChange('emails', filteredEmails);
  };

  const showInput = () => {
    setNewInputVisible(true);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setNewEmail(value);
  };

  const handleInputConfirm = () => {
    if (validateEmail(newEmail)) {
      const tempEmails = [...allEmails];
      tempEmails.push({ email: newEmail, is_primary: null, is_verified: false });
      handleValueChange('emails', tempEmails);
      setNewInputVisible(false);
      setNewEmail('');
    }
    return setNewInputVisible(false);
  };

  const handlePropertyChange = (propertyName, value, email) => {
    const updatedEmailSet = allEmails.map((item) => {
      if (item.email !== email && item.is_primary && propertyName === 'is_primary' && value) {
        return { ...item, [propertyName]: false };
      }
      if (item.email === email) {
        return { ...item, [propertyName]: value };
      }
      return item;
    });
    handleValueChange('emails', updatedEmailSet);
  };

  return (
    <>
      {allEmails?.map(({ email, is_primary, is_verified, id }) => {
        const tagElem = (
          <Popover
            key={email}
            content={
              <Space direction="vertical" style={{ width: '100%' }}>
                <Row justify="space-between">
                  <label>Is Verified</label>
                  <Switch
                    checked={is_verified}
                    onChange={(checked) => handlePropertyChange('is_verified', checked, email)}
                  />
                </Row>
                <Row justify="space-between">
                  <label>Is Primary</label>
                  <Switch
                    disabled={is_primary}
                    checked={is_primary}
                    onChange={(checked) => handlePropertyChange('is_primary', checked, email)}
                  />
                </Row>
              </Space>
            }
            title="Actions"
            trigger="click"
          >
            <Tag
              icon={
                is_verified && is_primary ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : is_verified && !is_primary ? (
                  <CheckCircleOutlined />
                ) : !is_verified && is_primary ? (
                  <CheckOutlined />
                ) : null
              }
              size="middle"
              className="edit-tag"
              key={id}
              closable={!is_primary}
              onClose={(event) => event.preventDefault()}
              closeIcon={
                <Popconfirm
                  title="Are you sure to remove this email?"
                  onConfirm={() => handleClose(email)}
                  okText="Yes"
                  cancelText="No"
                >
                  <CloseOutlined style={{ marginLeft: 10 }} />
                </Popconfirm>
              }
              style={{ fontSize: '14px', padding: '3px 7px', margin: '3px' }}
            >
              {email}
            </Tag>
          </Popover>
        );
        return tagElem;
      })}
      {newInputVisible ? (
        <Input
          ref={inputTag}
          type="text"
          size="middle"
          className="tag-input"
          value={newEmail}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      ) : canEdit ? (
        <Tag
          style={{ fontSize: '14px', padding: '3px 7px', margin: '3px' }}
          className="site-tag-plus"
          onClick={showInput}
        >
          <PlusOutlined /> New Email
        </Tag>
      ) : null}
    </>
  );
};

AllEmails.propTypes = {
  canEdit: PropTypes.bool,
  allEmails: PropTypes.array,
  handleValueChange: PropTypes.func,
};

export default AllEmails;
