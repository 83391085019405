export const CODES_TO_SENTENCES = {
  forgot_password: {
    EMAIL_SENT: 'If we have your email in our system, we will send you a password-reset link',
    NOT_FOUND: 'Email Not Found',
  },
  reset_password: {
    NOT_FOUND: 'Uh oh, this looks like an invalid link',
    INVALID_CREDENTIALS: 'Uh oh, this looks like an invalid link',
    PASSWORD_UPDATED: 'Your password has been updated',
  },
  login: {
    LOGIN_SUCCESS: 'Login successful',
    NOT_FOUND: 'Incorrect email or password',
  },
  views: {
    VIEW_WITH_NAME_ALREADY_EXISTS: 'View with a same name already exist, choose a different name',
    VIEW_CREATED: 'New view created',
    VIEW_UPDATED: 'View has been updated',
    VIEW_DELETED: 'View has been deleted',
    NOT_FOUND: 'View not found',
  },
  assessment_form: {
    USER_CREATED: 'Assessment has been created',
  },
  company_profile: {
    USER_NOT_FOUND: 'User not found, create Client POC on Candidates page',
  },
  add_poc: {
    Forbidden: 'Unauthorize Access',
  },
};
