import React, { useEffect, useState } from 'react';
import './pending-forms.less';
import { Row, Col, message, Result, Skeleton, Space } from 'antd';
import { useLocation, Link } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import {
  Card,
  PageHeader,
  Content,
  Button,
  FloatingBugReportLink,
  ProfileTimeline,
  SplashPage,
} from 'Components';
import { getOutstandingTasks } from 'Api/tasks-service';
import GeneratePendingForm from './generate-pending-form';
import { submitFeedback, getMeetingFormInfo } from 'Api/common-service';
import GoogleCalendarIcon from 'Assets/images/google-calendar-icon.png';
import HcIcon from 'Assets/images/hc-logo-black.png';
import { HumanEventConfig } from 'Constants';
import { newHumanEventFormSchema } from 'Schemas/event-schema';
import { getOrgDomains } from '../../api/event-service';

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const PendingForms = () => {
  let query = useQuery();
  const token = query.get('token');
  const single_feedback = query.get('single_feedback');
  const isSingleFeedback = single_feedback === 'True';
  const [meetingData, setMeetingData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMoreForms, setIsLoadingMoreForms] = useState(false);
  const [isLoadingAllForms, setIsLoadingAllForms] = useState(false);
  const [activeForm, setActiveForm] = useState(0);
  const [numFeedbackForms, setNumFeedbackForms] = useState(0);
  const [numFormsDisplayed, setNumFormsDisplayed] = useState(10);

  const [showSplashPage, setShowSplashPage] = useState(false);
  const [splashContent, setSplashContent] = useState(null);
  const [splashSub, setSplashSub] = useState('Your submissions for this meeting:');

  const [orgDomains, setOrgDomains] = useState({});

  useEffect(async () => {
    if (isSingleFeedback) {
      return fetchSingleFeedback();
    }
  }, []);

  useEffect(async () => {
    if (!isSingleFeedback) {
      await fetchAllPendingFeedbacks();
    }
  }, [numFormsDisplayed]);

  useEffect(async () => {
    if (Object.keys(orgDomains).length === 0 || meetingData.length > 0) {
      let domains = meetingData
        .map((meeting) =>
          meeting['other_data']['attendees'].map((attendee) =>
            'email' in attendee && attendee['email'] ? attendee['email'].split('@').pop() : null
          )
        )
        .flat()
        .filter((x) => x);
      domains = domains.filter(
        (element, index) => domains.indexOf(element) === index && !element.includes('human.capital')
      );

      let { data } = await getOrgDomains(domains);
      setOrgDomains(data);
    }
  }, [meetingData]);

  const fetchAllPendingFeedbacks = async () => {
    try {
      let { headers, data } = await getOutstandingTasks(token, {
        page_num: 1,
        page_size: numFormsDisplayed,
      });
      const { total_records: total } = JSON.parse(headers['x-pagination']);

      setMeetingData(data.tasks);
      setNumFeedbackForms(total);
      if (numFormsDisplayed > total) {
        setNumFormsDisplayed(total);
      }
      setIsLoading(false);
      setIsLoadingMoreForms(false);
      setIsLoadingAllForms(false);
    } catch (err) {
      message.error(err.message);
    }
  };

  const fetchSingleFeedback = async () => {
    try {
      const { data } = await getMeetingFormInfo(token);
      setMeetingData([data]);
      setIsLoading(false);
    } catch (err) {
      message.error(err.message);
    }
  };

  const formatForBe = (data) => {
    Object.keys(data)
      .filter((key) => key.includes('interested_portfolio') || key.includes('referral'))
      .forEach((key) => {
        data[key] = data[key].map(({ value }) => value);
      });
  };

  const submitFeedbackForm = async (data) => {
    try {
      if (!data.meeting_id) {
        throw new Error('Failed To Submit: Meeting Form Info Not Found');
      }
      formatForBe(data);
      const otherData = { meeting_title: data.meeting_title };
      await submitFeedback({ ...data, ...otherData }, token);
      if (!isSingleFeedback) {
        message.success('Form submitted successfully');
      }
      await fetchAllPendingFeedbacks();
      return 'success';
    } catch (err) {
      setIsLoading(false);
      if (err.message === 'Feedback already has submission') {
        setSplashSub('Your form was submitted already.');
        return 'resubmission';
      }
      return err.message;
    }
  };

  const handleShowMore = async () => {
    let increasedDisplayCount = numFormsDisplayed + 10;
    if (increasedDisplayCount > numFeedbackForms) {
      increasedDisplayCount = numFeedbackForms;
    }
    setNumFormsDisplayed(increasedDisplayCount);
    setIsLoadingMoreForms(true);
  };

  const handleShowAll = async () => {
    setNumFormsDisplayed(numFeedbackForms);
    setIsLoadingAllForms(true);
  };

  return (
    <div>
      <div className="center-fullscreen">
        <SplashPage
          showing={showSplashPage}
          content={splashContent}
          subOverride={splashSub}
          captionSize={24}
        />
      </div>
      <div>
        <Row>
          <Col span={24}>
            <PageHeader
              style={{ boxShadow: 'rgb(57 63 72 / 10%) 0px 1px 9px' }}
              sticky
              title={
                <>
                  <Link to={'/'}>
                    <img
                      style={{ height: '30px', marginRight: 10 }}
                      src={HcIcon}
                      alt="Pending Feedback"
                    />
                  </Link>
                  +
                  <img
                    style={{ height: '30px', margin: '0px 10px' }}
                    src={GoogleCalendarIcon}
                    alt="Pending Feedback"
                  />
                  Meeting Feedback
                </>
              }
              extra={
                !isSingleFeedback
                  ? [
                      <Space key="num-forms-text" align="center">
                        Showing {numFormsDisplayed} of {numFeedbackForms} Forms
                      </Space>,
                      <Button
                        disabled={numFormsDisplayed >= numFeedbackForms}
                        onClick={handleShowMore}
                        loading={isLoadingMoreForms}
                        key="show-more-btn"
                        size="middle"
                      >
                        Show More
                      </Button>,
                      <Button
                        disabled={numFormsDisplayed >= numFeedbackForms || isLoadingMoreForms}
                        onClick={handleShowAll}
                        loading={isLoadingAllForms}
                        key="show-all-btn"
                        size="middle"
                      >
                        Show All
                      </Button>,
                    ]
                  : null
              }
            ></PageHeader>
            <Skeleton active loading={isLoading}>
              <Content className="pending-forms-content">
                {meetingData.length ? (
                  isSingleFeedback ? (
                    // JIT
                    <Card
                      spacing="large"
                      className="active-form"
                      style={{ maxWidth: 950, margin: '0px auto' }}
                    >
                      <GeneratePendingForm
                        eventData={meetingData[0]}
                        onFormSubmit={(data) => {
                          setIsLoading(true);
                          submitFeedbackForm(data)
                            .then((status) => {
                              switch (status) {
                                case 'success':
                                  setSplashContent(
                                    <div className="pending-form-timeline">
                                      <ProfileTimeline
                                        eventConfig={HumanEventConfig}
                                        eventFormSchema={newHumanEventFormSchema}
                                        entityType={'user'}
                                        entityId={meetingData[0]['feedback_provider_id']}
                                        hideHeader={true}
                                        showLoading={true}
                                        limit={10}
                                        meetingId={meetingData[0]['id']}
                                      />
                                    </div>
                                  );
                                  break;
                                case 'resubmission':
                                  message.info('Feedback submitted already');
                                  break;
                                default:
                                  message.error(status);
                                  return;
                              }
                              if (isSingleFeedback) {
                                setShowSplashPage(true);
                              }
                            })
                            .catch((err) => message.error(err));
                        }}
                        viewOnly={false}
                        orgDomains={orgDomains}
                      />
                    </Card>
                  ) : (
                    // EOD
                    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 950: 2, 1050: 3 }}>
                      <Masonry columnsCount={3} gutter={'20px'}>
                        {meetingData.map((item, index) => (
                          <Card
                            spacing="small"
                            key={item.id}
                            className={activeForm === index ? 'active-form' : null}
                            style={{ maxHeight: '2600px', overflowY: 'auto' }}
                            onClick={() => setActiveForm(index)}
                          >
                            <GeneratePendingForm
                              eventData={item}
                              onFormSubmit={submitFeedbackForm}
                              viewOnly={false}
                              orgDomains={orgDomains}
                            />
                          </Card>
                        ))}
                      </Masonry>
                    </ResponsiveMasonry>
                  )
                ) : (
                  <div>
                    <Result
                      status="success"
                      title={<>Seems like you have completed all the Feedbacks</>}
                    />
                  </div>
                )}
                <FloatingBugReportLink />
              </Content>
            </Skeleton>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PendingForms;
