import React, { useState } from 'react';
import Card from '../card/card';
import { Tooltip, Row } from 'antd';
import { ReadOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
// import { Collapse } from 'antd';
// const { Panel } = Collapse;

const tooltipMessages = {
  canRead: 'This user/role has read access',
  canWrite: 'This user/role has write access',
  cannotRead: 'This user/role does not have read access',
  cannotWrite: 'This user/role does not have write access',
};

const Policy = ({ policy, description }) => {
  const { read, write } = policy;

  return (
    <Row align="middle" justify="middle">
      <div>{description}</div>
      <div style={{ float: 'right' }}>
        <Tooltip title={read ? tooltipMessages.canRead : tooltipMessages.cannotRead}>
          <ReadOutlined style={{ color: read ? 'green' : 'red', marginLeft: '10px' }} />
        </Tooltip>

        <Tooltip title={write ? tooltipMessages.canWrite : tooltipMessages.cannotWrite}>
          <EditOutlined style={{ color: write ? 'green' : 'red', marginLeft: '10px' }} />
        </Tooltip>
      </div>
    </Row>
  );
};

const displayPoliciesList = (policiesList, descriptionKey, emptyMessage = 'No Data') => {
  if (policiesList && policiesList.length > 0) {
    return policiesList.map((policy) =>
      Policy({ description: policy[descriptionKey], policy: policy.policy })
    );
  } else {
    return emptyMessage;
  }
};

const SecurityPolicy = ({ title, user_policies, role_policies }) => {
  const [activeTabKey, setActiveTabKey] = useState('roles');
  const tabList = [
    {
      key: 'roles',
      tab: 'Roles',
    },
    {
      key: 'users',
      tab: 'Users',
    },
  ];

  const contentList = {
    roles: displayPoliciesList(role_policies, 'role_table_name', 'No role policies defined'),
    users: displayPoliciesList(user_policies, 'user_id', 'No user policies defined'),
  };

  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  return (
    <Card
      title={title || 'Security Policy'}
      extra={
        <Tooltip title={`Who will have access to this information?`}>
          <InfoCircleOutlined />
        </Tooltip>
      }
      onTabChange={onTabChange}
      activeTabKey={activeTabKey}
      tabList={tabList}
      style={{ minWidth: '250px' }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
};

Policy.propTypes = {
  policy: PropTypes.any,
  description: PropTypes.string,
};

SecurityPolicy.propTypes = {
  title: PropTypes.string,
  user_policies: PropTypes.any,
  role_policies: PropTypes.any,
};

export default SecurityPolicy;
