import { UserRoles } from 'Constants';
import { capitalize } from 'lodash';

const filterSchema = {
  user: {
    subTypes: {
      key: 'role',
      allowed_values: UserRoles.map((role) => {
        let display_hint = role;
        display_hint = display_hint.replace('_', ' ').replace('-', ' ');
        return { display_hint_primary: capitalize(display_hint), value: role };
      }),
      placeholder: 'Select role to filter humans',
    },
    default: [
      {
        name: 'first_name',
        label: 'First Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'last_name',
        label: 'Last Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'email',
        label: 'Email',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hc_poc',
        label: 'HC Poc',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'gender',
        label: 'Gender',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'dob',
        label: 'DOB',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'linkedins',
        label: 'Linkedins',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'phone',
        label: 'Phone',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'grad_year',
        label: 'Grad Year',
        filterOptions: [],
        type: 'number',
      },
      {
        name: 'school',
        label: 'School',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'action_items',
        label: 'Action Items',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'worked_at',
        label: 'Worked At',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'current_employer',
        label: 'Current Employer',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'created_at',
        label: 'Created At',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'updated_at',
        label: 'Updated At',
        filterOptions: [],
        type: 'date',
      },
      { name: 'Currently Working On', label: 'Currently Working On', type: 'string' },
      {
        name: 'Describe Your Interest In Further Education',
        label: 'Interest In Further Education',
        type: 'string',
      },
      { name: 'Entrepreneur Monitoring', label: 'Entrepreneur Monitoring', type: 'string' },
      { name: 'Entrepreneurship Persona', label: 'Entrepreneurship Persona', type: 'string' },
      {
        name: 'Estimated Timeline To Founding',
        label: 'Estimated Timeline To Founding',
        type: 'string',
      },
      { name: 'Fit For Our Team?', label: 'Fit For Our Team?', type: 'string' },
      { name: 'Gap Semester/Year', label: 'Gap Semester/Year', type: 'string' },
      { name: 'How Did You Meet?', label: 'How Did You Meet?', type: 'string' },
      { name: 'Ideal Role', label: 'Ideal Role', type: 'string' },
      { name: 'Industries', label: 'Industries', type: 'string' },
      { name: 'Investment Team Status', label: 'Investment Team Status', type: 'string' },
      { name: 'Looking For?', label: 'Looking For?', type: 'string' },
      { name: 'Permanent Principal Tags', label: 'Permanent Principal Tags', type: 'string' },
      { name: 'Preferred Pronoun', label: 'Preferred Pronoun', type: 'string' },
      { name: 'Recommendation Review', label: 'Recommendation Review', type: 'string' },
      { name: 'Recommendation Score', label: 'Recommendation Score', type: 'string' },
      { name: 'Relationship Strength', label: 'Relationship Strength', type: 'string' },
      { name: 'Top Motivations', label: 'Top Motivations', type: 'string' },
      { name: 'Member Persona', label: 'Member Persona', type: 'string' },
      { name: 'Batch Tag', label: 'Batch Tag', type: 'string' },
      { name: 'Gem Projects', label: 'Gem Projects', type: 'string' },
      { name: 'Event Type', label: 'Event Type', type: 'string' },
      { name: 'Event Range', label: 'Event Range', type: 'date' },
      { name: 'Event Title', label: 'Event Title', type: 'string' },
      {
        name: 'has_potential_duplicates',
        label: 'Has Potential Duplicates',
        type: 'string',
      },
    ],
    future_founder: [
      {
        name: 'fundraising_stage',
        label: 'Fundraising Stage',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'bullishness_scores',
        label: 'Bullishness Scores',
        filterOptions: [],
        type: 'number',
      },
      {
        name: 'Future Founder Occupation',
        label: 'Future Founder Occupation',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Future Founder Stage',
        label: 'Future Founder Stage',
        filterOptions: [],
        type: 'string',
      },
      { name: 'Founder Score', label: 'Founder Score', type: 'string' },
    ],
    executive: [
      {
        name: 'Executive Functions',
        label: 'Executive Functions',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Executive Geographies',
        label: 'Executive Geographies',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Executive Projects',
        label: 'Executive Projects',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Executive Key Experiences',
        label: 'Executive Key Experiences',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Ethnic Diversity',
        label: 'Ethnic Diversity',
        filterOptions: [],
        type: 'string',
      },
    ],
    member: [
      {
        name: 'Matchmaking Status',
        label: 'Matchmaking Status',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'Outreach Communication Log',
        label: 'Outreach Communication Log',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'member_intake_date',
        label: 'Member Intake Date',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'member_form_date',
        label: 'Member Form Date',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'Engineering Rank',
        label: 'Engineering Rank',
        filterOptions: [],
        type: 'string',
      },
      {
        name: '2021 Ops Tags',
        label: '2021 Ops Tags',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  portfolio: {
    default: [
      {
        name: 'name',
        label: 'Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hc_poc',
        label: 'HC Poc',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'action_items',
        label: 'Action Items',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'engagement_stage',
        label: 'Engagement Stage',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'affinity',
        label: 'Affinity',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'trust',
        label: 'Trust',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'ops',
        label: 'Ops',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hiring',
        label: 'Hiring',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  deal: {
    default: [
      {
        name: 'name',
        label: 'Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'deal_status',
        label: 'Deal Status',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'deal_stage',
        label: 'Deal Stage',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hc_poc',
        label: 'HC Poc',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'action_items',
        label: 'Action Items',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  analytics: {
    default: [
      {
        name: 'hc_poc',
        label: 'POC',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'from',
        label: 'From Date',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'to',
        label: 'To Date',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'metric',
        label: 'Metric',
        filterOptions: [],
        type: 'select',
      },
    ],
  },
  organization: {
    default: [
      {
        name: 'name',
        label: 'Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hc_client_status',
        label: 'Client Status',
        filterOptions: [],
        type: 'string',
      },
      /*
      {
        name: 'type',
        label: 'Type',
        filterOptions: [],
        type: 'string',
      },
      */
      {
        name: 'action_items',
        label: 'Action Items',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'hc_poc',
        label: 'HC Poc',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'website',
        label: 'Website',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'year_founded',
        label: 'Year Founded',
        filterOptions: [],
        type: 'number',
      },
    ],
  },
  intro: {
    default: [
      {
        name: 'client_name',
        label: 'Client',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'member_name',
        label: 'Member',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'principal_name',
        label: 'Principal',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'position_name',
        label: 'Position',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'client_status',
        label: 'Client Status',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'member_status',
        label: 'Member Status',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'intro_email_sent',
        label: 'Intro Email Sent',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'is_stale',
        label: 'Stale',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  interview: {
    default: [
      {
        name: 'client_name',
        label: 'Client',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'member_name',
        label: 'Member',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'principal_name',
        label: 'Principal',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'position_name',
        label: 'Position',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'stage',
        label: 'Stage',
        filterOptions: [],
        type: 'select',
      },
      {
        name: 'batch_tag',
        label: 'Batch Tag',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  position: {
    default: [
      {
        name: 'name',
        label: 'Name',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'org_name',
        label: 'Company',
        filterOptions: [],
        type: 'string',
      },
      {
        name: 'target_start_date',
        label: 'Target Date',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'status',
        label: 'Status',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
  meeting: {
    default: [
      {
        name: 'from',
        label: 'From',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'to',
        label: 'To',
        filterOptions: [],
        type: 'date',
      },
      {
        name: 'associates',
        label: 'Associates',
        filterOptions: [],
        type: 'string',
      },
    ],
  },
};

export { filterSchema };
