import React, { useEffect, useState } from 'react';
import { Row, Col, Radio, message, Menu } from 'antd';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, FilterSection, PageHeader, Content, Kanban } from 'Components';
import { PortfolioKanbanCard, PortfolioKanbanModal } from './components/portfolio-kanban-modal';
import PortfolioList from './components/portfolio-list';
import AddPortfolioModal from './components/add-portfolio-modal';
import { useStoreValue } from 'Context';
import { pullAndSetViews, exportCsv } from 'Helpers';
import { makeShowable } from 'Utils';
import { exportPortfolios } from '../../api/org-service';

const Portfolios = () => {
  const [views, setViews] = useState([]);
  const [currentView, setCurrentView] = useState({});
  const [portfolioKanbanData, setPortfolioKanbanData] = useState({});
  const [listType, setListType] = useState('table');
  const [defaultViewId, setDefaultViewId] = useState(null);
  const [showAddPortfolioModal, setShowAddPortfolioModal] = useState(false);
  const [{ user }] = useStoreValue();

  const entity = 'portfolio';

  const exportPortfoliosCsv = async () => {
    try {
      let { data: csvData } = await exportPortfolios({
        filters: currentView.filters,
        entity_type: 'organization',
      });
      if (csvData) {
        exportCsv(csvData, `Portfolios_${new Date().toLocaleDateString('en-US')}.csv`);
        message.success('Portfolio CSV downloading...');
      } else {
        message.warning('No Data!');
      }
    } catch (e) {
      message.error(makeShowable(e.message, 'Something went wrong while exporting CSV'));
    }
  };

  const handleMoreMenuClick = ({ key }) => {
    if (key === 'export-csv') {
      exportPortfoliosCsv();
    }
  };

  const moreMenu = (
    <Menu onClick={handleMoreMenuClick}>
      <Menu.Item key="export-csv" icon={<DownloadOutlined />}>
        Export CSV
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    pullAndSetViews(entity, user?.id, setViews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Col
          span={5}
          style={{
            borderRight: '1px solid lightgrey',
          }}
        >
          <FilterSection
            entity={entity}
            views={views}
            setViews={setViews}
            moreMenu={moreMenu}
            currentView={currentView}
            setCurrentView={setCurrentView}
            defaultViewId={defaultViewId}
            setDefaultViewId={setDefaultViewId}
          />
        </Col>

        <Col
          span={19}
          style={{
            maxHeight: 'calc(100vh - 70px)',
            overflowY: 'scroll',
          }}
        >
          <PageHeader
            sticky
            title={'💼  Portfolio'}
            extra={[
              <Button
                key="add-new"
                size="middle"
                type="primary"
                onClick={() => setShowAddPortfolioModal(true)}
              >
                <PlusOutlined /> Add new
              </Button>,
              <Radio.Group
                key="kanban_select"
                onChange={({ target }) => setListType(target?.value)}
                value={listType}
                style={{ marginLeft: '5px' }}
              >
                <Radio.Button value="table">Table</Radio.Button>
                <Radio.Button value="kanban">Kanban</Radio.Button>
              </Radio.Group>,
            ]}
          ></PageHeader>
          <Content>
            {listType === 'kanban' ? (
              <Kanban
                currentView={currentView}
                kanbanType={'Organization'}
                kanbanEnumField={'PortfolioProfile.engagement_stage'}
                kanbanData={portfolioKanbanData}
                setKanbanData={setPortfolioKanbanData}
                customizeKanbanCard={PortfolioKanbanCard}
                customizeKanbanModal={PortfolioKanbanModal}
              />
            ) : (
              <PortfolioList
                entity={entity}
                views={views}
                setViews={setViews}
                currentView={currentView}
                setCurrentView={setCurrentView}
                enableQuickSearch={true}
                setDefaultViewId={setDefaultViewId}
                setPortfolioKanbanData={setPortfolioKanbanData}
              />
            )}
          </Content>
        </Col>
      </Row>
      <AddPortfolioModal
        showModal={showAddPortfolioModal}
        setShowModal={setShowAddPortfolioModal}
      />
    </div>
  );
};

export default Portfolios;
