import { Tag } from 'Components';
import { StarOutlined } from '@ant-design/icons';
import { getMeetingURL } from 'Api/tasks-service';
import { get } from 'lodash';
import { formatDateTimeStrFromISO } from 'Utils';
import renderHTML from 'react-render-html';

const getOrganizerEmail = (task) => {
  if (!task?.other_data) return 'N/A';
  return task?.other_data.organizer?.email;
};

const getWhen = (task) => {
  return task.other_data
    ? { start_time: task?.other_data?.start_time, end_time: task?.other_data?.end_time }
    : {};
};

const getParticipants = (task) => {
  return task?.other_data?.attendees;
};

const verifyDescriptionHTML = (descriptionHTML) => {
  if (descriptionHTML instanceof Array) {
    return descriptionHTML.every((htmlTag) => !htmlTag.type?.includes('@'));
  }
  return true;
};

const getDescription = ({ other_data = {} }) => {
  if (other_data?.description) {
    const pattern = /<mailto.+>/gi;
    other_data.description = other_data.description.replaceAll(pattern, '');
  }
  let descriptionHTML = renderHTML(
    other_data.description ? other_data.description : 'No Description'
  );
  if (!verifyDescriptionHTML(descriptionHTML)) {
    descriptionHTML = other_data.description ? other_data.description : 'No Description';
  }
  return <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>{descriptionHTML}</div>;
};

const routeToFeedbackPage = (task) => {
  getMeetingURL(task.id)
    .then((resp) => {
      const url = `${resp.data}&single_feedback=True`;
      window.open(url, '_blank', 'noopener,noreferrer');
    })
    .catch((err) => console.log(err));
  return;
};

const displayTime = (start_time, end_time) => {
  const str_start_time = formatDateTimeStrFromISO(start_time);
  const str_end_time = formatDateTimeStrFromISO(end_time);
  const same_date = str_start_time.split(' ')[0] == str_end_time.split(' ')[0];

  if (str_start_time && str_end_time && same_date) {
    const date = str_start_time.split(' ')[0];
    const start = str_start_time.slice(str_start_time.indexOf(' '), str_start_time.length);
    const end = str_end_time.slice(str_end_time.indexOf(' '), str_end_time.length);
    return `${date} ${start} - ${end}`;
  }
  return `${str_start_time} - ${str_end_time}`;
};

const columns = (emailToUserMap, phoneToUserMap) => {
  const isInternalParticipant = (participant) => {
    const user = emailToUserMap[participant.email];

    const emailCondition = participant.email?.endsWith('@human.capital');
    const profileCondition =
      user && (user?.roles?.investment?.is_active || user?.roles?.principal?.is_active);
    const isInternal = emailCondition || profileCondition;
    return isInternal;
  };

  // TODO: @Ishaq - set color of tag based on completion
  const participantDisplay = (task, participant, organizer_email) => {
    const isOrganizer = (participant, organizer_email) => {
      return organizer_email == participant.email;
    };

    let user = emailToUserMap[participant.email];
    if (participant.phoneNumber && !user) {
      user = phoneToUserMap[participant.phoneNumber];
    }

    const profileURL = user?.id ? `/human/${user.id}` : '#';

    const anchorProps = user?.id
      ? {
          href: profileURL,
          target: '_blank',
          rel: 'noreferrer',
        }
      : {};
    const tagProps = {
      color: user?.id ? 'blue' : null,
      icon: isOrganizer(participant, organizer_email) ? <StarOutlined /> : null,
    };

    const user_full_name =
      user?.first_name || user?.last_name
        ? `${user?.first_name ? user.first_name : ''} ${user?.last_name ? user.last_name : ''}`
        : null;

    const info =
      user_full_name && !user_full_name.includes('#MISSINGVALUE')
        ? user_full_name
        : participant.email
        ? participant.email
        : participant.phoneNumber;

    return (
      <a {...anchorProps} key={`meeting-${task.other_data.id}participant-${participant.email}`}>
        <Tag {...tagProps}>{`${info}`}</Tag>
      </a>
    );
  };

  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        return (
          <div
            style={{ maxHeight: '200px', overflowY: 'scroll' }}
            onClick={() => routeToFeedbackPage(record)}
          >
            <a>{`${title}`}</a>
          </div>
        );
      },
      type: 'string',
      width: 220,
      fixed: 'left',
      isDefault: true,
    },
    {
      title: 'Time',
      dataIndex: 'when',
      key: 'time',
      render: (_, record) => {
        const when = getWhen(record);
        const start_time = get(when, 'start_time', '');
        const end_time = get(when, 'end_time', '');
        return when ? displayTime(start_time, end_time) : `Time not provided`;
      },
      type: 'string',
      width: 220,
      isDefault: true,
    },
    {
      title: 'Internal Participants',
      dataIndex: 'participants',
      key: 'participants',
      render: (_, record) => {
        const organizer_email = getOrganizerEmail(record);
        const participants = getParticipants(record);
        return (
          <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
            {participants
              .filter(isInternalParticipant)
              .map((p) => participantDisplay(record, p, organizer_email, emailToUserMap))}
          </div>
        );
      },
      type: 'string',
      width: 220,
      isDefault: true,
    },
    {
      title: 'External Participants',
      dataIndex: 'participants',
      key: 'participants',
      render: (_, record) => {
        const participants = getParticipants(record);
        const organizer_email = getOrganizerEmail(record);
        return (
          <div style={{ maxHeight: '200px', overflowY: 'scroll' }}>
            {participants
              .filter((p) => !isInternalParticipant(p))
              .map((p) => participantDisplay(record, p, organizer_email, emailToUserMap))}
          </div>
        );
      },
      type: 'string',
      width: 220,
      isDefault: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => getDescription(record),
      type: 'string',
      width: 220,
      isDefault: true,
    },
  ];
};

export { columns };
