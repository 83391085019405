import React, { useState } from 'react';
import { Form, Row, Col, Typography, Divider } from 'antd';
import { EyeTwoTone, EyeInvisibleOutlined, LoginOutlined } from '@ant-design/icons';
import { Button, Card, Input, FloatingBugReportLink } from 'Components';
import PropTypes from 'prop-types';
import { useStoreValue } from 'Context';
import { Link, useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { login, onGoogleLogin } from 'Api/auth-service';
import { makeShowable } from 'Utils';
import Logo from 'Assets/images/hc-logo-black.png';

const { Text } = Typography;

const Login = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [, dispatch] = useStoreValue();
  const history = useHistory();

  // provide params as shown below to redirect to desired url post login
  // history.push('/login', { from: previousUrl })
  const redirectAfterLogin = props?.location?.state?.from || '/';

  const handleFormSubmit = async (data) => {
    setIsSubmitting(true);
    setError(null);
    try {
      const {
        data: { message: msg, data: userData },
      } = await login(data);
      if (msg === 'LOGGED_IN') {
        dispatch({ type: 'LOGIN', payload: { user: userData, stale: false } });
        history.push(redirectAfterLogin);
      }
    } catch (e) {
      setError(makeShowable(e.message, 'login'));
    }
    setIsSubmitting(false);
  };

  return (
    <div className="auth_container">
      <img
        style={{ height: '40px', position: 'absolute', top: 50, left: 50 }}
        src={Logo}
        alt="Human Capital Logo"
      />
      <Card
        title={
          <span style={{ fontWeight: 200, fontSize: 30, letterSpacing: 2 }}>
            <LoginOutlined style={{ color: '#52c41a' }} /> Login
          </span>
        }
        style={{ width: 350 }}
      >
        <Form layout="vertical" name="normal_login" onFinish={handleFormSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Please enter your email' },
              { type: 'email', message: 'Please enter a valid email' },
            ]}
          >
            <Input type="email" size="large" placeholder="eg: abc@email.com" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <Input
              type="password"
              size="large"
              placeholder="Password"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item>
            <Row justify="space-between">
              <Col>
                <Button
                  style={{ backgroundColor: 'black', color: 'white' }}
                  disabled={isSubmitting}
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  Log in
                </Button>
              </Col>
              <Col>
                <Row>
                  <Link style={{ color: 'black' }} className="anchor_link" to="create_account">
                    Create an account
                  </Link>
                </Row>
                <Row>
                  <Link style={{ color: 'black' }} className="anchor_link" to="forgot-password">
                    Forgot password?
                  </Link>
                </Row>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <Divider orientation="center">OR</Divider>
        <GoogleLogin
          width={300}
          size="large"
          onSuccess={async (authInfo) => {
            try {
              const {
                data: { message: msg, data: userData },
              } = await onGoogleLogin(authInfo);
              if (msg === 'LOGGED_IN') {
                dispatch({ type: 'LOGIN', payload: { user: userData, stale: false } });
                history.push(redirectAfterLogin);
              }
            } catch (e) {
              setError(makeShowable(e.message, 'login'));
            }
          }}
          onError={() => {
            setError('Google sign-in failed');
          }}
          auto_select
          useOneTap
        />
        {error ? <Text type="danger">{error}</Text> : null}
      </Card>
      <FloatingBugReportLink />
    </div>
  );
};

Login.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

export default Login;
