import React from 'react';
import PropTypes from 'prop-types';
import { Row, Modal, Form, Space, message } from 'antd';
import { Button } from 'Components';
import { leverSchema } from 'Schemas/export-csv-formats-schema';
import { generateFormFields } from 'Helpers';

const ExportLever = ({ showModal, setShowModal, exportCsv }) => {
  const [exportLeverForm] = Form.useForm();

  const exportLeverCSV = async (values) => {
    try {
      const { lever_posting_url } = values;
      const exportLeverCSVEventFlag = true;
      exportCsv('Lever', { lever_posting_url: lever_posting_url }, exportLeverCSVEventFlag);
      setShowModal(false);
    } catch (err) {
      console.log(err.message);
      message.error(err.message || 'Something went wrong');
    }
  };

  return (
    <Modal
      title="Export Lever Format CSV"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
    >
      <Form
        form={exportLeverForm}
        onFinish={exportLeverCSV}
        layout="vertical"
        initialValues={{
          lever_posting_url: '',
        }}
      >
        <Row gutter={[24]}>{generateFormFields(leverSchema)}</Row>
        <Row justify="end">
          <Space>
            <Button type="primary" htmlType="submit">
              Export
            </Button>
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  );
};

ExportLever.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  exportCsv: PropTypes.func,
};

export default ExportLever;
