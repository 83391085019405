import React from 'react';
import { Button as AntdButton } from 'antd';
import PropTypes from 'prop-types';
import './button.less';

const Button = ({ children, size = 'large', ...rest }) => {
  return (
    <AntdButton size={size} {...rest}>
      {children}
    </AntdButton>
  );
};

Button.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
