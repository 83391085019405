import { request } from './http-service';

const getMeta = () => {
  return request({
    url: `/meta/`,
    method: 'get',
  });
};

const searchEntities = (searchText, opts = {}) => {
  const urlParams = new URLSearchParams({ q: searchText, ...opts }).toString();
  return request({
    url: `/common-search?${urlParams}`,
    method: 'get',
  });
};

// this is quick_search across views
const quickSearchWithinView = (searchText, current_view_id) => {
  return request({
    url: `/entities/quick-search?search_text=${searchText}&view_id=${current_view_id}`,
    method: 'get',
    headers: {
      make_event: false,
    },
  });
};

// quick search for dropdowns & search bars
const quickSearchByEntity = (searchText, entity) => {
  return request({
    url: `/quick-search/${entity}?search_text=${searchText}`,
    method: 'get',
  });
};

const submitFeedback = (formData, token) => {
  return request({
    url: `/submit-feedback`,
    method: 'post',
    data: { ...formData },
    ...(token
      ? {
          headers: {
            Authorization: 'Bearer ' + token,
            'x-api-key': 'ignore',
          },
        }
      : {}),
  });
};

const getMeetingFormInfo = (token) => {
  const urlParam = new URLSearchParams({ token }).toString();
  return request({
    url: `/meeting_form_info?${urlParam}`,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + token,
      'x-api-key': 'ignore',
    },
  });
};

const generateDeeplinkToken = (data = {}) => {
  const urlParam = new URLSearchParams({ data: JSON.stringify(data) }).toString();
  return request({
    url: `/generate_deeplink_token?${urlParam}`,
    method: 'get',
  });
};

const getDeeplinkInfo = (token) => {
  const urlParam = new URLSearchParams({ token }).toString();
  return request({
    url: `/get_deeplink_info?${urlParam}`,
    method: 'get',
  });
};

const authorizeCalendar = () => {
  return request({
    url: `/set_calendar_id`,
    method: 'get',
  });
};

export {
  getMeta,
  searchEntities,
  quickSearchWithinView,
  quickSearchByEntity,
  submitFeedback,
  getMeetingFormInfo,
  generateDeeplinkToken,
  getDeeplinkInfo,
  authorizeCalendar,
};
