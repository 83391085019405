import React from 'react';
import { PageHeader as AntdPageHeader } from 'antd';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './page-header.less';

const PageHeader = ({ sticky = false, className, ...props }) => {
  return (
    <AntdPageHeader className={cx(className, sticky ? `sticky` : '')} {...props}></AntdPageHeader>
  );
};

PageHeader.propTypes = {
  sticky: PropTypes.bool,
  className: PropTypes.string,
};

export default PageHeader;
