import React from 'react';
import { Link } from 'react-router-dom';
import { FeatureTable } from 'Components';
import { getPortfolio, updateOrg } from 'Api/org-service';
import { Input, message, Select, Tooltip } from 'antd';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => <Link to={`/org/${record.id}`}>{name}</Link>,
    type: 'string',
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    type: 'string',
    width: 100,
  },
  {
    title: 'Client status',
    dataIndex: 'hc_client_status',
    key: 'hc_client_status',
    type: 'string',
    width: 150,
  },
  {
    title: '✅  Action Items',
    dataIndex: 'action_items',
    key: 'action_items',
    type: 'string',
    render: (action_items = '', record) => {
      return (
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          defaultValue={action_items?.length ? action_items?.split('|') : []}
          tokenSeparators={['|']}
          size="middle"
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Actions"
          disabled={!record?.editable}
          tagRender={({ label, onClose }) => {
            return (
              <Tooltip title={label}>
                <span className="ant-select-selection-item">
                  <span className="ant-select-selection-item-content">{label}</span>
                  <span className="ant-select-selection-item-remove" onClick={onClose}>
                    X
                  </span>
                </span>
              </Tooltip>
            );
          }}
          onChange={async (value) => {
            try {
              await updateOrg(record.id, { action_items: value.join('|') });
              message.success('Action items updated');
            } catch (err) {
              message.error(err?.message || 'Something went wrong');
            }
          }}
        ></Select>
      );
    },
    width: 250,
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
    type: 'string',
    render: (website) => <a href={`${website}`}>{website}</a>,
    width: 250,
  },
  {
    title: 'Team size',
    dataIndex: 'size_and_team_breakdown',
    key: 'size_and_team_breakdown',
    type: 'string',
    width: 300,
  },
  {
    title: 'Year founded',
    dataIndex: 'year_founded',
    key: 'year_founded',
    type: 'string',
    width: 130,
  },
  {
    title: 'Quick notes',
    dataIndex: 'quick_notes',
    key: 'quick_notes',
    type: 'string',
    render: (quick_notes, record) => {
      return (
        <Input.TextArea
          style={{ width: '100%' }}
          onBlur={async (e) => {
            const newValue = e.target.value;
            if (newValue === record.quick_notes) {
              return;
            }
            try {
              await updateOrg(record.id, { quick_notes: newValue });
              record.quick_notes = newValue;
              message.success('Company info updated successfully');
            } catch (err) {
              message.error(err?.message || 'Something went wrong');
            }
          }}
          defaultValue={quick_notes}
          disabled={!record?.editable}
        />
      );
    },
    width: 250,
  },
];

const PortfolioList = ({ ...rest }) => {
  return (
    <FeatureTable
      columns={columns}
      getList={getPortfolio}
      opts={{ read_hydrated_relationships: false }}
      {...rest}
    />
  );
};

export default PortfolioList;
