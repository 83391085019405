import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Space,
  Dropdown,
  Tooltip,
  Menu,
  Checkbox,
  message,
  Input as AntInput,
} from 'antd';
import {
  MoreOutlined,
  StarFilled,
  StarOutlined,
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  LockOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { omit } from 'lodash';
import { useStoreValue } from 'Context';
import { Input, Button } from 'Components';
import { v4 as uuid_v4 } from 'uuid';
import './filter-section.less';

const ViewsListItem = ({
  viewItem,
  addDefaultView,
  deleteCurrentView,
  updateViewData,
  createViewData,
  pullLatestViews,
  setDefaultViewId,
}) => {
  const [renameViewMode, setRenameViewMode] = useState(false);
  const [newViewName, setNewViewName] = useState('');
  const [{ user }] = useStoreValue();
  const { SubMenu } = Menu;
  const userId = user.id;

  const isAuthor = viewItem.source_user_id === userId || false;
  const isDefault = isAuthor && viewItem.is_author_default;
  // const publicAccess = isAuthor || viewItem.permissions.is_public;
  const canDelete = isAuthor || viewItem.permissions.can_public_delete;
  const canUpdate = isAuthor || viewItem.permissions.can_public_update;

  useEffect(() => {
    if (renameViewMode) setNewViewName(viewItem.name);
  }, [renameViewMode]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setNewViewName(value);
  };

  const renameView = async () => {
    const viewToUpdate = { ...viewItem, name: newViewName };
    await updateViewData(viewToUpdate);
    setRenameViewMode(false);
    pullLatestViews();
  };

  const viewMenu = (viewId) => (
    <Menu mode="vertical">
      <Menu.Item
        disabled={isDefault}
        key="default"
        onClick={({ domEvent: event }) => {
          event.stopPropagation();
          addDefaultView(viewId);
        }}
      >
        <StarOutlined /> Set as default
      </Menu.Item>
      <Menu.Item
        disabled={!canUpdate}
        key="rename"
        onClick={({ domEvent: event }) => {
          event.stopPropagation();
          setRenameViewMode(true);
        }}
      >
        <EditOutlined /> Rename
      </Menu.Item>
      <Menu.Item disabled={!canDelete} key="delete" onClick={() => deleteCurrentView(viewId)}>
        <DeleteOutlined /> Delete
      </Menu.Item>
      <Menu.Item
        key="duplicate"
        onClick={async () => {
          const resultView = await createViewData(
            omit({ ...viewItem, name: `${viewItem.name} copy (${uuid_v4().substr(0, 5)})` }, [
              'id',
              'permissions',
              'is_author_default',
            ])
          );
          setDefaultViewId(resultView.id);
        }}
      >
        <CopyOutlined /> Duplicate
      </Menu.Item>
      <SubMenu icon={<LockOutlined />} title="Permissions" key="view-permission">
        <Menu.Item key="public-access">
          <Checkbox
            disabled={!isAuthor}
            checked={viewItem.permissions.is_public}
            onChange={async ({ target }) => {
              const isChecked = target.checked;
              let viewItemToUpdate = { ...viewItem };
              if (!isChecked) {
                viewItemToUpdate['permissions'] = {
                  is_public: false,
                  can_public_update: false,
                  can_public_delete: false,
                };
              } else {
                viewItemToUpdate['permissions']['is_public'] = true;
              }
              await updateViewData(viewItemToUpdate);
              message.success('Permissions updated');
            }}
          >
            Allow Public Access
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="allow-edit">
          <Checkbox
            disabled={!(isAuthor && viewItem.permissions.is_public)}
            checked={viewItem.permissions.can_public_update}
            onChange={async ({ target }) => {
              const isChecked = target.checked;
              let viewItemToUpdate = { ...viewItem };
              viewItemToUpdate['permissions']['can_public_update'] = isChecked;
              await updateViewData(viewItemToUpdate);
              message.info('Permissions updated');
            }}
          >
            Allow Edit
          </Checkbox>
        </Menu.Item>
        <Menu.Item key="allow-delete">
          <Checkbox
            disabled={!(isAuthor && viewItem.permissions.is_public)}
            checked={viewItem.permissions.can_public_delete}
            onChange={async ({ target }) => {
              const isChecked = target.checked;
              let viewItemToUpdate = { ...viewItem };
              viewItemToUpdate['permissions']['can_public_delete'] = isChecked;
              await updateViewData(viewItemToUpdate);
              message.info('Permissions updated');
            }}
          >
            Allow Delete
          </Checkbox>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );

  return (
    <Row justify="space-between" align="middle" style={{ flexGrow: 1 }}>
      <Col>
        {renameViewMode ? (
          <AntInput.Group compact style={{ padding: '10px 0px' }}>
            <Input
              size="middle"
              name="view name input"
              value={newViewName}
              onChange={handleInputChange}
              autoFocus
              style={{ width: 'calc(100% - 64px)', borderRadius: 4 }}
              defaultValue="git@github.com:ant-design/ant-design.git"
            />
            <Tooltip title="Save">
              <Button
                size="middle"
                icon={<CheckOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  renameView();
                }}
              />
            </Tooltip>
            <Tooltip title="Cancel Rename">
              <Button
                size="middle"
                icon={<CloseOutlined />}
                onClick={(event) => {
                  event.stopPropagation();
                  setRenameViewMode(false);
                }}
              />
            </Tooltip>
          </AntInput.Group>
        ) : (
          <h4 style={{ margin: 0, lineHeight: 1.3 }}>
            {viewItem.name} <br />
            <span style={{ fontWeight: 400, color: 'gray', fontSize: 12 }}>
              by {viewItem.source_user_name || 'System'}
            </span>
          </h4>
        )}
      </Col>
      <Col>
        <Space>
          {viewItem.source_user_id === userId && viewItem.is_author_default ? (
            <Tooltip title="Default View">
              <StarFilled style={{ color: '#e4ae34' }} />
            </Tooltip>
          ) : null}
          {!renameViewMode ? (
            <Dropdown
              trigger={['click']}
              placement="bottomRight"
              overlay={viewMenu(viewItem.id)}
              onClick={(event) => event.stopPropagation()}
            >
              <MoreOutlined style={{ fontSize: 20 }} />
            </Dropdown>
          ) : null}
        </Space>
      </Col>
    </Row>
  );
};

ViewsListItem.propTypes = {
  viewItem: PropTypes.object,
  addDefaultView: PropTypes.func,
  deleteCurrentView: PropTypes.func,
  updateViewData: PropTypes.func,
  createViewData: PropTypes.func,
  pullLatestViews: PropTypes.func,
  setDefaultViewId: PropTypes.func,
};

export default ViewsListItem;
