import { request } from './http-service';

const login = (data) => {
  data.email = data.email ? data.email.toLowerCase() : '';
  return request({
    url: '/login',
    method: 'post',
    data,
  });
};

const logout = () => {
  return request({
    url: '/logout',
    method: 'post',
  });
};

const createAccount = (data) => {
  return request({
    url: '/create_account',
    method: 'post',
    data,
  });
};

const forgotPassword = (data) => {
  data.email = data.email ? data.email.toLowerCase() : '';
  return request({
    url: '/forgot_password',
    method: 'post',
    data,
  });
};

const resetPassword = (data) => {
  return request({
    url: '/reset_password',
    method: 'post',
    data,
  });
};

const resendVerification = (data) => {
  return request({
    url: '/send_verification_email',
    method: 'post',
    data,
  });
};

const onGoogleLogin = async (auth_info) => {
  return request({
    url: '/google-auth',
    method: 'post',
    data: auth_info,
  });
};

export {
  login,
  logout,
  forgotPassword,
  resetPassword,
  resendVerification,
  createAccount,
  onGoogleLogin,
};
