import React from 'react';
import { Input, message, Select, Tooltip } from 'antd';
import { LinkedinFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Tag, AllPOCs, FeatureTable } from 'Components';
import { getUsers } from 'Api/user-service';
import { updateUser } from 'Api/user-service';

const custom_fields = [
  'Matchmaking Status',
  'Outreach Communication Log',
  'Engineering Rank',
  '2021 Ops Tags',
  'Future Founder Occupation',
  'Future Founder Stage',
  'Executive Functions',
  'Executive Geographies',
  'Executive Key Experiences',
  'Executive Projects',
  'Ethnic Diversity',
  'Additional Motivations',
  'Currently Working On',
  'Describe Your Interest In Further Education',
  'Entrepreneur Monitoring',
  'Entrepreneurship Persona',
  'Estimated Timeline To Founding',
  'Fit For Our Team?',
  'Founder Score',
  'Gap Semester/Year',
  'How Did You Meet?',
  'Ideal Role',
  'Industries',
  'Investment Team Status',
  'Looking For?',
  'Permanent Principal Tags',
  'Preferred Pronoun',
  'Recommendation Review',
  'Recommendation Score',
  'Relationship Strength',
  'Top Motivations',
  'Member Persona',
  'Batch Tag',
  'Gem Projects',
];

const HumanList = ({ ...rest }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (first_name, record) => (
        <Link
          target={'_blank'}
          to={`/human/${record.id}`}
        >{`${first_name} ${record.last_name}`}</Link>
      ),
      type: 'string',
      width: 220,
      fixed: 'left',
      isDefault: true,
    },
    {
      title: 'Emails',
      dataIndex: 'emails',
      key: 'primary_email',
      render: (emails) =>
        emails?.map((emailInfo) =>
          emailInfo.is_verified && emailInfo.is_primary ? (
            <Tag key={emailInfo.email} type="success">
              {emailInfo.email}
            </Tag>
          ) : (
            <Tag key={emailInfo.email}>{emailInfo.email}</Tag>
          )
        ),
      type: 'string',
      width: 300,
      isDefault: true,
    },
    {
      title: 'POCs',
      dataIndex: 'hc_pocs',
      key: 'hc_pocs',
      render: (hc_pocs, record) => <AllPOCs pocs={hc_pocs || []} entityId={record.id} />,
      type: 'string',
      width: 150,
      isDefault: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      type: 'string',
      width: 100,
      isDefault: true,
    },
    {
      title: 'LinkedIn',
      dataIndex: 'linkedins',
      key: 'linkedins',
      render: (linkedins) =>
        linkedins ? (
          <a
            href={
              linkedins.includes('www.linkedin.com')
                ? linkedins
                : `https://www.linkedin.com/in/${linkedins}/`
            }
            style={{ fontSize: 24, color: '#0277B5' }}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinFilled />
          </a>
        ) : null,
      type: 'string',
      width: 100,
      isDefault: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      type: 'string',
      width: 150,
      isDefault: true,
    },
    {
      title: 'Action Items',
      dataIndex: 'action_items',
      key: 'action_items',
      type: 'string',
      render: (action_items = '', record) => {
        return (
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            defaultValue={action_items?.length ? action_items?.split('|') : []}
            tokenSeparators={['|']}
            size="middle"
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Actions"
            disabled={!record.editable}
            tagRender={({ label, onClose }) => {
              return (
                <Tooltip title={label}>
                  <span className="ant-select-selection-item">
                    <span className="ant-select-selection-item-content">{label}</span>
                    <span className="ant-select-selection-item-remove" onClick={onClose}>
                      X
                    </span>
                  </span>
                </Tooltip>
              );
            }}
            onChange={async (value) => {
              try {
                await updateUser(record.id, { action_items: value.join('|') });
                message.success('Action items updated');
              } catch (err) {
                message.error(err?.message || 'Something went wrong');
              }
            }}
          ></Select>
        );
      },
      width: 250,
      isDefault: true,
    },
    {
      title: 'Quick Notes',
      dataIndex: 'quick_notes',
      key: 'quick_notes',
      type: 'string',
      allowClear: true,
      render: (quick_notes = '', record) => {
        return (
          <Input.TextArea
            style={{ width: '100%' }}
            onBlur={async (e) => {
              const newValue = e.target.value;

              // Don't send request when user clicks then unclicks quick notes
              if (newValue === '') {
                if (!record.quick_notes) return;
              }

              if (newValue === record.quick_notes) {
                return;
              }
              try {
                await updateUser(record.id, { quick_notes: newValue });
                record.quick_notes = newValue;
                message.success('User info updated successfully');
              } catch (err) {
                message.error(err?.message || 'Something went wrong');
              }
            }}
            defaultValue={quick_notes}
            disabled={!record.editable}
          />
        );
      },
      width: 250,
      isDefault: true,
    },
    ...custom_fields.map((fieldItem) => {
      return {
        title: fieldItem,
        dataIndex: fieldItem,
        key: fieldItem,
        width: 200,
        type: 'string',
        ellipsis: {
          showTitle: false,
        },
        render: (item) => {
          const itemStr = Array.isArray(item) ? item.join(', ') : item;
          return (
            <Tooltip placement="topLeft" title={itemStr}>
              {itemStr}
            </Tooltip>
          );
        },
      };
    }),
  ];

  return <FeatureTable columns={columns} getList={getUsers} enableQuickSearch {...rest} />;
};

export default HumanList;
