import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Space, Divider, Drawer, Result, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Table } from 'Components';
import { useStoreValue } from 'Context';
import CustomFieldPair from './custom-field-pair';
import { v4 as uuid_v4 } from 'uuid';
import { bulkUpdateUsers } from 'Api/user-service';

const columnsConfig = [
  {
    title: 'Name',
    dataIndex: 'first_name',
    key: 'name',
    render: (first_name, record) => (
      <Link to={`/human/${record.id}`}>{`${first_name} ${record.last_name}`}</Link>
    ),
    type: 'string',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
];

const BulkUpdateDrawer = ({ visible, setVisible, bulkData }) => {
  const newFieldSet = {
    key: uuid_v4(),
    fieldName: undefined,
    value: undefined,
  };
  const [customFieldList, setCustomFieldList] = useState([]);
  const [fieldsToUpdate, setFieldsToUpdate] = useState([newFieldSet]);
  const [readyToUpdate, setReadyToUpdate] = useState(false);
  const [{ meta: metaStore }] = useStoreValue();

  useEffect(() => {
    if (metaStore) {
      const { user: userCustomFieldList } = metaStore?.custom_fields || {};
      setCustomFieldList(userCustomFieldList);
    }
  }, [metaStore]);

  useEffect(() => {
    let isValidFieldSet = fieldsToUpdate.every((field) => {
      return field.fieldName && field.value;
    });
    setReadyToUpdate(isValidFieldSet);
  }, [fieldsToUpdate]);

  const removeFields = (key) => {
    let tempFieldsToUpdate = [...fieldsToUpdate];
    const indexToRemove = tempFieldsToUpdate.findIndex((item) => item.key === key);
    tempFieldsToUpdate.splice(indexToRemove, 1);
    setFieldsToUpdate(tempFieldsToUpdate);
  };

  const handleFieldChange = (key, fieldName, value) => {
    let tempFieldsToUpdate = [...fieldsToUpdate];
    const indexToUpdate = tempFieldsToUpdate.findIndex((item) => item.key === key);
    tempFieldsToUpdate[indexToUpdate][fieldName] = value;
    setFieldsToUpdate(tempFieldsToUpdate);
  };

  const bulkUpdateCandidates = async () => {
    try {
      const idsToUpdate = bulkData
        .map(({ id }) => (id ? parseInt(id) : undefined))
        .filter((item) => item);
      const customFields = fieldsToUpdate.reduce((acc, { fieldName, value }) => {
        acc[fieldName] = value;
        return acc;
      }, {});
      await bulkUpdateUsers({
        custom_fields: customFields,
        ids: [...idsToUpdate],
      });
      message.success('Users updated successfully');
    } catch (err) {
      console.log(err);
      message.error(err?.message || 'Something went wrong');
    }
  };

  return (
    <Drawer title="Bulk Update" visible={visible} width={500} onClose={() => setVisible(false)}>
      {fieldsToUpdate.map((fieldSet) => (
        <CustomFieldPair
          key={fieldSet.key}
          customFieldsList={customFieldList}
          setCustomFieldList={setCustomFieldList}
          remove={removeFields}
          fieldSet={fieldSet}
          handleFieldChange={handleFieldChange}
        />
      ))}
      <Row style={{ marginTop: 10 }}>
        <Button
          type="dashed"
          size="middle"
          onClick={() => {
            let tempArr = [...fieldsToUpdate];
            tempArr.push(newFieldSet);
            setFieldsToUpdate(tempArr);
          }}
          block
          icon={<PlusOutlined />}
        >
          Add field
        </Button>
      </Row>
      <Row style={{ marginTop: 20 }} justify="end">
        <Space>
          <Button
            type="primary"
            disabled={!readyToUpdate}
            onClick={bulkUpdateCandidates}
            size="middle"
          >
            {`Update ${bulkData?.length} Members`}
          </Button>
          <Button onClick={() => setVisible(false)} size="middle">
            Cancel
          </Button>
        </Space>
      </Row>
      <Divider />
      {bulkData?.length ? (
        <>
          <h4>Glance at candidates to be updated</h4>
          <Table rowKey={({ id }) => id} columns={columnsConfig} dataSource={bulkData} />
        </>
      ) : (
        <Result status="warning" title="Kindly upload a valid csv file" />
      )}
    </Drawer>
  );
};

BulkUpdateDrawer.propTypes = {
  visible: PropTypes.bool,
  bulkData: PropTypes.array,
  setVisible: PropTypes.func,
};

export default BulkUpdateDrawer;
