import { IntroStages } from 'Constants';
import { Link as RouterLink } from 'react-router-dom';

const newIntroFormSchema = [
  {
    label: 'Select Candidate',
    name: 'source_user_id',
    type: 'search',
    search_entity: 'user',
    placeholder: 'Search candidate name',
    required: true,
    span: 24,
  },
  {
    label: 'Select Company',
    name: 'destination_organization_id',
    type: 'search',
    search_entity: 'organization',
    placeholder: 'Search company name',
    required: true,
    span: 24,
  },
  {
    label: 'Client Status',
    name: 'other_data::client_status',
    type: 'select',
    initial_value: 'HIDDEN',
    allowed_values: IntroStages.map((value) => {
      return { value };
    }),
    span: 12,
  },
  {
    label: 'Member Status',
    name: 'other_data::member_status',
    type: 'select',
    initial_value: IntroStages[0],
    allowed_values: IntroStages.map((value) => {
      return { value };
    }),
    span: 12,
  },
];

const introsColumnConfig = [
  {
    title: 'Company',
    dataIndex: 'client_name',
    key: 'client_name',
    width: 200,
    render: (_, record) => (
      <RouterLink to={`/org/${record.destination_organization_id}`}>
        {record.other_data.client_name}
      </RouterLink>
    ),
    sorter: (a, b) =>
      a.other_data.client_name < b.other_data.client_name
        ? -1
        : a.other_data.client_name > b.other_data.client_name
        ? 1
        : 0,
    sortDirections: ['ascend', 'descend'],
    ellipsis: true,
  },
  {
    title: 'Member Status',
    dataIndex: 'member_status',
    render: (name, record) => record.other_data.member_status,
    filters: IntroStages.map((introStage) => {
      return { text: introStage, value: introStage };
    }),
    onFilter: (value, record) => record.other_data.member_status === value,
    key: 'member_status',
    width: 120,
  },
  {
    title: 'Client Status',
    dataIndex: 'client_status',
    render: (name, record) => record.other_data.client_status,
    filters: IntroStages.map((introStage) => {
      return { text: introStage, value: introStage };
    }),
    onFilter: (value, record) => record.other_data.client_status === value,
    key: 'client_status',
    width: 120,
  },
  {
    title: 'Intro Email Sent',
    dataIndex: 'intro_email_sent',
    width: 120,
    render: (name, record) => (record.other_data.intro_email_sent ? 'Yes' : 'No'),
    key: 'intro_email_sent',
    filters: [
      {
        text: 'Yes',
        value: 'Yes',
      },
      {
        text: 'No',
        value: 'No',
      },
    ],
    onFilter: (value, record) => (record.other_data.intro_email_sent ? 'Yes' : 'No') === value,
  },
  {
    title: 'Stale',
    dataIndex: 'is_stale',
    width: 60,
    render: (name, record) => (record.other_data.is_stale ? 'Yes' : 'No'),
    key: 'is_stale',
    filters: [
      {
        text: 'Yes',
        value: 'Yes',
      },
      {
        text: 'No',
        value: 'No',
      },
    ],
    onFilter: (value, record) => (record.other_data.is_stale ? 'Yes' : 'No') === value,
    defaultFilteredValue: ['No'],
  },
];

export { newIntroFormSchema, introsColumnConfig };
