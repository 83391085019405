import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Button, FilterSection, PageHeader, Content } from 'Components';
import CompanyList from './components/org-list';
import AddCompanyModal from './components/add-org-modal';
import { useStoreValue } from 'Context';
import { pullAndSetViews } from 'Helpers';

const Organizations = () => {
  const [views, setViews] = useState([]);
  const [currentView, setCurrentView] = useState({});
  const [defaultViewId, setDefaultViewId] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [{ user }] = useStoreValue();

  const entity = 'organization';

  useEffect(() => {
    pullAndSetViews(entity, user?.id, setViews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row>
        <Col
          span={5}
          style={{
            borderRight: '1px solid lightgrey',
          }}
        >
          <FilterSection
            entity={entity}
            views={views}
            setViews={setViews}
            currentView={currentView}
            setCurrentView={setCurrentView}
            defaultViewId={defaultViewId}
            setDefaultViewId={setDefaultViewId}
          />
        </Col>

        <Col
          span={19}
          style={{
            maxHeight: 'calc(100vh - 70px)',
            overflowY: 'scroll',
          }}
        >
          <PageHeader
            sticky
            title={'🏢  Organizations'}
            extra={[
              <Button
                key="add-new"
                size="middle"
                type="primary"
                onClick={() => setShowAddCompanyModal(true)}
              >
                <PlusOutlined /> Add new
              </Button>,
            ]}
          ></PageHeader>
          <Content>
            <CompanyList
              entity={entity}
              views={views}
              setViews={setViews}
              currentView={currentView}
              setCurrentView={setCurrentView}
              enableQuickSearch={false}
              setDefaultViewId={setDefaultViewId}
            />
          </Content>
        </Col>
      </Row>
      <AddCompanyModal showModal={showAddCompanyModal} setShowModal={setShowAddCompanyModal} />
    </div>
  );
};

export default Organizations;
