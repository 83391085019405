import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import './pending-forms.less';
import { userFeedbackSchema, sketchyAttendee } from 'Schemas/meeting-schema';
import './pending-forms.less';
import {
  Row,
  Form,
  Space,
  Typography,
  Radio,
  Col,
  message,
  Collapse,
  Popover,
  Tag,
  Badge,
  Checkbox,
  Alert,
  Modal,
  Tooltip,
} from 'antd';
import { Button } from 'Components';
import { generateFormFields, generateGroupOptions } from 'Helpers';
import { useDebounce } from 'Helpers/hooks';
import { updateEvent } from 'Api/event-service';
import { updateUser } from 'Api/user-service';
import { cloneDeep, isEmpty, isEqual, isNumber } from 'lodash';
import {
  StepForwardOutlined,
  UndoOutlined,
  EnvironmentOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
  DiffOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { toReadableDateFormat } from 'Utils';
import { isDate } from 'moment';
import { SecurityPolicy, Input } from 'Components';
import AddHuman from '../humans/components/add-human-modal';
import AddCompanyModal from '../organizations/components/add-org-modal';
import AddPortfolioModal from '../portfolios/components/add-portfolio-modal';
import { PlusOutlined } from '@ant-design/icons';

const { Title } = Typography;

const only_user_policy = {
  user_policies: [
    {
      user_id: 'You',
      policy: {
        read: true,
        write: true,
      },
    },
  ],
  role_policies: [],
};

const all_internal_policy = {
  user_policies: [],
  role_policies: [
    {
      role_table_name: 'Investment',
      policy: {
        read: true,
        write: true,
      },
    },
    {
      role_table_name: 'Principal',
      policy: {
        read: true,
        write: true,
      },
    },
  ],
};

const GeneratePendingForm = ({ eventData, onFormSubmit, viewOnly = false, orgDomains = {} }) => {
  const [meetingHappened, setMeetingHappened] = useState(true);
  const [meetingIsPersonal, setMeetingIsPersonal] = useState(false);
  const [isConfidential, setIsConfidential] = useState(undefined);

  const [meetingAbout, setMeetingAbout] = useState(undefined);
  const [meetingSource, setMeetingSource] = useState(undefined);

  const [security, setSecurity] = useState(all_internal_policy);
  const [humanModalShowing, setHumanModalShowing] = useState(false);
  const [orgModalShowing, setOrgModalShowing] = useState(false);
  const [portfolioModalShowing, setPortfolioModalShowing] = useState(false);
  const [curAttendeeId, setCurAttendeeId] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  const eventMeta = useRef({});
  const [meetingForm] = Form.useForm();
  const { Panel } = Collapse;
  const {
    id: meetingId,
    title,
    other_data: { attendees, end_time, location },
    destination_ids,
    meta,
    feedback_provider_id,
    latest_attendee_events = [],
    attendee_current_info = {},
  } = eventData;
  const [activePanels, setActivePanels] = useState([...destination_ids]);
  const [referral, setReferral] = useState(null);
  const [errorSummary, setErrorSummary] = useState(null);
  const [copyClicked, setCopyClicked] = useState(false);

  useEffect(async () => {
    setFormDisabled(viewOnly);
  }, []);

  useEffect(() => {
    const savedForm = feedback_provider_id in meta ? meta[feedback_provider_id] : {};
    if ('meeting_happened' in savedForm) {
      setMeetingHappened(savedForm['meeting_happened'] === 'Yes');
    }
    if ('meeting_is_personal' in savedForm) {
      setMeetingIsPersonal(savedForm['meeting_is_personal'] === 'Yes');
    }
    if ('meeting_about' in savedForm) {
      setMeetingAbout(savedForm['meeting_about']);
    }
    if ('meeting_source' in savedForm) {
      setMeetingSource(savedForm['meeting_source']);
    }
    if ('activePanels' in savedForm) {
      setActivePanels(savedForm['activePanels']);
    }
    if ('referral' in savedForm) {
      setReferral(savedForm['referral']);
    }
    eventMeta.current = savedForm;
    meetingForm.setFieldsValue(eventMeta.current);
    updateSecurityPolicy(
      meetingForm.getFieldValue('is_confidential'),
      attendees,
      meetingForm.getFieldValue('selected_departments')
    );
  }, [meta]);

  let meetingTime = new Date(end_time).toLocaleString('en-US', {
    timeZone: 'America/Los_Angeles',
    weekday: 'long',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  });

  const handleRadioChange = (radioValue, radioField) => {
    const val = radioValue === 'Yes' ? true : false;
    if (radioField === 'meeting_happened') {
      setMeetingHappened(val);
    } else if (radioField === 'is_personal') {
      setMeetingIsPersonal(val);
    } else if (radioField === 'meeting_about') {
      meetingForm.resetFields(['meeting_source', 'orgs_source', 'referral']);
      setMeetingSource(undefined);
      setMeetingAbout(radioValue);
    } else if (radioField === 'meeting_source') {
      meetingForm.resetFields(['orgs_source']);
      setMeetingSource(radioValue);
    }
  };

  const getPrefillValues = (attendeeId, fieldName) => {
    let attendee_event = latest_attendee_events.find(
      (event) => event.destination_id === attendeeId
    );
    if (!attendee_event) return;
    let { other_data: { [fieldName]: fieldValue } = {} } = attendee_event;
    return fieldValue;
  };

  const getFormattedLabel = (attendeeId, currentLabel) => {
    let attendee_event = latest_attendee_events.find(
      (event) => event.destination_id === attendeeId
    );
    if (!attendee_event) return currentLabel;
    const isValidDate = isDate(new Date(attendee_event?.updated_at));
    if (isValidDate) {
      return (
        <>
          {currentLabel}
          <span style={{ marginLeft: 12, fontSize: 14, color: '#808080', fontStyle: 'italic' }}>
            ( Last updated on {toReadableDateFormat(attendee_event?.updated_at)} )
          </span>
        </>
      );
    }
    return currentLabel;
  };

  const getUpdatedUserSchema = () => {
    return attendees.map((participant, index) => {
      if (!isEmpty(participant.email) && participant.email.includes('@human.capital')) return;
      const participantId =
        !isNumber(participant.id) && isEmpty(participant.id)
          ? destination_ids[index]
          : participant.id;
      // check if website exists by their email
      const domain = !isEmpty(participant.email) ? participant.email.split('@').pop() : null;
      let getDomainFromEmail = orgDomains[domain];

      const hydratedFeedback = userFeedbackSchema.map((schema) => {
        let { name, conditional_items, label } = schema;
        let conditionalCopies = cloneDeep(conditional_items);
        const updatedName = `${name}_${participantId}_meeting_${meetingId}`;
        if (conditionalCopies?.length) {
          conditionalCopies = conditionalCopies.map((conditionalCopy) => {
            if (conditionalCopy) {
              conditionalCopy['parent_key'] = updatedName;
              conditionalCopy['schema_name'] = conditionalCopy['name'];
              conditionalCopy[
                'name'
              ] = `${conditionalCopy['name']}_${participantId}_meeting_${meetingId}`;
              conditionalCopy['when_to_update'] = conditionalCopy['when_to_update'](updatedName);
              conditionalCopy['when_to_show'] = conditionalCopy['when_to_show'](updatedName);

              if (getDomainFromEmail && conditionalCopy['name'].includes('org_association')) {
                const { domain, id: orgId } = getDomainFromEmail;
                conditionalCopy['initial_value'] = { label: domain, value: orgId };
              }
            }
            // could have gone the recursive way but for now
            // we expect only 2 level of conditional questions, hence repetitive stuff
            if (conditionalCopy?.['conditional_item']) {
              let nestedCondition = conditionalCopy['conditional_item'];
              if ('canAddNewEntity' in nestedCondition) {
                nestedCondition['canAddNewEntity'] = () => setHumanModalShowing(!humanModalShowing);
              }
              nestedCondition['parent_key'] = conditionalCopy['name'];
              nestedCondition['schema_name'] = nestedCondition['name'];
              nestedCondition[
                'name'
              ] = `${nestedCondition['name']}_${participantId}_meeting_${meetingId}`;
              nestedCondition['when_to_update'] = nestedCondition['when_to_update'](
                conditionalCopy['name']
              );
              nestedCondition['when_to_show'] = nestedCondition['when_to_show'](
                conditionalCopy['name']
              );
              nestedCondition['disabled'] = formDisabled;
              conditionalCopy['conditional_item'] = nestedCondition;
            }
            if (conditionalCopy['name'].includes('org_association')) {
              conditionalCopy['notFoundOverrideContent'] = (
                <div
                  onClick={() => {
                    'search_entity' in conditionalCopy &&
                    conditionalCopy['search_entity'] === 'portfolio'
                      ? setPortfolioModalShowing(!portfolioModalShowing)
                      : setOrgModalShowing(!orgModalShowing);
                    setCurAttendeeId(participantId);
                  }}
                >
                  <Button size="small" icon={<PlusOutlined />} type="link" disabled={formDisabled}>
                    Org does not exist yet. Click to add it!
                  </Button>
                </div>
              );
            }
            conditionalCopy['disabled'] = formDisabled;
            return conditionalCopy;
          });
        }
        let initialValue;
        let updatedLabel = label;

        if (name.includes('meeting_with')) {
          initialValue = getPrefillValues(participantId, name);
          if (initialValue) {
            // formatted labels only for prefilled values
            updatedLabel = getFormattedLabel(participantId, label);
          }
          // set initial value for conditional item
          if (conditionalCopies.length) {
            conditionalCopies = conditionalCopies.map((conditionalItem) => {
              let conditionalValue = getPrefillValues(
                participantId,
                conditionalItem['schema_name']
              );
              if (conditionalValue) {
                let updatedConditionalLabel = getFormattedLabel(
                  participantId,
                  conditionalItem.label
                );
                conditionalItem['initial_value'] = conditionalValue;
                conditionalItem['label'] = updatedConditionalLabel;
              }
              return conditionalItem;
            });
          }
        }
        let participantFeedback = {
          ...schema,
          name: updatedName,
          key: updatedName,
          conditional_item: conditionalCopies,
          required: activePanels.includes(participantId) && schema.required,
          initial_value: initialValue,
          label: updatedLabel,
          disabled: formDisabled,
        };

        return participantFeedback;
      });

      if (participant.can_set_security) {
        hydratedFeedback.push({
          label: (
            <>
              Is this attendee confidential?
              <Tooltip title={`Who will be able to see this attendee's profile?`}>
                <InfoCircleOutlined style={{ marginLeft: '10px' }} />
              </Tooltip>
            </>
          ),
          key: `security_${participantId}`,
          name: `security_${participantId}`,
          required: false,
          disabled: formDisabled,
          span: 24,
          type: 'radio-group',
          allowed_values: [
            {
              display_hint_primary: 'Private to user',
              value: 'private_to_user',
            },
            {
              display_hint_primary: 'Private to attendees',
              value: 'private_to_attendees',
            },
            {
              display_hint_primary: 'Private to my department',
              value: 'private_to_departments',
            },
            {
              display_hint_primary: 'Public to all company',
              value: 'public',
            },
          ],
          conditional_item: [
            {
              label: 'Departments',
              name: `security_departments_${participantId}`,
              type: 'select',
              multi_valued: true,
              span: 24,
              required: true,
              allowed_values: [
                {
                  display_hint_primary: 'Investment',
                  value: 'investment',
                },
                {
                  display_hint_primary: 'Principal',
                  value: 'principal',
                },
              ],
              parent_key: `security_${participantId}`,
              when_to_show: (getFieldValue) => {
                return getFieldValue(`security_${participantId}`) === 'private_to_departments';
              },
              when_to_update: (prevValue, newValue) =>
                prevValue[`security_${participantId}`] !== newValue[`security_${participantId}`],
              disabled: formDisabled,
            },
          ],
        });
      }

      const handleSkipAttendee = () => {
        // below schema to filter if attendee fields have any data
        const userFeedbackSchemaNames = [
          'meeting_with',
          'interested_portfolio',
          'org_association',
          'attendee_notes',
          'security',
        ];
        let attendeeLatestData = latest_attendee_events?.find(
          (event) => event.destination_id === participantId
        );
        const currentFormData = meetingForm.getFieldsValue();
        const newActivePanels = activePanels.filter((panelKey) => panelKey !== participantId);
        // setActivePanels(newActivePanels);
        let newMeta = { ...eventMeta.current };
        // any data present in skipped user then inject formdata
        // since it holds initial value/latest value set for the field
        newMeta = { ...currentFormData, ...newMeta };
        // newMeta['activePanels'] = newActivePanels;
        const changedFields = [];
        const filterAttendeeFields = userFeedbackSchemaNames.reduce((acc, fieldName) => {
          const updatedFieldName = `${fieldName}_${participantId}_meeting_${meetingId}`;
          if (currentFormData[updatedFieldName]) {
            acc[fieldName] = currentFormData[updatedFieldName];
          }
          return acc;
        }, {});

        const confirmSkipAttendee = () =>
          Modal.confirm({
            title: 'Are you sure you want to skip this attendee',
            icon: <ExclamationCircleFilled />,
            content: 'Field changes to this attendee will be lost',
            okText: 'Yes Skip',
            cancelText: 'Cancel',
            onOk() {
              // clear all fields
              for (const [k] of Object.entries(newMeta)) {
                if (k.includes(participantId)) {
                  newMeta[k] = '';
                  changedFields.push({ name: [k], value: '' });
                }
              }
              setActivePanels(newActivePanels);
              newMeta['activePanels'] = newActivePanels;
              meetingForm.setFieldsValue(newMeta);
              return debouncedFieldsChange(changedFields);
            },
          });
        // case 1:
        // latest info absent but user modified some fields
        if (!attendeeLatestData && Object.keys(filterAttendeeFields).length) {
          return confirmSkipAttendee();
        }
        if (attendeeLatestData) {
          let anyFieldModified = !Object.keys(filterAttendeeFields).every((key) =>
            isEqual(attendeeLatestData['other_data'][key], filterAttendeeFields[key])
          );
          // case 2:
          // latest info exists but user also made some modification
          if (anyFieldModified) {
            return confirmSkipAttendee();
          }
        }
        // case 3:
        // updates value as it is
        setActivePanels(newActivePanels);
        meetingForm.setFieldsValue(newMeta);
        changedFields.push({ name: ['activePanels'], value: newActivePanels });
        return debouncedFieldsChange(changedFields);
      };

      const handleUndoSkipAttendee = () => {
        const newActivePanels = [...activePanels, participantId];
        setActivePanels(newActivePanels);
        debouncedFieldsChange([{ name: ['activePanels'], value: newActivePanels }]);
      };

      const handleCopyAttendee = (id) => {
        // grab all questions here
        let copiedVals = Object.entries(meetingForm.getFieldsValue(''));
        copiedVals = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          copiedVals.filter(([key, val]) => key.includes(id.toString()) && !key.includes('|'))
        );

        const newMeta = { ...eventMeta.current };
        for (const key in copiedVals) {
          const ind = key.search(/[0-9]/);
          const label = key.slice(0, ind) + 'copy';
          newMeta[label] = copiedVals[key];
        }

        eventMeta.current = newMeta;
        setCopyClicked(true);
      };

      const handlePasteAttendee = (id) => {
        const curMeta = { ...eventMeta.current };
        let copyKeys = Object.keys(curMeta).filter((key) => key.includes('copy'));
        copyKeys = copyKeys.map((key) => [key, curMeta[key]]);
        // replace 'x_copy' with 'x_attendeeId_meetingId'
        const pasteEntries = copyKeys.map((key) => [
          key[0].replace(
            'copy',
            key[0].includes('security')
              ? `${id.toString()}`
              : `${id.toString()}_meeting_${meetingId}`
          ),
          key[1],
        ]);
        const updatedFields = [];
        for (let k = 0; k < pasteEntries.length; k++) {
          updatedFields.push({ name: [pasteEntries[k][0]], value: pasteEntries[k][1] });
        }
        debouncedFieldsChange(updatedFields);
        meetingForm.setFieldsValue(Object.fromEntries(pasteEntries));
      };

      const missingFields = () => {
        const attendeeId = participantId;
        const currentUserInfo = attendee_current_info[attendeeId];
        if (!currentUserInfo) return [];
        const fieldNames = sketchyAttendee.map((field) => field.name);
        return Object.keys(currentUserInfo).filter((field) => {
          if (
            fieldNames.includes(field) &&
            (currentUserInfo[field] === '#MISSINGVALUE' ||
              currentUserInfo[field] === '' ||
              !currentUserInfo[field] ||
              (field === 'first_name' && currentUserInfo[field].includes('@')))
          )
            return true;
          return false;
        });
      };

      const getMissingFieldSchema = () => {
        const missingFieldsList = missingFields();
        let result = sketchyAttendee
          .filter(({ name }) => missingFieldsList.includes(name))
          .map((field) => {
            return {
              ...field,
              name: `${field.name}|${participantId}`,
              disabled: formDisabled,
            };
          });
        return result;
      };

      const getDisplayName = () => {
        const attendeeId = participantId;
        const attendeeInfo = attendee_current_info[attendeeId];

        if (attendeeInfo) {
          const { first_name, last_name } = attendeeInfo;
          if (first_name && first_name !== '#MISSINGVALUE') {
            return `${first_name} ${last_name}`;
          }
        }

        return (
          (participant?.displayName?.trim() !== '#MISSINGVALUE' && participant.displayName) ||
          participant.email ||
          participant.phoneNumber
        );
      };

      return (
        <Collapse
          collapsible="disabled"
          defaultActiveKey={[...activePanels]}
          activeKey={activePanels}
          key={index}
          className="attendees-collapse"
        >
          <Panel
            showArrow={false}
            style={{
              overflow: 'auto',
              display: 'block',
            }}
            extra={
              <div>
                {activePanels.includes(participantId) ? (
                  <Button
                    onClick={() => handleCopyAttendee(participantId)}
                    size="small"
                    type="link"
                    disabled={formDisabled}
                  >
                    <CopyOutlined /> Copy
                  </Button>
                ) : null}
                {copyClicked && activePanels.includes(participantId) ? (
                  <Button
                    onClick={() => handlePasteAttendee(participantId)}
                    size="small"
                    type="link"
                    disabled={formDisabled}
                  >
                    <DiffOutlined /> Paste
                  </Button>
                ) : null}
                {activePanels.includes(participantId) ? (
                  <Button
                    onClick={handleSkipAttendee}
                    size="small"
                    type="link"
                    disabled={formDisabled}
                  >
                    <StepForwardOutlined /> Skip Feedback
                  </Button>
                ) : (
                  <Button
                    onClick={handleUndoSkipAttendee}
                    size="small"
                    type="link"
                    disabled={formDisabled}
                  >
                    <UndoOutlined /> Undo Skip
                  </Button>
                )}
              </div>
            }
            header={
              <h3 className="attendee-title">
                🧑‍💼 {getDisplayName()}
                {getDomainFromEmail && 'domain' in getDomainFromEmail ? (
                  <Tag color={'green'} style={{ marginLeft: '15px' }}>
                    {getDomainFromEmail['domain']}
                  </Tag>
                ) : null}
                {activePanels.includes(participantId) ? null : (
                  <span style={{ zIndex: 2, fontSize: 14, fontWeight: 400, color: '#737373' }}>
                    (Feedback Skipped)
                  </span>
                )}
              </h3>
            }
            key={participantId}
            collapsible={formDisabled ? 'disabled' : 'header'}
          >
            <div key={`row_${participantId}_meeting_${meetingId}`}>
              {missingFields()?.length > 0 ? (
                <Badge.Ribbon color={'#69e5e3'} style={{ top: 0, right: 1 }} text="Optional Info">
                  <Row gutter={12} className="missing-info">
                    {generateFormFields(getMissingFieldSchema(), meetingForm)}
                  </Row>
                </Badge.Ribbon>
              ) : null}
              <Row style={{ margin: '16px' }}>
                {generateFormFields(hydratedFeedback, meetingForm)}
              </Row>
            </div>
          </Panel>
        </Collapse>
      );
    });
  };

  const handleFormSubmit = async (values) => {
    for (let key in values) {
      if (
        (key.includes('org_association') || key.includes('interested_portfolio')) &&
        typeof values[key] !== 'object'
      ) {
        message.error('Org Association Invalid. Please check that you tagged a valid company!');
        return null;
      }
    }
    onFormSubmit({
      ...values,
      location: location,
      meeting_id: meetingId,
      participant_ids: destination_ids,
      meeting_title: title,
    });
  };

  const updateSecurityPolicy = (is_confidential, attendees, selected_departments = []) => {
    setIsConfidential(is_confidential);

    if (is_confidential == 'private_to_user') {
      setSecurity(only_user_policy);
    } else if (is_confidential == 'public') {
      setSecurity(all_internal_policy);
    } else if (is_confidential == 'private_to_attendees') {
      const user_policies = [];

      attendees.forEach((attendee) => {
        if (attendee.is_internal)
          user_policies.push({
            user_id: attendee.full_name || attendee.displayName || attendee.email,
            policy: {
              read: true,
              write: true,
            },
          });
      });

      setSecurity({
        user_policies,
        role_policies: [],
      });
    } else if (is_confidential == 'private_to_departments') {
      const role_policies = selected_departments.map((dept) => {
        return {
          role_table_name: dept,
          policy: {
            read: true,
            write: true,
          },
        };
      });
      setSecurity({
        user_policies: [],
        role_policies,
      });
    }
  };

  const updateAttendeeInfo = (changedFields) => {
    try {
      for (const field of changedFields) {
        let [fieldName, userIdStr] = field.name[0]?.split('|');
        let userId = parseInt(userIdStr);
        if (sketchyAttendee.map(({ name }) => name).includes(fieldName) && userId) {
          if (fieldName === 'linkedins' && field.value?.includes('/in/')) {
            let username = field.value.substring(field.value.indexOf('/in/') + '/in/'.length);
            if (username.includes('/')) {
              username = username.substring(0, username.indexOf('/'));
            }
            field.value = username;
          }
          updateUser(
            userId,
            {
              [fieldName]: field.value,
            },
            {
              make_event: false,
            }
          );
        }
      }
    } catch (err) {
      console.log(err);
      message.error(err?.message || 'Something went wrong ');
    }
  };

  const debouncedFieldsChange = useDebounce(async (changedFields, allFields) => {
    try {
      // don't auto save when submit clicked
      if (!changedFields.length) return;
      // this updates missing user fields
      await updateAttendeeInfo(changedFields);
      const newMeta = { ...meta };
      newMeta[feedback_provider_id] = eventMeta.current;
      for (const field of changedFields) {
        newMeta[feedback_provider_id][field.name[0]] = field.value;
      }
      await updateEvent(meetingId, { ...eventData, meta: newMeta });
      updateSecurityPolicy(
        allFields?.find((fields) => fields.name.includes('is_confidential'))?.value,
        attendees,
        allFields?.find((fields) => fields.name.includes('selected_departments'))?.value
      );
      message.destroy();
      message.success('Update Saved');
      eventMeta.current = newMeta[feedback_provider_id];
    } catch (err) {
      message.error(err.message);
    }
  }, 500);

  const updateReferral = (newValue) => {
    meetingForm.setFieldsValue({
      ['referral']: [newValue],
    });
    setReferral([newValue]);
  };

  const updateOrgAssociation = (newValue) => {
    meetingForm.setFieldsValue({
      [`org_association_${curAttendeeId}_meeting_${meetingId}`]: newValue,
    });

    const updatedFields = [
      { name: [`org_association_${curAttendeeId}_meeting_${meetingId}`], value: newValue },
    ];
    debouncedFieldsChange(updatedFields);
  };

  const showErrorMessage = ({ errorFields }) => {
    if (errorFields) {
      let errors = errorFields.map(({ errors }) => errors.join(' | ')).join('\n');
      return setErrorSummary(errors);
    }
    return;
  };

  return (
    <>
      <Form
        form={meetingForm}
        layout="vertical"
        onFinish={handleFormSubmit}
        onFinishFailed={showErrorMessage}
        preserve={false}
        className="meeting-feedback-form"
        onFieldsChange={debouncedFieldsChange}
      >
        <Row>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Title level={3} style={{ color: '#737373' }}>
              🤝🏽 {title}
            </Title>
            <div style={{ fontWeight: 400, fontSize: 18 }}>
              {location ? (
                <span>
                  <EnvironmentOutlined /> {location}
                  {' - '}
                </span>
              ) : null}
              <span>ended on</span> <br />
              <span className="primary-color">{meetingTime}</span>
            </div>
          </Col>
        </Row>
        <br />
        <Form.Item
          name="meeting_is_personal"
          label="Is this a personal meeting? (Non Work Related)"
          initialValue={meetingIsPersonal ? 'Yes' : 'No'}
          tooltip={{
            title: `Non work related event like doctor's appointment or personal meetings
            where you wish feedback to never be requested again with the attendees in this meeting.`,
            icon: <InfoCircleOutlined />,
          }}
          required={true}
        >
          <Radio.Group
            key={`meeting_is_personal_${meetingId}`}
            onChange={({ target }) => handleRadioChange(target?.value, 'is_personal')}
            size="large"
            disabled={formDisabled}
          >
            <Radio value="Yes">Yes</Radio>
            <Radio value="No">No</Radio>
          </Radio.Group>
        </Form.Item>

        {!(meetingIsPersonal == undefined || meetingIsPersonal) ? (
          <>
            <Form.Item
              name="meeting_happened"
              label="Did you attend this meeting?"
              initialValue={meetingHappened ? 'Yes' : 'No'}
              required={true}
            >
              <Radio.Group
                key={`meeting_happened_${meetingId}`}
                onChange={({ target }) => handleRadioChange(target?.value, 'meeting_happened')}
                size="large"
                disabled={formDisabled}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </Form.Item>
            {meetingHappened && !meetingIsPersonal ? (
              <>
                <Form.Item
                  name="is_confidential"
                  label={
                    <>
                      Is this meeting confidential?
                      <Popover
                        content={
                          <SecurityPolicy
                            user_policies={security.user_policies}
                            role_policies={security.role_policies}
                          />
                        }
                        placement="bottom"
                      >
                        <QuestionCircleOutlined style={{ color: '#6c78d5', marginLeft: '10px' }} />
                      </Popover>
                    </>
                  }
                  rules={[{ required: true, message: 'Confidentiality is required' }]}
                >
                  <Radio.Group size="large" disabled={formDisabled}>
                    <Space direction="vertical">
                      <Radio value={'private_to_user'}>Private to me</Radio>
                      <Radio value={'private_to_attendees'}>Private to attendees</Radio>
                      <Radio value={'private_to_departments'}>Private to my department</Radio>
                      <Radio value={'public'}>Public to all company</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {isConfidential == 'private_to_departments' ? (
                  <>
                    <Form.Item
                      name="selected_departments"
                      label="Select departments"
                      required={true}
                    >
                      <Checkbox.Group disabled={formDisabled}>
                        <Space direction="vertical">
                          {generateGroupOptions(
                            [
                              { display_hint_primary: 'Investment', value: 'investment' },
                              {
                                display_hint_primary: 'Talent',
                                value: 'principal',
                              },
                            ],
                            'checkbox'
                          )}
                        </Space>
                      </Checkbox.Group>
                    </Form.Item>
                  </>
                ) : null}

                <Form.Item
                  name="meeting_about"
                  label="Meeting type?"
                  rules={[
                    {
                      required: true,
                      message: 'Meeting type? is required',
                    },
                  ]}
                >
                  <Radio.Group
                    key={`meeting_about`}
                    onChange={({ target }) => handleRadioChange(target?.value, 'meeting_about')}
                    size="large"
                    disabled={formDisabled}
                  >
                    <Space direction="vertical">
                      <Radio value="First Meeting">First Meeting</Radio>
                      <Radio value="Regularly Scheduled Meeting">Regularly Scheduled Meeting</Radio>
                      <Radio value="Inbound catch-up">Inbound catch-up</Radio>
                      <Radio value="Outbound catch-up">Outbound catch-up</Radio>
                      <Radio value="Interview">Interview</Radio>
                      <Radio value="Ad-hoc Meeting">Ad-hoc Meeting</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                {meetingAbout == 'First Meeting' ? (
                  <>
                    <Form.Item
                      name="meeting_source"
                      label="What was the source of the meeting?"
                      required={true}
                    >
                      <Radio.Group
                        key={`meeting_source`}
                        onChange={({ target }) =>
                          handleRadioChange(target?.value, 'meeting_source')
                        }
                        size="large"
                        disabled={formDisabled}
                      >
                        <Space direction="vertical">
                          <Radio value="Referral">Referral</Radio>
                          <Radio value="Cold outreach">Cold outreach</Radio>
                          <Radio value="Inbound">Inbound</Radio>
                          <Radio value="Portfolio company">Portfolio company</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                    {meetingSource == 'Referral' ? (
                      <>
                        {generateFormFields([
                          {
                            label: 'Search & select from Humans database',
                            required: true,
                            name: 'referral',
                            type: 'search',
                            multi_valued: false,
                            search_entity: 'user',
                            span: 24,
                            parent_key: 'meeting_source',
                            when_to_update: (dynamicKey) => (prevValues, currentValues) =>
                              prevValues[dynamicKey] !== currentValues[dynamicKey],
                            when_to_show: (dynamicKey) => (getFieldValue) =>
                              getFieldValue(dynamicKey) === 'Referral',
                            // this stores the label as well as value
                            // else existing data are shown as ids onload
                            labelInValue: true,
                            value: referral,
                            setValue: updateReferral,
                            // to avoid storing html node skipEmail is true
                            skipEmail: true,
                            notFoundOverrideContent: (
                              <div onClick={() => setHumanModalShowing(!humanModalShowing)}>
                                <Button size="small" icon={<PlusOutlined />} type="link">
                                  User does not exist yet. Click to add them!
                                </Button>
                              </div>
                            ),
                            disabled: formDisabled,
                          },
                        ])}
                      </>
                    ) : null}
                    {meetingSource == 'Portfolio company' ? (
                      <>
                        {generateFormFields([
                          {
                            // will be displayed only when to_show is true
                            // using curry function to inject dynamic keys
                            label: 'Which Portfolio companies?',
                            name: 'orgs_source',
                            type: 'search',
                            required: true,
                            multi_valued: true,
                            parent_key: 'meeting_source',
                            search_entity: 'portfolio',
                            span: 24,
                            when_to_update: (dynamicKey) => (prevValues, currentValues) =>
                              prevValues[dynamicKey] !== currentValues[dynamicKey],
                            when_to_show: (dynamicKey) => (getFieldValue) =>
                              getFieldValue(dynamicKey) === 'HC Engagement - Candidate',
                            hasSchoolAndCampusOrg: false,
                            labelInValue: true,
                            disabled: formDisabled,
                          },
                        ])}
                      </>
                    ) : null}
                  </>
                ) : null}
                <Form.Item name="meeting_notes" label="Meeting Notes">
                  <Input
                    key={`meeting_notes_${meetingId}`}
                    allowClear
                    type="textarea"
                    placeholder="The meeting went ..."
                    disabled={formDisabled}
                  />
                </Form.Item>
                {getUpdatedUserSchema()}
              </>
            ) : null}
            <br />
          </>
        ) : null}
        {errorSummary ? (
          <Alert
            style={{ whiteSpace: 'pre-line' }}
            message="Error"
            closable
            onClose={() => setErrorSummary(null)}
            description={errorSummary}
            type="error"
          />
        ) : null}
        <Row justify="space-between" style={{ marginTop: 15 }}>
          <Space>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={
                formDisabled ||
                (meetingHappened === undefined &&
                  (meetingIsPersonal === undefined || !meetingIsPersonal))
              }
            >
              {formDisabled ? 'Submitted' : 'Submit'}
            </Button>
          </Space>
        </Row>

        <AddHuman
          showModal={humanModalShowing}
          setShowModal={setHumanModalShowing}
          redirect={false}
          onSubmit={updateReferral}
          setResult={updateReferral}
        />
        <AddCompanyModal
          showModal={orgModalShowing}
          setShowModal={setOrgModalShowing}
          redirect={false}
          setResult={updateOrgAssociation}
        />
        <AddPortfolioModal
          showModal={portfolioModalShowing}
          setShowModal={setPortfolioModalShowing}
          redirect={false}
          setResult={updateOrgAssociation}
        />
      </Form>
    </>
  );
};

GeneratePendingForm.propTypes = {
  onFormSubmit: PropTypes.func,
  eventData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    destination_ids: PropTypes.array,
    feedback_provider_id: PropTypes.number,
    latest_attendee_events: PropTypes.array,
    other_data: PropTypes.shape({
      location: PropTypes.string,
      attendees: PropTypes.array,
      end_time: PropTypes.string,
    }),
    meta: PropTypes.object,
    attendee_current_info: PropTypes.shape({}),
  }),
  viewOnly: PropTypes.bool,
  orgDomains: PropTypes.object,
};

export default GeneratePendingForm;
