import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import './content.less';
import cx from 'classnames';

const { Content: AntdContent } = Layout;

const Content = ({ spacing = 'small', className, ...rest }) => {
  return <AntdContent className={cx(className, `spacing-${spacing}`)} {...rest}></AntdContent>;
};

Content.propTypes = {
  spacing: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
};

Content.defaultProps = {
  spacing: 'small',
  className: null,
};

export default Content;
