import React from 'react';
import PropTypes from 'prop-types';
import { Select, Spin, Row, Col } from 'antd';
import debounce from 'lodash/debounce';
import { searchEntities } from 'Api/common-service';
import { quickSearchByEntity } from 'Api/common-service';

const DebounceSelect = ({
  fetchOptions,
  debounceTimeout = 400,
  notFoundOverride = null,
  ...props
}) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      setValue(value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      filterOption={false}
      onFocus={debounceFetcher}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : value?.length ? notFoundOverride : null}
      {...props}
      options={options}
    />
  );
};

const fetchEntityList = async (
  query,
  entity,
  skipEmail,
  hasSchoolAndCampusOrg = true,
  onlyInternalHumans = false
) => {
  try {
    if (query) {
      if (entity === 'portfolio') {
        let { data: portfolioData } = await quickSearchByEntity(query, entity);
        return portfolioData.map((item) => {
          return {
            label: item['name'],
            value: item.id,
          };
        });
      }
      let { data = {} } = await searchEntities(query, {
        hasSchoolAndCampusOrg,
        onlyInternalHumans,
      });
      return data[entity]?.map((item) => {
        if (entity === 'user') {
          const { first_name, last_name = '', emails } = item;
          const primaryEmailObj = emails.find((email) => email.is_primary);
          if (skipEmail) {
            // in nestedFormList, select search sends the label value
            // to avoid sending react node we use skipEmail flag
            return {
              label: `${first_name} ${last_name}`,
              value: item.id,
            };
          }
          return {
            label: (
              <Row justify="space-between">
                <Col>{`${first_name} ${last_name} `}</Col>
                <Col>{primaryEmailObj?.email || ''}</Col>
              </Row>
            ),
            value: item.id,
          };
        }
        return {
          label: item['name'],
          value: item.id,
        };
      });
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

const SelectSearch = ({
  search_entity: searchEntity,
  value,
  setValue,
  skipEmail,
  hasSchoolAndCampusOrg = true,
  onlyInternalHumans = false,
  ...rest
}) => {
  return (
    <DebounceSelect
      showSearch
      value={value}
      onSelect={setValue}
      fetchOptions={(query) =>
        fetchEntityList(query, searchEntity, skipEmail, hasSchoolAndCampusOrg, onlyInternalHumans)
      }
      {...rest}
    />
  );
};

SelectSearch.propTypes = {
  search_entity: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  skipEmail: PropTypes.bool,
  hasSchoolAndCampusOrg: PropTypes.bool,
  onlyInternalHumans: PropTypes.bool,
};

DebounceSelect.propTypes = {
  fetchOptions: PropTypes.func,
  debounceTimeout: PropTypes.number,
  notFoundOverride: PropTypes.any,
};

export default SelectSearch;
