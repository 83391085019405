import React from 'react';
import PropTypes from 'prop-types';
import { Modal, List, Row, Col, Space, Typography, Divider } from 'antd';
import { Button } from 'Components';
import { UsergroupDeleteOutlined, ControlOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
const { Title, Paragraph } = Typography;

const DedupeModal = ({ showModal, setShowModal, duplicates = [] }) => {
  const history = useHistory();

  // Disabling the autoMerge feature as we build confidence in dedup service
  // const autoMergeDuplicates = async (dupeId) => {
  //   try {
  //     let { data: duplicates } = await getDuplicatesByDupeId(dupeId);
  //     let newMergedUser = duplicates[0]['new_user'];
  //     let { data: newUserId } = await resolveDuplicates(dupeId, newMergedUser);
  //     message.success('Resolved', 1).then(() => {
  //       message
  //         .loading('Generating profile for new user', 2)
  //         .then(() =>
  //           message
  //             .info('Redirecting to new profile')
  //             .then(() => history.push(`/human/${newUserId}`))
  //         );
  //     });
  //   } catch (error) {
  //     message.error(error.message || 'Something went wrong');
  //   }
  // };

  return (
    <Modal
      title="👨‍👦‍👦  Duplicate Records"
      visible={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      footer={false}
      width={650}
    >
      <List
        size="small"
        dataSource={duplicates}
        renderItem={({ dupe_id, first_name, last_name, user_id }) => (
          <List.Item>
            <Row justify="space-between" style={{ width: '100%' }}>
              <Col>
                <Link
                  style={{ marginRight: 20, fontSize: 16 }}
                  target={'_blank'}
                  to={`/human/${user_id}`}
                >
                  <UsergroupDeleteOutlined /> {`${first_name} ${last_name}`}
                </Link>
              </Col>
              <Col>
                <Space size="small">
                  <Button
                    onClick={() => history.push(`/merge/humans?dupe_id=${dupe_id}`)}
                    size="default"
                    type="primary"
                  >
                    <ControlOutlined /> Manual Dedupe
                  </Button>
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
      <Divider />
      <Typography>
        <Title level={4}>Manual Dedupe</Title>
        <Paragraph>
          Compare two potential human records and, if they do represent the same human, preview and
          merge the corresponding data points into one human record.
        </Paragraph>
      </Typography>
    </Modal>
  );
};

DedupeModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  duplicates: PropTypes.array,
};

export default DedupeModal;
